import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import { setupListeners } from '@reduxjs/toolkit/query/react'
import {propertiesReducer} from "./properties/reducers";
import {propertyReducer} from "./property/reducers";
import {usersReducer} from "./users/reducers";
import {rulesReducer} from "./rules/reducers";
import {rolesReducer} from "./roles/reducers";
import {adminsReducer} from "./admin/reducers";
import {securitiesReducer} from "./security/reducers";
import {sessionsReducer} from "./sessions/reducers";
import {transactionsReducer} from "./transactions/reducers";
import {masterDataReducer} from "./masterdata/reducers";
import {loansReducer} from "./loan/reducers";
import { educationMaterialsReducer } from "./education-materials/reducers";
import { propertyTeaserApi } from '../services/property-teaser';
import { educationMaterialGroupsApi } from '../services/education-material-groups';

const rootReducer = combineReducers({
  properties: propertiesReducer,
  property: propertyReducer,
  users: usersReducer,
  rules: rulesReducer,
  roles: rolesReducer,
  admins: adminsReducer,
  securities: securitiesReducer,
  sessions: sessionsReducer,
  transactions: transactionsReducer,
  masterData: masterDataReducer,
  loans: loansReducer,
  educationMaterials: educationMaterialsReducer,

  [propertyTeaserApi.reducerPath]: propertyTeaserApi.reducer,
  [educationMaterialGroupsApi.reducerPath]: educationMaterialGroupsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(
      propertyTeaserApi.middleware,
      educationMaterialGroupsApi.middleware,
    );
  },
})

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch

export default store
