import React, { ChangeEvent, memo } from 'react';
import { TagDescription, fieldTagsColors } from '../constants/fields-tags';

interface MultipleSelecetProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => any;
  disabled?: boolean;
  selected: Array<string | null>;
  label?: string;
  options: Array<{ label: string; value: string | null }>;
  name: string;
  changeCallback: (updatedValue: Array<string | null>) => void;
  fieldTags?: Array<TagDescription>;
};

export const MultipleSelecet: React.FC<MultipleSelecetProps> = memo(({
  label,
  options,
  name,
  disabled,
  selected,
  changeCallback,
  fieldTags
}) => {
  const toggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const updatedSelected = checked ? [...selected, value || null] : selected.filter((a) => a !== value || null);

    changeCallback(updatedSelected);
  };

  return (
    <div className='my-3 d-flex flex-wrap-small'>
      <div className="label-fix-width position-relative">
          <div
            className='d-flex flex-column position-absolute'
            style={{
              gap: '3px',
              minWidth: '8px',
              left: '-14px',
            }}
          >
            {
              fieldTags && fieldTags.map((tagDescription) => {
                let mockUp;

                switch(typeof tagDescription) {
                  // this is enum value
                  case 'number':
                    const tagColor = fieldTagsColors[tagDescription].color;
                    mockUp = (
                      <div
                        style={{
                          background: tagColor,
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                        }}
                      />
                    )
                  break;
                }
                return mockUp;
              })
            }
          </div>
          {label && 
            <label className='label-fix-width'>
              {label}
            </label>
          }
        </div>
      <div>
        {options.map(({ label, value }, index) => {
          const isSelected = selected?.includes(value);
          const isEmpty = !value;

          let btnClass = '';
          if (isSelected) {
            if (isEmpty) {
              btnClass = 'btn-danger';
            } else {
              btnClass = 'btn-primary';
            }
          } else {
            btnClass = 'btn-light';
          }

          return (
            <label className={`mb-0 btn ${btnClass} mr-2`}>
              <input
                type='checkbox'
                name={name}
                className='btn-check d-none w-100 h-100'
                value={value || undefined}
                disabled={disabled}
                checked={isSelected}
                onChange={toggleChange}
              />
              {label || 'Null'}
            </label>
          );
        })}
      </div>
    </div>
  );
});
