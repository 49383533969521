import { Action } from 'redux';
import { EducationMaterialsGroup } from '../../types/education-materials/education-materials-group';
import { EducationMaterial } from '../../types/education-materials/education-material';
import { PartialBy } from '../../types/partial-by';

export enum EducationMaterialsGroupsActionsTypes {
  SET_LOADING_EDUCATION_MATERIALS_GROUPS = 'set-loading-educations-materials-groups',
  SET_ERROR_EDUCATION_MATERIALS_GROUPS = 'set-error-educations-materials-groups',
  SET_EDUCATION_MATERIALS_GROUPS = 'set-educations-materials-groups',
  SET_UPDATING_EDUCATION_MATERIALS_GROUPS = 'set-updating-educations-materials-groups',
  UPDATE_EDUCATION_MATERIALS_GROUPS_ORDER = 'update-educations-materials-groups-ordering',
  ADD_EDUCATION_MATERIAL = 'add-educations-material',
  SET_EDUCATION_MATERIAL_FORM_ENTITY = 'set-educations-material-entity',
}

export interface EducationMaterialsGroupsState {
  groups: Array<EducationMaterialsGroup> | null;
  isLoading: boolean;
  error: null | string;
  isUpdating: boolean;
  materialFormEntity: { [key: string]: PartialBy<EducationMaterial, '_id' | 'mimeType'> }
}

export const initialValuesMaterialFormEntity: { [key: string]: PartialBy<EducationMaterial, '_id' | 'mimeType'> } = {
  en: {
    name: '',
    description: '',
    accessFor: [],
    file: '',
    customThumbnail: '',
    suggestedMaterials: [],
    tags: [],
  },
  es: {
    name: '',
    description: '',
    accessFor: [],
    file: '',
    customThumbnail: '',
    suggestedMaterials: [],
    tags: [],
  },
};

export const INITIAL_STATE: EducationMaterialsGroupsState = {
  groups: null,
  isLoading: false,
  error: null,
  isUpdating: false,
  materialFormEntity: initialValuesMaterialFormEntity,
};

export interface SetLoadingEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_LOADING_EDUCATION_MATERIALS_GROUPS;
  payload: boolean;
}

export interface SetUpdatingEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_UPDATING_EDUCATION_MATERIALS_GROUPS;
  payload: boolean;
}

export interface SetErrorEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_ERROR_EDUCATION_MATERIALS_GROUPS;
  payload: string | null;
}

export interface SetEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIALS_GROUPS;
  payload: Array<EducationMaterialsGroup> | null;
}

export interface UpdateOrderEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.UPDATE_EDUCATION_MATERIALS_GROUPS_ORDER;
  payload: Array<{ _id: string; order: number }>;
}

export interface SetEducationMaterialFormEntity extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIAL_FORM_ENTITY;
  payload: PartialBy<EducationMaterial, '_id' | 'mimeType'>;
}