import {
    ADD_TRANSACTION,
    UPDATE_TRANSACTION,
    INITIAL_STATE,
    QUERY_TRANSACTIONS,
    DELETE_TRANSACTION
} from './types';

export const transactionsReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case ADD_TRANSACTION:
            return {
                transactions: [...state.transactions, payload]
            }
        case QUERY_TRANSACTIONS:{
            return {
                transactions: payload
            }
        }
        case DELETE_TRANSACTION:
            return {
                transactions: state.transactions.filter(transaction => transaction._id !== payload)
            }
        case UPDATE_TRANSACTION:
            return {
                transactions: [...state.transactions.map(transaction => {
                    if (transaction._id === payload.transactionId) {
                        return {...transaction, ...payload.updated}
                    }
                    return transaction
                })]
            }
        default:
            return state;
    }
};
