import api from "./estatingApi";
import {User} from "../store/users/types";
import {SignInTokenType} from "../screens/user/UserDetails";

export const SignInTokenAPI = {
    signToken: (userId: string) => {
        return new Promise<SignInTokenType | null>(async (resolve, reject) => {
            try {
                const response = await api.post(`/signInToken`, {userId})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },
    getUserLinks: (userId: string) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.get(`/signInToken/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                resolve([])
            } catch (e) {
                resolve([])
            }
        })
    },

    signedInUsers: (tokenID:string) => {
        return new Promise<User[]>(async (resolve) => {
            try {
                const response = await api.get(`/signInToken/user/${tokenID}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                resolve([])
            } catch (e) {
                resolve([])
            }
        })
    },
    deleteUserLink: (id: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.delete(`/signInToken/${id}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to delete user predefined link'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to delete user predefined link'))
            }
        })
    },

    prolongUserLink: (id: string) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.get(`/signInToken/prolong/${id}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                resolve(null)
            } catch (e) {
                resolve(null)
            }
        })
    },

}
