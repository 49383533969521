import { memo, useEffect, useState, FC } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './WysiwygEditor.scss';
import { fieldTagsColors, TagDescription } from '../constants/fields-tags';


// helper function
const createEditorState = (html: string) => {
  const { contentBlocks, entityMap } = htmlToDraft(html);
  return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap));
};

export interface WysiwygEditorProps {
  value: string;
  onValueChange: (value: string) => void;
  fieldTags?: Array<TagDescription>;
}

const WysiwygEditor: FC<WysiwygEditorProps> = memo(({ value, onValueChange, fieldTags }) => {
  const [editorState, setEditorState] = useState(createEditorState(value));

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
    onValueChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  useEffect(() => {
    if (value === '') {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  return (
    <div className='position-relative'>
      <div
        className='d-flex flex-column position-absolute'
        style={{
          gap: '3px',
          minWidth: '8px',
          left: '-14px',
        }}
      >
        {
          fieldTags && fieldTags.map((tagDescription) => {
            let mockUp;

            switch(typeof tagDescription) {
              // this is enum value
              case 'number':
                const tagColor = fieldTagsColors[tagDescription].color;
                mockUp = (
                  <div
                    style={{
                      background: tagColor,
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                    }}
                  />
                )
              break;
            }
            return mockUp;
          })
        }
      </div>
      <Editor
        editorState={editorState}
        // contentState={}
        toolbarClassName='draft-toolbar'
        wrapperClassName='draft-wrapper'
        editorClassName='draft-editor'
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'remove',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
});

export default WysiwygEditor;