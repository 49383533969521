import React, {useRef} from 'react'
import Input from "../../components/Input";
import Button from "../../components/Button";
import { TagDescription, fieldTagsColors } from '../../constants/fields-tags';

type TagsProps = {
    tags: string[];
    callback: (tags: string[]) => any;
    fieldTags?: Array<TagDescription>;
}

const Tags : React.FC<TagsProps> = React.memo(({ tags, callback, fieldTags }) => {
    const [localTags, setLocalTags] = React.useState<string[]>(tags)
    const [tagToAdd, setTagToAdd] = React.useState<string>('')

    const ref = useRef(callback)

    React.useEffect(() => {
        ref.current(localTags)
    }, [localTags])

    const removeTag = (index: number) => {
        setLocalTags(localTags => {
            return [
                ...localTags.filter((_, idx) => idx !== index)
            ]
        })
    }

    const addTag = () => {
        if (tagToAdd) {
            setLocalTags(localTags => {
                return [
                    ...localTags,
                    tagToAdd
                ]
            })
            setTagToAdd('')
        }

    }

    const _renderIcon = () => {
        return <i className='fe fe-plus input-group-text cursor-pointer' onClick={() => addTag()} />
    }

    return (
        <>
            <div className='d-flex align-items-center btn-group position-relative'>
                <div
                    className='d-flex flex-column position-absolute'
                    style={{
                        gap: '3px',
                        minWidth: '8px',
                        left: '-14px',
                    }}
                >
                    {
                        fieldTags && fieldTags.map((tagDescription) => {
                            let mockUp;

                            switch(typeof tagDescription) {
                                // this is enum value
                                case 'number':
                                    const tagColor = fieldTagsColors[tagDescription].color;
                                    mockUp = (
                                        <div
                                            style={{
                                                background: tagColor,
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    )
                                break;
                            }
                            return mockUp;
                        })
                    }
                </div>
                <Input
                    containerClass='my-2 col-xl-4 px-0'
                    label='Tags'
                    value={tagToAdd}
                    inputProps={{
                        onKeyDown: (e) => {
                            if (e.keyCode === 13) {
                                addTag()
                            }
                        },
                        placeholder: '(Press Enter to add)'
                    }}
                    rightIcon={_renderIcon()}
                    onChange={(e) => setTagToAdd(e.target.value)}
                    withSuperScript={false}
                />
            </div>
            <div className='d-flex flex-wrap'>
                {
                    tags.map((tag, index) => {
                        return (
                            <div className='bg-dark rounded p-2 m-2 d-flex align-items-center' key={'propertyTag_' + index}>
                                <span className='mr-3'>{tag}</span>
                                <Button className='btn-dark btn-sm btn-rounded-circle' onClick={() => removeTag(index)}>
                                    <i className="fe fe-x cursor-pointer" />
                                </Button>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
})

export default Tags
