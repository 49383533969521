import { memo, useCallback, useState, useEffect, FC } from 'react';
import { EducationMaterial } from '../../../types/education-materials/education-material';
import { MaterialsListItem } from './materials-list-item';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteEducationMaterial, updateEducationMaterialsOrder } from '../../../store/education-materials/thunks';
import { debounce } from 'lodash';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../types/drag-and-drop/item-types';

interface GroupMaterialsListProps {
  materials: Array<EducationMaterial>;
  groupId?: string;
  readonly?: boolean;
}

export const MaterialsList: FC<GroupMaterialsListProps>
  = memo(({ materials, groupId, readonly }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [materialsState, setMaterialsState] = useState<Array<EducationMaterial>>(JSON.parse(JSON.stringify(materials)));

  useEffect(() => {
    setMaterialsState(materials);
  }, [materials]);

  const findMaterial = useCallback(
    (id: string) => {
      const targetIndex = materialsState.findIndex((material) => material._id === id);

      return {
        material: materialsState[targetIndex],
        index: targetIndex,
      };
    },
    [materialsState]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeOrder = useCallback(
    debounce((reorderedObjectsIds: Array<string>) => {
      if (!groupId) {
        return;
      }

      dispatch(updateEducationMaterialsOrder(groupId, reorderedObjectsIds));
    }, 500),
    [dispatch]
  );

  const moveMaterial = useCallback(
    (id: string, atIndex: number) => {
      const { material, index } = findMaterial(id);
      const updatedOrder = update(materialsState, {
        $splice: [
          [index, 1],
          [atIndex, 0, material],
        ],
      });
      
      setMaterialsState(updatedOrder);
      onChangeOrder(updatedOrder.map((material) => material._id))
    },
    [findMaterial, materialsState, onChangeOrder]
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.MATERIAL }));

  const onDeleteMaterial = useCallback(
    (materialId: string) => {
      if (!groupId) {
        return;
      }

      dispatch(deleteEducationMaterial(groupId, materialId));
    },
    [dispatch, groupId]
  );

  const onEditMaterial = useCallback(
    (materialId: string) => {
      history.push(`/education-materials-groups/${groupId}/education-materials/${materialId}`);
    },
    [groupId, history]
  );

  return (
    <div className='position-relative d-flex flex-wrap justify-content-center' ref={drop}>
      {materialsState.map((material, index) => (
        <MaterialsListItem
          onEditMaterial={onEditMaterial}
          index={index}
          key={material._id}
          material={material}
          onDeleteMaterial={onDeleteMaterial}
          moveMaterial={moveMaterial}
          findMaterial={findMaterial}
          readonly={readonly}
        />
      ))}
    </div>
  );
});
