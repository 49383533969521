import { GoldenSheetApi } from "../api/golden-sheet";
import {UpsertProperty} from "../store/property/types";
import moment from "moment-timezone";


const idToTimeout = {};
const idToFunction = {};

const runFunction = (id: string) => {
    const f = idToFunction[id];
    delete idToTimeout[id];
    delete idToFunction[id];
    f();
};

export const withDelay = (id: string, f: object, delay: number) => {
    const timeout = idToTimeout[id];
    if (timeout) {
        clearTimeout(timeout);
    }
    idToFunction[id] = f;
    idToTimeout[id] = setTimeout(() => {
        runFunction(id);
    }, delay);
};

export const _makeSuperscript = (value: string) => {
    const mapping =['⁰','¹','²','³','⁴','⁵','⁶','⁷','⁸','⁹']

    const reg = new RegExp(/\{{(.*?)\}}/g)

    return  value.replace(reg, function(match, g1, g2) {
        const superValue = match.replace('{{', '').replace('}}', '')
        if (!isNaN(parseInt(superValue))) {
            return superValue.split('').map(single => mapping[parseInt(single)]).join('')
        }
        return match

    });
}

export const _primeVal = (property: UpsertProperty) => {
    return (property?.internalName?.trim()  || property?.name?.trim() || property?._id);
}

export const getTzDate = (time: Date, pattern: string = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(time).tz(moment.tz.guess()).format(pattern);
}

export const formatDate = (date) => {
    return date ? moment(date).format('MM/DD/YYYY') : '';
};

export const debounce = (func: Function, delay: number) => {
  let timeout;

  return (...params) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, params);
    }, delay);
  };
};

export const getPath = async (path: string) => {
  return GoldenSheetApi.queryGoldenSheetDirectories(path);;
};

