import { memo, FC, useMemo, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { TableContext } from '../..';
import { Event, SubscriptionStatus } from '../../../../store/property/types';
import { eventHeight, eventStatsColors } from '../../utils/constants';
import { calculateEventDuration, calculateEventXPosition } from '../../utils/helpers/calculations';

interface TimelineEventProps {
  event: Event;
  index: number;
}

export const TimelineEvent: FC<TimelineEventProps> = memo(({ event, index }) => {
  const { dateOneMonthBeforeStart, setSelectedEvent } = useContext(TableContext);
  const positionX = useMemo(() => 
    calculateEventXPosition(event, dateOneMonthBeforeStart)
  , [
    event,
    dateOneMonthBeforeStart
  ]);

  const eventLength = useMemo(() => 
    calculateEventDuration(event)
  , [event]);

  const onEventSelected = useCallback(() => {
    setSelectedEvent({event, index});
  }, [event, index, setSelectedEvent]);

  return (
    <EventContainer
      width={eventLength}
      positionX={positionX}
      backgrounColor={eventStatsColors[SubscriptionStatus[event.subscriptionStatus]]}
      onClick={onEventSelected}
    >
      <TextContainer>
        {SubscriptionStatus[event.subscriptionStatus]}
      </TextContainer>
    </EventContainer>
  )
});

interface EventComponentProps {
  positionX: number;
  width: number;
  backgrounColor: string;
}

const TextContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: calc(100% - 20px);
`;

const EventContainer = styled.div<EventComponentProps>`
  position: absolute;

  left: ${({ positionX }) => positionX}px;

  height: ${eventHeight}px;
  width: ${({width}) => width}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({backgrounColor}) => backgrounColor};
  color: white;

  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);

  z-index: 1;

  cursor: pointer;
`