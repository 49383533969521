import React from 'react'
import { DeleteUser, QueryUsers, User } from '../../store/users/types';
import {Can} from "../../casl/Can";
import Select from "../../components/Select";
import Input from "../../components/Input";
import hubSpotSVG from "../../assets/hubspot.svg";
import estatingLogoBlue from "../../assets/estating_cropped.png";
import Button from "../../components/Button";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {selectUsers} from "../../store/users/selectors";
import {UsersAPI} from "../../api/userApi";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteUser, queryUsers} from "../../store/users/actions";
import {COUNTRIES} from "../../constants/countries";
import {config} from "../../appConfig";
import classNames from 'classnames';
import { WhoAreYou } from '../../types/user/who-are-you';

type TableProps = {
    tableData: 'allUsers' | 'pendingToDelete'
}

const DATE_OPTIONS: { [key: string]: string } = {
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit'
};

const SEARCH_FIELDS_SELECT: {
    label: string,
    value: string
}[] = [
    {
        label: 'Email',
        value: 'email'
    },
    {
        label: 'First Name',
        value: 'firstName'
    },
    {
        label: 'Last Name',
        value: 'lastName'
    },
    {
        label: 'Who Are you',
        value: 'whoAreYou'
    },
    {
        label: 'Country',
        value: 'country'
    },
    {
        label: 'Origin',
        value: 'origin'
    }
]
const PLACEHOLDER_MAPPING = {
    email: 'Search users by email',
    firstName: 'Search users by First Name',
    lastName: 'Search users by Last Name',
    origin: 'cms / hubspot',
    whoAreYou: 'Financial Advisor / Investor / Other',
    country: 'Select country'
}


type AvailableSearchFields = 'email' | 'firstName' | 'lastName' | 'whoAreYou' | 'origin' | 'country' | string


const Table: React.FC<TableProps> = ({tableData}) => {

    const [windowSize, setWindowSize] = React.useState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
    })
    const users = useSelector(selectUsers)

    // const [sortMethod, setSortMethod] = React.useState<'desc' | 'asc'>('desc')
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null)
    const [userToDisable, setUserToDisable] = React.useState<string>('')
    const [userToDelete, setUserToDelete] = React.useState<string>('')

    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [search, setSearch] = React.useState<string>('')
    const [searchBy, setSearchBy] = React.useState<AvailableSearchFields>('email')

    const [usersCount, setUsersCount] = React.useState<number>(0)
    const [userToDeleteInfo, setUserToDeleteInfo] = React.useState<string>('')

    const [searchedValues, setSearchedValues] = React.useState<{ [key: string]: string }>({})

    const [loading, setLoading] = React.useState(false)
    const LIMIT = 50
    const dispatch = useDispatch()

    React.useEffect(() => {
        let params: any = {
            page: currentPage,
            limit: LIMIT,
            ...searchedValues
        }
        const pendingToDelete = tableData === 'pendingToDelete'
        if (pendingToDelete) {
            params.pendingToDelete = true
        }
        setLoading(true)
        UsersAPI.queryUsers(params)
            .then(({users, count}) => {
                setUsersCount(count)
                dispatch<QueryUsers>(queryUsers(users))
            })
            .catch(e => {
            })
            .finally(() => {
                setLoading(false)
            })
    }, [currentPage, searchedValues, tableData, dispatch])

    const _renderPages = () => {
        const pagesCount = usersCount % LIMIT !== 0 ? Math.trunc(usersCount / LIMIT) + 1 : usersCount / LIMIT
        const pagesArr: JSX.Element[] = []
        if (currentPage > 1) {
            pagesArr.push(
                <Button
                    key='pageIndicatorStart'
                    className={`btn-sm mx-1 btn-light`}
                    onClick={() => setCurrentPage(1)}
                >
                    <i className="fe fe-arrow-left"></i>
                </Button>
            )
        }

        if (currentPage - 5 > 0) {
            for (let i = currentPage - 5; i <= currentPage + 5; i++) {

                if (i <= pagesCount) {
                    pagesArr.push(
                        <Button
                            key={'pageIndicator_' + i}
                            className={`btn-sm ${currentPage === i ? 'btn-success' : 'btn-light'} mx-1`}
                            label={String(i)}
                            onClick={() => setCurrentPage(i)}
                        />
                    )
                }


            }
        } else {
            for (let i = currentPage; i <= currentPage + 10; i++) {

                if (i <= pagesCount) {
                    pagesArr.push(
                        <Button
                            key={'pageIndicator_' + i}
                            className={`btn-sm ${currentPage === i ? 'btn-success' : 'btn-light'} mx-1`}
                            label={String(i)}
                            onClick={() => setCurrentPage(i)}
                        />
                    )
                }


            }
        }


        if (currentPage < pagesCount) {
            pagesArr.push(
                <Button
                    key='pageIndicatorEnd'
                    className={`btn-sm mx-1 btn-light`}
                    onClick={() => setCurrentPage(pagesCount)}
                >
                    <i className="fe fe-arrow-right"></i>
                </Button>
            )
        }


        return pagesArr
    }

    const detachPropertyForUser = (propertyId: string = '', user?: User) => {
        const targetUser = user || selectedUser

        if (targetUser) {
            UsersAPI.detachPropertiesForUser(
                targetUser._id,
                [propertyId]
            ).then(user => {
                setSelectedUser(user)
                // dispatch<UpdateUser>(updateUser(targetUser?._id, user))
            })
        }

    }

    const handleResize = React.useCallback(
        (e) => {
            setWindowSize({
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            })
        },
        []
    )

    React.useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [handleResize])

    React.useEffect(() => {
        return function () {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    React.useEffect(() => {
        return function () {
            localStorage.removeItem('page')
        }
    }, [])


    const handleSort = (e: any) => {
        if (e.target.parentElement.tagName === 'TH') {
            let sortColumn = e.target.getAttribute('data-sort');
            if (sortColumn) {
                sortColumn = sortColumn.split(',')
                // setUsersLocal(() => {
                //     return [
                //         ...orderBy(
                //             users,
                //             sortColumn,
                //             sortMethod
                //         )
                //     ]
                // });
                // setSortMethod(sortMethod => sortMethod === 'desc' ? 'asc' : 'desc')
            }
        }
    };

    const disableUser = () => {
        UsersAPI.disableUser(userToDisable).then((id) => {
            setUsersCount((usersCount - 1))
            dispatch<DeleteUser>(deleteUser(userToDisable));

        })
    }

    const enableUser = (userId: string) => {
        UsersAPI.enableUser(userId).then((id) => {
            setUsersCount((usersCount - 1))
            dispatch<DeleteUser>(deleteUser(userId));

        })
    }

    const fullDeleteUser = () => {
        UsersAPI.deleteUser(userToDelete)
            .then(() => {
                dispatch<DeleteUser>(deleteUser(userToDelete))
            })
            .catch(e => {
                alert(e.message)
            })
    }

    const _renderSearchInput = () => {
        switch (searchBy) {
            case "firstName":
            case "lastName":
            case "email":
                return (
                    <Input
                        type='search'
                        id='search-users-input'
                        value={search}
                        rightIcon={
                            <i onClick={() => {
                                if (!!search) {
                                    setSearchedValues(searchedValues => {
                                        searchedValues[searchBy] = search

                                        return {...searchedValues}
                                    })
                                    setSearch('')
                                }
                            }}
                               className="fe fe-plus input-group-text cursor-pointer mr-1"/>
                        }
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        inputProps={{
                            placeholder: PLACEHOLDER_MAPPING[searchBy],
                            onKeyDown: (e) => {
                                if (e.keyCode === 13) {
                                    if (!!search) {
                                        setSearchedValues(searchedValues => {
                                            searchedValues[searchBy] = search

                                            return {...searchedValues}
                                        })
                                        setSearch('')
                                    }
                                }
                            }
                        }}
                        className='form-control'
                    />
                )
            case 'country':
            case 'origin':
            case 'whoAreYou':
                let values: string[] = []
                if (searchBy === 'country') {
                    values = COUNTRIES
                }
                if (searchBy === 'origin') {
                    values = ['cms', 'hubspot']
                }
                if (searchBy === 'whoAreYou') {
                    values = Object.values(WhoAreYou)
                }
                return (
                    <Select
                        containerClass='w-100'
                        rightIcon={
                            <i onClick={() => {
                                if (!!search) {
                                    setSearchedValues(searchedValues => {
                                        searchedValues[searchBy] = search

                                        return {...searchedValues}
                                    })
                                    setSearch('')
                                }
                            }}
                               className="fe fe-plus input-group-text cursor-pointer mr-1"/>
                        }
                        values={values}
                        name={searchBy}
                        selected={search}
                        selectProps={{
                            onKeyDown: (e) => {
                                e.preventDefault()
                                if (e.keyCode === 13) {
                                    if (!!search) {
                                        setSearchedValues(searchedValues => {
                                            searchedValues[searchBy] = search

                                            return {...searchedValues}
                                        })
                                        setSearch('')
                                    }
                                }
                            }
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={PLACEHOLDER_MAPPING[searchBy]}
                    />
                )
            default:
                return null
        }
    }

    const getUserDependencies = (userId: string) => {
        UsersAPI.getUserDependenciesInfo(userId)
            .then(res => setUserToDeleteInfo(res))
            .catch(() => {
            })
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='h2 d-flex align-items-center  mb-0 my-3 text-right'>All users count: {loading ? (
                    <div className="spinner-border spinner-border-sm small ml-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : usersCount}</p>
                <Can I='export' a='user'>
                    <a className='btn-primary btn-sm'
                       href={`${config.ESTATING_API_URI}/user/csv?token=${localStorage.getItem('jwtToken')}`}>
                        Export All
                    </a>
                </Can>
            </div>
            <div className='card'>
                <div className='card-header flex-column align-items-start'>
                    <div className='d-flex flex-wrap'>
                        <div className='col px-0 mr-1'>
                            {
                                _renderSearchInput()
                            }
                        </div>
                        <Select
                            containerClass='col-sm-3 px-0 mr-sm-3 mr-0'
                            selected={searchBy}
                            placeholder='Please select field to search'
                            onChange={(e) => {
                                setSearch('')
                                setSearchBy(e.target.value)
                            }}
                            values={SEARCH_FIELDS_SELECT}
                        />
                    </div>
                    {
                        Object.keys(searchedValues).length > 0 ?
                            <div className='w-100 d-flex mt-3'>
                                {
                                    Object.entries(searchedValues).map(([searchBy, search]) => {
                                        return (
                                            <div className='bg-dark rounded m-2 p-2 d-flex align-items-center'
                                                 key={'userSearchBy_' + searchBy}>
                                                <span className='mr-2'>{searchBy} : {search}</span>
                                                <Button className='btn-dark btn-sm btn-rounded-circle' onClick={() => {
                                                    setSearchedValues(searchedValues => {
                                                        delete searchedValues[searchBy]

                                                        return {...searchedValues}
                                                    })
                                                }}>
                                                    <i className="fe fe-x cursor-pointer"/>
                                                </Button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                    }
                </div>
                <div className="card-body">
                    <div className='table-responsive ml-0 pt-0 pl-0 pr-0'>
                        {
                            windowSize.width <= 600 ?
                                users.map(user => {

                                    return (
                                        <div className="card" key={user._id}>
                                            <div className="card-header bg-dark">
                                                <p className='card-header-title'><b>User
                                                    ID: </b>{user._id}
                                                </p>
                                            </div>

                                            <div className="card-body">
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <h3>Name</h3>
                                                        <p>{`${user.firstName} ${user.lastName}`}</p>
                                                        <hr/>
                                                        <h3>Email</h3>
                                                        <p>{user.email}</p>
                                                    </div>
                                                    <div>
                                                        <h3>Country </h3>
                                                        <p>{user.country}</p>
                                                        <hr/>
                                                        <h3>Who are you?</h3>
                                                        <p>{user.whoAreYou}</p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                {
                                                    (user.properties && user.properties.length > 0) ?
                                                        <div>
                                                            <h3>Properties </h3>
                                                            {
                                                                user.properties.map((propertyObj, index) => {
                                                                    const {
                                                                        property,
                                                                        isScanned,
                                                                        isInterested
                                                                    } = propertyObj
                                                                    return (
                                                                        <div
                                                                            key={user._id + '_userProperty_' + index}
                                                                            className='alert alert-sm alert-dark  my-1 p-3'>
                                                                            <Link
                                                                                to={`/opportunity-edit/${property._id}`}
                                                                                className='h4'
                                                                            >
                                                                                {property.name || property._id}
                                                                            </Link>
                                                                            <div
                                                                                className='mt-2 d-flex flex-wrap'>
                                                                                {isInterested && <span
                                                                                    className='badge badge-soft-success p-2 mr-2'>Interested</span>}
                                                                                {isScanned && <span
                                                                                    className='badge badge-soft-primary p-2 mr-2'>Scanned</span>}
                                                                                {!isScanned && <span
                                                                                    className='badge badge-soft-warning p-2'>Attached</span>}
                                                                            </div>
                                                                            {/*{*/}
                                                                            {/*    propertyObj.isInterested ?*/}
                                                                            {/*        <span*/}
                                                                            {/*            className='bg-success radius-circle'><i*/}
                                                                            {/*            className="fe fe-check"/></span>*/}
                                                                            {/*        :*/}
                                                                            {/*        <span*/}
                                                                            {/*            className='bg-danger radius-circle'><i*/}
                                                                            {/*            className="fe fe-x"/></span>*/}

                                                                            {/*}*/}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <hr/>
                                                {
                                                    user.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ?
                                                        <Can I='readIVO' a='user'>
                                                            <div
                                                                className='d-flex justify-content-end align-items-center'>
                                                                <Link to={`/user/${user._id}`}
                                                                      className='btn btn-light'>
                                                                    Edit
                                                                </Link>
                                                            </div>
                                                        </Can>
                                                        :
                                                        <div
                                                            className='d-flex justify-content-end align-items-center'>
                                                            <Link to={`/user/${user._id}`}
                                                                  className='btn btn-light'>
                                                                Edit
                                                            </Link>
                                                        </div>
                                                }

                                                <hr/>

                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <table
                                    onClick={(e) => handleSort(e)}
                                    className='table table-sm table-nowrap card-table table-bordered'>
                                    <thead>
                                    <tr>
                                        <th>
                                            <a href='#!' className='text-muted sort'
                                               data-sort='firstName,lastName'>
                                                Name / Email
                                            </a>
                                        </th>
                                        <th className='text-center'>
                                            <select
                                                defaultValue=""
                                                className='form-control form-control-sm'
                                                onChange={(e) => {
                                                    // setUsersLocal(() => {
                                                    //     const select = e.target.value
                                                    //     return select === 'all' ?
                                                    //         users
                                                    //         :
                                                    //         users.filter(u => u.whoAreYou === e.target.value)
                                                    // })

                                                }}
                                            >
                                                <option value="" disabled>Who are you?</option>
                                                {Object.values(WhoAreYou).map((value) =>
                                                    <option value={value}>{value}</option>
                                                )}
                                                <option value="all">All</option>
                                            </select>
                                            {/*<a href='#!' className='text-muted' data-sort='whoAreYou'>*/}
                                            {/*    Who are you*/}
                                            {/*</a>*/}
                                        </th>
                                        <th className='text-center'>
                                            <a href='#!' className='text-muted'>
                                                Opportunities list
                                            </a>
                                        </th>
                                        <th className='text-center'>
                                            <a href='#!' className='text-muted'>
                                                Financial advisor
                                            </a>
                                        </th>
                                        <th>
                                            <a href='#!' className='text-muted sort'
                                               data-sort='createdAt'>
                                                Created At
                                            </a>
                                        </th>
                                        <th>
                                            <a href='#!' className='text-muted'>
                                                Actions
                                            </a>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className='list'>
                                    {
                                        users.map(user => {
                                            return (
                                                <tr key={user._id}>
                                                    <td>
                                                        <div
                                                            className='d-flex justify-content-between flex-wrap align-items-start'>
                                                            <div>
                                                                {
                                                                    user.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ?
                                                                        <>
                                                                            <Can I='readIVO' a='user'>
                                                                                <Link
                                                                                    className='h5'
                                                                                    to={`/user/${user._id}`}>
                                                                                    <b>{`${user.firstName} ${user.lastName}`}</b>
                                                                                </Link>
                                                                            </Can>
                                                                            <Can not I='readIVO'
                                                                                 a='user'>
                                                                            <span
                                                                                className='h5'><b>{`${user.firstName} ${user.lastName}`}</b></span>
                                                                            </Can>
                                                                        </>
                                                                        :
                                                                        <Link
                                                                            className='h5'
                                                                            to={`/user/${user._id}`}>
                                                                            <b>{`${user.firstName} ${user.lastName}`}</b>
                                                                        </Link>
                                                                }


                                                                <p className='h5'>{user.email}</p>
                                                            </div>
                                                            {
                                                                user.origin === 'hubspot' ?
                                                                    <img
                                                                        height={40}
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title='Imported from HubSpot'
                                                                        src={hubSpotSVG}
                                                                        alt="HubSpot icon"/>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                user.origin === 'cms' ?
                                                                    <img
                                                                        height={40}
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title='Registered from our web/mobile app'
                                                                        src={estatingLogoBlue}
                                                                        alt="HubSpot icon"/>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </td>
                                                    <td className='align-middle'>
                                                        <p className='h5'>{user.whoAreYou}</p>
                                                    </td>
                                                    {
                                                        (user.properties && user.properties.length) ?
                                                            <td className='align-middle text-wrap w-30 overflow-auto'>
                                                                <div style={{
                                                                    maxHeight: 200
                                                                }}>
                                                                    {
                                                                        user.properties
                                                                            .filter(propertyObj => {
                                                                                return propertyObj.property && propertyObj.property._id && propertyObj.property.name;
                                                                            })
                                                                            .map((propertyObj, index) => {
                                                                            const {
                                                                                isInterested,
                                                                                isScanned,
                                                                                property = {
                                                                                    '_id': 'no-id',
                                                                                    'name': 'unknown'
                                                                                }
                                                                            } = propertyObj
                                                                            const bookmarkStatus = user.bookmarks.find((bookmark) => bookmark?.property?._id === property._id)?.status
                                                                            return (
                                                                                <div
                                                                                    key={property._id + '_tableList_' + index}
                                                                                    className='alert alert-dark my-2 border d-flex align-items-start justify-content-between flex-wrap'>
                                                                                    <div
                                                                                        className='w-75'>
                                                                                        <Link
                                                                                            to={`/opportunity-edit/${property._id}`}
                                                                                            target='_blank'
                                                                                            className="h4 d-block mb-0">{property.name || property._id}</Link>
                                                                                        {!!isInterested &&
                                                                                        <span
                                                                                            className='badge badge-soft-success p-2 mt-2 mr-2'>Interested</span>}
                                                                                        {!!isScanned &&
                                                                                        <span
                                                                                            className='badge badge-soft-secondary p-2 mt-2 mr-2'>Scanned</span>}
                                                                                        {!isScanned &&
                                                                                        <span
                                                                                            className='badge badge-soft-warning p-2 mt-2'>Attached</span>}
                                                                                        <span
                                                                                            className={classNames('badge p-2 mt-2 ml-2', {
                                                                                                'badge-soft-success': bookmarkStatus === 'Bookmarked',
                                                                                                'badge-soft-danger': bookmarkStatus === 'Unbookmarked'
                                                                                            })}>
                                                                                                {bookmarkStatus}
                                                                                            </span>
                                                                                    </div>
                                                                                    {
                                                                                        !isInterested && !user.is_verified ?
                                                                                            <Can
                                                                                                I='attachDetachProperty'
                                                                                                a='user'>
                                                                                                <button
                                                                                                    className='btn btn-danger btn-sm'
                                                                                                    onClick={() => detachPropertyForUser(property._id, user)}>
                                                                                                    Remove
                                                                                                </button>
                                                                                            </Can>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                                // <Link key={user._id + '_property_' + index}
                                                                                //       to={`/property-upsert/${property._id}`}
                                                                                //       target={'_blank'}
                                                                                //       className={`d-flex my-1 btn ${!!isInterested ? 'bg-success' : 'bg-secondary'}`}>
                                                                                //     <p className='h5 mb-0'>{propertyObj.property?.name || propertyObj.property._id}</p>
                                                                                // </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </td>
                                                            :
                                                            <td className='align-middle'>
                                                                <p className='h5 text-center mb-0'>
                                                                    No property (scanned / attached)
                                                                </p>
                                                            </td>
                                                    }
                                                    <td className='align-middle'>
                                                        {
                                                            user.financialAdvisor ?
                                                                <>
                                                                    <Link
                                                                        className='h5'
                                                                        to={`/user/${user.financialAdvisor._id}`}>
                                                                        <b>{`${user.financialAdvisor.firstName} ${user.financialAdvisor.lastName}`}</b>
                                                                    </Link>
                                                                    <p>{user.financialAdvisor.email}</p>
                                                                </>
                                                                :
                                                                <p>Not exist</p>
                                                        }
                                                    </td>
                                                    <td className='align-middle'>
                                                        <p className='h5'>
                                                            {(user.createdAt && new Date(user.createdAt).toLocaleString("en-US", DATE_OPTIONS)) || 'Not available'}
                                                        </p>
                                                    </td>
                                                    <td className='text-center'>
                                                        {/*<button*/}
                                                        {/*    data-toggle='modal'*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        setSelectedUser(user)*/}
                                                        {/*    }}*/}
                                                        {/*    data-target='#list-of-properties'*/}
                                                        {/*    className='btn btn-primary btn-sm btn-block'>*/}
                                                        {/*    Properties*/}
                                                        {/*</button>*/}
                                                        <div className="btn-group">
                                                            {
                                                                user.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ?
                                                                    <Can I='readIVO' a='user'>
                                                                        <Link
                                                                            to={`user/${user._id}`}
                                                                            className='btn btn-light btn-sm'>
                                                                            Edit
                                                                        </Link>
                                                                    </Can>
                                                                    :
                                                                    <Link
                                                                        to={`user/${user._id}`}
                                                                        className='btn btn-light btn-sm'>
                                                                        Edit
                                                                    </Link>
                                                            }
                                                            {
                                                                tableData === 'allUsers' ?
                                                                    <Can I='disable' a='user'>
                                                                        <Button
                                                                            className="btn btn-warning btn-sm"
                                                                            dataToggle="modal"
                                                                            dataTarget="#disable-user"
                                                                            onClick={() => setUserToDisable(user._id)}
                                                                        >
                                                                            Disable
                                                                        </Button>
                                                                    </Can>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                tableData === 'pendingToDelete' ?
                                                                    <Can I='enable' a='user'>
                                                                        <Button
                                                                            className="btn btn-success btn-sm"
                                                                            onClick={() => enableUser(user._id)}>
                                                                            Enable
                                                                        </Button>
                                                                    </Can>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                tableData === 'pendingToDelete' ?
                                                                    <Can I='delete' a='user'>
                                                                        <Button
                                                                            className="btn btn-danger btn-sm"
                                                                            dataToggle='modal'
                                                                            dataTarget='#delete-user'
                                                                            onClick={() => {
                                                                                setUserToDelete(user._id)
                                                                                getUserDependencies(user._id)
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </Can>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={6} className='text-center'>
                                            {
                                                _renderPages()
                                            }
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                        }

                        {
                            tableData === 'allUsers' ?
                                <ConfirmationModal
                                    id='disable-user'
                                    label='You want to disable this user?'
                                    message='(Disabled users can not be signed in on our web / mobile apps)'
                                    danger={true}
                                    handleYes={disableUser}
                                    idToDelete={userToDisable}
                                />
                                :
                                null

                        }
                        {
                            tableData === 'pendingToDelete' ?
                                <ConfirmationModal
                                    id='delete-user'
                                    label='You want to delete this user?'
                                    message={
                                        <>
                                            <b>Dependencies</b><br/>
                                            {userToDeleteInfo}
                                        </>
                                    }
                                    danger={true}
                                    handleYes={fullDeleteUser}
                                    idToDelete={userToDelete}
                                />
                                :
                                null

                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Table
