import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PropertyTeaserForm from './property-teaser/PropertyTeaserForm';
import { PROPERTY_SUMMARY } from '../../constants/property';
import { PropertyTeaserType } from '../../store/property/types';
import { PropertyTeaserAPI } from '../../api/propertyTeaserApi';
import Snackbar from '../../components/Snackbar';
import { DroppedFile } from '../../types/dropped-file';

type ParamList = { id: string };

const EditProperty: React.FC<RouteComponentProps<ParamList>> = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [translations, setTranslations] = useState<{[key: string]: PropertyTeaserType} | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  // console.log(translations)
  const propertyId = match.params.id;
  // { en: PROPERTY_TEASER, es: PROPERTY_TEASER_ESPANISH }
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (!propertyId) {
          return;
        }
        const translations = await PropertyTeaserAPI.findById(propertyId);

        if (!translations.en.summary.length) {
          translations.en.summary = PROPERTY_SUMMARY;
        }
        setTranslations(translations);
      } catch (e: any) {
        setErrorMessage(e.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [propertyId]);

  const upsert = async (
    translations: { [key: string]: PropertyTeaserType },
    droppedImages: DroppedFile[],
    imagesToDelete,
    callback
  ) => {
    try {
      setLoading(true);
      const { en: baseValue } = translations;
      baseValue.dataRoom.marketResearchImage = imagesToDelete.includes(baseValue.dataRoom.marketResearchImage)
        ? undefined
        : baseValue.dataRoom.marketResearchImage;
      baseValue.assets = baseValue.assets.filter((a) => !imagesToDelete.includes(a));
      baseValue.floorPlan.assets = baseValue.floorPlan.assets.filter((a) => !imagesToDelete.includes(a));
      baseValue.partners = baseValue.partners.filter((a) => !imagesToDelete.includes(a));
      baseValue.risk.riskMitigationImages = baseValue.risk.riskMitigationImages.filter(
        (img) => !imagesToDelete.includes(img)
      );
      if (!baseValue.securityAndRoles.aboutThePartner) {
        baseValue.securityAndRoles.aboutThePartner = { text: '' };
      }
      baseValue.securityAndRoles.aboutThePartner.image = imagesToDelete.includes(
        baseValue.securityAndRoles.aboutThePartner.image
      )
        ? undefined
        : baseValue.securityAndRoles.aboutThePartner.image;
      baseValue.securityAndRoles.roles = baseValue.securityAndRoles.roles.map((img) => {
        const copy = { ...img };
  
        if (imagesToDelete.includes(img.image)) {
          delete copy.image;
        }
  
        return copy;
      });

      const updatedTranslations = await PropertyTeaserAPI.edit(propertyId, baseValue, translations, droppedImages)
  
      setTranslations(updatedTranslations);
      if (typeof callback === 'function') {
        callback && callback();
      }
    } catch (error: any) {
      setErrorMessage(error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (!propertyId || !translations) {
    return null;
  }

  return (
    <Fragment>
      <PropertyTeaserForm
        initialValues={translations}
        onSubmit={upsert}
        isEdit={true}
        isLoading={loading}
        availableLanguages={[
          { label: 'Default', value: 'en' },
          { label: 'Spanish', value: 'es' },
        ]}
      />
      <Snackbar
        show={!!errorMessage}
        position='bottom'
        color='success'
        snackbarHandler={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default withRouter(EditProperty);
