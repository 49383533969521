import { toast } from 'react-toastify';
import { EducationMaterialsApi } from '../../api/education-materials-api';
import { AppThunk } from '../types';
import {
  setLoadingEducationMaterialsGroups,
  setErrorEducationMaterialsGroups,
  setGroupsEducationMaterialsGroups,
} from './actions';
import { ReactText } from 'react';
import { EducationMaterialsGroup } from '../../types/education-materials/education-materials-group';
import { EducationMaterial } from '../../types/education-materials/education-material';
import { PartialBy } from '../../types/partial-by';
import { DroppedFile } from '../../types/dropped-file';
import { setEducationMaterialFormEntity } from './actions';

export const getEducationMaterialsGroups: (queryParams?: URLSearchParams) => AppThunk = (queryParams) => (
  async (dispatch) => {
    try {
      dispatch(setLoadingEducationMaterialsGroups(true));
      dispatch(setGroupsEducationMaterialsGroups(await EducationMaterialsApi.queryEducationMaterialsGroups(queryParams)));
    } catch (error: any) {
      const errorMessge = error.message || 'Error on loading education materials';
      toast(errorMessge, { type: 'error' });
      dispatch(setErrorEducationMaterialsGroups(errorMessge));
    } finally {
      dispatch(setLoadingEducationMaterialsGroups(false));
    }
  }
);

export const createEducationMaterialGroup: AppThunk = async (dispatch, getState) => {
  let toastId: null | ReactText = null;
  try {
    toastId = toast.loading('Creating group', { draggable: true });

    const newGroup = await EducationMaterialsApi.mutationCreateEducationMaterialsGroup();
    const prevValue = getState().educationMaterials.groups || [];

    dispatch(setGroupsEducationMaterialsGroups([...prevValue, newGroup]));

    if (toastId) {
      toast.update(toastId, {
        render: 'Successfully created group',
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      });
    }
  } catch (error: any) {
    if (toastId) {
      toast.update(toastId, {
        render: error.message || 'Error on creation group',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
    dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on creation group'));
  } finally {
    dispatch(setLoadingEducationMaterialsGroups(false));
  }
};

export const updateEducationMaterialsGroup: (
  id: string,
  fields: Partial<Omit<EducationMaterialsGroup, '_id'>>,
  params?: URLSearchParams
) => AppThunk = (id, fields, params) => async (dispatch, getState) => {
  let toastId: null | ReactText = null;
  try {
    toastId = toast.loading('Updating group', { draggable: true });

    const updatedGroup = await EducationMaterialsApi.mutationUpdateEducationMaterialsGroup(id, fields, params);
    const prevValue = JSON.parse(JSON.stringify(getState().educationMaterials.groups)) as Array<EducationMaterialsGroup> | null;

    if (!prevValue) {
      throw new Error('groups not loaded');
    }

    const updatedItemIndex = prevValue.findIndex((group) => group._id === updatedGroup._id);

    if (~updatedItemIndex) {
      prevValue[updatedItemIndex] = updatedGroup;
      dispatch(setGroupsEducationMaterialsGroups([...prevValue]));
      if (toastId) {
        toast.update(toastId, {
          render: 'Successfully updated group',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  } catch (error: any) {
    if (toastId) {
      toast.update(toastId, {
        render: error.message || 'Error on updating group',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
    dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on updating group'));
  } finally {
    dispatch(setLoadingEducationMaterialsGroups(false));
  }
};

export const updateEducationMaterialsGroupsOrder: (reorderedObjectsIds: Array<string>) => AppThunk =
  (reorderedObjectsIds) => async (dispatch) => {
    let toastId: null | ReactText = null;
    try {

      toastId = toast.loading('Updating order', { draggable: true });

      dispatch(
        setGroupsEducationMaterialsGroups(
          await EducationMaterialsApi.mutationUpdateOrderEducationMaterialsGroups(reorderedObjectsIds)
        )
      );

      if (toastId) {
        toast.update(toastId, {
          render: 'Successfully updated order',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
      }
    } catch (error: any) {
      if (toastId) {
        toast.update(toastId, {
          render: error.message || 'Error on updating order',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      }

      dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on updating order'));
    } finally {
      dispatch(setLoadingEducationMaterialsGroups(false));
    }
  };

export const deleteEducationMaterialsGroup: (id: string) => AppThunk = (id) => async (dispatch) => {
  let toastId: null | ReactText = null;
  try {
    toastId = toast.loading('Deleting group', { draggable: true });
    dispatch(setGroupsEducationMaterialsGroups(await EducationMaterialsApi.mutationDeleteEducationMaterialsGroup(id)));
    if (toastId) {
      toast.update(toastId, {
        render: 'Successfully deleted group',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
    }
  } catch (error: any) {
    if (toastId) {
      toast.update(toastId, {
        render: error.message || 'Error on deleting group',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
    dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on deleting group'));
  } finally {
    dispatch(setLoadingEducationMaterialsGroups(false));
  }
};

export const addEducationMaterial: (
  groupId: string,
  values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> },
  droppedFile: DroppedFile | null,
  customThumbnail: DroppedFile | null,
  queryParams?: URLSearchParams
) => AppThunk = (groupId, values, droppedFile, customThumbnail, queryParams) => async (dispatch, getState) => {
  let toastId: null | ReactText = null;
  try {
    toastId = toast.loading('Adding material', { draggable: true });
    const updatedGroup = await EducationMaterialsApi.mutationAddEducationMaterialToGroup(groupId, values, droppedFile, customThumbnail, queryParams);
    const prevValue = JSON.parse(JSON.stringify(getState().educationMaterials.groups)) as Array<EducationMaterialsGroup> | null;

    if (!prevValue) {
      throw new Error('groups not loaded');
    }

    const updatedItemIndex = prevValue.findIndex((group) => group._id === updatedGroup._id);

    if (~updatedItemIndex) {
      prevValue[updatedItemIndex] = updatedGroup;
      dispatch(setGroupsEducationMaterialsGroups(prevValue));
      if (toastId) {
        toast.update(toastId, {
          render: 'Successfully added material',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  } catch (error: any) {
    if (toastId) {
      toast.update(toastId, {
        render: error.message || 'Error on adding material',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  }
};

export const updateEducationMaterialsOrder: (groupId: string, reorderedObjectsIds: Array<string>) => AppThunk =
  (groupId, reorderedObjectsIds) => async (dispatch, getState) => {
    let toastId: null | ReactText = null;
    try {
      toastId = toast.loading('Updating order', { draggable: true });
      const updatedGroup = await EducationMaterialsApi.mutationUpdateOrderEducationMaterials(
        groupId,
        reorderedObjectsIds
      );

      const prevValue = JSON.parse(JSON.stringify(getState().educationMaterials.groups)) as Array<EducationMaterialsGroup> | null;

      if (!prevValue) {
        throw new Error('groups not loaded');
      }

      const updatedGroupItemIndex = prevValue.findIndex((group) => group._id === updatedGroup._id);

      if (~updatedGroupItemIndex) {
        prevValue[updatedGroupItemIndex] = updatedGroup;
        dispatch(setGroupsEducationMaterialsGroups(prevValue));
        if (toastId) {
          toast.update(toastId, {
            render: 'Successfully updated group',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        }
      }
    } catch (error: any) {
      if (toastId) {
        toast.update(toastId, {
          render: error.message || 'Error on updating order',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      }
      dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on updating order'));
    } finally {
      dispatch(setLoadingEducationMaterialsGroups(false));
    }
  };

export const deleteEducationMaterial: (groupId: string, materialId: string) => AppThunk =
  (groupId, materialId) => async (dispatch, getState) => {
    let toastId: null | ReactText = null;
    try {
      toastId = toast.loading('Deleting education material', { draggable: true });
      const updatedGroup = await EducationMaterialsApi.mutationDeleteEducationMaterial(groupId, materialId);

      const prevValue = JSON.parse(JSON.stringify(getState().educationMaterials.groups)) as Array<EducationMaterialsGroup> | null;

      if (!prevValue) {
        throw new Error('groups not loaded');
      }

      const updatedItemIndex = prevValue.findIndex((group) => group._id === updatedGroup._id);

      if (~updatedItemIndex) {
        prevValue[updatedItemIndex] = updatedGroup;
        dispatch(setGroupsEducationMaterialsGroups(prevValue));
        if (toastId) {
          toast.update(toastId, {
            render: 'Successfully deleted material',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        }
      }
    } catch (error: any) {
      if (toastId) {
        toast.update(toastId, {
          render: error.message || 'Error on deleting material',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      }
      dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on deleting material'));
    } finally {
      dispatch(setLoadingEducationMaterialsGroups(false));
    }
  };

export const getEducationMaterialFormEntity: (groupId: string, materialId: string) => AppThunk =
  (groupId, materialId) => async (dispatch, getState) => {
    try {
      dispatch(setLoadingEducationMaterialsGroups(true));
      dispatch(setGroupsEducationMaterialsGroups(await EducationMaterialsApi.queryEducationMaterialsGroups()));
      dispatch(setEducationMaterialFormEntity(await EducationMaterialsApi.queryEducationMaterial(groupId, materialId)));
    } catch (error: any) {
      dispatch(setErrorEducationMaterialsGroups(error.message || 'Error on loading material'));
    } finally {
      dispatch(setLoadingEducationMaterialsGroups(false));
    }
  };

export const updateEducationMaterial: (
  groupId: string,
  materialId: string,
  values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> },
  droppedFile: DroppedFile | null,
  customThumbnail: DroppedFile | null,
  queryParams?: URLSearchParams,
) => AppThunk = (groupId, materialId, values, droppedFile, customThumbnail, queryParams) => async (dispatch, getState) => {
  let toastId: null | ReactText = null;
  try {
    toastId = toast.loading('Adding material', { draggable: true });
    const updatedGroup = await EducationMaterialsApi.mutationUpdateEducationMaterial(
      groupId,
      materialId,
      values,
      droppedFile,
      customThumbnail,
      queryParams
    );
    const prevValue = JSON.parse(JSON.stringify(getState().educationMaterials.groups)) as Array<EducationMaterialsGroup> | null;

    if (!prevValue) {
      throw new Error('groups not loaded');
    }

    const updatedGroupIndex = prevValue.findIndex((group) => group._id === updatedGroup._id);
    const updatedMaterialindex = updatedGroup.materials.findIndex((material) => material._id === materialId);
    if (~updatedGroupIndex && ~updatedMaterialindex) {
      prevValue[updatedGroupIndex] = updatedGroup;

      dispatch(setEducationMaterialFormEntity(updatedGroup.materials[updatedMaterialindex]));
      dispatch(setGroupsEducationMaterialsGroups(prevValue));
      if (toastId) {
        toast.update(toastId, {
          render: 'Successfully updated material',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  } catch (error: any) {
    if (toastId) {
      toast.update(toastId, {
        render: error.message || 'Error on updating material',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  }
};
