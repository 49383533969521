import {
    ADD_ADMIN,
    AddAdmin,
    AdminType,
    CHANGE_ROLE,
    ChangeAdminRole,
    DELETE_ADMIN,
    DeleteAdmin,
    QUERY_ADMINS,
    QueryAdmins,
    UPDATE_ADMIN,
    UpdateAdmin
} from './types';


export const queryAdmins = (admins :  AdminType[] ) : QueryAdmins => {
    return {
        type: QUERY_ADMINS,
        payload: admins
    }
}

export const deleteAdmin = (adminId :  string ) : DeleteAdmin => {
    return {
        type: DELETE_ADMIN,
        payload: adminId
    }
}

export const addAdmin = (admin :  AdminType ) : AddAdmin => {
    return {
        type: ADD_ADMIN,
        payload: admin
    }
}

export const updateAdmin = (adminId : string, updated: AdminType ) : UpdateAdmin => {
    return {
        type: UPDATE_ADMIN,
        payload: {adminId, updated}
    }
}

export const changeAdminRole = (adminId : string, role: string ) : ChangeAdminRole => {
    return {
        type: CHANGE_ROLE,
        payload: {adminId, role}
    }
}
