import {Action} from "redux";
import {AdditionalValue} from "../property/types";

export const QUERY_SECURITIES = 'QUERY_SECURITIES'
export const ADD_SECURITY = 'ADD_SECURITY'
export const UPDATE_SECURITY = 'UPDATE_SECURITY'
export const DELETE_SECURITY = 'DELETE_SECURITY'

export type SecurityType = {
    _id?: string,
    transaction?: string,
    masterdata?: string,
    internalName: string,
    csd: string,
    compartmentNumber: string,
    issueDate: string,
    consolidatedDate: string,
    applicableCosts: string,
    multipleTradableSize: string,
    prohibitionOfSales: string,
    interestAccrualPeriod: string,
    interestCalculationBasis: string,
    interestPaymentDate: string,
    maturityDate: string,
    redemptionBasis: string,
    partialRedemption: string,
    issuanceOfFurtherCertificates: string,
    listing: string,
    additionalSellingRestrictions: string,
    custodian: string,
    clearingSystem: string,
    propertyData: AdditionalValue[]
}

export interface QuerySecurities extends Action {
    type: typeof QUERY_SECURITIES,
    payload: SecurityType[]
}

export interface DeleteSecurity extends Action {
    type: typeof DELETE_SECURITY,
    payload: string
}

export interface AddSecurity extends Action {
    type: typeof ADD_SECURITY,
    payload: SecurityType
}

export interface UpdateSecurity extends Action {
    type: typeof UPDATE_SECURITY,
    payload: {securityId: string, updated: SecurityType}
}

export interface SecurityState {
    securities: SecurityType[]
}

export const INITIAL_STATE: SecurityState = {
    securities: []
};

