import { EducationMaterial } from '../../types/education-materials/education-material';
import { EducationMaterialsGroup } from '../../types/education-materials/education-materials-group';
import { PartialBy } from '../../types/partial-by';
import {
  UpdateOrderEducationMaterialsGroups,
  EducationMaterialsGroupsActionsTypes,
  SetEducationMaterialsGroups,
  SetLoadingEducationMaterialsGroups,
  SetErrorEducationMaterialsGroups,
  SetUpdatingEducationMaterialsGroups,
  SetEducationMaterialFormEntity,
} from './types';

export const setLoadingEducationMaterialsGroups = (payload: boolean): SetLoadingEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_LOADING_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setUpdatingEducationMaterialsGroups = (payload: boolean): SetUpdatingEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_UPDATING_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setErrorEducationMaterialsGroups = (payload: string): SetErrorEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_ERROR_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setGroupsEducationMaterialsGroups = (
  payload: Array<EducationMaterialsGroup> | null
): SetEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const updateOrderEducationMaterialsGroups = (
  payload: Array<{ _id: string; order: number }>
): UpdateOrderEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.UPDATE_EDUCATION_MATERIALS_GROUPS_ORDER,
  payload,
});

export const setEducationMaterialFormEntity = (
  payload: PartialBy<EducationMaterial, '_id' | 'mimeType'>
): SetEducationMaterialFormEntity => ({
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIAL_FORM_ENTITY,
  payload,
});
