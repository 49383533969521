import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import React, {MouseEvent} from 'react'
import Dropzone, {FileWithPath} from "react-dropzone";
import { DroppedFile, FileCategory } from '../types/dropped-file';

export type DropImagesProps = {
    droppedImages: DroppedFile[],
    callback: (droppedImages: DroppedFile[]) => any,
    multiple?: boolean,
    disabled?: boolean,
    description?: string | JSX.Element,
    category: FileCategory,
    maxCount?: number,
    acceptFileExtensions?: string | Array<string>;
}


const DropImages: React.FC<DropImagesProps> = ({
                                                   callback,
                                                   droppedImages,
                                                   multiple = true,
                                                   description = '',
                                                   disabled=false,
                                                   category,
                                                   maxCount,
                                                   acceptFileExtensions = ["image/*", "video/mp4"]
                                               }) => {
    const [droppedImagesLocal, setDroppedImagesLocal] = React.useState<DroppedFile[]>([])

    React.useEffect(() => {
        setDroppedImagesLocal(droppedImages.filter(img => img.category === category))
    }, [droppedImages, category])

    const onDrop = async (acceptedFiles: FileWithPath[]) => {
        let uploadedImagesPaths: any[] = [];
        if (maxCount && maxCount <= droppedImagesLocal.length) {
            return;
        }

        uploadedImagesPaths = droppedImagesLocal.map((img) => img.path);
        acceptedFiles = acceptedFiles.filter((uploadedFile) => {
            return !uploadedImagesPaths.includes(uploadedFile.path) && category;
        });

        const thumbnails = await Promise.all(acceptedFiles.map((file) => {
            if (file.type.indexOf('video/') === 0) {
                return generateVideoThumbnails(file as File, 1, file.type).then((thumbnails) => {
                    return thumbnails[0]
                });
            }
            return null;
        }))

        callback([
            ...droppedImages,
            ...acceptedFiles.map((file, i) =>
                Object.assign(file, {
                    preview: thumbnails[i] || URL.createObjectURL(file),
                    category
                })
            ),
        ])
    };

    const deletePreviewImage = (e: MouseEvent<HTMLButtonElement>, imagePath: string | undefined) => {
        e.preventDefault();
        if (imagePath) {
            callback(droppedImages.filter((uploadedImage) => {
                return uploadedImage.path !== imagePath;
            }));
        }
    };


    return (
        <Dropzone
            onDrop={onDrop}
            accept={acceptFileExtensions}
            disabled={disabled}
            multiple={multiple}
        >
            {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                <div className="h-100" data-toggle="dropzone">
                {
                    !droppedImages.length && (  <div
                        className={`dz-message h-100 d-flex flex-column align-items-center justify-content-center
                        ${isDragActive ? (isDragReject ? "border-danger" : "") : droppedImagesLocal.length > 0 ? 'border-success' : ''}`}
                        {...getRootProps()}>
                        <input name="uploadedImages" {...getInputProps()}/>
                        <span>Click here or drop a file</span>
                        {
                            description ?
                                typeof description === 'string' ?
                                    <p>{description}</p>
                                    :
                                    description
                                :
                                null
                        }
                    </div>)
                }
                    <aside className='d-flex flex-wrap h-100'>
                        {
                            droppedImagesLocal.map((file, index) => {
                                return <div key={file.preview} className='h-100'>
                                    <div className="position-relative h-100">
                                        <div className="w-100 h-100">
                                            <img className="avatar-img rounded" alt='...'
                                                 key={file.preview}
                                                 src={file.preview}/>
                                        </div>
                                        <button onClick={e => {
                                            deletePreviewImage(e, file.path)
                                        }}
                                                className='btn btn-block btn-danger btn-sm position-absolute opacity-hover-5'
                                                style={{top: '0'}}>Remove
                                        </button>
                                    </div>
                                </div>

                            })
                        }
                    </aside>
                </div>
            )}
        </Dropzone>
    )
}

export default DropImages
