import React from 'react';
import Loading from '../Loading';
import './BlackBox.scss';

type Props = {};

const BlackBox: React.FC<Props> = () => {
  return (
    <div className='black-box-overlay'>
      <Loading
        style={{
          width: 100,
          height: 100,
        }}
      />
    </div>
  );
};

export default BlackBox;
