import React, { DetailedHTMLProps, useRef, InputHTMLAttributes } from 'react'
import {_makeSuperscript} from "../helpers/utils";
import cryptoRandomString from 'crypto-random-string';
import { TagDescription, fieldTagsColors } from '../constants/fields-tags';

export type InputProps = {
    id?: string,
    label?: string,
    inputProps?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' & 'style'>,
    value?: string | number | readonly string[] | undefined,
    style?: React.CSSProperties,
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    rightComponent?: JSX.Element,
    required?: boolean,
    name?: string,
    type?: string,
    validator?: (value: string | number | readonly string[] | undefined) => boolean,
    callback?: (isValid: boolean, field: string) => void,
    validFeedback?: string,
    invalidFeedback?: string,
    withSuperScript?: boolean,
    containerClass?: string,
    labelClass?: string,
    fixedWidth?: boolean,
    fieldTags?: Array<TagDescription>;
    formikError?: string;
}

export const Input: React.FC<InputProps> = ({
                                                id,
                                                label,
                                                inputProps,
                                                style,
                                                className,
                                                leftIcon,
                                                onChange = () => {
                                                },
                                                value = '',
                                                required,
                                                name,
                                                type,
                                                withSuperScript = true,
                                                callback,
                                                validator,
                                                validFeedback,
                                                invalidFeedback,
                                                containerClass = '',
                                                fixedWidth = true,
                                                labelClass = '',
                                                rightIcon,
                                                rightComponent,
                                                fieldTags,
                                                formikError
                                            }) => {
    const [isValid, setIsValid] = React.useState(true)

    const inputId = useRef(id || inputProps?.id || cryptoRandomString({length: 10}))

    React.useEffect(() => {
        if (callback && !!value && !!name) {
            callback(isValid, name)
        }
    }, [callback, isValid, value, name])


    React.useEffect(() => {
        if (validator) {
            !!value ?
                setIsValid(validator(value))
                :
                setIsValid(true)

        }
    }, [validator, value])


    const _validatorClass = () => {
        if (!!value) {
            if (isValid && validator) {
                return 'is-valid'
            } else if ((!isValid && validator) || !!formikError) {
                return 'is-invalid'
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    return (
        <div className={`d-flex flex-wrap-small w-100 ${containerClass}`}>
            <div className='d-flex position-relative align-items-center'>
                <div
                    className='d-flex flex-column position-absolute'
                    style={{
                        gap: '3px',
                        minWidth: '8px',
                        left: '-14px',
                    }}
                >
                    {
                        fieldTags && fieldTags.map((tagDescription) => {
                            let mockUp;

                            switch(typeof tagDescription) {
                                // this is enum value
                                case 'number':
                                    const tagColor = fieldTagsColors[tagDescription].color;
                                    mockUp = (
                                        <div
                                            style={{
                                                background: tagColor,
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    )
                                break;
                            }
                            return mockUp;
                        })
                    }
                </div>
                {
                    label &&
                    <label
                        htmlFor={inputId.current}
                        className={`col-form-label ${fixedWidth ? 'label-fix-width' : 'col-sm-3 px-0'} ${required ? 'font-weight-bold' : ''} ${labelClass} position-relative`}
                    >
                        {label}{required ? '*' : ''}
                    </label>
                }
            </div>
            <div className="input-group">
                {
                    leftIcon &&
                    <div className="input-group-prepend">
                        {
                            leftIcon
                        }
                    </div>
                }

                <input
                    type={type}
                    id={inputId.current}
                    style={{
                        ...style,
                    }}
                    value={value}
                    name={name}
                    onChange={(e) => {
                        validator && setIsValid(validator(e.target.value))
                        withSuperScript && (e.target.value = _makeSuperscript(e.target.value))
                        onChange(e)
                    }}
                    className={`form-control ${className || ''} ${_validatorClass()}`}
                    {...inputProps}
                />
                {
                    isValid && validFeedback ?
                        <div className="valid-feedback">
                            {validFeedback}
                        </div>
                        :
                        null
                }
                {
                    !isValid && invalidFeedback ?
                        <div className="invalid-feedback">
                            {invalidFeedback}
                        </div>
                        :
                        null
                }
                {formikError &&
                    <div className="invalid-feedback">
                        {formikError}
                    </div>
                }
                {
                    rightIcon &&
                    <div className="input-group-append ml-1">
                        {
                            rightIcon
                        }
                    </div>
                }
                {
                    rightComponent && <div className="input-group-append ml-1">{rightComponent}</div>
                }
            </div>
        </div>
    )
}

export default Input
