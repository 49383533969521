import React, {MouseEvent} from 'react';

type ConfirmationModalProps = {
    id: string,
    label: string,
    message: string | JSX.Element,
    confirmBtnLabel?: boolean,
    danger?: boolean,
    handleYes: (e: MouseEvent<HTMLButtonElement>, idToDelete: string) => any
    idToDelete: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    return (
        <div className="modal fade" id={props.id} tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className='modal-header'>
                        <h4 className="modal-title">{props.label}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{whiteSpace: 'pre'}}>
                        {props.message}
                    </div>
                    <div className="modal-footer">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-sm btn-white" data-dismiss="modal">Cancel</button>
                            <button type="button" className={`btn ${props.danger ? 'btn-danger' : 'btn-white'}`}
                                    data-dismiss="modal" onClick={(e) => props.handleYes(e, props.idToDelete)}>
                                {props.confirmBtnLabel || 'Yes'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default ConfirmationModal;
