import React, {useEffect} from 'react'
import AdminsTab from "./components/AdminsTab";
import {AbilityContext} from "../../casl/Can";
import {RolesAPI} from "../../api/roleApi";
import RolesTab from "./components/RolesTab";
import {QueryRoles} from "../../store/roles/types";
import {useDispatch} from "react-redux";
import {queryRoles} from "../../store/roles/actions";
import {QueryAdmins} from "../../store/admin/types";
import {queryAdmins} from "../../store/admin/actions";
import {useAbility} from "@casl/react";

type RolesProps = {}

const Roles: React.FC<RolesProps> = () => {
    const [hasError, setHasError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState<'admin' | 'role' | ''>('')

    const dispatch = useDispatch()
    const ability = useAbility(AbilityContext);

    const checkAdminAccessibility = () => {
        const canRead = ability.can('read', "admin")
        const canCreate = ability.can('create', "admin")
        const canUpdate = ability.can('update', "admin")
        const canDelete = ability.can('delete', "admin")

        return canRead || canCreate || canUpdate || canDelete;
    }
    const checkRolesAccessibility = () => {
        const canRead = ability.can('read', "role")
        const canCreate = ability.can('create', "role")
        const canUpdate = ability.can('update', "role")
        const canDelete = ability.can('delete', "role")

        return canRead || canCreate || canUpdate || canDelete;
    }

    const haveRolesAccess = checkRolesAccessibility()
    const haveAdminAccess = checkAdminAccessibility()
    React.useEffect(() => {
        if (haveRolesAccess) {
            setActiveTab("role")
        }
        if (haveAdminAccess && !haveRolesAccess) {
            setActiveTab("admin")
        }
    }, [haveRolesAccess, haveAdminAccess])

    useEffect(() => {
        if (haveRolesAccess && activeTab === "role") {
            setLoading(true)
            RolesAPI.queryRoles()
                .then(roles => {
                    dispatch<QueryRoles>(queryRoles(roles))
                    setHasError(false)
                    setErrorMessage('')
                })
                .catch(err => {
                    setHasError(true)
                    setErrorMessage(err.message)
                }).finally(() => {
                setLoading(false)
            })
        }

    }, [dispatch, haveRolesAccess, activeTab])

    useEffect(() => {
        if (haveAdminAccess && activeTab === "admin") {
            setLoading(true)
            RolesAPI.queryAdmins()
                .then(admins => {
                    dispatch<QueryAdmins>(queryAdmins(admins))
                    setHasError(false)
                    setErrorMessage('')
                })
                .catch(err => {
                    setHasError(true)
                    setErrorMessage(err.message)
                }).finally(() => {
                setLoading(false)
            })
        }
    }, [dispatch, haveAdminAccess, activeTab])


    return (
        <>
            <h1>Roles & Admins</h1>
            <hr/>
            <div>
                <ul className="nav nav-tabs mb-3" role="tablist">
                    {
                        haveRolesAccess ?
                            <li className="nav-item" onClick={() => setActiveTab("role")}>
                                <a className={`nav-link pt-0 pb-2 ${activeTab === "role" ? 'active' : ''}`}
                                   data-toggle="tab" href="#tab_roles" role="tab"
                                   aria-selected="true">Roles</a>
                            </li>
                            :
                            null
                    }
                    {
                        haveAdminAccess ?
                            <li className="nav-item" onClick={() => setActiveTab("admin")}>
                                <a className={`nav-link pt-0 pb-2 ${activeTab === "admin" ? 'active' : ''}`}
                                   data-toggle="tab" href="#tab_create_admin" role="tab"
                                   aria-selected="false">Admins</a>
                            </li>
                            :
                            null
                    }

                </ul>
                {
                    loading ?
                        <div className='w-100 mt-5'>
                            <span className='m-auto loader loader-primary loader-lg'/>
                        </div>
                        :
                        <div className="tab-content" id="tabs-tabContent">
                            <div className={`tab-pane fade show ${activeTab === "role" ? 'active' : ''}`} id="tab_roles"
                                 role="tabpanel">
                                {
                                    haveRolesAccess ?
                                        <RolesTab/>
                                        :
                                        null
                                }
                            </div>
                            <div className={`tab-pane fade show ${activeTab === "admin" ? 'active' : ''}`} id="tab_create_admin"
                                 role="tabpanel">
                                {
                                    haveAdminAccess ?
                                        <AdminsTab/>
                                        :
                                        null
                                }
                            </div>
                        </div>
                }
                {
                    (hasError && !!errorMessage) ?
                        <div className="alert alert-warning text-center py-4" role="alert">
                            <h1 className='mb-0'>{errorMessage}</h1>
                            <span>Please contact us for questions (info@estating.com)</span>
                        </div>
                        :
                        null

                }
            </div>
        </>

    )
}

export default Roles
