import {ActionType, INITIAL_STATE, QUERY_SESSIONS} from './types';

export const sessionsReducer = (state = INITIAL_STATE, {type, payload}: ActionType) => {

    switch (type) {
        case QUERY_SESSIONS:
            return {
                sessions: payload
            }
        default:
            return state;
    }
};
