import React, {ChangeEvent, MouseEvent} from 'react'
import {RolesAPI} from "../../../api/roleApi";
import {AddRole, RoleType, UpdateRole} from "../../../store/roles/types";
import {useDispatch, useSelector} from "react-redux";
import {addRole, updateRole} from "../../../store/roles/actions";
import Input from "../../../components/Input";
import {RuleType} from "../../../store/rules/types";
import {roleSelector} from "../../../store/roles/selectors";

export const ALL_RULES: {
    label: string,
    action: string,
    subject: string,
    conditions?: { [key: string]: any }
}[] = [
    {
        label: 'See IVO\'s users details',
        action: 'readIVO',
        subject: 'user'
    },
    {
        label: 'Change IVO\'s users details',
        action: 'updateIVO',
        subject: 'user'
    },
    {
        label: 'Full access to see all the properties',
        action: 'toggleFullAccess',
        subject: 'user'
    },
    {
        label: 'Attach / Detach to 1 property per time',
        action: 'attachDetachProperty',
        subject: 'user'
    },
    {
        label: 'Change State of property to Published',
        action: 'updateStatusToPublish',
        subject: 'property',
    },
    {
        label: 'Change State of property to Not Published',
        action: 'updateStatusToNotPublish',
        subject: 'property',
    },
    {
        label: 'Export User\'s Report',
        action: 'export',
        subject: 'user'
    },
    {
        label: 'Accept IVO\'s data and send the Contract to signature',
        action: 'manageContracts',
        subject: 'user'
    },

    //Properties
    {
        label: 'Read property',
        action: 'read',
        subject: 'property'
    },
    {
        label: 'Reorder properties',
        action: 'reorder',
        subject: 'property'
    },
    {
        label: 'Create properties with Draft status only',
        action: 'create',
        subject: 'property'
    },
    {
        label: 'Update property',
        action: 'update',
        subject: 'property'
    },
    {
        label: 'Delete property',
        action: 'delete',
        subject: 'property'
    },
    {
        label: 'Read investor report passwords',
        action: 'read',
        subject: 'property-investor-report'
    },
    {
        label: 'Edit investor report passwords',
        action: 'update',
        subject: 'property-investor-report'
    },


    //Users
    {
        label: 'Read user',
        action: 'read',
        subject: 'user'
    },

    {
        label: 'Update user',
        action: 'update',
        subject: 'user'
    },
    {
        label: 'Delete user',
        action: 'delete',
        subject: 'user'
    },
    {
        label: 'Disable user access to our web/mobile app',
        action: 'disable',
        subject: 'user',
    },
    {
        label: 'Enable user access to our web/mobile app',
        action: 'enable',
        subject: 'user'
    },

    //Roles
    {
        label: 'Read role',
        action: 'read',
        subject: 'role'
    },
    {
        label: 'Create role',
        action: 'create',
        subject: 'role'
    },
    {
        label: 'Update role',
        action: 'update',
        subject: 'role'
    },
    {
        label: 'Delete role',
        action: 'delete',
        subject: 'role'
    },

    //Admins
    {
        label: 'Read admin',
        action: 'read',
        subject: 'admin'
    },
    {
        label: 'Create admin',
        action: 'create',
        subject: 'admin'
    },
    {
        label: 'Update admin',
        action: 'update',
        subject: 'admin'
    },
    {
        label: 'Delete admin',
        action: 'delete',
        subject: 'admin'
    },
    {
        label: 'Reset admin password',
        action: 'resetPassword',
        subject: 'admin'
    },
    {
        label: 'Read enabled user(s)',
        action: 'readDisabled',
        subject: 'user',
    },

    //Education materials
    {
        label: 'Read education materials',
        action: 'read',
        subject: 'education-materials'
    },
    {
        label: 'Create education materials',
        action: 'create',
        subject: 'education-materials'
    },
    {
        label: 'Update education materials',
        action: 'update',
        subject: 'education-materials'
    },
    {
        label: 'Delete education materials',
        action: 'delete',
        subject: 'education-materials'
    },
    {
        label: 'Read education material',
        action: 'read',
        subject: 'education-material'
    },
    {
        label: 'Create education material',
        action: 'create',
        subject: 'education-material'
    },
    {
        label: 'Update education material',
        action: 'update',
        subject: 'education-material'
    },
    {
        label: 'Delete education material',
        action: 'delete',
        subject: 'education-material'
    },
]

type UpsertRoleProps = {
    cancelBtnClick?: (e: MouseEvent<HTMLButtonElement>) => any,
    callback?: (...args: any) => any,
    roleToUpdate?: string
}
const RoleUpsert: React.FC<UpsertRoleProps> = ({
                                                   cancelBtnClick = () => {
                                                   },
                                                   callback,
                                                   roleToUpdate = ''
                                               }) => {
    const [roleToUpsert, setRoleToUpsert] = React.useState<RoleType>({
        role: '',
        label: '',
        rules: [],
    })
    const dispatch = useDispatch()
    const role = useSelector(roleSelector(roleToUpdate))

    React.useEffect(() => {
        role && setRoleToUpsert(role)
    }, [role])

    const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        setRoleToUpsert(newRole => {
            newRole[e.target.name] = e.target.value
            return {...newRole}
        })
    }

    const _isValid = () => {
        const {role, label} = roleToUpsert
        return !!role && !!label
    }

    const upsertRole = () => {
        if (!_isValid()) {
            return false
        }

        if (roleToUpdate) {
            RolesAPI.updateRole(
                roleToUpdate,
                roleToUpsert
            ).then(updated => {
                // alert('Role successfully updated')
                dispatch<UpdateRole>(updateRole(roleToUpdate, updated))
                setRoleToUpsert({
                    role: '',
                    label: '',
                    rules: [],
                })
                callback && callback()
            }).catch(() => {})
        } else {
            RolesAPI.addRole(roleToUpsert)
                .then(role => {
                    dispatch<AddRole>(addRole(role))
                    setRoleToUpsert({
                        role: '',
                        label: '',
                        rules: [],
                    })
                    callback && callback()
                }).catch(() => {
            })
        }

    }

    const checkAccess = (index: number, subject: string, action: string, conditions?: { [key: string]: any }) => {
        const _isSameConditions = (objFirst: { [key: string]: any }, ObjSecond: { [key: string]: any }) => {
            let isSame = true
            Object.keys(objFirst).forEach(key => {
                if (typeof objFirst[key] === 'string') {
                    isSame = objFirst[key] === ObjSecond[key]
                } else {
                    isSame = _isSameConditions(objFirst[key], ObjSecond[key])
                }
            })

            return isSame
        }

        const {rules} = roleToUpsert
        const ruleIndex = rules
            .findIndex(
                rule => {
                    return rule.subject === subject && rule.actions === action
                        && (conditions ? _isSameConditions(rule.conditions, conditions) : true)
                })
        const ruleToAdd: RuleType = {
            actions: action,
            subject: subject,
        }
        conditions && (ruleToAdd.conditions = conditions)
        return ruleIndex !== -1 ?
            <div className='circle-30 bg-success cursor-pointer'
                 onClick={() => setRoleToUpsert(roleToAdd => {
                     roleToAdd.rules = [...roleToAdd.rules.filter((_, index) => index !== ruleIndex)]

                     return {...roleToAdd}
                 })}>
            </div>
            :
            <div className='circle-30 bg-danger cursor-pointer' onClick={() => setRoleToUpsert(roleToAdd => {
                roleToAdd.rules = [...roleToAdd.rules, ruleToAdd]
                return {...roleToAdd}
            })}
            >
            </div>
    }


    return (
        <>
            {
                !!roleToUpdate ?
                    <div className={`d-flex flex-wrap-small align-items-start w-100 my-3`}>
                        <p className='label-fix-width mb-0'>Prefix:</p>
                        <p className='mb-0'>{roleToUpsert.role}</p>
                    </div>
                    :
                    <Input
                        onChange={onChange}
                        name='role'
                        value={roleToUpsert.role}
                        label='Prefix:'
                    />
            }

            <Input
                onChange={onChange}
                name='label'
                containerClass='my-2'
                value={roleToUpsert.label}
                label='Label(description of role):'
            />
            <div className="card">
                <div className="table-responsive">
                    <table className='table table-nowrap table-bordered mb-0'>
                        <thead>
                        <tr>
                            <td className='position-relative'>Resources</td>
                            <th className='text-center'>On / Off</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            ALL_RULES.map((ruleObj, index) => {
                                return (
                                    <tr key={ruleObj.label + '_row_' + index}>
                                        <th className='bg-dark'>{ruleObj.label}</th>
                                        <td className='align-middle'>{checkAccess(index, ruleObj.subject, ruleObj.action, ruleObj.conditions)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={2} className='text-right'>
                                <div className="btn-group">
                                    <button className='btn btn-success' onClick={upsertRole}>
                                        {!!roleToUpdate ? 'Update' : 'Create'}
                                    </button>
                                    <button className='btn btn-light' onClick={cancelBtnClick}>Cancel</button>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>

    )
}

export default RoleUpsert
