import { DefaultParamType, TFnType, TranslationKey } from "@tolgee/react";

export const convertEnumToOptions = (
  enumObject: { [key: string]: string | number },
  nullable?: boolean,
  translationFunction?: TFnType<DefaultParamType, string, TranslationKey>,
  enumName?: string
) => {
  const out: Array<{ label: string; value: string | null }> = Object.entries(enumObject).map(([key, value]) => ({
    label: (translationFunction && enumName) ? 
      (translationFunction(`enums.${enumName}.${key}`) || String(value))
      :
      String(value),
    value: key,
  }));

  if (nullable) {
    out.push({ label: 'Null', value: null });
  }

  return out;
};
