import React from 'react'
import {Can} from "../../../casl/Can";
import {useDispatch, useSelector} from "react-redux";
import {rolesSelector} from "../../../store/roles/selectors";
import ConfirmationModal from "../../../modal/ConfirmationModal";
import InputModal from "../../../modal/InputModal";
import {RolesAPI} from "../../../api/roleApi";
import {AddRole, DeleteRole} from "../../../store/roles/types";
import {addRole, deleteRole} from "../../../store/roles/actions";

type RolesTableProps = {
    editBtnClick?: (roleId: string) => any,
    addRoleBtnClick?: () => void
}

const RolesTable : React.FC<RolesTableProps> = ({
                                                    editBtnClick = () => {},
                                                    addRoleBtnClick = () => {}
                                                }) => {
    const roles = useSelector(rolesSelector)
    const [roleToDelete, setRoleToDelete] = React.useState('')

    const [roleToCopy, setRoleToCopy] = React.useState({
        role: '',
        label: '',
        roleId: ''
    })
    const dispatch = useDispatch()

    const deleteRoleLocal = (e, roleId: string) => {
        RolesAPI.deleteRole(roleId)
            .then(() => {
                // alert('Role successfully deleted')
                dispatch<DeleteRole>(deleteRole(roleId))
            }).catch(e => {
            alert(e.message)
        })
    }

    const copyRoleLocal = (e, inputValues) => {
        RolesAPI.copyRole(
            roleToCopy.roleId,
            inputValues
        ).then(role => {
            // alert('Role successfully copied')
            dispatch<AddRole>(addRole(role))
        }).catch(e => {
            alert(e.message)
        })
    }

    return (
        <>
            <div className="card">
                <div className='table-responsive'>
                    <table className='table table-sm table-nowrap card-table table-bordered'>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            roles.length > 0 ?
                                roles.map(role => {
                                    return (
                                        <tr key={role._id}>
                                            <td><h4 className='font-weight-bold'>{role.label}</h4></td>
                                            <td className='text-center'>
                                                <div className='btn-group'>
                                                    <Can I='create' a='role'>
                                                        <a
                                                            href='#!'
                                                            onClick={() => setRoleToCopy({
                                                                label: role.label,
                                                                roleId: role._id || '',
                                                                role: role.role
                                                            })}
                                                            data-target="#duplicate-role"
                                                            data-toggle='modal'
                                                            className="btn btn-sm btn-white">Copy
                                                        </a>
                                                    </Can>
                                                    <Can I='update' a='role'>
                                                        <button className="btn btn-light btn-sm"
                                                                onClick={() => {
                                                                    editBtnClick(role._id || '')
                                                                }}>
                                                            Edit
                                                        </button>
                                                    </Can>
                                                    <Can I='delete' a='role'>
                                                        {
                                                            role.role !== 'default' ?
                                                                <button className="btn btn-danger btn-sm"
                                                                        data-toggle="modal"
                                                                        onClick={() => setRoleToDelete(role._id || '')}
                                                                        data-target="#delete-role"
                                                                >
                                                                    Delete
                                                                </button>
                                                                :
                                                                null
                                                        }

                                                    </Can>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <Can I='delete' a='role'>
                <ConfirmationModal
                    id='delete-role'
                    label='You want to delete this role?'
                    message='Are you sure?'
                    danger={true}
                    handleYes={deleteRoleLocal}
                    idToDelete={roleToDelete}
                />
            </Can>
            <Can I='create' a='role'>
                <button className="btn btn-rounded-circle btn-success btn-bottom-right-fixed m-3"
                        onClick={addRoleBtnClick}>
                    <span className="fe fe-plus"></span>
                </button>
                <InputModal
                    label='Set new role'
                    id='duplicate-role'
                    inputs={[
                        {
                            name: 'role',
                            value: `${roleToCopy.role} - (Copy)`,
                            inputProps: {
                                placeholder: 'Set new role name'
                            }
                        },
                        {
                            name: 'label',
                            value: `${roleToCopy.label} - (Copy)`,
                            inputProps: {
                                placeholder: 'Set new role prefix'
                            }
                        }
                    ]}
                    handleYes={copyRoleLocal}
                />
            </Can>
        </>

    )
}

export default RolesTable
