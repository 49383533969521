import {Action} from "redux";

export const QUERY_MASTERDATA = 'QUERY_MASTERDATA'
export const ADD_MASTERDATA = 'ADD_MASTERDATA'
export const UPDATE_MASTERDATA = 'UPDATE_MASTERDATA'
export const DELETE_MASTERDATA = 'DELETE_MASTERDATA'

export const PRICING_SUPPLEMENT_STATUS = Object.freeze({
    Preliminary: 'Preliminary',
    Final: 'Final',
    Null: 'Null'
})

export type MasterData = {
    _id?: string,
    internalName: string,
    pricingSupplementDate: string,
    lei: string,
    ppmDate: string,
    pricingSupplementStatus: string
}

export interface QueryMasterDataAll extends Action {
    type: typeof QUERY_MASTERDATA,
    payload: MasterData[]
}

export interface DeleteMasterData extends Action {
    type: typeof DELETE_MASTERDATA,
    payload: string
}

export interface AddMasterData extends Action {
    type: typeof ADD_MASTERDATA,
    payload: MasterData
}

export interface UpdateMasterData extends Action {
    type: typeof UPDATE_MASTERDATA,
    payload: {masterDataId: string, updated: MasterData}
}

export interface MasterDataState {
    masterData: MasterData[]
}

export const INITIAL_STATE: MasterDataState = {
    masterData: []
};

