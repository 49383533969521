import {
    INITIAL_STATE, QUERY_MASTERDATA,
    DELETE_MASTERDATA, ADD_MASTERDATA, UPDATE_MASTERDATA
} from './types';

export const masterDataReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_MASTERDATA:
            return {
                masterData: payload
            }
        case DELETE_MASTERDATA:
            return {
                masterData: state.masterData.filter(masterdata => masterdata._id !== payload)
            }
        case ADD_MASTERDATA:
            return {
                masterData: [...state.masterData, payload]
            }
        case UPDATE_MASTERDATA:
            return {
                masterData: [...state.masterData.map(masterData => {
                    if (masterData._id === payload.masterDataId) {
                        return payload.updated
                    }
                    return masterData
                })]
            }
        default:
            return state;
    }
};
