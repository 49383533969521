import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { IAuditLogApi, UsersAPI } from '../../api/userApi';
import { config } from '../../appConfig';

type UserPropertiesTableProps = {
  user?: string;
};

const UserInteractionsTable: React.FC<UserPropertiesTableProps> = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const [interactions, setInteractions] = React.useState<IAuditLogApi[]>([]);

  const loadInteractions = async () => {
    setLoading(true);
    try {
      let interactions;
      if (user) {
        interactions = await UsersAPI.queryInteractionsLog(user);
      } else {
        interactions =await UsersAPI.queryAllInteractionsLog();
      }
      setInteractions(interactions);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadInteractions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );
  }

  let exportHref = `${config.ESTATING_API_URI}/auditLog/${user}/download/interactions?token=${localStorage.getItem(
    'jwtToken'
  )}`;
  if (!user) {
    exportHref = `${config.ESTATING_API_URI}/auditLog/download/interactions?token=${localStorage.getItem(
      'jwtToken'
    )}`
  }

  return (
    <>
      <a
        className='btn-primary btn-sm d-block ml-auto mb-3'
        style={{ width: 'fit-content' }}
        href={exportHref}
      >
        Export All
      </a>
      <div className='card'>
        <div className='table-responsive'>
          <table className='table table-bordered mb-0'>
            <thead>
              <tr>
                {!user && (
                  <td className='text-center'>User</td>
                )}
                <td className='text-center'>Opportunities name</td>
                <td className='text-center'>Share with</td>
                <td className='text-center'>Date</td>
                <td className='text-center'>Time</td>
                <td className='text-center'>Investment Data</td>
                <td className='text-center'>Interaction Type</td>
              </tr>
            </thead>
            <tbody>
              {interactions.map((interaction, i) => {
                return (
                  <tr key={i}>
                    {!user && (
                      <td>
                        <Link to={`/user/${interaction.user._id}`}>
                        {interaction.user.firstName} {interaction.user.lastName}
                        </Link>
                      </td>
                    )}
                    <td className='bg-dark'>
                      <Link to={`/opportunity-edit/${interaction.changes[0].values.opportunity._id}`}>
                        {interaction.changes[0].values.opportunity.name}
                      </Link>
                    </td>
                    <td className='bg-dark'>{interaction.changes[0].values.sharedWith || '--'}</td>
                    <td className='bg-dark'>{moment(interaction.date).format('DD.MM.YY')}</td>
                    <td className='bg-dark'>{moment(interaction.date).format('HH:mm')}</td>
                    <td className='bg-dark'>
                      {interaction.changes[0].values.ISIN && (
                        <ul>
                          <li>ISIN: {interaction.changes[0].values.ISIN}</li>
                          <li>Internal Name: {interaction.changes[0].values.internalName}</li>
                          <li>Investment Strategy: {interaction.changes[0].values.investmentStrategy}</li>
                          <li>Classification: {interaction.changes[0].values.classification}</li>
                          <li>Address: {interaction.changes[0].values.address}</li>
                          <li>Service Provider Name: {interaction.changes[0].values.serviceProviderName}</li>
                          <li>Contact Person Name: {interaction.changes[0].values.contactPersonName}</li>
                          <li>Telephone Number: {interaction.changes[0].values.telephoneNumber}</li>
                          <li>Email: {interaction.changes[0].values.email}</li>
                          <li>Investment Amount: {interaction.changes[0].values.investmentAmount}</li>
                          <li>Custody Bank: {interaction.changes[0].values.custodyBank}</li>
                          <li>Bank Contact Name and Email: {interaction.changes[0].values.bankContactNameEmail}</li>
                          <li>Currency: {interaction.changes[0].values.currency}</li>
                          <li>Issuance Volume: {interaction.changes[0].values.issuanceVolume}</li>
                          <li>Minimum Investment: {interaction.changes[0].values.minimumInvestment}</li>
                        </ul>
                      )}
                    </td>
                    <td>{interaction.action === 'INVESTMENT_ASK' ? 'Investment' : 'Share'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UserInteractionsTable;
