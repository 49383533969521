import {
    INITIAL_STATE, QUERY_SECURITIES, DELETE_SECURITY,
    UPDATE_SECURITY, ADD_SECURITY
} from './types';

export const securitiesReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_SECURITIES:
            return {
                securities: payload
            }
        case DELETE_SECURITY:
            return {
                securities: state.securities.filter(security => security._id !== payload)
            }
        case ADD_SECURITY:
            return {
                securities: [...state.securities, payload]
            }
        case UPDATE_SECURITY:
            return {
                securities: [...state.securities.map(security => {
                    if (security._id === payload.securityId) {
                        return payload.updated
                    }
                    return security
                })]
            }
        default:
            return state;
    }
};
