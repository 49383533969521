import React from 'react';
import {SessionsApi} from "../../api/sessions";
import {useDispatch, useSelector} from "react-redux";
import {selectSessions} from "../../store/sessions/selectors";
import {QuerySessions} from "../../store/sessions/types";
import {querySessions} from "../../store/sessions/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {config} from "../../appConfig";
import prettyMilliseconds from "pretty-ms";
import {getTzDate} from "../../helpers/utils";

const Table: React.FC = () => {

    const dispatch = useDispatch();
    const userSessions = useSelector(selectSessions);

    React.useEffect(() => {
        SessionsApi.querySessions()
            .then((session) => {
                dispatch<QuerySessions>(querySessions(session));
            })
            .catch((err) => console.error(err));
    }, [dispatch]);

    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<Date>(new Date());

    return (
        <>
            <div className='d-flex justify-content-end align-items-center my-3'>
                <div className='d-flex session-export-dates'>
                    <DatePicker className='py-1' selected={startDate} onChange={(date: Date) => setStartDate(date)}/>
                    <DatePicker className='mx-2 py-1' selected={endDate} onChange={(date: Date) => setEndDate(date)}/>
                </div>
                <a className='btn-primary btn-sm px-3 py-1'
                   href={`${config.ESTATING_API_URI}/sessionLog/export/csv?token=${localStorage.getItem('jwtToken')}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`}>
                    Export Sessions
                </a>
            </div>
            <div className='card'>
                <div className='card-header'>
                    <div className='col'>
                        <div className='row align-items-center'>
                            <div className='col-auto pr-0'>
                                <span className='fe fe-search text-muted'></span>
                            </div>
                            <div className='col'>
                                <input
                                    type='search'
                                    className='form-control form-control-flush search'
                                    placeholder='Search'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className='table table-sm card-table table-bordered'>
                        <thead>
                        <tr>
                            <th>
                                <a href='#!' className='text-muted'>
                                    NAME
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    EMAIL
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    LOGIN TIME
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    LOGOUT TIME
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    SESSION SIZE
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    DEVICE TYPE
                                </a>
                            </th>
                            <th>
                                <a href='#!' className='text-muted'>
                                    Actions
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody className='session-list'>
                        {
                            userSessions && userSessions.length > 0 ?
                                userSessions.map((userSession) => {
                                    return <React.Fragment key={userSession._id}>

                                        {
                                            userSession.sessions && userSession.sessions.length > 0 ?
                                                userSession.sessions.map(({
                                                                              logoutTime,
                                                                              loginTime,
                                                                              sessionSize,
                                                                              deviceType,
                                                                              action,
                                                                              _id
                                                                          }) => {
                                                    const loginTimeTz = getTzDate(loginTime);
                                                    const logoutTimeTz = (action === 'sign_out')
                                                        ? getTzDate(logoutTime) :
                                                        'currently active';
                                                    const prettySessionSize = (action === 'sign_out')
                                                        ? prettyMilliseconds(sessionSize)
                                                        : 'present';
                                                    return <tr key={_id}>
                                                        <td>
                                                            {userSession.user?.firstName}{" "}{userSession.user?.lastName}
                                                        </td>
                                                        <td>
                                                            {userSession.user?.email}
                                                        </td>
                                                        <td>{loginTimeTz}</td>
                                                        <td>{logoutTimeTz}</td>
                                                        <td>{prettySessionSize}</td>
                                                        <td>{deviceType}</td>
                                                        <td className="align-middle">
                                                            <button className="btn btn-danger btn-sm">
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                }) : null
                                        }
                                    </React.Fragment>
                                }) : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Table;
