import { memo, useEffect } from 'react'
import {Router, Route, Redirect, Switch} from 'react-router-dom'
import history from "./history";
import MainLayout from "./layouts/MainLayout";
import LogIn from "./screens/authentication/LogIn";
import PropertiesTable from "./screens/properties/PropertiesTable";
import Users from "./screens/users/Users";
import { EducationMaterials } from './screens/education-materials';
import Roles from "./screens/roles/Roles";
import {AbilityContext} from '../src/casl/Can'
import useAbility from "./casl/useAbility";
import {Ability, AbilityTuple, MongoQuery} from "@casl/ability";
import SingleUser from "./screens/user/User";
import {useDispatch} from "react-redux";
import {RolesAPI} from "./api/roleApi";
import {SubjectRawRule} from "@casl/ability/dist/types/RawRule";
import {QueryRules} from "./store/rules/types";
import {queryRules} from "./store/rules/actions";
import Sessions from "./screens/sessions/Sessions";
import AddProperty from "./screens/property/AddProperty";
import EditProperty from "./screens/property/EditProperty";
import { ToastContainer } from 'react-toastify';
import { AddEducationMaterial } from './screens/education-materials/add-education-material';
import { EditEducationMaterial } from './screens/education-materials/edit-education-material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageProvider } from './contexts/language/language-context';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// @ts-ignore
//TODO check type
const PrivateRoute = ({component: Component, ...rest},) => {
  return (
    <Route
      {...rest}
      exact={true}
      render={(props) => {
        return localStorage.getItem('jwtToken') ? <Component {...props} /> : <Redirect to='/login'/>;
      }}
    />
  );
};


export const App = memo(() => {
  const ability: Ability<AbilityTuple, MongoQuery> | null = useAbility();
  const dispatch = useDispatch();

  useEffect(() => {
    RolesAPI.queryRules()
      .then((rules: SubjectRawRule<string, string, any>[]) => {
        dispatch<QueryRules>(queryRules(rules))
      })
      .catch(() => {})
  }, [dispatch])

  if (!ability) {
    return (
      <Router history={history}>
        <Switch>
          <Route
            path='/login'
            exact
            component={() => {
              return <LogIn/>
            }}
          />
        </Switch>
      </Router>
    )
  }

  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <LanguageProvider>
        <DndProvider backend={HTML5Backend}>
          <AbilityContext.Provider value={ability}>
            <Router history={history}>
              <Switch>
                <Route
                  path='/login'
                  exact
                  component={() => {
                    return <LogIn/>
                  }}
                />

                <PrivateRoute
                  path='/opportunities'
                  exact
                  component={() => {
                    return <MainLayout category='properties'>
                      <PropertiesTable/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute 
                  path='/opportunity-add'
                  exact
                  component={() => {
                    return <MainLayout category='properties'>
                      <AddProperty/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/opportunity-edit/:id'
                  exact
                  component={() => {
                    return <MainLayout category='properties'>
                      <EditProperty/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/users'
                  exact
                  component={() => {
                    return <MainLayout category='users'>
                      <Users/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/user/:id'
                  exact
                  component={() => {
                    return <MainLayout category='users'>
                        <SingleUser/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/roles'
                  exact
                  component={() => {
                    return <MainLayout category='roles'>
                      <Roles/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/sessions'
                  exact
                  component={() => {
                    return <MainLayout category='sessions'>
                      <Sessions/>
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/education-materials-groups'
                  exact
                  component={() => {
                    return <MainLayout category='education-materials-groups'>
                      <EducationMaterials />
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/education-materials-groups/:groupid/education-materials/add'
                  exact
                  component={() => {
                    return <MainLayout category='education-materials-groups'>
                      <AddEducationMaterial />
                    </MainLayout>
                  }}
                />

                <PrivateRoute
                  path='/education-materials-groups/:groupId/education-materials/:materialId'
                  exact
                  component={() => {
                    return <MainLayout category='education-materials-groups'>
                      <EditEducationMaterial />
                    </MainLayout>
                  }}
                />
                <Redirect from='/' to='/opportunities'/>
              </Switch>
            </Router>
            <ToastContainer
              position="bottom-left"
              draggable
              autoClose={2000}
            />
          </AbilityContext.Provider>
        </DndProvider>
      </LanguageProvider>
    </StyleSheetManager>
  );
});
