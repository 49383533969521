import { ChangeEvent, FC, memo } from 'react';
import { TagDescription, fieldTagsColors } from '../constants/fields-tags';

interface SelectBoxProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => any;
  onSave: () => any;
  disabled?: boolean;
  isNew: boolean;
  selected: string | null;
  label?: string;
  options: Array<{ label: string; value: string | null }>;
  name: string;
  fieldTags?: Array<TagDescription>;
}

export const SelectBox: FC<SelectBoxProps> = memo(
  ({ label, options, name, onChange, onSave, disabled, isNew, selected, fieldTags }) => {
    const toggleChange = async (e: ChangeEvent<HTMLInputElement>) => {
      await onChange(e);
      !isNew && onSave && onSave();
    };

    return (
      <div className='my-3 d-flex flex-wrap-small'>
        <div className="label-fix-width position-relative">
          <div
            className='d-flex flex-column position-absolute'
            style={{
              gap: '3px',
              minWidth: '8px',
              left: '-14px',
            }}
          >
            {
              fieldTags && fieldTags.map((tagDescription) => {
                let mockUp;

                switch(typeof tagDescription) {
                  // this is enum value
                  case 'number':
                    const tagColor = fieldTagsColors[tagDescription].color;
                    mockUp = (
                      <div
                        style={{
                          background: tagColor,
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                        }}
                      />
                    )
                  break;
                }
                return mockUp;
              })
            }
          </div>
          {label && 
            <label className='label-fix-width'>
              {label}
            </label>
          }
        </div>
        <div>
          {options.map(({ label, value }) => {
            const isSelected = value === selected;
            const isEmpty = !value;

            let btnClass = '';
            if (isSelected) {
              if (isEmpty) {
                btnClass = 'btn-danger';
              } else {
                btnClass = 'btn-primary';
              }
            } else {
              btnClass = 'btn-light';
            }

            return (
              <label className={`mb-0 btn ${btnClass} mr-2`}>
                {label || 'Null'}
                <input
                  type='radio'
                  name={name}
                  className='btn-check d-none'
                  value={value || undefined}
                  disabled={disabled}
                  checked={isSelected}
                  onChange={toggleChange}
                />
              </label>
            );
          })}
        </div>
      </div>
    );
  }
);
