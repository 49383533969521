import React, {ReactChild, ReactFragment, ReactPortal} from 'react'

interface ButtonProps {
    styles?: React.CSSProperties,
    className?: string,
    buttonAttributes?: React.ButtonHTMLAttributes<Omit<HTMLButtonElement, 'type'>>,
    label?: string,
    children?: ReactChild | ReactFragment | ReactPortal | null | undefined;
    icon?: JSX.Element,
    type?: 'button' | 'submit' | 'reset' | undefined,
    iconPosition?: string,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) =>  any,
    dataToggle?: string,
    dataTarget?: string,
}

const Button: React.FC<ButtonProps> = ({
                                           className,
                                           styles,
                                           buttonAttributes,
                                           label,
                                           icon,
                                           children,
                                           onClick,
                                           dataToggle,
                                           dataTarget,
                                           type = 'button'
                                       }) => {
    return (
        <button
            style={{
                position: 'relative',
                ...styles,
            }}
            onClick={onClick}
            type={type}
            className={`btn ${className || ''}`}
            data-toggle={dataToggle}
            data-target={dataTarget}
            {...buttonAttributes}>
            <span style={{
                position: 'absolute',
                left: '10%'
            }}>
                {
                    icon &&
                    icon
                }
            </span>
            {
                children &&
                children
            }
            {
                (!children && label) &&
                label
            }

        </button>
    )
}

// const styles = {
//    left: {
//        position: 'absolute',
//        left: '10%'
//    }
// }

export default Button
