import {Action} from 'redux';
import {InputHTMLAttributes} from "react";
import { Dayjs } from 'dayjs';
import { DroppedFile } from '../../types/dropped-file';
import { WhoAreYou } from '../../types/user/who-are-you';

export const UPSERT_PROPERTY = 'UPSERT_PROPERTY';
export const CLEAN_PROPERTY = 'CLEAN_PROPERTY'
export const UPDATE_DEFAULT_PROPERTY_MODE = 'UPDATE_DEFAULT_PROPERTY_MODE';

export enum PropertyOpportunityTypeEnum {
    PROPERTY_INVESTMENTS = 'Property Investments',
    LP_INVESTMENTS = 'LP Investments',
    PRIVATE_EQUITY_INVESTMENT = 'Private Equity Investment'
}

export enum PropertyStateEnum {
    DRAFT = 'Draft',
    PUBLISHED = 'Published',
    NOT_PUBLISHED = 'Not published',
}

export enum PropertyType {
    RESIDENTIAL = 'Residential',
    MULTIFAMILY = 'Multifamily',
    COLIVING = 'Coliving',
    INDUSTRIAL = 'Industrial',
    HOTEL = 'Hotel',
    MIXED_USE = 'Mixed-use',
    LAND = 'Land',
    MULTIPLE = 'Multiple',
}

export enum CapitalStructureEnum {
    EQUITY = 'Equity',
    PREFERRED_EQUITY = 'Preferred Equity',
    DEBT = 'Debt'
}

export enum PropertyInvestmentStrategyEnum {
    WEALTH_PRESERVATION = 'Wealth Preservation',
    WEALTH_GROWTH = 'Wealth Growth',
}

export enum PropertyCategoryEnum {
    CORE = 'Core',
    CORE_PLUS = 'Core +',
    VALUE_ADD = 'Value-Add',
    OPPORTUNITY = 'Opportunity',
}

export enum FinancialStructure {
    LEVERED = 'Levered',
    UNLEVERED = 'Unlevered',
}

export type AssetType =
    'Residential'
    | 'Multifamily'
    | 'Coliving'
    | 'Industrial'
    | 'Hotel'
    | 'Mixed-use'
    | 'Land'
    | 'Multiple'

export enum Category {
    CORE = 'Core',
	CORE_PLUS = 'Core +',
	VALUE_ADD = 'Value-Add',
	OPPORTUNITY = 'Opportunity',
}

export type PropertyStatus =
    'Open'
    | 'Closed'
    | 'Pre-subscription'
    | 'Secondary'
    | 'Example'
    | string | null;

export type StateOfProperty =
    'Draft'
    | 'Published'
    | 'Not published'
    | string
    | null

export type ViewFromCio = {
    title: string,
    body: string[],
    disclaimer: string
}

export enum SubscriptionStatus {
    PRE_SUBSCRIPTION = 'Pre-subscription',
    PRIMARY_MARKET = 'Subscription Open',
    SECONDARY_MARKET = 'Secondary Market',
    CLOSED_TRANSACTION = 'Closed',
}

export enum TeaserStatus {
    TRANSACTION_EXAMPLE = 'Transaction example',
    STRICTLY_PRELIMINARY = 'Strictly preliminary',
    PRICING_SUPPLEMENT_AVALILABLE = 'Pricing supplement available',
    CLOSED_TRANSACTION = 'Closed transaction',
    SEARCH_MANDATE = 'Search mandate',
}

export enum Orientation {
    VERTICAL = 'Vertical',
    HORIZONTAL = 'Horizontal',
}

export interface EstateManagement {
    values: AdditionalValue[],
    descriptions: string[]
}

export interface PropertyFloorPlanDetails {
    values: AdditionalValue[],
    disclaimers: string[]
}

export type HtmlBoxes = {
    _id?: string,
    header: string,
    htmlString: string,
}
export interface Financials {
    infoBlockLeft: HtmlBoxes[],
    statistics?: StatisticsType,
    infoBlockRight: HtmlBoxes[],
    priceInfos: AdditionalValue[],
    percentInfos: AdditionalValue[],
    disclaimers: string[]
}

export interface LocationDetails {
    city: {
        name: string,
        descriptions: string[],
        images: any[],
        disclaimers: string[]
    },
    country: {
        graphTitle: string,
        name: string,
        descriptions: string[],
        images: any[],
        disclaimers: string[]
    },
    neighborhood: {
        name: string,
        descriptions: string[],
        images: any[],
        disclaimers: string[],
        footerValues: AdditionalValue[]
    }
}

export interface OpportunityType {
    _id?: string,
    subHeader: {
        text: string,
        icon: string
    };
    header: string;
    descriptions: string[];

    [key: string]: any
}

export interface InfoBox {
    _id?: string,
    header: string;
    descriptions: string[];

    [key: string]: any
}


export interface StatisticsType {
    header: string,
    columns: string[],
    rows: Array<{
        label: string,
        value: string,
        data: {
            [key: string]: any
        }
    }>,
    disclaimers?: string[]

    [key: string]: any
}

export interface DataRoom {
    marketResearchText: string;
    marketResearchImage?: string;
    documents: Array<ToggleDocumentRoleProtected>
}

export interface Property {
    _id: string;
    name: string;
    internalName?: string,
    mapping?: any,
    assets: string[];
    floorPlan: FloorPlan;
    details: PropertyDetails;
    location: Location;
    expectedReturn: string;
    cio: ViewFromCio;
    costs: string;
    investment: AdditionalValue[];
    documents: ToggleDocument[];
    opportunity: OpportunityType[],
    statistics?: StatisticsType,
    disclaimer: string[]
    status: keyof typeof SubscriptionStatus,
    classification?: PropertyInvestmentStrategyEnum,
    estateManagement: EstateManagement,
    propertyDetails: PropertyFloorPlanDetails,
    swotAnalysis: InfoBox[],
    financials: Financials,
    locationDetails: LocationDetails,
    state: StateOfProperty,
    propertyType?: AssetType[],
    capitalStructure: keyof typeof CapitalStructureEnum,
    qrCode?: string,
    createdAt?: string,
    updatedAt?: string,
    isDefaultProperty: boolean,
    tags?: string[],
    security?: string
}

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type UpsertProperty = WithOptional<Property, '_id'>


export interface PropertyObj {
    property: PropertyTeaserType,
    _id: string,
    isInterested?: boolean,
    isScanned?: boolean
}

export type ToggleDocument = {
    name: string,
    description?: string,
    file?: File,
    url?: string
}

export interface ToggleDocumentRoleProtected extends ToggleDocument {
    allowedFor: Array<WhoAreYou>
}

export interface Document {
    name: string;
    url: string;
    description?: string,
    _id?: string;
}

export interface PropertyDetails {
    values: AdditionalValue[],
    highlights: Highlight[],
    disclaimers: string[]

}

export interface AdditionalValue {
    [key: string]: any,

    _id?: string;
    name: string;
    value: string;
    tooltip?: string;
    image?: string;
    info?: string,
    inputProps?: InputHTMLAttributes<any>,
    subValues?: AdditionalValue[]
}

export interface Highlight {
    [key: string]: any,

    _id?: string;
    info?: string,
    inputProps?: InputHTMLAttributes<any>,
    description: string;
    value: string;
    icon: string;
}

export interface FloorPlan {
    assets: string[];
    highlights: Highlight[];
    descriptions: string[];
}

export interface Location {
    address: string;
    coords: {
        latitude: number;
        longitude: number;
        city: string;
        state: string;
        country: string;
    };
    images: string[],
    descriptions: string[];
}

export interface PropertyState {
    property: PropertyTeaserType | null;
}


export interface CleanProperty extends Action {
    type: typeof CLEAN_PROPERTY;
}


export interface UpsertPropertyAction extends Action {
    type: typeof UPSERT_PROPERTY;
    payload: UpsertProperty;
}

export interface UpdateDefaultPropertyMode extends Action {
    type: typeof UPDATE_DEFAULT_PROPERTY_MODE;
    payload: boolean
}


export interface RowValue {
    name: string,
    value: string,
    tooltip?: string
}

type IconHighlight = {
    _id?: string,
    description: string,
    value: string,
    icon: string,
}

export enum AboutType {
    OPPORTUNITY = 'opportunity',
    PROPERTY = 'property'
}

type PropertyTeaserAboutType = {
    values: RowValue[],
    descriptions: string[],
    footnotes: string[]
    wysiwygDescription?: string,
    aboutType?: keyof typeof AboutType
}

export interface Event {
    subscriptionStatus: SubscriptionStatus,
    startDate: string | Dayjs,
    endDate: string | Dayjs,
}

export interface Risk {
    riskMitigationFactors: string;
    riskMitigationImages: Array<string>;
    documents: Array<ToggleDocument>;
}

export enum AllowedCountries {
    AFGHANISTAN = "Afghanistan",
    ALBANIA = "Albania",
    ALGERIA = "Algeria",
    AMERICAN_SAMOA = "American Samoa",
    ANDORRA = "Andorra",
    ANGOLA = "Angola",
    ANGUILLA = "Anguilla",
    ANTARCTICA = "Antarctica",
    ANTIGUA_AND_BARBUDA = "Antigua & Barbuda",
    ARGENTINA = "Argentina",
    ARMENIA = "Armenia",
    ARUBA = "Aruba",
    ASCENSION_ISLAND = "Ascension Island",
    AUSTRALIA = "Australia",
    AUSTRIA = "Austria",
    AZERBAIJAN = "Azerbaijan",
    BAHAMAS = "Bahamas",
    BAHRAIN = "Bahrain",
    BANGLADESH = "Bangladesh",
    BARBADOS = "Barbados",
    BELARUS = "Belarus",
    BELGIUM = "Belgium",
    BELIZE = "Belize",
    BENIN = "Benin",
    BERMUDA = "Bermuda",
    BHUTAN = "Bhutan",
    BOLIVIA = "Bolivia",
    BOSNIA_AND_HERZEGOVINA = "Bosnia & Herzegovina",
    BOTSWANA = "Botswana",
    BOUVET_ISLAND = "Bouvet Island",
    BRAZIL = "Brazil",
    BRITISH_INDIAN_OCEAN_TERRITORY = "British Indian Ocean Territory",
    BRITISH_VIRGIN_ISLANDS = "British Virgin Islands",
    BRUNEI = "Brunei",
    BULGARIA = "Bulgaria",
    BURKINA_FASO = "Burkina Faso",
    BURUNDI = "Burundi",
    CAMBODIA = "Cambodia",
    CAMEROON = "Cameroon",
    CANADA = "Canada",
    CANARY_ISLANDS = "Canary Islands",
    CAPE_VERDE = "Cape Verde",
    CARIBBEAN_NETHERLANDS = "Caribbean Netherlands",
    CAYMAN_ISLANDS = "Cayman Islands",
    CENTRAL_AFRICAN_REPUBLIC = "Central African Republic",
    CEUTA_AND_MELILLA = "Ceuta & Melilla",
    CHAD = "Chad",
    CHILE = "Chile",
    CHINA = "China",
    CHRISTMAS_ISLAND = "Christmas Island",
    CLIPPERTON_ISLAND = "Clipperton Island",
    COCOS_KEELING_ISLANDS = "Cocos (Keeling) Islands",
    COLOMBIA = "Colombia",
    COMOROS = "Comoros",
    CONGO_BRAZZAVILLE = "Congo - Brazzaville",
    CONGO_KINSHASA = "Congo - Kinshasa",
    COOK_ISLANDS = "Cook Islands",
    COSTA_RICA = "Costa Rica",
    CROATIA = "Croatia",
    CUBA = "Cuba",
    CURAÇAO = "Curaçao",
    CYPRUS = "Cyprus",
    CZECHIA = "Czechia",
    COTE_DIVOIRE = "Côte d’Ivoire",
    DENMARK = "Denmark",
    DIEGO_GARCIA = "Diego Garcia",
    DJIBOUTI = "Djibouti",
    DOMINICA = "Dominica",
    DOMINICAN_REPUBLIC = "Dominican Republic",
    ECUADOR = "Ecuador",
    EGYPT = "Egypt",
    EL_SALVADOR = "El Salvador",
    EQUATORIAL_GUINEA = "Equatorial Guinea",
    ERITREA = "Eritrea",
    ESTONIA = "Estonia",
    ESWATINI = "Eswatini",
    ETHIOPIA = "Ethiopia",
    FALKLAND_ISLANDS = "Falkland Islands (Islas Malvinas)",
    FAROE_ISLANDS = "Faroe Islands",
    FIJI = "Fiji",
    FINLAND = "Finland",
    FRANCE = "France",
    FRENCH_GUIANA = "French Guiana",
    FRENCH_POLYNESIA = "French Polynesia",
    FRENCH_SOUTHERN_TERRITORIES = "French Southern Territories",
    GABON = "Gabon",
    GAMBIA = "Gambia",
    GEORGIA = "Georgia",
    GERMANY = "Germany",
    GHANA = "Ghana",
    GIBRALTAR = "Gibraltar",
    GREECE = "Greece",
    GREENLAND = "Greenland",
    GRENADA = "Grenada",
    GUADELOUPE = "Guadeloupe",
    GUAM = "Guam",
    GUATEMALA = "Guatemala",
    GUERNSEY = "Guernsey",
    GUINEA = "Guinea",
    GUINEA_BISSAU = "Guinea-Bissau",
    GUYANA = "Guyana",
    HAITI = "Haiti",
    HEARD_AND_MCDONALD_ISLANDS = "Heard & McDonald Islands",
    HONDURAS = "Honduras",
    HONG_KONG = "Hong Kong",
    HUNGARY = "Hungary",
    ICELAND = "Iceland",
    INDIA = "India",
    INDONESIA = "Indonesia",
    IRAN = "Iran",
    IRAQ = "Iraq",
    IRELAND = "Ireland",
    ISLE_OF_MAN = "Isle of Man",
    ISRAEL = "Israel",
    ITALY = "Italy",
    JAMAICA = "Jamaica",
    JAPAN = "Japan",
    JERSEY = "Jersey",
    JORDAN = "Jordan",
    KAZAKHSTAN = "Kazakhstan",
    KENYA = "Kenya",
    KIRIBATI = "Kiribati",
    KOSOVO = "Kosovo",
    KUWAIT = "Kuwait",
    KYRGYZSTAN = "Kyrgyzstan",
    LAOS = "Laos",
    LATVIA = "Latvia",
    LEBANON = "Lebanon",
    LESOTHO = "Lesotho",
    LIBERIA = "Liberia",
    LIBYA = "Libya",
    LIECHTENSTEIN = "Liechtenstein",
    LITHUANIA = "Lithuania",
    LUXEMBOURG = "Luxembourg",
    MACAO = "Macao",
    MADAGASCAR = "Madagascar",
    MALAWI = "Malawi",
    MALAYSIA = "Malaysia",
    MALDIVES = "Maldives",
    MALI = "Mali",
    MALTA = "Malta",
    MARSHALL_ISLANDS = "Marshall Islands",
    MARTINIQUE = "Martinique",
    MAURITANIA = "Mauritania",
    MAURITIUS = "Mauritius",
    MAYOTTE = "Mayotte",
    MEXICO = "Mexico",
    MICRONESIA = "Micronesia",
    MOLDOVA = "Moldova",
    MONACO = "Monaco",
    MONGOLIA = "Mongolia",
    MONTENEGRO = "Montenegro",
    MONTSERRAT = "Montserrat",
    MOROCCO = "Morocco",
    MOZAMBIQUE = "Mozambique",
    MYANMAR_BURMA = "Myanmar (Burma)",
    NAMIBIA = "Namibia",
    NAURU = "Nauru",
    NEPAL = "Nepal",
    NETHERLANDS = "Netherlands",
    NEW_CALEDONIA = "New Caledonia",
    NEW_ZEALAND = "New Zealand",
    NICARAGUA = "Nicaragua",
    NIGER = "Niger",
    NIGERIA = "Nigeria",
    NIUE = "Niue",
    NORFOLK_ISLAND = "Norfolk Island",
    NORTH_KOREA = "North Korea",
    NORTH_MACEDONIA = "North Macedonia",
    NORTHERN_MARIANA_ISLANDS = "Northern Mariana Islands",
    NORWAY = "Norway",
    OMAN = "Oman",
    PAKISTAN = "Pakistan",
    PALAU = "Palau",
    PALESTINE = "Palestine",
    PANAMA = "Panama",
    PAPUA_NEW_GUINEA = "Papua New Guinea",
    PARAGUAY = "Paraguay",
    PERU = "Peru",
    PHILIPPINES = "Philippines",
    PITCAIRN_ISLANDS = "Pitcairn Islands",
    POLAND = "Poland",
    PORTUGAL = "Portugal",
    PUERTO_RICO = "Puerto Rico",
    QATAR = "Qatar",
    ROMANIA = "Romania",
    RUSSIA = "Russia",
    RWANDA = "Rwanda",
    REUNION = "Réunion",
    SAMOA = "Samoa",
    SAN_MARINO = "San Marino",
    SAUDI_ARABIA = "Saudi Arabia",
    SENEGAL = "Senegal",
    SERBIA = "Serbia",
    SEYCHELLES = "Seychelles",
    SIERRA_LEONE = "Sierra Leone",
    SINGAPORE = "Singapore",
    SINT_MAARTEN = "Sint Maarten",
    SLOVAKIA = "Slovakia",
    SLOVENIA = "Slovenia",
    SOLOMON_ISLANDS = "Solomon Islands",
    SOMALIA = "Somalia",
    SOUTH_AFRICA = "South Africa",
    SOUTH_GEORGIA_SOUTH_SANDWICH_ISLANDS = "South Georgia & South Sandwich Islands",
    SOUTH_KOREA = "South Korea",
    SOUTH_SUDAN = "South Sudan",
    SPAIN = "Spain",
    SRI_LANKA = "Sri Lanka",
    ST_BARTHELEMY = "St. Barthélemy",
    ST_HELENA = "St. Helena",
    ST_KITTS_NEVIS = "St. Kitts & Nevis",
    ST_LUCIA = "St. Lucia",
    ST_MARTIN = "St. Martin",
    ST_PIERRE_MIQUELON = "St. Pierre & Miquelon",
    ST_VINCENT_GRENADINES = "St. Vincent & Grenadines",
    SUDAN = "Sudan",
    SURINAME = "Suriname",
    SVALBARD_JAN_MAYEN = "Svalbard & Jan Mayen",
    SWEDEN = "Sweden",
    SWITZERLAND = "Switzerland",
    SYRIA = "Syria",
    SAO_TOME_PRINCIPE = "São Tomé & Príncipe",
    TAIWAN = "Taiwan",
    TAJIKISTAN = "Tajikistan",
    TANZANIA = "Tanzania",
    THAILAND = "Thailand",
    TIMOR_LESTE = "Timor-Leste",
    TOGO = "Togo",
    TOKELAU = "Tokelau",
    TONGA = "Tonga",
    TRINIDAD_TOBAGO = "Trinidad & Tobago",
    TRISTAN_DA_CUNHA = "Tristan da Cunha",
    TUNISIA = "Tunisia",
    TURKEY = "Turkey",
    TURKMENISTAN = "Turkmenistan",
    TURKS_CAICOS_ISLANDS = "Turks & Caicos Islands",
    TUVALU = "Tuvalu",
    US_OUTLYING_ISLANDS = "U.S. Outlying Islands",
    US_VIRGIN_ISLANDS = "U.S. Virgin Islands",
    UGANDA = "Uganda",
    UKRAINE = "Ukraine",
    UNITED_ARAB_EMIRATES = "United Arab Emirates",
    UNITED_KINGDOM = "United Kingdom",
    UNITED_STATES = "United States",
    URUGUAY = "Uruguay",
    UZBEKISTAN = "Uzbekistan",
    VANUATU = "Vanuatu",
    VATICAN_CITY = "Vatican City",
    VENEZUELA = "Venezuela",
    VIETNAM = "Vietnam",
    WALLIS_FUTUNA = "Wallis & Futuna",
    WESTERN_SAHARA = "Western Sahara",
    YEMEN = "Yemen",
    ZAMBIA = "Zambia",
    ZIMBABWE = "Zimbabwe",
    ALAND_ISLANDS = "Åland Islands",
}

export interface LocationDescription {
    address: string;
    coords: {
        latitude: number;
        longitude: number;
        city: string;
        state: string;
        country: AllowedCountries | null;
    };
}

export enum IconType {
    CALENDAR = 'CALENDAR',
    CALCULATOR = 'CALCULATOR',
    CURRENCY = 'CURRENCY',
    PIE_CHART = 'PIE_CHART',
    COINS = 'COINS',
    LINE_CHART = 'LINE_CHART',
    LINE = 'LINE',
    RISK_LEVELS = 'RISK_LEVELS',
    PROGRESS_BAR = 'PROGRESS_BAR',
    TEXT = 'TEXT'
}

export interface CustomField {
    _id?: string;
    id: string;
    name: string;
    value: string;
    tooltip: string;
    type: IconType | null;
}

export type PropertyTeaserType = {
    _id?: string,
    qrCode?: string,
    createdAt?: Date,
    updatedAt?: Date,
    name: string,
    internalName: string,
    opportunityType: keyof typeof PropertyOpportunityTypeEnum,
    assets: string[],
    summary: Array<CustomField>,
    teaserSummary: string,
    teaserFootnotes: string[],
    goldenSheetPath: string;
    about: PropertyTeaserAboutType,
    risk: Risk,
    dataRoom: DataRoom,
    academyMaterials: Array<string>,
    details: {
        values: RowValue[];
        highlights: IconHighlight[];
        footnotes: Array<AdditionalValue>;
        secondaryKpi: Array<RowValue>;
        highlighted: Array<CustomField>;
        secondary: {
            financialStructure: FinancialStructure | null;
            capitalStructure:  keyof typeof CapitalStructureEnum;
            assetType: Array<keyof typeof PropertyType>;
            others: Array<CustomField>;
        }
    },
    performanceReport: {
        documents: Array<ToggleDocumentRoleProtected>,
        passwords: Array<{ decoded: string }>,
        isPasswordSetted?: boolean,
    },
    floorPlan: {
        assets: string[],
        footnotes: string[],
        orientation: Orientation,
    },
    securityAndRoles: {
        roles: Array<RowValue & { image?: string; attachedImage?: DroppedFile; }>,
        security: RowValue[]
        aboutThePartner: {
            text: string,
            image?: string,
        },
    },
    partners: string[],
    documents: ToggleDocument[],
    disclaimers: Array<AdditionalValue>,
    investmentStrategy: Array<AdditionalValue>,
    tags: string[]
    options: {
        financialStructure: keyof typeof FinancialStructure | null,
        status: keyof typeof SubscriptionStatus | null,
        resaleAvailable: boolean;
        state: keyof typeof PropertyStateEnum | null,
        teaserStatus: keyof typeof TeaserStatus | null,
        investmentStrategy: keyof typeof PropertyInvestmentStrategyEnum | null,
        category: keyof typeof Category | null,
        propertyType: Array<keyof typeof PropertyType | null>,
        capitalStructure: keyof typeof CapitalStructureEnum,
        isin: string,
        relincPrice: string
    },
    location: {
        address: string,
        coords: {
            latitude: number,
            longitude: number,
            city: string,
            state: string,
            country: string,
        },
        footnotes: string[],
    },
    specialLocationDescription?: string;
    locations: Array<LocationDescription>,
    isDefaultProperty: boolean,
    timeline: {
        enabled: boolean,
        events: Array<Event>,
    }
}


export const INITIAL_STATE: PropertyState = {
    property: null,
};

