import { ChangeEvent, MouseEvent, useState, FC, useEffect, useCallback, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmationModal from '../../modal/ConfirmationModal';
import { selectProperties } from '../../store/properties/selectors';
import { SetProperties, AddProperty, DeleteProperty, UpdateDefaultPropertyMode } from '../../store/properties/types';
import { setProperties, addProperty, deleteProperty, updateDefaultPropertyMode } from '../../store/properties/actions';
import InputModal from '../../modal/InputModal';
import { 
  cloneDeep,
  debounce,
  isEqual,
  orderBy,
} from 'lodash';
import { PropertyTeaserType } from '../../store/property/types';
import { Can } from '../../casl/Can';
import { PropertyTeaserAPI } from '../../api/propertyTeaserApi';
import BlackBox from '../../components/black-box/BlackBox';
import { ProptertiesTable } from './propterties-table';
import { Button } from 'react-bootstrap';
import { updatePropertiesOrder } from '../../store/properties/thunks';

const PropertiesTable: FC<{}> = memo(() => {
  const [loading, setLoading] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const [actionLoading, setActionLoading] = useState(false);

  const [sortMethod, setSortMethod] = useState<'desc' | 'asc'>('desc');
  const properties = useSelector(selectProperties);
  const [propertiesLocal, setPropertiesLocal] = useState<Array<PropertyTeaserType>>([]);

  const isPropertiesChanged = useMemo(() => !isEqual(properties, propertiesLocal), [properties, propertiesLocal]);
  const dispatch = useDispatch();

  const onSave = useCallback(() => {
    dispatch(updatePropertiesOrder(propertiesLocal.map(({ _id }) => _id as string)));
  }, [dispatch, propertiesLocal]);


  const [propertyToCopy, setPropertyToCopy] = useState({
    name: '',
    id: '',
  });

  useEffect(() => {
    setPropertiesLocal(cloneDeep(properties));
  }, [properties]);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    PropertyTeaserAPI.findAll()
      .then((properties) => {
        dispatch<SetProperties>(setProperties(properties));
        setErrorMessage('');
        setHasError(false);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const handleResize = useCallback((e) => {
    setWindowSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const duplicateProperty = (e: MouseEvent<HTMLButtonElement>, inputValues) => {
    e.preventDefault();
    setActionLoading(true);
    PropertyTeaserAPI.copy({ ...propertyToCopy, ...inputValues })
      .then((property) => {
        dispatch<AddProperty>(addProperty(property));
        setHasError(false);
        setErrorMessage('');
      })
      .catch((e) => {
        setHasError(true);
        setErrorMessage(e.message);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const removeProperty = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.preventDefault();
    setActionLoading(true);
    PropertyTeaserAPI.removeById(propertyToDelete).finally(() => {
      setActionLoading(false);
      dispatch<DeleteProperty>(deleteProperty(id));
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((e: ChangeEvent<HTMLInputElement>) => {
    PropertyTeaserAPI.findAll({
      internalName: e.target.value.trim(),
    }).then((properties) => {
      dispatch<SetProperties>(setProperties(properties));
    });
  }, 500), [dispatch]);

  const handleSort = (e: any) => {
    if (e.target.parentElement.tagName === 'TH') {
      let sortColumn = e.target.getAttribute('data-sort');
      if (sortColumn) {
        sortColumn = sortColumn.split(',');
        setPropertiesLocal(() => {
          return [...orderBy(properties, sortColumn, sortMethod)];
        });
        setSortMethod((sortMethod) => (sortMethod === 'desc' ? 'asc' : 'desc'));
      }
    }
  };

  const changeDefaultPropertyMode = (id: string, defaultPropertyMode: boolean): void => {
    PropertyTeaserAPI.updateDefaultPropertyMode(id, defaultPropertyMode)
      .then((updatedDefaultPropertyMode) => {
        const { id, isDefaultProperty } = updatedDefaultPropertyMode;
        dispatch<UpdateDefaultPropertyMode>(updateDefaultPropertyMode(id, isDefaultProperty));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (loading) {
    return (
      <div className='w-100 mt-5'>
        <span className='m-auto loader loader-primary loader-lg' />
      </div>
    );
  }

  return (
    <div>
      {hasError && !!errorMessage ? (
        <div className='alert alert-warning text-center py-4' role='alert'>
          <h3 className='mb-0 font-weight-bold'>{errorMessage}</h3>
          <span>Please contact us for questions (info@estating.com)</span>
        </div>
      ) : null}
      <div className='card mb-3'>
        {actionLoading && <BlackBox />}

        <Can I='read' a='property'>
          <div className='card-header'>
            <div className='col'>
              <div className='row align-items-center'>
                <div className='col-auto pr-0'>
                  <span className='fe fe-search text-muted'></span>
                </div>
                <div className='col'>
                  <input
                    type='search'
                    onChange={(e) => handleSearch(e)}
                    className='form-control form-control-flush search'
                    placeholder='Search'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive ml-0 pt-0 pl-0 pr-0'>
            {windowSize.width <= 600 ? (
              propertiesLocal.map((property) => {
                const { highlights } = property.details;
                const { assets } = property.floorPlan;
                return (
                  <div className='card' key={property._id}>
                    <div className='card-header bg-dark'>
                      <Link to={`/opportunity-edit/${property._id}`} className='table-row'>
                        <p className='card-header-title'>{property.internalName || property.name}</p>
                      </Link>
                    </div>

                    <div className='card-body'>
                      <div className=''>
                        <h4>Address: </h4>
                        <p>{property && property.locations && property.locations[0]?.address}</p>
                      </div>
                      <hr />
                      <div className='d-flex align-items-start justify-content-between position-relative'>
                        <div>
                          {highlights &&
                            highlights.length > 0 &&
                            highlights.map((highlight) => {
                              return (
                                <p className='mb-0' key={highlight._id}>
                                  <b>{highlight.description}: </b>
                                  {highlight.value}
                                </p>
                              );
                            })}
                        </div>
                        <img width='50%' className='bg-white p-3 rounded' alt='floorplan' src={assets[0]} />
                      </div>
                      <hr />
                      <div className='d-flex justify-content-between'>
                        {property.qrCode ? (
                          <a
                            title={property.name}
                            href={property.qrCode}
                            download={`${property.name}-${property._id}.png`}
                          >
                            <img src={property.qrCode} className='avatar qr-image' alt='QR of property' height={30} />
                          </a>
                        ) : (
                          'Not published'
                        )}

                        <div className='btn-group align-self-start'>
                          <Link className='btn btn-primary btn-sm' to={`/opportunity-edit/${property?._id}`}>
                            Edit
                          </Link>
                          <button
                            className='btn btn-danger btn-sm'
                            data-toggle='modal'
                            onClick={() => setPropertyToDelete(property?._id || '')}
                            data-target='#delete-property'
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <ProptertiesTable
                handleSort={handleSort}
                propertiesLocal={propertiesLocal}
                setPropertiesLocal={setPropertiesLocal}
                changeDefaultPropertyMode={changeDefaultPropertyMode}
                setPropertyToCopy={setPropertyToCopy}
                setPropertyToDelete={setPropertyToDelete}
              />
            )}
          </div>
        </Can>
        <div className='bottom-fixed-save bg-light p-3 align-items-center flex-wrap'>
          {loading ? (
            <div className='w-100 position-absolute'>
              <span className='m-auto loader loader-primary loader-lg'></span>
            </div>
          ) : null}
          {isPropertiesChanged && !loading ? (
            <p className='h6 alert alert-warning mb-0 py-2 mr-auto col-12 col-md-8 col-lg-8 col-xl-8 box-shadow-footer'>
              You have unsaved changes, click{' '}
              <Button className='btn-primary btn-sm' onClick={() => setPropertiesLocal(properties)}>
                <p className='h6 mb-0'>here</p>
              </Button>{' '}
              to reset your changes, or save them in future
            </p>
          ) : null}
          {isPropertiesChanged && 
            <div className='pt-2 mr-6'>
              <Button className='btn btn-success' onClick={onSave}>
                Save
              </Button>
            </div>
          }
        </div>
        <Can I='create' a='property'>
          <Link className='btn btn-rounded-circle btn-success btn-bottom-right-fixed m-3' to='/opportunity-add'>
            <span className='fe fe-plus' />
          </Link>
        </Can>

        <Can I='create' a='property'>
          <InputModal
            label='Set new property name'
            id='set-duplicate-property-name'
            inputs={[
              {
                name: 'name',
                value: `${propertyToCopy.name} - (Copy)`,
                inputProps: {
                  placeholder: 'New property name',
                },
              },
            ]}
            handleYes={duplicateProperty}
          />
        </Can>
        <Can I='delete' a='property'>
          <ConfirmationModal
            id='delete-property'
            label='You want to delete this property?'
            message='Are you sure?'
            danger={true}
            handleYes={removeProperty}
            idToDelete={propertyToDelete}
          />
        </Can>
      </div>
    </div>
  );
});

export default withRouter(PropertiesTable);
