import { memo, FC, createContext, useEffect, useCallback } from 'react';
import { Can } from '../../casl/Can';
import { MaterialGroupList } from './components/material-group-list';
import { EducationMaterialsGroup } from '../../types/education-materials/education-materials-group';
import { useDispatch, useSelector } from 'react-redux';
import { createEducationMaterialGroup, getEducationMaterialsGroups } from '../../store/education-materials/thunks';
import { educationMaterialsGroupsLoadingSelector } from '../../store/education-materials/selectors';
import { LanguageSwicher } from '../../components/language-switcher';
import { useLanguage } from '../../contexts/language/language-context';

interface MaterialgGroupsContext {
  onCreateGroup: (group: any) => void;
  onRemoveGroup: (index: number, id: string) => void;
  onChangeOrder: (reorderedObjectsIds: Array<string>) => void;
  groups: Array<EducationMaterialsGroup>;
}

export const MaterialgGroupsContextContext = createContext<MaterialgGroupsContext>({} as MaterialgGroupsContext);

export const EducationMaterials: FC<{}> = memo(() => {
  const dispatch = useDispatch();
  const isLoading = useSelector(educationMaterialsGroupsLoadingSelector);
  const { currentLanguage, setCurrentLanguage } = useLanguage();

  useEffect(() => {
    dispatch(getEducationMaterialsGroups(new URLSearchParams([
      ['lang', currentLanguage]
    ])));
  }, [currentLanguage, dispatch]);

  const onAddEducationMaterialsGroup = useCallback(() => {
    dispatch(createEducationMaterialGroup);
  }, [dispatch]);



  if (isLoading) {
    return (
      <div className='w-100 mt-5'>
        <span className='m-auto loader loader-primary loader-lg' />
      </div>
    );
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <LanguageSwicher
          selectedLanguage={currentLanguage}
          availableLanguages={[
            { label: 'Default', value: 'en' },
            { label: 'Spanish', value: 'es' },
          ]}
          onLanguageSelect={setCurrentLanguage}
        />
      </div>
      <MaterialGroupList />

      <Can I='create' a='education-material'>
        <div
          className='btn btn-rounded-circle btn-success btn-bottom-right-fixed m-3 cursor-pointer'
          onClick={onAddEducationMaterialsGroup}
        >
          <span className='fe fe-plus'></span>
        </div>
      </Can>
    </>
  );
});
