import React, {ChangeEvent, FormEvent} from 'react';
import api from "../../api/estatingApi";
import history from "../../history";
import logo from '../../assets/estating.png'
import bg from '../../assets/bg.jpg'
import {RolesAPI} from "../../api/roleApi";
import {QueryRules} from "../../store/rules/types";
import {queryRules} from "../../store/rules/actions";
import {useDispatch} from "react-redux";

const LogIn: React.FC<{}> = () => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')


    const handleChange = (e: ChangeEvent<HTMLInputElement>, key: 'email' | 'password') => {
        const {value} = e.target
        switch (key) {
            case 'email':
                setEmail(value)
                return
            case 'password':
                setPassword(value)
                return
            default:
                return null
        }
    };
    const dispatch = useDispatch()
    const getRules = (callback: (...args: any[]) => any) => {
        RolesAPI.queryRules()
            .then(async (rules) => {
                await dispatch<QueryRules>(queryRules(rules))
                callback()
            })
            .catch(() => {
            })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await api
            .post('/login', {email, password})
            .then((response) => {
                localStorage.setItem('jwtToken', response.headers['x-auth']);
                api.defaults.headers = {
                    'Content-Type': 'application/json',
                    'x-auth': response.headers['x-auth'],
                };
                getRules(() => history.push('/opportunities'))
            })
            .catch((response) => {
                setError(true)
                setErrorMessage('Invalid email or password.')
            });
    };


    return (
        <div
            style={{backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
        >
            <div className='container'>
                <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
                    <div className='col-12 col-md-8 col-xl-6 d-flex flex-column login-bg'>
                        <img src={logo} alt="Estating logo" className='my-5 align-self-center'/>
                        {/*<h1 className='display-4 text-center mb-3'>Welcome back</h1>*/}
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className='form-group'>
                                <input
                                    type='email'
                                    value={email}
                                    className='form-control form-control-white'
                                    onChange={(e) => handleChange(e, 'email')}
                                    placeholder='Enter your email'
                                />
                            </div>
                            <div className='form-group'>
                                <div className='input-group input-group-merge'>
                                    <input
                                        type='password'
                                        value={password}
                                        onChange={(e) => handleChange(e, 'password')}
                                        className='form-control form-control-white'
                                        placeholder='Enter your password'
                                    />
                                </div>
                            </div>
                            {
                                error &&
                                <div className='alert alert-danger text-center' role='alert'>
                                    {errorMessage}
                                </div>
                            }
                            <div className='form-group'>
                                <div className='input-group input-group-merge'>
                                    <input type='submit' value='Sign in'
                                           className='btn btn-lg btn-block btn-primary mb-3'/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default LogIn
