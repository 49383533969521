import {
    QUERY_USERS,
    QueryUsers,
    UPDATE_USER,
    UpdateUser,
    User,
    VERIFY_TOGGLE,
    VerifyToggle,
    DELETE_USER,
    DeleteUser,
} from './types';


export const queryUsers = ( users :  User[] ) : QueryUsers => {
    return {
        type: QUERY_USERS,
        payload: users
    }
}

export const verifyToggle = ( userId :  string, is_verified: boolean ) : VerifyToggle => {
    return {
        type: VERIFY_TOGGLE,
        payload: {userId, is_verified}
    }
}

export const updateUser = ( userId :  string, updated: User ) : UpdateUser => {
    return {
        type: UPDATE_USER,
        payload: {userId, updated}
    }
}

export const deleteUser = ( userId :  string): DeleteUser => {
    return {
        type: DELETE_USER,
        payload: {userId}
    }
}
