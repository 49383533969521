import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap'
import logo from '../assets/estating.png'
import api from '../api/estatingApi'
import history from '../history'
import {AbilityContext} from "../casl/Can";
import {useAbility} from "@casl/react";

type TopNavbarProps = {
    category: 'properties' | 'users' | 'roles' | 'sessions' | 'education-materials-groups'
}

const TopNavbar: React.FC<TopNavbarProps> = ({category}) => {

    const ability = useAbility(AbilityContext);

    const logOut = async () => {
        localStorage.removeItem('jwtToken');
        await api.post('/logout').then((response) => {
            history.push('/login');
        });
    }

    const checkPropertyAccessibility = () => {
        const canRead = ability.can('read', "property")
        const canCreate = ability.can('create', "property")
        const canUpdate = ability.can('update', "property")
        const canDelete = ability.can('delete', "property")

        return canRead || canCreate || canUpdate || canDelete;
    }

    const checkUserAccessibility = () => {
        const canExport = ability.can('export', "user")
        const canAttachDetachProperty = ability.can('attachDetachProperty', "user")
        const canUpdateIVOUser = ability.can('updateIVO', "user")
        const canToggleFullAccess = ability.can('toggleFullAccess', "user")
        const canReadIVO = ability.can('readIVO', "user")
        const canDelete = ability.can('delete', "user")
        const canUpdate = ability.can('update', "user")
        const canRead = ability.can('read', "user")

        return canExport || canAttachDetachProperty ||
            canUpdateIVOUser || canToggleFullAccess || canReadIVO || canDelete ||
            canUpdate || canRead;
    }


    const checkRolesAdminsAccessibility = () => {
        const readRole = ability.can('read', "role")
        const createRole = ability.can('create', "role")
        const updateRole = ability.can('update', "role")
        const deleteRole = ability.can('delete', "role")
        const readAdmin = ability.can('read', "admin")
        const createAdmin = ability.can('create', "admin")
        const updateAdmin = ability.can('update', "admin")
        const deleteAdmin = ability.can('delete', "admin")

        return readRole || createRole || updateRole || deleteRole || readAdmin ||
            createAdmin || updateAdmin || deleteAdmin;
    }


    return (

        <nav className='navbar navbar-expand-lg navbar-light pb-0' id='topnav'>
            <div className='container-fluid px-3'>
                <img src={logo} alt="Estating logo"
                     height={50}
                     className='mr-3 mb-3'
                />
                <button
                    className='navbar-toggler ml-auto'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbar'
                    aria-controls='navbar'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='collapse navbar-collapse w-100 mx-0' id='navbar'>
                    <ul className='navbar-nav w-100'>
                        {
                            checkPropertyAccessibility() ? <li
                                    className='nav-item'
                                    style={{backgroundColor: category === 'properties' ? ' #12263f' : ''}}
                                >
                                    <Link
                                        to='/opportunities'
                                        className={`nav-link m-3 ${category === 'properties' ? 'active' : ''} `}
                                    >
                                        Opportunities
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {
                            checkUserAccessibility() ? <li
                                    className='nav-item'
                                    style={{backgroundColor: category === 'users' ? ' #12263f' : ''}}
                                >
                                    <Link
                                        to='/users'
                                        className={`nav-link m-3 ${category === 'users' ? 'active' : ''} `}
                                    >
                                        Users
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {
                            checkRolesAdminsAccessibility() ? <li
                                    className='nav-item'
                                    style={{backgroundColor: category === 'roles' ? ' #12263f' : ''}}
                                >
                                    <Link
                                        to='/roles'
                                        className={`nav-link m-3 ${category === 'roles' ? 'active' : ''} `}
                                    >
                                        Roles and Admins
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {/*{*/}
                        {/*    checkUserContractsAccessibility() ?*/}
                        {/*        <li*/}
                        {/*            className='nav-item'*/}
                        {/*            style={{backgroundColor: category === 'contracts' ? ' #12263f' : ''}}*/}
                        {/*        >*/}
                        {/*            <Link*/}
                        {/*                to='/contracts'*/}
                        {/*                className={`nav-link m-3 ${category === 'contracts' ? 'active' : ''} `}*/}
                        {/*            >*/}
                        {/*                Contracts*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*        :*/}
                        {/*        null*/}
                        {/*}*/}
                        {
                                <li
                                    className='nav-item'
                                    style={{backgroundColor: category === 'sessions' ? ' #12263f' : ''}}
                                >
                                    <Link
                                        to='/sessions'
                                        className={`nav-link m-3 ${category === 'sessions' ? 'active' : ''} `}
                                    >
                                        Sessions
                                    </Link>
                                </li>
                        }
                        {
                                <li
                                    className='nav-item'
                                    style={{backgroundColor: category === 'education-materials-groups' ? '#12263f' : ''}}
                                >
                                    <Link
                                        to='/education-materials-groups'
                                        className={`nav-link m-3 ${category === 'education-materials-groups' ? 'active' : ''}`}
                                    >
                                        Estating Academy
                                    </Link>

                                </li>
                        }

                        <li
                            className='nav-item d-flex align-items-center ml-auto'
                        >
                            <button className='btn btn-outline-danger my-2'
                                    onClick={logOut}
                            >
                                Logout
                            </button>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    );

}


export default TopNavbar;
