import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from './base-query';
import { EducationMaterialsGroup } from '../types/education-materials/education-materials-group';

export const educationMaterialGroupsApi = createApi({
  reducerPath: 'educationMaterialGroupsApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
		getEducationMaterialGroups: builder.query<Array<EducationMaterialsGroup>, void>({
			query: () => `education-materials-groups`
		})
	}),
});

export const { useGetEducationMaterialGroupsQuery } = educationMaterialGroupsApi;