import { memo, FC, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { PropertyTeaserType, SubscriptionStatus } from '../../store/property/types';
import { Link } from 'react-router-dom';
import { Can } from '../../casl/Can';
import { ItemTypes } from '../../types/drag-and-drop/item-types';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableItem } from '../../types/drag-and-drop/draggable-item';

interface ProptertiesTableRowProps {
  property: PropertyTeaserType;
  moveProperty: (id: string, to: number) => void;
  findProperty: (id: string) => { property: PropertyTeaserType; index: number };
  setPropertyToCopy: Dispatch<SetStateAction<{
    name: string;
    id: string;
  }>>;
  setPropertyToDelete: Dispatch<SetStateAction<string>>;
  changeDefaultPropertyMode: (id: string, defaultPropertyMode: boolean) => void;
}

export const ProptertiesTableRow: FC<ProptertiesTableRowProps> = memo(({
  property,
  moveProperty,
  findProperty,
  setPropertyToDelete,
  setPropertyToCopy,
  changeDefaultPropertyMode
}) => {
  const originalIndex = useMemo(() => 
    findProperty(property._id as string).index,
  [findProperty, property._id]);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.PROPERTY_TEASER,
      item: { id: property._id as string, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveProperty(droppedId, originalIndex);
        }
      },
    }),
    [property._id, originalIndex, moveProperty]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.PROPERTY_TEASER,
      hover({ id: draggedId }: DraggableItem) {
        if (draggedId !== property._id) {
          const { index: overIndex } = findProperty(property._id as string);
          moveProperty(draggedId, overIndex);
        }
      },
    }),
    [findProperty, moveProperty]
  );

  const opacity = useMemo(() => isDragging ? 0 : 1, [isDragging]);

  return (
    <tr
      id={property._id}
      ref={(node) => drop(preview(node))}
      style={{ opacity }}
      className='position-relative'
    >
      <Can I='reorder' a='property'>
        <td className='align-middle'>
          <a
            href='#!'
            onClick={(e) => e.preventDefault()}
            className={`btn btn-sm btn-primary mr-2 d-flex justify-content-center align-items-center`}
            style={{ width: '30px', height: '30px' }}
            ref={(node) => drag(node)}
          >
            <i className='fe fe-move'></i>
          </a>
        </td>
      </Can>
      <td className='align-middle w-25'>
        <Link to={`/opportunity-edit/${property._id}`} className='table-row'>
          {property.internalName || property.name}
        </Link>
      </td>
      <td className='align-middle'>
        <label className='mb-0 btn btn-primary'>
          {SubscriptionStatus[property.options.status as keyof typeof SubscriptionStatus]}
        </label>
      </td>
      <td className='align-middle'>{property && property.locations && property.locations[0]?.address}</td>
      <Can I='update' a='property'>
        <td>
          <div className='d-flex mt-2'>
            <div className='custom-control custom-checkbox-toggle'>
              <input
                type='checkbox'
                name='isDefaultProperty'
                defaultChecked={property.isDefaultProperty}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  changeDefaultPropertyMode(property._id || '', e.target.checked);
                }}
                className='custom-control-input'
                id={'isDefaultProperty' + property._id}
              />
              <label className='custom-control-label' htmlFor={'isDefaultProperty' + property._id} />
            </div>
          </div>
        </td>
      </Can>
      <td>
        <p className='mb-0'>
          <b>Created:</b>{' '}
          {(property.createdAt &&
            new Date(property.createdAt).toLocaleDateString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            })) ||
            'Not available'}
        </p>
        <p className='mb-0'>
          <b>Updated: </b>{' '}
          {(property.updatedAt &&
            new Date(property.updatedAt).toLocaleDateString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            })) ||
            'Not available'}
        </p>
      </td>
      <td className='align-middle text-center'>
        {property.qrCode ? (
          <a title={property.name} href={property.qrCode} download={`${property.name}-${property._id}.png`}>
            <img src={property.qrCode} className='avatar qr-image' alt='QR of property' height={30} />
          </a>
        ) : (
          <p>
            Not available <br />
            (publish property to see it)
          </p>
        )}
      </td>
      <td className='align-middle'>
        <div className='btn-group'>
          <Can I='create' a='property'>
            <a
              href='#!'
              onClick={() =>
                setPropertyToCopy({
                  name: property.name,
                  id: property._id || '',
                })
              }
              data-target='#set-duplicate-property-name'
              data-toggle='modal'
              className='btn btn-sm btn-white'
            >
              Copy
            </a>
          </Can>
          <Can I='update' a='property'>
            <Link className='btn btn-white btn-sm' to={`/opportunity-edit/${property._id || ''}`}>
              Edit
            </Link>
          </Can>
          <Can I='delete' a='property'>
            <button
              className='btn btn-danger btn-sm'
              data-toggle='modal'
              onClick={() => setPropertyToDelete(property._id || '')}
              data-target='#delete-property'
            >
              Delete
            </button>
          </Can>
        </div>
      </td>
    </tr>
  );
});
