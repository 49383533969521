import React, {useContext} from 'react'
import {User} from "../../store/users/types";
import {PropertyTeaserType} from "../../store/property/types";
import {UsersAPI} from "../../api/userApi";
import {Link} from "react-router-dom";
import {AbilityContext, Can} from "../../casl/Can";
import {PropertyTeaserAPI} from "../../api/propertyTeaserApi";

type UserPropertiesTableProps = {
    user: User
}

const UserPropertiesTable: React.FC<UserPropertiesTableProps> = ({user}) => {

    const [localUser, setLocalUser] = React.useState<User | null>(null)
    const [loading, setLoading] = React.useState(false)
    const [properties, setProperties] = React.useState<PropertyTeaserType[]>([])
    const ability = useContext(AbilityContext)

    React.useEffect(() => {
        setLocalUser(user)
    }, [user])

    React.useEffect(() => {
        setLoading(true)
        PropertyTeaserAPI.findAll()
            .then(properties => {
                setProperties(properties)
            })
            .catch(e => {
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    if (!localUser) {
        return null
    }


    const attachPropertyForUser = (propertyId: string, user?: User) => {
        const targetUser = user || localUser
        const haveAccess = ability.can('attachDetachProperty', 'user')
        if (!haveAccess) {
            alert('Access denied')
            return
        }

        if (targetUser) {
            UsersAPI.attachPropertiesForUser(
                targetUser._id,
                [propertyId]
            ).then(user => {
                setLocalUser(user)
            })
        }

    }

    const detachPropertyForUser = (propertyId: string, user?: User) => {
        const targetUser = user || localUser
        const haveAccess = ability.can('attachDetachProperty', 'user')
        if (!haveAccess) {
            alert('Access denied')
            return
        }

        if (targetUser) {
            UsersAPI.detachPropertiesForUser(
                targetUser._id,
                [propertyId]
            ).then(user => {
                setLocalUser(user)
            })
        }

    }

    const toggleAttachProperty = (id: string = '') : any => {
        if (localUser && properties) {
            const propertyAttached = localUser.properties && localUser.properties.find(p => p.property && p.property._id === id)
            const haveFullAccess = !!localUser.is_verified

            return (
                (propertyAttached || haveFullAccess) ?
                    {
                        elem: <div className={`circle-30 bg-success ${haveFullAccess ? 'opacity-07' : 'cursor-pointer'}`}
                                   onClick={() => { !haveFullAccess && detachPropertyForUser(id, localUser)}}/>,
                        isInterested: propertyAttached?.isInterested,
                        isScanned: propertyAttached?.isScanned,
                        attached: true
                    }
                    :
                    {
                        elem: <div className='circle-30 bg-danger cursor-pointer'
                                   onClick={() => attachPropertyForUser(id)}
                        />,
                        isInterested: false,
                        isScanned: false,
                        attached: false
                    }

            )
        }

    }



    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='card'>
                <div className="table-responsive">
                    <table className='table table-bordered mb-0'>
                        <thead>
                        <Can I='toggleFullAccess' a='user'>
                            <tr>
                                <td colSpan={2} />
                                <th className='text-center'>
                                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                                        <label className='mb-0 ml-3' htmlFor={`${localUser._id}-full_access `}>Full Access</label>
                                        <div className='custom-control custom-checkbox-toggle ml-3'>
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`${localUser._id}-full_access `}
                                                defaultChecked={user.is_verified}
                                                onChange={(e) => {
                                                    UsersAPI.toggleVerify(user._id, e.target.checked)
                                                        .then(is_verified => {
                                                            setLocalUser({
                                                                ...localUser,
                                                                is_verified
                                                            })
                                                        }).catch(() => {})
                                                }}
                                            />
                                            <label className="custom-control-label"
                                                   htmlFor={`${user._id}-full_access `}
                                            />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </Can>
                        <tr>
                            <td className='text-center'>Opportunities name</td>
                            <td className='text-center'>Bookmark</td>
                            <th className='text-center'>On / Off</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            properties.map(p => {
                                const {isInterested, isScanned, elem, attached} = toggleAttachProperty(p._id)
                                return (
                                    <tr key={p._id}>
                                        <th className='bg-dark'>
                                            <Link to={`/opportunity-edit/${p._id}`} target='_blank'>
                                                {p.name || p._id}
                                            </Link>
                                            <div className='mt-2'>
                                                {(isInterested && attached) && <span
                                                    className='badge badge-soft-success p-2 mr-2'>Interested</span>}
                                                {(isScanned && attached) && <span
                                                    className='badge badge-soft-secondary p-2 mr-2'>Scanned</span>}
                                            </div>
                                        </th>
                                        <td className='bg-dark'>
                                            {user.bookmarks.find((bookmark) => bookmark?.property?._id === p?._id)?.status}
                                        </td>
                                        <td>
                                            {elem}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default UserPropertiesTable
