import {Action} from "redux";
import {RuleType} from "../rules/types";

export const QUERY_ROLES = 'QUERY_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

export type RoleType = {
    _id?: string,
    role: string,
    label: string,
    rules: RuleType[]
}

export interface QueryRoles extends Action {
    type: typeof QUERY_ROLES,
    payload: RoleType[]
}

export interface DeleteRole extends Action {
    type: typeof DELETE_ROLE,
    payload: string
}

export interface AddRole extends Action {
    type: typeof ADD_ROLE,
    payload: RoleType
}

export interface UpdateRole extends Action {
    type: typeof UPDATE_ROLE,
    payload: {roleId: string, updated: RoleType}
}

export interface RolesState {
    roles: RoleType[]
}

export const INITIAL_STATE: RolesState = {
    roles: []
};

