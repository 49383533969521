import React from 'react'
import {User} from "../../store/users/types";
import {DocuSignApi} from "../../api/docusignApi";
import Button from "../../components/Button";
import {UsersAPI} from "../../api/userApi";
import {DocusignTemplate} from "../contacts/SendContracts";

type UserContractsProps = {
    user: User,
}

type SignerRoles = 'client' | 'luxops1' | 'issuer1' | 'luxops2' | 'issuer2'

export type Signer = {
    signerEmail: string,
    signerName: string,
    roleName: SignerRoles
}

export type ContractType = {
    template: DocusignTemplate,
    signers: Signer[],
    statusDateTime: string,
    envelopeId: string,
    status: string,
    _id: string
}

const UserContracts: React.FC<UserContractsProps> = ({
                                                         user
                                                     }) => {

    const [loading, setLoading] = React.useState(false)
    const [contracts, setContracts] = React.useState<ContractType[]>([])

    const [actionLoading, setActionLoading] = React.useState(false)
    const [actionRowId, setActionRowId] = React.useState('')

    React.useEffect(() => {
        setLoading(true)
        UsersAPI.queryUserContracts(user._id)
            .then(contracts => {
                setContracts(contracts)
            })
            .catch(e => {})
            .finally(() => {
                setLoading(false)
            })
    }, [user._id])


    const checkStatus = (envelopeId: string, userId: string) => {
        setActionLoading(true)
        DocuSignApi.updateEnvelopeStatus(
            envelopeId,
            userId
        ).then(contracts => {
            setContracts(contracts)
        }).catch(() => {})
            .finally(() => {
                setActionLoading(false)
                setActionRowId('')
            })
    }

    return (
        <>
            {
                loading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    contracts && contracts.length > 0 ?
                        contracts.map((contract, cIndex) => {
                            const {template, signers, statusDateTime} = contract
                            const {name, description, emailSubject} = template

                            return (
                                <div key={contract._id}
                                     className='alert alert-light justify-content-between d-flex align-items-start flex-wrap position-relative'>
                                    {
                                        actionLoading && actionRowId === contract._id ?
                                            <div
                                                className='w-100 h-100 position-absolute bg-light d-flex justify-content-center align-items-center'
                                                style={{
                                                    zIndex: 2,
                                                    opacity: 0.5,
                                                    top: 0,
                                                    left: 0
                                                }}>
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className='col-xl-3 px-0'>
                                        <hr/>
                                        <h3 className='text-center'>Template</h3>
                                        <hr/>
                                        <p className='mb-0'><b>Name:</b> {name}</p>
                                        <p className='mb-0'><b>Description:</b> {description}</p>
                                        <p className='mb-0'><b>Email subject:</b> {emailSubject}</p>
                                        <h4><b>Date of creation:</b> {new Date(statusDateTime).toLocaleDateString('es-ES', {
                                            minute: '2-digit',
                                            hour: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            month: '2-digit'

                                        })}</h4>
                                    </div>
                                    <div className='col-xl-3 px-0'>
                                        <hr/>
                                        <h3 className='text-center'>Signers</h3>
                                        <hr/>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                signers.map((signer, sIndex) => {
                                                    return (
                                                        <div key={contract._id + '_' + cIndex + '_signer_' + sIndex}
                                                             className='p-3 rounded m-2 border'>
                                                            <p className='mb-0'><b>Email: </b>{signer.signerEmail}</p>
                                                            <p className='mb-0'><b>Name: </b>{signer.signerName}</p>
                                                            <p className='mb-0'><b>Role: </b>{signer.roleName}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xl-3 px-0'>
                                        <hr/>
                                        <h3 className='text-center'>Envelope information</h3>
                                        <hr/>
                                        <p><b>Status: </b>{contract.status}</p>
                                        {
                                            contract.status !== 'completed' ?
                                                <Button className='btn btn-primary btn-sm'
                                                        onClick={() => {
                                                            setActionRowId(contract._id)
                                                            checkStatus(contract.envelopeId, user._id)
                                                        }}>
                                                    Update status
                                                </Button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            )
                        })
                        :
                        null

            }

        </>
    )
}

export default UserContracts
