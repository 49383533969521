import React from 'react'
import {useAbility} from "@casl/react";
import {AbilityContext, Can} from "../../casl/Can";
import Table from "./Table";
import UserInteractionsTable from '../user/UserInteractionsTable';

type UsersType = {};

const Users: React.FC<UsersType> = () => {

    const [activeTab, setActiveTab] = React.useState<'pendingToDelete' | 'allUsers' | 'interactions'>('allUsers');

    const ability = useAbility(AbilityContext);
    const hasReadAccessPendingUsers = ability.can("readDisabled", "user");


    return (
        <>
            <div className="">
                <ul className="nav nav-tabs mb-3" role="tablist">
                    {
                        hasReadAccessPendingUsers ?
                            <li className="nav-item" onClick={() => setActiveTab("allUsers")}>
                                <a className={`nav-link pt-0 pb-2 ${activeTab === "allUsers" ? 'active' : ''}`}
                                   data-toggle="tab" href="#tab_users" role="tab"
                                   aria-selected="true">All Users</a>
                            </li>
                            :
                            null
                    }
                    {
                        hasReadAccessPendingUsers ?
                            <li className="nav-item" onClick={() => setActiveTab("pendingToDelete")}>
                                <a className={`nav-link pt-0 pb-2 ${activeTab === "pendingToDelete" ? 'active' : ''}`}
                                   data-toggle="tab" href="#tab_pending_to_delete" role="tab"
                                   aria-selected="false">Pending To Delete</a>
                            </li>
                            :
                            null
                    }
                    <li className="nav-item" onClick={() => setActiveTab("interactions")}>
                        <a className={`nav-link pt-0 pb-2 ${activeTab === "interactions" ? 'active' : ''}`}
                            data-toggle="tab" href="#tab_interactions" role="tab"
                            aria-selected="false">Interactions</a>
                    </li>
                </ul>
                <Can I='read' a='user'>
                    <div className="tab-content" id="tabs-tabContent">
                        {
                            activeTab === 'allUsers' ?
                                <div
                                    className={`tab-pane fade show ${activeTab === "allUsers" ? 'active' : ''}`}
                                    id="tab_users"
                                    role="tabpanel">
                                    <Table tableData='allUsers'/>
                                </div>
                                :
                                null
                        }
                        {
                            activeTab === 'pendingToDelete' ?
                                <div
                                    className={`tab-pane fade show ${activeTab === "pendingToDelete" ? 'active' : ''}`}
                                    id="tab_pending_to_delete"
                                    role="tabpanel">
                                    <Table tableData='pendingToDelete'/>
                                </div>
                                :
                                null
                        }
                        {
                            activeTab === 'interactions' ?
                                <div
                                    className={`tab-pane fade show ${activeTab === "interactions" ? 'active' : ''}`}
                                    id="tab_interactions"
                                    role="tabpanel">
                                    <UserInteractionsTable/>
                                </div>
                                :
                                null
                        }
                    </div>
                </Can>

            </div>
        </>
    )
}

export default Users;
