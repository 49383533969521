import React, {ChangeEvent, MouseEvent, useContext} from "react";
import {Input} from "../../components/Input";
import {UsersAPI} from "../../api/userApi";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import validator from 'validator'
import {AbilityContext} from "../../casl/Can";
import FileUpload from "../../components/FileUpload";
import Select from "../../components/Select";
import {COUNTRIES} from "../../constants/countries";
import {User} from "../../store/users/types";
import {SelectDate} from "../../components/DatePicker";
import RadioButtons from "../../components/RadioButtons";
import _ from "lodash";

type OfficeAddressType = {
    street: string,
    streetAddition: string,
    postalCode: string,
    poBox: string,
    city: string,
    country: string,
    estate: string,
    phoneNumber: string,
    faxNumber: string,
    serviceProviderName: string,
    registrationNumber: string,
    contact: {
        name: string,
        email: string,
        phone: string,
    },
};

type ContactAddressType = Partial<Pick<OfficeAddressType, 'street' | 'streetAddition' | 'postalCode' | 'poBox' | 'city' | 'country' | 'estate'> & { contactPersonName: string }>

type BusinessActivitiesType = {
    numberOfSalespeople: string,
    backgroundSalespeople: string,
    salesLocations: string,
    clientSegments: string[],
    totalAssetsUnderManagement: string,
    offerAdvisoryMandates: 'Yes' | 'No',
    offerDiscretionaryMandates: 'Yes' | 'No',
    areClientsProfiled: 'Yes' | 'No',
    clientsDomicilies: string,
    coreProductOfferings: string,
    custodianBanks: string,
    numberOfClients: string,
    clientRelationShip: string[]
};

type CompanyDataType = {
    dataOfIncorporation: {
        date: string,
        documents: string[],
    },
    ownership: {
        value: string,
        documents: string[],
    },
    licenseOfOperate: {
        value: string,
        documents: string[]
    },
    registrations: {
        value: string,
        documents: string[]
    },
    regulatoryStatus: string,
    supervisorAuthority: string,
    serviceAgreementSignedOn: string
};

type InternalOrganisationType = {
    riskManagement: 'Inhouse' | 'Outsourced' | 'No',
    compliance: 'Inhouse' | 'Outsourced' | 'No',
    operations: 'Inhouse' | 'Outsourced' | 'No',
    manuals: 'Inhouse' | 'Outsourced' | 'No',
    activitiesPerformed: string,
    authorizedSignatoriesA?: {
        name: string,
        email: string,
        [key: string]: string
    },
    authorizedSignatoriesB?: {
        name: string,
        email: string
        [key: string]: string
    },
    signingRightsDocuments: string[]
};

type AdditionalInfoProps = {
    is_accepted: boolean,
    user: User
}

const ReviewField = (props: { id: string, onChange: (id: string, status: string, comment: string) => void, reviewObject: any }) => {
  const onChangeStatus = (e) => {
    props.onChange(props.id, e.target.value, props.reviewObject.comment);
  }
  const onChangeComment = (e) => {
    props.onChange(props.id, props.reviewObject.status, e.target.value);
  }

  return (
    <div className='d-flex' style={{ gap: '3px' }}>
      <Select
        selected={props.reviewObject.status}
        onChange={onChangeStatus}
        selectProps={{ style: { minWidth: '125px' } }}
        values={['New', 'Approved', 'Reverted', 'Unknown']}
      />
      <Input
        value={props.reviewObject.comment}
        onChange={onChangeComment}
        inputProps={{ placeholder: 'Comment here...', style: { minWidth: '300px' } }}
      />
    </div>
  );
};

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({user, is_accepted = false}) => {
    const [officeAddress, setOfficeAddress] = React.useState<OfficeAddressType>({
        street: "",
        streetAddition: "",
        postalCode: "",
        poBox: "",
        city: "",
        country: "",
        estate: "",
        phoneNumber: "",
        faxNumber: "",
        serviceProviderName: "",
        registrationNumber: "",
        contact: {
            name: "",
            email: "",
            phone: "",
        }
    });
    const [contactAddress, setContactAddress] = React.useState<ContactAddressType>({
        street: "",
        streetAddition: "",
        postalCode: "",
        poBox: "",
        city: "",
        country: "",
        estate: "",
        contactPersonName: ""
    });
    const [companyData, setCompanyData] = React.useState<CompanyDataType>({
        dataOfIncorporation: {
            date: "",
            documents: [],
        },
        ownership: {
            value: "",
            documents: [],
        },
        regulatoryStatus: "",
        supervisorAuthority: "",
        licenseOfOperate: {
            value: "",
            documents: []
        },
        registrations: {
            value: "",
            documents: []
        },
        serviceAgreementSignedOn: ""
    });
    const [businessActivities, setBusinessActivities] = React.useState<BusinessActivitiesType>({
        numberOfSalespeople: "",
        backgroundSalespeople: "",
        salesLocations: "",
        clientSegments: [""],
        totalAssetsUnderManagement: "",
        offerAdvisoryMandates: "No",
        areClientsProfiled: "No",
        offerDiscretionaryMandates: "No",
        clientsDomicilies: "",
        coreProductOfferings: "",
        custodianBanks: "",
        numberOfClients: "",
        clientRelationShip: [""]
    });
    const [internalOrganisation, setInternalOrganisation] = React.useState<InternalOrganisationType>({
        riskManagement: "No",
        compliance: "No",
        operations: "No",
        manuals: "No",
        activitiesPerformed: "",
        signingRightsDocuments: [],
    });
    const [reviewData, setReviewData] = React.useState<any>(undefined);

    const getReviewField = (key: string):any  => {
        return _.get(reviewData, key);
    }

    const onChangeReviewField = (id: string, status: string, comment: string) => {
        setReviewData((reviewData) => {
            _.set(reviewData, `${id}.status`, status);
            _.set(reviewData, `${id}.comment`, comment);
            return {...reviewData};
        })
    }

    const changeInternalOrganisation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        const splitedName = name.split(/([.])/);

        if (splitedName.includes(".")) {
            const fields = splitedName.filter(val => val !== '.')

            setInternalOrganisation(internalOrganisation => {
                internalOrganisation[fields[0]][[fields[1]]] = value

                return {...internalOrganisation}
            });
        } else {
            setInternalOrganisation({
                ...internalOrganisation,
                [name]: value
            });
        }


    };
    React.useEffect(() => {
        if (user._id) {
            UsersAPI.getIvoAdditionalInfo(user._id)
                .then(additionalInfo => {
                    if (additionalInfo) {
                        setOfficeAddress(additionalInfo.officeAddress)
                        setCompanyData(additionalInfo.companyData)
                        setInternalOrganisation(additionalInfo.internalOrganisation)
                        setBusinessActivities(additionalInfo.businessActivities)
                        setContactAddress(additionalInfo.contactAddress)
                        setReviewData(additionalInfo.review);
                    }

                })
        }
    }, [user])

    const changeOfficeAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        const splitedName = name.split(/([.])/);

        if (splitedName[1] === ".") {
            setOfficeAddress({
                ...officeAddress,
                [splitedName[0]]: {
                    ...officeAddress.contact,
                    [splitedName[2].toLowerCase()]: value
                }
            });
        } else {
            setOfficeAddress({
                ...officeAddress,
                [name]: value
            });
        }
    };

    const changeContactAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setContactAddress({
            ...contactAddress,
            [name]: value
        });
    };

    const changeCompanyData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        const splitedName = name.split(/([.])/);

        if (splitedName.includes(".")) {
            const fields = splitedName.filter(val => val !== '.')

            setCompanyData(companyData => {
                companyData[fields[0]][[fields[1]]] = value

                return {...companyData}
            });
        } else {
            setCompanyData({
                ...companyData,
                [name]: value
            });
        }
    };

    const _changeCompanyData = (name, value) => {
        const splitedName = name.split(/([.])/);

        if (splitedName.includes(".")) {
            const fields = splitedName.filter(val => val !== '.')

            setCompanyData(companyData => {
                companyData[fields[0]][[fields[1]]] = value

                return {...companyData}
            });
        } else {
            setCompanyData({
                ...companyData,
                [name]: value
            });
        }
    };

    const removeDocument = (field: 'dataOfIncorporation' | 'ownership' | 'licenseOfOperate' | 'registrations' | '', index: number) => {
        setCompanyData(companyData => {
            companyData[field].documents = companyData[field].documents.filter((_, idx) => idx !== index)

            return {...companyData}
        })
    }

    const removeSigningRightsDocument = (field: 'signingRightsDocuments', index: number) => {
        setInternalOrganisation(internalOrganisation => {
            internalOrganisation[field] = internalOrganisation[field].filter((_, idx) => idx !== index)

            return {...internalOrganisation}
        })
    }


    const [documentsToAdd, setDocumentsToAdd] = React.useState<any>({
        incorporationDocuments: [],
        ownershipDocuments: [],
        licenseOfOperateDocuments: [],
        registrationsDocuments: [],
        signingRightsDocuments: []
    })

    const onChangeDocuments = (e: ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target

        if (files) {
            const file = Array.from(files);
            setDocumentsToAdd(documentsToAdd => {
                documentsToAdd[e.target.name].push(...file);
                return {...documentsToAdd}
            })
        }
    }

    const removeUploadedFile = (field: 'incorporationDocuments' | 'ownershipDocuments' |
        'licenseOfOperateDocuments' | 'registrationsDocuments' | 'signingRightsDocuments', file: File) => {
        setDocumentsToAdd(documentsToAdd => {
            documentsToAdd[field] = documentsToAdd[field].filter((f) => f.name !== file.name)

            return {...documentsToAdd}
        })
    }


    const [hasError, setHasError] = React.useState<boolean>(false)
    const [validationResults, setValidationResults] = React.useState<{ [key: string]: boolean }>({})

    const changeValidation = (isValid: boolean, field: string) => {
        if (validationResults[field] !== isValid) {
            setValidationResults(validationResults => (
                    {
                        ...validationResults,
                        [field]: isValid
                    }
                )
            )
        }
    }

    const _isValid = () => {
        return Object.values(validationResults).filter(isValid => !isValid).length === 0 ||
            Object.keys(validationResults).length === 0
    }

    const toggleIsAccepted = async (is_accepted: boolean) => {
        if (user._id) {
            await UsersAPI.toggleAcceptUserAdditionalInfo(user._id, is_accepted)
        }
    }

    const [responseMessage, setResponseMessage] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const save = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (!user._id) {
            return
        }
        const {
            incorporationDocuments: incorporationDocumentToAdd,
            ownershipDocuments: ownershipDocumentToAdd,
            licenseOfOperateDocuments: licenseOfOperateDocumentToAdd,
            registrationsDocuments: registrationsDocumentToAdd,
            signingRightsDocuments: signingRightsDocumentToAdd
        } = documentsToAdd

        setLoading(true)

        const additionalInformation = {
            companyData,
            officeAddress,
            internalOrganisation,
            businessActivities,
            contactAddress
        }
        UsersAPI.upsertAdditionalInfoIVO(
            user._id,
            additionalInformation,
            {
                incorporationDocumentToAdd,
                ownershipDocumentToAdd,
                licenseOfOperateDocumentToAdd,
                registrationsDocumentToAdd,
                signingRightsDocumentToAdd
            },
            reviewData
        )
            .then(updated => {
                setOfficeAddress(updated.officeAddress)
                setCompanyData(updated.companyData)
                setInternalOrganisation(updated.internalOrganisation)
                setBusinessActivities(updated.businessActivities)

                setResponseMessage(() => {
                    setTimeout(() => {
                        setResponseMessage('')
                    }, 3000)

                    return 'Successfully updated'
                })
                setHasError(false)
                setDocumentsToAdd(() => ({
                    incorporationDocuments: [],
                    ownershipDocuments: [],
                    licenseOfOperateDocuments: [],
                    registrationsDocuments: [],
                    signingRightsDocuments: []
                }))
            }).catch(e => {
            setHasError(true)
            setResponseMessage(() => {
                setTimeout(() => {
                    setResponseMessage('')
                }, 3000)

                return e.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const changeBusinessActivities = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setBusinessActivities({
            ...businessActivities,
            [name]: value
        });
    };

    const changeBusinessActivitiesArray = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        const arr = businessActivities[name].includes(value) ? businessActivities[name].filter(i => i !== value) : [...businessActivities[name], value];
        setBusinessActivities({
            ...businessActivities,
            [name]: arr
        });
    };

    const _isSameAddress = () => {
        const {
            streetAddition: oStreetAddition,
            street: oStreet,
            estate: oEstate,
            postalCode: oPostalCode,
            poBox: oPoBox,
            city: oCity,
            country: oCountry,
        } = officeAddress
        const {
            streetAddition: cStreetAddition,
            street: cStreet,
            estate: cEstate,
            postalCode: cPostalCode,
            poBox: cPoBox,
            city: cCity,
            country: cCountry,
        } = contactAddress

        return oStreetAddition === cStreetAddition && oStreet === cStreet &&
            oEstate === cEstate && oPostalCode === cPostalCode && oPoBox === cPoBox &&
            oCity === cCity && oCountry === cCountry
    }

    const ability = useContext(AbilityContext)
    const canUpdateIVO = ability.can('updateIVO', 'user')

    const onApproveAll = () => {
        setReviewData((reviewData) => {
            const aprovingAll = (data: any) => {
                return _.mapValues(data, (o) => {
                    if (o.status) {
                        return {...o, status: 'Approved', comment: ''}
                    } else {
                        return aprovingAll(o);
                    }
                })
            }
            return aprovingAll(reviewData);
        });
    }

    return (
        <div className='mb-5'>
            <Button onClick={() => onApproveAll()} className="btn-primary btn-sm d-block ml-auto mb-3" label="Approve All"/>
            {/*Office Address*/}
            <div className='border p-5'>
                <h2 className='text-center'>Office Address</h2>
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='Street'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.street" reviewObject={getReviewField('officeAddress.street')}/>}
                    name='street'
                    value={officeAddress.street}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeOfficeAddress}
                    label='Street Addition'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.streetAddition" reviewObject={getReviewField('officeAddress.streetAddition')}/>}
                    name='streetAddition'
                    value={officeAddress.streetAddition}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='Postal Code'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.postalCode" reviewObject={getReviewField('officeAddress.postalCode')}/>}
                    name='postalCode'
                    validator={value => validator.isPostalCode(value, "any")}
                    callback={(isValid, field) => changeValidation(isValid, 'contactAddress_' + field)}
                    value={officeAddress.postalCode}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeOfficeAddress}
                    label='PO Box'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.poBox" reviewObject={getReviewField('officeAddress.poBox')}/>}
                    name='poBox'
                    value={officeAddress.poBox}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='City'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.city" reviewObject={getReviewField('officeAddress.city')}/>}
                    name='city'
                    value={officeAddress.city}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='State'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.estate" reviewObject={getReviewField('officeAddress.estate')}/>}
                    containerClass='my-2'
                    name='estate'
                    value={officeAddress.estate}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Select
                    label='Country'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.country" reviewObject={getReviewField('officeAddress.country')}/>}
                    selected={officeAddress.country}
                    onChange={changeOfficeAddress}
                    name='country'
                    values={COUNTRIES}
                    placeholder='Select the country'
                />

                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeOfficeAddress}
                    label='Phone Number'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.phoneNumber" reviewObject={getReviewField('officeAddress.phoneNumber')}/>}
                    name='phoneNumber'
                    value={officeAddress.phoneNumber}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='Fax Number'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.faxNumber" reviewObject={getReviewField('officeAddress.faxNumber')}/>}
                    name='faxNumber'
                    value={officeAddress.faxNumber}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeOfficeAddress}
                    label='Service Provider Name'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.serviceProviderName" reviewObject={getReviewField('officeAddress.serviceProviderName')}/>}
                    name='serviceProviderName'
                    value={officeAddress.serviceProviderName}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='Registration Number'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.registrationNumber" reviewObject={getReviewField('officeAddress.registrationNumber')}/>}
                    name='registrationNumber'
                    value={officeAddress.registrationNumber}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeOfficeAddress}
                    label='Contact Person Name'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.contact.name" reviewObject={getReviewField('officeAddress.contact.name')}/>}
                    name='contact.Name'
                    containerClass='my-2'
                    value={officeAddress.contact.name}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />

                <Input
                    type="email"
                    onChange={changeOfficeAddress}
                    label='Contact Person Email'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.contact.email" reviewObject={getReviewField('officeAddress.contact.email')}/>}
                    name='contact.email'
                    validator={(value => validator.isEmail(value))}
                    callback={changeValidation}
                    value={officeAddress.contact.email}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />

                <Input
                    type="tel"
                    onChange={changeOfficeAddress}
                    label='Contact Person Phone'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="officeAddress.contact.phone" reviewObject={getReviewField('officeAddress.contact.phone')}/>}
                    name='contact.phone'
                    containerClass='my-2'
                    value={officeAddress.contact.phone}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />

                <div className='d-flex align-items-center mt-2'>
                    <label htmlFor={user._id + '_contactAddressIsSame'}
                           className="p-0 m-0 label-fix-width">
                        Contact Address is same
                    </label>
                    <div className="custom-control custom-checkbox-toggle">
                        <input
                            type="checkbox"
                            checked={_isSameAddress()}
                            onChange={e => {
                                if (e.target.checked) {
                                    const {contact, phoneNumber, faxNumber, ...address} = officeAddress
                                    setContactAddress(address)
                                } else {
                                    setContactAddress({
                                        street: '',
                                        streetAddition: '',
                                        estate: '',
                                        postalCode: '',
                                        poBox: '',
                                        city: '',
                                        country: '',
                                    })
                                }
                            }}
                            className="custom-control-input"
                            id={user._id + '_contactAddressIsSame'}/>
                        <label className="custom-control-label"
                               htmlFor={user._id + '_contactAddressIsSame'}/>
                    </div>
                </div>
            </div>

            {/*Contact Address*/}
            <div className='border p-5 my-3'>
                <h2 className='text-center'>Contact Address</h2>
                <Input
                    type="text"
                    onChange={changeContactAddress}
                    label='Street'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.street" reviewObject={getReviewField('contactAddress.street')}/>}
                    name='street'
                    value={contactAddress.street}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeContactAddress}
                    label='Street Addition'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.streetAddition" reviewObject={getReviewField('contactAddress.streetAddition')}/>}
                    name='streetAddition'
                    value={contactAddress.streetAddition}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeContactAddress}
                    label='Postal Code'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.postalCode" reviewObject={getReviewField('contactAddress.postalCode')}/>}
                    name='postalCode'
                    validator={value => validator.isPostalCode(value, "any")}
                    callback={changeValidation}
                    value={contactAddress.postalCode}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeContactAddress}
                    label='PO Box'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.poBox" reviewObject={getReviewField('contactAddress.poBox')}/>}
                    name='poBox'
                    value={contactAddress.poBox}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeContactAddress}
                    label='City'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.city" reviewObject={getReviewField('contactAddress.city')}/>}
                    name='city'
                    value={contactAddress.city}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Input
                    type="text"
                    onChange={changeContactAddress}
                    label='State'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.estate" reviewObject={getReviewField('contactAddress.estate')}/>}
                    name='estate'
                    containerClass='my-2'
                    value={contactAddress.estate}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
                <Select
                    label='Country'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.country" reviewObject={getReviewField('contactAddress.country')}/>}
                    selected={contactAddress.country}
                    onChange={changeContactAddress}
                    name='country'
                    placeholder='Select the country'
                    values={COUNTRIES}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={changeContactAddress}
                    label='Contact Person Name'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="contactAddress.contactPersonName" reviewObject={getReviewField('contactAddress.contactPersonName')}/>}
                    name='contactPersonName'
                    value={contactAddress.contactPersonName}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                />
            </div>

            {/*Company Data*/}
            <div className='border p-5'>
                <SelectDate
                    name='dataOfIncorporation.date'
                    label='Date of Incorporation'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.dataOfIncorporation.date" reviewObject={getReviewField('companyData.dataOfIncorporation.date')}/>}
                    value={new Date(companyData.dataOfIncorporation.date)}
                    onChange={_changeCompanyData}
                />
                <FileUpload
                    id='dataOfIncorporation'
                    documents={companyData.dataOfIncorporation.documents}
                    uploadedDocuments={documentsToAdd.incorporationDocuments}
                    onChange={onChangeDocuments}
                    removeDocument={removeDocument}
                    removeUploadedFile={removeUploadedFile}
                    disabled={!canUpdateIVO}
                    field='dataOfIncorporation'
                    name='incorporationDocuments'
                    label='Add Document'
                />

                <div>
                    <Input
                        type="text"
                        onChange={changeCompanyData}
                        label='Ownership'
                        rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.ownership.value" reviewObject={getReviewField('companyData.ownership.value')}/>}
                        name='ownership.value'
                        inputProps={{
                            disabled: !canUpdateIVO
                        }}
                        value={companyData.ownership.value}
                    />

                    <FileUpload
                        id='ownership'
                        documents={companyData.ownership.documents}
                        uploadedDocuments={documentsToAdd.ownershipDocuments}
                        onChange={onChangeDocuments}
                        removeDocument={removeDocument}
                        removeUploadedFile={removeUploadedFile}
                        disabled={!canUpdateIVO}
                        field='ownership'
                        name='ownershipDocuments'
                        label='Add Document'
                    />

                </div>

                <Input
                    type="text"
                    onChange={changeCompanyData}
                    label='Regulatory Status'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.regulatoryStatus" reviewObject={getReviewField('companyData.regulatoryStatus')}/>}
                    required={true}
                    name='regulatoryStatus'
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={companyData.regulatoryStatus}
                />
                <Input
                    type="text"
                    onChange={changeCompanyData}
                    label='Supervisor Authority'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.supervisorAuthority" reviewObject={getReviewField('companyData.supervisorAuthority')}/>}
                    required={true}
                    name='supervisorAuthority'
                    containerClass='my-2'
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={companyData.supervisorAuthority}
                />

                <div>
                    <Input
                        type="text"
                        onChange={changeCompanyData}
                        label='License of Operate'
                        rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.licenseOfOperate.value" reviewObject={getReviewField('companyData.licenseOfOperate.value')}/>}
                        required={true}
                        name='licenseOfOperate.value'
                        inputProps={{
                            disabled: !canUpdateIVO
                        }}
                        value={companyData.licenseOfOperate.value}
                    />

                    <FileUpload
                        id='licenseOfOperate'
                        documents={companyData.licenseOfOperate.documents}
                        uploadedDocuments={documentsToAdd.licenseOfOperateDocuments}
                        onChange={onChangeDocuments}
                        removeDocument={removeDocument}
                        removeUploadedFile={removeUploadedFile}
                        disabled={!canUpdateIVO}
                        field='licenseOfOperate'
                        name='licenseOfOperateDocuments'
                        label='Add Document'
                    />

                </div>
                <div>
                    <Input
                        type="text"
                        onChange={changeCompanyData}
                        label='Registrations'
                        rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="companyData.registrations.value" reviewObject={getReviewField('companyData.registrations.value')}/>}
                        required={true}
                        name='registrations.value'
                        inputProps={{
                            disabled: !canUpdateIVO
                        }}
                        value={companyData.registrations.value}
                    />

                    <FileUpload
                        id='registrations'
                        documents={companyData.registrations.documents}
                        uploadedDocuments={documentsToAdd.registrationsDocuments}
                        onChange={onChangeDocuments}
                        removeDocument={removeDocument}
                        removeUploadedFile={removeUploadedFile}
                        disabled={!canUpdateIVO}
                        field='registrations'
                        name='registrationsDocuments'
                        label='Add Document'
                    />

                </div>
            </div>

            {/*Internal Organization*/}
            <div className='border p-5 my-3'>
                <h2 className='text-center'>Internal Organization</h2>

                {/*---------------------------- Risk Management start ----------------------------*/}
                <RadioButtons
                    label='Risk Management'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.riskManagement" reviewObject={getReviewField('internalOrganisation.riskManagement')}/>}
                    items={[
                        {
                            label: 'Inhouse',
                            value: 'Inhouse',
                        } ,
                        {
                            label: 'Outsourced',
                            value: 'Outsourced',
                        },
                        {
                            label: 'No',
                            value: 'No',
                        }
                    ]}
                    containerClass='mb-2'
                    checked={internalOrganisation.riskManagement}
                    onChange={changeInternalOrganisation}
                    name='riskManagement'
                />
                {/*---------------------------- Risk Management end ----------------------------*/}

                {/*---------------------------- Compliance start ----------------------------*/}
                <RadioButtons
                    label='Compliance'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.compliance" reviewObject={getReviewField('internalOrganisation.compliance')}/>}
                    items={[
                        {
                            label: 'Inhouse',
                            value: 'Inhouse',
                        } ,
                        {
                            label: 'Outsourced',
                            value: 'Outsourced',
                        },
                        {
                            label: 'No',
                            value: 'No',
                        }
                    ]}
                    containerClass='mb-2'
                    checked={internalOrganisation.compliance}
                    onChange={changeInternalOrganisation}
                    name='compliance'
                />
                {/*---------------------------- Compliance end ----------------------------*/}

                {/*---------------------------- Operations start ----------------------------*/}
                <RadioButtons
                    label='Operations'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.operations" reviewObject={getReviewField('internalOrganisation.operations')}/>}
                    items={[
                        {
                            label: 'Inhouse',
                            value: 'Inhouse',
                        } ,
                        {
                            label: 'Outsourced',
                            value: 'Outsourced',
                        },
                        {
                            label: 'No',
                            value: 'No',
                        }
                    ]}
                    containerClass='mb-2'
                    checked={internalOrganisation.operations}
                    onChange={changeInternalOrganisation}
                    name='operations'
                />
                {/*---------------------------- Operations end ----------------------------*/}

                {/*---------------------------- Manuals end ----------------------------*/}
                <RadioButtons
                    label='Manuals'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.manuals" reviewObject={getReviewField('internalOrganisation.manuals')}/>}
                    items={[
                        {
                            label: 'Inhouse',
                            value: 'Inhouse',
                        } ,
                        {
                            label: 'Outsourced',
                            value: 'Outsourced',
                        },
                        {
                            label: 'No',
                            value: 'No',
                        }
                    ]}
                    containerClass='mb-2'
                    checked={internalOrganisation.manuals}
                    onChange={changeInternalOrganisation}
                    name='manuals'
                />
                {/*---------------------------- Manuals end ----------------------------*/}

                <TextArea
                    onChange={changeInternalOrganisation}
                    label='Activities Performed'
                    textAreaProps={{
                        name: 'activitiesPerformed',
                        disabled: !canUpdateIVO
                    }}
                    value={internalOrganisation.activitiesPerformed}
                />

                <div className='d-flex flex-wrap-small align-items-start my-2'>
                    <label
                        className='label-fix-width'>
                        Authorized Signatories (A)
                    </label>
                    <div className='w-100'>
                        <Input
                            value={internalOrganisation?.authorizedSignatoriesA?.name || ''}
                            name='authorizedSignatoriesA.name'
                            rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.authorizedSignatoriesA.name" reviewObject={getReviewField('internalOrganisation.authorizedSignatoriesA.name')}/>}
                            onChange={changeInternalOrganisation}
                            inputProps={{
                                placeholder: "Name"
                            }}
                        />
                        <Input
                            validator={value => validator.isEmail(value)}
                            callback={(isValid, field) => changeValidation(isValid, field)}
                            value={internalOrganisation?.authorizedSignatoriesA?.email || ''}
                            rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.authorizedSignatoriesA.email" reviewObject={getReviewField('internalOrganisation.authorizedSignatoriesA.email')}/>}
                            containerClass='my-1'
                            name='authorizedSignatoriesA.email'
                            onChange={changeInternalOrganisation}
                            inputProps={{
                                placeholder: "Email"
                            }}
                        />
                    </div>
                </div>

                <div className='d-flex flex-wrap-small align-items-start my-2'>
                    <label
                        className='label-fix-width'>
                        Authorized Signatories (B)
                    </label>
                    <div className='w-100'>
                        <Input
                            value={internalOrganisation?.authorizedSignatoriesB?.name || ''}
                            rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.authorizedSignatoriesB.name" reviewObject={getReviewField('internalOrganisation.authorizedSignatoriesB.name')}/>}
                            name='authorizedSignatoriesB.name'
                            onChange={changeInternalOrganisation}
                            inputProps={{
                                placeholder: "Name"
                            }}
                        />
                        <Input
                            validator={value => validator.isEmail(value)}
                            callback={(isValid, field) => changeValidation(isValid, field)}
                            value={internalOrganisation?.authorizedSignatoriesB?.email || ''}
                            containerClass='my-1'
                            name='authorizedSignatoriesB.email'
                            onChange={changeInternalOrganisation}
                            inputProps={{
                                placeholder: "Email"
                            }}
                            rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="internalOrganisation.authorizedSignatoriesB.email" reviewObject={getReviewField('internalOrganisation.authorizedSignatoriesB.email')}/>}
                        />
                    </div>
                </div>

                <FileUpload
                    id='signingRights'
                    documents={internalOrganisation.signingRightsDocuments}
                    uploadedDocuments={documentsToAdd.signingRightsDocuments}
                    onChange={onChangeDocuments}
                    removeSigningRightsDocument={removeSigningRightsDocument}
                    removeUploadedFile={removeUploadedFile}
                    disabled={!canUpdateIVO}
                    signingRightsField='signingRightsDocuments'
                    name='signingRightsDocuments'
                    label='Add Document'
                    text='*please provide documents confirming signing rights and
                                necessary combinations'
                />
            </div>

            {/*Business Activities*/}
            <div className='border p-5'>
                <h2 className='text-center'>Business Activities</h2>
                <Input
                    label="Number of Salespeople"
                    onChange={changeBusinessActivities}
                    type="text"
                    name="numberOfSalespeople"
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={businessActivities.numberOfSalespeople}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.numberOfSalespeople" reviewObject={getReviewField('businessActivities.numberOfSalespeople')}/>}
                />
                <Input
                    label="Professional Background Salespeople"
                    onChange={changeBusinessActivities}
                    type="text"
                    name="backgroundSalespeople"
                    containerClass='my-2'
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={businessActivities.backgroundSalespeople}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.backgroundSalespeople" reviewObject={getReviewField('businessActivities.backgroundSalespeople')}/>}
                />
                <Input
                    label="Sales Locations"
                    onChange={changeBusinessActivities}
                    type="text"
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    name="salesLocations"
                    value={businessActivities.salesLocations}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.salesLocations" reviewObject={getReviewField('businessActivities.salesLocations')}/>}
                />
                <Input
                    label="Number of Clients"
                    onChange={changeBusinessActivities}
                    type="text"
                    containerClass='my-2'
                    validator={value => validator.isNumeric(`${value}`)}
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    name="numberOfClients"
                    value={businessActivities.numberOfClients}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.numberOfClients" reviewObject={getReviewField('businessActivities.numberOfClients')}/>}
                />
                <RadioButtons
                    label='Client Segments'
                    type='checkbox'
                    items={[
                        {
                            label: 'UHNW',
                            value: 'UHNW',
                        } ,
                        {
                            label: 'HNW',
                            value: 'HNW',
                        },
                        {
                            label: 'Retail',
                            value: 'Retail',
                        }
                    ]}
                    containerClass='mb-2'
                    checkedArray={businessActivities.clientSegments}
                    onChange={changeBusinessActivitiesArray}
                    name='clientSegments'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.clientSegments" reviewObject={getReviewField('businessActivities.clientSegments')}/>}
                />
                <RadioButtons
                    label='Do you profile your clients?'
                    items={[
                        {
                            label: 'Yes',
                            value: 'Yes',
                        } ,
                        {
                            label: 'No',
                            value: 'No',
                        }
                    ]}
                    containerClass='mb-2'
                    checked={businessActivities.areClientsProfiled}
                    onChange={changeBusinessActivities}
                    name='areClientsProfiled'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.areClientsProfiled" reviewObject={getReviewField('businessActivities.areClientsProfiled')}/>}
                />
                <Input
                    label="Total Assets under Management"
                    onChange={changeBusinessActivities}
                    containerClass='my-2'
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    name="totalAssetsUnderManagement"
                    value={businessActivities.totalAssetsUnderManagement}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.totalAssetsUnderManagement" reviewObject={getReviewField('businessActivities.totalAssetsUnderManagement')}/>}
                />
                <RadioButtons
                    label='What’s your relationship with your clients?'
                    type='checkbox'
                    items={[
                        {
                            label: 'Advisory',
                            value: 'Advisory',
                        } ,
                        {
                            label: 'Discretionary',
                            value: 'Discretionary',
                        }
                    ]}
                    containerClass='mb-2'
                    checkedArray={businessActivities.clientRelationShip}
                    onChange={changeBusinessActivitiesArray}
                    name='clientRelationShip'
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.clientRelationShip" reviewObject={getReviewField('businessActivities.clientRelationShip')}/>}
                />



                <Input
                    label="Top 5 Clients Domicilies"
                    onChange={changeBusinessActivities}
                    type="text"
                    name="clientsDomicilies"
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={businessActivities.clientsDomicilies}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.clientsDomicilies" reviewObject={getReviewField('businessActivities.clientsDomicilies')}/>}
                />
                <Input
                    label="Core Product Offerings"
                    onChange={changeBusinessActivities}
                    type="text"
                    containerClass='my-2'
                    name="coreProductOfferings"
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={businessActivities.coreProductOfferings}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.coreProductOfferings" reviewObject={getReviewField('businessActivities.coreProductOfferings')}/>}
                />
                <Input
                    label="Custodian Banks Used for Clients (name banks and location)"
                    onChange={changeBusinessActivities}
                    type="text"
                    name="custodianBanks"
                    inputProps={{
                        disabled: !canUpdateIVO
                    }}
                    value={businessActivities.custodianBanks}
                    rightComponent={reviewData && <ReviewField onChange={onChangeReviewField} id="businessActivities.custodianBanks" reviewObject={getReviewField('businessActivities.custodianBanks')}/>}
                />
                <label>Only banks connected to SIX, Euroclear or Clearstream are eligible</label>

                <SelectDate
                    name='serviceAgreementSignedOn'
                    label='Service agreement signed with Estating on'
                    value={new Date(companyData.serviceAgreementSignedOn)}
                    onChange={_changeCompanyData}
                />

            </div>

            {
                canUpdateIVO ?
                    <div
                        className='bottom-fixed-save justify-content-between bg-light p-3 align-items-center flex-wrap'>
                        <Button
                            label='Save'
                            className={`${!_isValid() ? 'disabled' : ''} btn-success ${loading ? 'is-loading disabled' : ''} ${!_isValid() ? 'disabled' : ''}`}
                            onClick={save}
                            buttonAttributes={{
                                disabled: !_isValid()
                            }}
                        />
                        {
                            responseMessage ?
                                <div
                                    className={`alert mb-0 col-4 px-0 py-2 text-center  ${hasError ? 'alert-danger' : 'alert-success'}`}>
                                    {responseMessage}
                                </div>
                                :
                                null
                        }
                        <div className='d-flex align-items-center'>
                            <label htmlFor={user._id + '_isActive'}
                                   className="p-0 m-0">
                                <p className="h5 p-0 mr-3 mb-0">Verified</p>
                            </label>
                            <div className="custom-control custom-checkbox-toggle">
                                <input
                                    type="checkbox"
                                    defaultChecked={is_accepted}
                                    onChange={e => {
                                        toggleIsAccepted(e.target.checked)
                                    }}
                                    className="custom-control-input"
                                    id={user._id + '_isActive'}/>
                                <label className="custom-control-label"
                                       htmlFor={user._id + '_isActive'}/>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    );
};

export default AdditionalInfo;
