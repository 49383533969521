import React, {MouseEvent} from 'react'
import Input, {InputProps} from "../components/Input";

type InputModalProps = {
    confirmBtnLabel?: string,
    label: string,
    handleYes: (e: MouseEvent<HTMLButtonElement>, inputValues: { [key: string]: any }) => any,
    id: string,
    inputs?: InputProps[]
}

const InputModal: React.FC<InputModalProps> = ({
                                                   confirmBtnLabel,
                                                   label,
                                                   handleYes,
                                                   id,
                                                   inputs
                                               }) => {
    const [inputValues, setInputValues] = React.useState<{ [key: string]: string | number | readonly string[] | undefined }>({})

    React.useEffect(() => {
        if (inputs && inputs.length > 0) {
            setInputValues(inputValues => {
                inputs.forEach(input => {
                    input.name && (inputValues[input.name] = input.value)
                })

                return {...inputValues}
            })
        }
    }, [inputs])

    const onChange = (e) => {
        setInputValues(inputValues => {
            inputValues[e.target.name] = e.target.value

            return {...inputValues}
        })
    }

    return (
        <div className="modal fade" id={id} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className='modal-header'>
                        <h4 className="modal-title">{label}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/*<input type="text"*/}
                        {/*       value={value}*/}
                        {/*       ref={modalInput}*/}
                        {/*       onChange={e => setValue(e.target.value)}*/}
                        {/*       className='form-control' placeholder='Enter the draft name'/>*/}
                        {
                            inputs && inputs.length > 0 ?
                                inputs.map((input, index) => {
                                    const {name, inputProps} = input

                                    return (
                                        <Input
                                            key={id + index}
                                            value={name ? inputValues[name] : ''}
                                            name={name}
                                            className='my-2'
                                            onChange={onChange}
                                            inputProps={inputProps}
                                        />
                                    )
                                })
                                :
                                null
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-white" data-dismiss="modal">Cancel</button>
                            <button type="button" className='btn btn-success'
                                    data-dismiss="modal" onClick={(e) => handleYes(e, inputValues)}>
                                {confirmBtnLabel || 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default InputModal
