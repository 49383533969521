import { ReactText } from "react";
import { AppThunk } from "../types";
import { toast } from "react-toastify";
import { setProperties } from "./actions";
import { PropertyTeaserAPI } from "../../api/propertyTeaserApi";

export const updatePropertiesOrder: (reorderedObjectsIds: Array<string>) => AppThunk =
  (reorderedObjectsIds) => async (dispatch) => {
    let toastId: null | ReactText = null;
    try {

      toastId = toast.loading('Updating order', { draggable: true });

      const response = await PropertyTeaserAPI.updateOrder(reorderedObjectsIds);

      console.log(response);

      dispatch(setProperties(response));

      if (toastId) {
        toast.update(toastId, {
          render: 'Successfully updated order',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
      }
    } catch (error: any) {
      if (toastId) {
        toast.update(toastId, {
          render: error.message || 'Error on updating order',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  };