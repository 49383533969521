import { memo, FC, useCallback } from 'react';
import { EducationMaterial } from '../../../types/education-materials/education-material';
import { LoadedFilePesenter } from './loaded-file-pesenter';
import { ItemTypes } from '../../../types/drag-and-drop/item-types';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableItem } from '../../../types/drag-and-drop/draggable-item';
import styled from 'styled-components';

interface GroupMaterialsListItemProps {
  material: EducationMaterial;
  index: number;
  onDeleteMaterial: (materialId: string) => void;
  onEditMaterial: (materialId: string) => void;
  moveMaterial: (id: string, to: number) => void;
  findMaterial: (id: string) => { material: EducationMaterial; index: number };
  readonly?: boolean;
}

export const MaterialsListItem: FC<GroupMaterialsListItemProps> = memo(
  ({ material, index, onDeleteMaterial, onEditMaterial, moveMaterial, findMaterial, readonly }) => {
    const { _id, file, customThumbnail, mimeType, name } = material;
    const originalIndex = findMaterial(_id).index;

    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: ItemTypes.MATERIAL,
        item: { id: _id, originalIndex },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const { id: droppedId, originalIndex } = item;
          const didDrop = monitor.didDrop();
          if (!didDrop) {
            moveMaterial(droppedId, originalIndex);
          }
        },
      }),
      [_id, originalIndex, moveMaterial]
    );

    const [, drop] = useDrop(
      () => ({
        accept: ItemTypes.MATERIAL,
        hover({ id: draggedId }: DraggableItem) {
          if (draggedId !== _id) {
            const { index: overIndex } = findMaterial(_id);
            moveMaterial(draggedId, overIndex);
          }
        },
      }),
      [findMaterial, moveMaterial]
    );

    const onDelete = useCallback(() => {
      onDeleteMaterial(_id);
    }, [onDeleteMaterial, _id]);

    const onEdit = useCallback(() => {
      onEditMaterial(_id);
    }, [onEditMaterial, _id]);

    const opacity = isDragging ? 0 : 1;

    return (
      <div className='m-3 overflow-hidden' ref={(node) => drop(preview(node))} style={{ opacity }}>
        <div className='position-relative'>
          {!readonly && 
            <button
              className='position-absolute btn btn-sm btn-danger d-flex justify-content-center align-items-center'
              style={{ width: '30px', height: '30px', zIndex: 1 }}
              onClick={onDelete}
            >
              <i className='fe fe-trash'></i>
            </button>
          }
          
          {!readonly && 
            <a
              href='#!'
              onClick={(e) => e.preventDefault()}
              className={`drag-image-btn position-absolute btn btn-sm btn-primary d-flex justify-content-center align-items-center`}
              style={{ width: '30px', height: '30px', zIndex: 1 }}
              ref={(node) => drag(node)}
            >
              <i className='fe fe-move'></i>
            </a>
          }
          
          <div className='image-number px-2' style={{ zIndex: 10 }}>
            {index + 1}
          </div>
          
          <MaterialTitle className="p-2 w-100">
            {name}
          </MaterialTitle>
          
          {!readonly && 
            <button
              className='position-absolute fixed-bottom btn btn-primary btn-sm'
              style={{ zIndex: 1 }}
              onClick={onEdit}
            >
              Edit
            </button>
          }

          {customThumbnail ? (
            <LoadedFilePesenter fileUrl={customThumbnail} mimeType={'image/'} />
          ) :
          (
            <LoadedFilePesenter fileUrl={file} mimeType={mimeType} />
          )}
          
        </div>
      </div>
    );
  }
);

const MaterialTitle = styled.div`
  position: absolute;

  bottom: 27px;
  left: 50%;

  transform: translateX(-50%);

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;

  z-index: 10;
`;
