import {
    ADD_ROLE,
    DELETE_ROLE,
    INITIAL_STATE, QUERY_ROLES, UPDATE_ROLE,
} from './types';

export const rolesReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_ROLES:
            return {
                roles: payload
            }
        case DELETE_ROLE:
            return {
                roles: state.roles.filter(role => role._id !== payload)
            }
        case ADD_ROLE:
            return {
                roles: [...state.roles, payload]
            }
        case UPDATE_ROLE:
            return {
                roles: [...state.roles.map(role => {
                    if (role._id === payload.roleId) {
                        return payload.updated
                    }
                    return role
                })]
            }
        default:
            return state;
    }
};
