import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import { _makeSuperscript } from '../helpers/utils';
import classNames from 'classnames';

type TextAreaProps = {
  id?: string;
  label?: string;
  textAreaProps?: Omit<
    DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'value' & 'style'
  >;
  value?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  containerClass?: string;
  invalidFeedback?: string;
};

const TextArea: React.FC<TextAreaProps> = ({
  textAreaProps,
  className = '',
  onChange = () => {},
  style,
  label,
  value = '',
  containerClass = '',
  invalidFeedback,
}) => {
  return (
    <div className={`d-flex flex-wrap-small flex-column ${containerClass}`}>
      {label && <label className='label-fix-width'>{label}</label>}
      <textarea
        className={classNames(`form-control w-100 textarea-min-height ${className}`, {
          'is-invalid': !!invalidFeedback,
        })}
        onChange={(e) => {
          e.target.value = _makeSuperscript(e.target.value);
          onChange(e);
        }}
        style={{
          ...style,
        }}
        value={value}
        {...textAreaProps}
      />
      {invalidFeedback ? <div className='invalid-feedback'>{invalidFeedback}</div> : null}
    </div>
  );
};

export default TextArea;
