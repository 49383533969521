import { Fragment, useState, FC, memo } from 'react';
import { PROPERTY_TEASER, PROPERTY_TEASER_ESPANISH } from '../../constants/property';
import 'jquery-ui/ui/widgets/sortable';
import { PropertyTeaserType } from '../../store/property/types';
import history from '../../history';
import { PropertyTeaserAPI } from '../../api/propertyTeaserApi';
import PropertyTeaserForm from './property-teaser/PropertyTeaserForm';
import Snackbar from '../../components/Snackbar';
import { DroppedFile } from '../../types/dropped-file';

const AddProperty: FC<{}> = memo(() => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const add = async (translations: { [key: string]: PropertyTeaserType },uploadedImages: DroppedFile[], _, callback) => {
    try {
      setLoading(true);
      const { en: baseValue } = translations;
      const { baseProperty } = await PropertyTeaserAPI.add(baseValue, translations, uploadedImages);

      callback && callback();
      if (baseProperty) {
        history.push(`/opportunity-edit/${baseProperty._id}`);
      }
    } catch (e: any) {
      setErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <PropertyTeaserForm
        initialValues={{ en: PROPERTY_TEASER, 'es': PROPERTY_TEASER_ESPANISH }}
        onSubmit={add}
        isEdit={false}
        isLoading={loading}
        availableLanguages={[
          { label: 'Default', value: 'en' },
          { label: 'Spanish', value: 'es' },
        ]}
      />
      <Snackbar
        show={!!errorMessage}
        position='bottom'
        color='success'
        snackbarHandler={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Fragment>
  );
});

export default AddProperty;
