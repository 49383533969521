import React, {CSSProperties} from 'react'

type Props = {
    style?: CSSProperties
}
const Loading: React.FC<Props> = ({style = {}}) => {


    return (
        <div className="d-flex justify-content-center my-3" style={style}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loading
