import {Action} from "redux";

export const QUERY_SESSIONS = 'QUERY_SESSIONS';

export interface SessionObj {
        _id: string,
        loginTime: Date,
        logoutTime: Date,
        sessionSize: number,
        action: string,
        token: string,
        deviceType: 'web' | 'mobile'
}

export interface Session {
    _id: string,
    sessions: SessionObj[]
    user: {
        firstName: string,
        lastName: string,
        email: string
    },
}

export interface QuerySessions extends Action {
    type: typeof QUERY_SESSIONS,
    payload: Session[]
}

export type ActionType = QuerySessions;

export interface SessionsState {
    sessions: Session[]
}

export const INITIAL_STATE: SessionsState = {
    sessions: []
}

