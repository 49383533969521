import React, { ChangeEvent } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';

export type ShowImagesProps = {
  images: string[];
  setDeletedImages?: (deletedImages: string[]) => any;
  imagesToDelete?: string[];
  prefix: string;
  onChangeMapping?: (e: ChangeEvent<HTMLInputElement>) => any;
  mapping?: any;
  sortCallback?: (sortedImages: string[]) => void;
  containerClassName?: string;
  contentContainerClassName?: string;
  fieldTags?: Array<TagDescription>;
};

const Images: React.FC<ShowImagesProps> = ({
  images,
  setDeletedImages,
  imagesToDelete,
  prefix,
  sortCallback,
  onChangeMapping,
  mapping = {},
  containerClassName,
  contentContainerClassName,
  fieldTags
}) => {
  const toggleDeleteImages = (e: ChangeEvent<HTMLInputElement>) => {
    if (setDeletedImages && imagesToDelete) {
      setDeletedImages(
        e.target.checked ? [...imagesToDelete, e.target.value] : [...imagesToDelete.filter((a) => a !== e.target.value)]
      );
    }
  };

  React.useEffect(() => {
    // TODO check types
    const sortableImages: any = $(`#${prefix}`);
    if (sortableImages && sortCallback) {
      sortableImages.sortable({
        update: () => {
          const newOrdered = sortableImages.sortable('toArray').map((itemId) => {
            const index = itemId.split('_').pop();
            return images[index];
          });

          sortCallback([...newOrdered]);
        },
        handle: `.sortable-${prefix}`,
        containment: 'parent',
        revert: true,
        cursor: 'pointer',
      });
    }
  }, [prefix, sortCallback, images]);

  const { mobile, web } = mapping;

  return (
    <div className={classNames('d-flex flex-wrap h-100', containerClassName || 'mt-5')} id={prefix}>
      {images.map((image, index) => {
        const fileParts = image.split('.');
        const fileExtension = fileParts[fileParts.length - 1];

        const tags = fieldTags && fieldTags.map((tagDescription) => {
          let mockUp;

          switch(typeof tagDescription) {
            // this is object value
            case 'object':
              const { tagType, amount } = tagDescription;
              const tagColor = fieldTagsColors[tagType].color;

              mockUp = (
                (amount === -1 || amount > index) &&
                <div
                  style={{
                    background: tagColor,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                  }}
                />
              )
            break;
          }
          return mockUp;
        });

        const imageToDelete = imagesToDelete?.length && imagesToDelete.includes(image);
        return (
          <div key={`${image}_${index}`} className='m-1 h-100' id={`media_${index}`}>
            <div style={{ zIndex: 1000 }} className='position-relative'>
              <div>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  onChange={toggleDeleteImages}
                  id={image + index}
                  value={image}
                />
                <label
                  className={`check-delete-image btn btn-sm ${imageToDelete ? 'btn-warning' : 'btn-danger'}`}
                  htmlFor={image + index}
                >
                  {imageToDelete ? <i className='fe fe-refresh-ccw'></i> : <i className='fe fe-trash'></i>}
                </label>
              </div>

              {!imageToDelete && sortCallback ? (
                <a href='#!' className={`btn btn-primary btn-sm drag-image-btn sortable-${prefix}`}>
                  <i className='fe fe-move'></i>
                </a>
              ) : null}
              <div className='image-number px-2'>
                {index + 1}
                <div
                  className='d-flex flex-column position-absolute'
                  style={{
                    gap: '3px',
                    minWidth: '8px',
                    left: '-11px',
                    top: '4px',
                  }}
                >
                  {tags}
                </div>
              </div> 
            </div>
            <div
              className={classNames(contentContainerClassName, {
                'image-to-delete border-danger': imageToDelete,
                'slider-image border border-2': !contentContainerClassName,
              })}
            >
              {fileExtension === 'mp4' ? (
                <video controls className='w-100 h-100' style={{ objectFit: 'cover' }}>
                  <source src={image} type='video/mp4' />
                </video>
              ) : (
                <img
                  // onClick={() => setImageBigView(image)}
                  src={image}
                  alt='Slider items'
                  className='w-100 h-100'
                  style={{ objectFit: 'cover' }}
                />
              )}
            </div>

            {onChangeMapping ? (
              <div className='bg-dark box-shadow-footer py-2 px-3 d-flex align-items-center'>
                <div className='d-flex'>
                  <div className='text-center'>
                    <label htmlFor={'mobile_' + prefix + index} className='p-0 m-0'>
                      <p className='h6 p-0 m-0'>Mobile</p>
                    </label>
                    <div className='custom-control custom-checkbox-toggle'>
                      <input
                        type='checkbox'
                        checked={!!mobile.values.includes(image)}
                        value={image}
                        onChange={onChangeMapping}
                        className='custom-control-input'
                        id={'mobile_' + prefix + index}
                      />
                      <label className='custom-control-label' htmlFor={'mobile_' + prefix + index} />
                    </div>
                  </div>
                  <div className='ml-2 text-center'>
                    <label htmlFor={'web_' + prefix + index} className='p-0 m-0'>
                      <p className='h6 p-0 m-0'>Web</p>
                    </label>
                    <div className='custom-control custom-checkbox-toggle'>
                      <input
                        type='checkbox'
                        checked={!!web.values.includes(image)}
                        value={image}
                        onChange={onChangeMapping}
                        className='custom-control-input'
                        id={'web_' + prefix + index}
                      />
                      <label className='custom-control-label' htmlFor={'web_' + prefix + index} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default Images;
