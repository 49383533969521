import { config } from "../appConfig";
const baseName = `${window.location.origin}/images/file-thumbnails/`

export const generateThumbnail = (mimeType: string, fileUrl: string) => {
  if (mimeType === "application/pdf") {
    return `${config.REACT_APP_ESTATING_PUBLIC_API_URI}/image/thumbnail?mimeType=${mimeType}&fileUrl=${fileUrl}&random=${performance.now()}`;
  } else if (mimeType.includes("zip")) {
    return `${baseName}zip.svg`;
  } else if (mimeType.includes("csv")) {
    return `${baseName}csv.svg`;
  } else if (mimeType.includes("msword")) {
    return `${baseName}doc.svg`;
  } else if (mimeType.includes("gif")) {
    return `${baseName}gif.svg`;
  } else if (mimeType === "image/jpeg") {
    return `${baseName}jpg.svg`;
  } else if (
    mimeType.includes("powerpoint") ||
    mimeType ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ) {
    return `${baseName}ppt.svg`;
  } else if (mimeType.includes("octet-stream")) {
    return `${baseName}rar.svg`;
  } else if (mimeType === "text/plain") {
    return `${baseName}txt.svg`;
  } else if (
    mimeType.includes("excel") ||
    mimeType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return `${baseName}xls.svg`;
  }
  return `${baseName}ppt.svg`;
}
