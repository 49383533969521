import { FC, useMemo } from 'react';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import { ToggleDocumentRoleProtected } from '../../../store/property/types';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';
import { MultipleSelecet } from '../../../components/multiple-selet';
import { useTranslate } from '@tolgee/react';
import { convertEnumToOptions } from '../../../helpers/convert-enum-to-options';
import { WhoAreYouEnum } from '../../../store/users/types';
import { filenameFromUrlWithId } from '../../../helpers/filename-from-url-with-id';

interface DocumentsProps {
  documents: Array<ToggleDocumentRoleProtected>;
  onChange: (changed: Array<ToggleDocumentRoleProtected>) => any;
  fieldTags?: Array<TagDescription>;
};

export const RoleProtectedDocuments: FC<DocumentsProps> = ({ documents, onChange, fieldTags }) => {
  const { t: tEnums } = useTranslate('enums');

  const {
    whoAreYouOptions,
  } = useMemo(() => ({
    whoAreYouOptions: convertEnumToOptions(WhoAreYouEnum, false, tEnums, 'who_are_you'),
  }), [tEnums]);

  return (
    <>
      {documents.map((document, index) => {
        const tags =
          fieldTags &&
          fieldTags.map((tagDescription) => {
            let mockUp;

            switch (typeof tagDescription) {
              // this is object value
              case 'object':
                const { tagType, amount } = tagDescription;
                const tagColor = fieldTagsColors[tagType].color;

                mockUp = (amount === -1 || amount > index) && (
                  <div
                    style={{
                      background: tagColor,
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                    }}
                  />
                );
                break;
            }
            return mockUp;
          });

        return (
          <div key={'document_' + index} className='position-relative'>
						<div
							className='d-flex flex-column position-absolute'
							style={{
								gap: '3px',
								minWidth: '8px',
								left: '-11px',
								top: '4px',
							}}
						>
							{tags}
						</div>
            <Input
              label='Name'
              className='my-2'
              required={true}
              value={document.name}
              onChange={(e) => {
                const toChange = [...documents];
                toChange[index].name = e.target.value;

                onChange(toChange);
              }}
            />
            <TextArea
              label='Short description'
              value={document.description}
              onChange={(e) => {
                const toChange = [...documents];
                toChange[index].description = e.target.value;

                onChange(toChange);
              }}
            />
            <MultipleSelecet
              onChange={console.log}
              selected={document.allowedFor}
              label='Visible for:'
              options={whoAreYouOptions}
              name='details.secondary.assetType'
              changeCallback={(values) => {
                const toChange = [...documents];
                toChange[index].allowedFor = values as any;

                onChange(toChange);
              }}
            />
            {document.url ? ( 
              <div className='alert alert-dark d-flex align-items-center justify-content-between flex-wrap'>
                <p className='mb-0 overflow-hidden'>{filenameFromUrlWithId(document.url)}</p>
                <div className='btn-group'>
                  {document.url ? (
                    <a href={document.url} download={true} className='btn btn-success btn-sm'>
                      Download
                    </a>
                  ) : null}

                  <Button
                    label='Remove'
                    onClick={() => {
                      const toChange = [...documents];
                      toChange.splice(index, 1);
                      onChange(toChange);
                    }}
                    className='btn-danger btn-sm'
                  />
                </div>
              </div>
            ) : (
              <div className='d-flex flex-wrap-small align-items-center my-2'>
                <label className='label-fix-width font-weight-bold'>File to upload*</label>
                <div className='d-flex justify-content-between w-100'>
                  <input
                    onChange={(e) => {
                      const toChange = [...documents];
                      // @ts-ignore
                      toChange[index].file = e.target.files[0];

                      onChange(toChange);
                    }}
                    multiple={false}
                    type='file'
                  />
                  <Button
                    label='Remove'
                    onClick={() => {
                      const toChange = [...documents];
                      toChange.splice(index, 1);
                      onChange(toChange);
                    }}
                    className='btn-danger btn-sm'
                  />
                </div>
              </div>
            )}

            <hr />
          </div>
        );
      })}

      <div className='d-flex justify-content-center mt-3'>
        <Button
          className='btn-rounded-circle btn-success mt-2'
          onClick={() => {
            const toAdd = {
              name: '',
              description: '',
              allowedFor: [],
            };
            onChange([...documents, toAdd]);
          }}
        >
          <span className='fe fe-plus'></span>
        </Button>
      </div>
    </>
  );
};
