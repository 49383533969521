import { ChangeEvent, FC, Fragment, MouseEvent, useEffect, useState } from 'react';
import { AdditionalValue } from '../../../store/property/types';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import $ from 'jquery';
import Images from './Images';
import DropImages from '../../../components/DropImages';
import { DroppedFile, FileCategory } from '../../../types/dropped-file';
import { FieldTag, TagDescription, fieldTagsColors } from '../../../constants/fields-tags';
import { cloneDeep } from 'lodash';

interface AdditionalValuesProps {
  baseValues: Array<AdditionalValue & { attachedImage?: DroppedFile; }>;
  baseOnChange: (changedValues: Array<AdditionalValue & { attachedImage?: DroppedFile; }>) => void;

  values: Array<AdditionalValue & { attachedImage?: DroppedFile; }>
  fixedValues?: string[];
  onChange: (changedValue: AdditionalValue[]) => any;
  onChangeMapping?: (changed: any) => any;
  prefix: string;
  sortCallback?: (sortedHighlights: AdditionalValue[]) => void;
  enableSort?: boolean;
  onSave: (e: MouseEvent<HTMLButtonElement>) => any;
  addSubValues?: boolean;
  mapping?: any;
  isNew: boolean;
  isTooltip?: boolean;
  isImageUpload?: boolean;
  imagesPrefix?: string;
  imagesToDelete?: Array<string>;
  setImagesToDelete?: (newValue: Array<string>) => void;
  fileCategory?: FileCategory;
  fieldTags?: Array<TagDescription>;
  fieldTagsExclusions?: {[key in FieldTag]: Array<string>}
  acceptFileExtensions?: string | Array<string>;
}

const AdditionalValues: FC<AdditionalValuesProps> = ({
  baseValues,
  baseOnChange,

  values,
  fixedValues,
  onChange,
  prefix,
  onChangeMapping,
  mapping = {},
  onSave,
  addSubValues,
  enableSort = true,
  isNew,
  isTooltip,
  isImageUpload,
  imagesPrefix = 'default_prefix',
  imagesToDelete,
  setImagesToDelete,
  fileCategory,
  fieldTags,
  fieldTagsExclusions,
  acceptFileExtensions
}) => {
  const [newAdditionalValues, setNewAdditionalValues] = useState<Array<AdditionalValue & { attachedImage?: DroppedFile; }>>([]);

  const addNewAdditionalValue = () => {
    setNewAdditionalValues((newDetailValue) => {
      return [
        ...newDetailValue,
        {
          name: '',
          value: '',
          tooltip: '',
        },
      ];
    });
  };

  const addValue = (e: MouseEvent<HTMLButtonElement>, index: number) => {
    const detailToAdd = newAdditionalValues[index];

    setNewAdditionalValues((newAddedDetails) => {
      return [...newAddedDetails.filter((_, idx) => idx !== index)];
    });

    onChange([...values, detailToAdd]);
    !isNew && onSave(e);
  };

  const onChangeNewValue = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setNewAdditionalValues((newDetailValues) => {
      newDetailValues[index][e.target.name] = e.target.value;
      return [...newDetailValues];
    });
  };

  const onRemoveNewValue = (e: MouseEvent<HTMLButtonElement>, index: number) => {
    e.preventDefault();
    setNewAdditionalValues((newDetailValues) => {
      return [...newDetailValues.filter((_, idx) => idx !== index)];
    });
  };

  useEffect(() => {
    const sortableAdditionalValues: any = $(`#${prefix}`);
    if (sortableAdditionalValues && enableSort) {
      sortableAdditionalValues.sortable({
        update: () => {
          const newOrdered = sortableAdditionalValues.sortable('toArray');
          const newOrderedValues = new Array(newOrdered.length);
          values.forEach((additionalValue) => {
            const index = newOrdered.indexOf(additionalValue.name);
            newOrderedValues[index] = additionalValue;
          });
          onChange([...newOrderedValues]);
        },
        handle: `.sortable-${prefix}`,
        containment: 'parent',
        revert: true,
        cursor: 'pointer',
      });
    }
  }, [prefix, onChange, values, enableSort]);

  const handleChangeMapping = (e) => {
    if (onChangeMapping) {
      const target = e.target.id.split('_')[0];
      const id = e.target.value;

      const toChange = { ...mapping };
      if (e.target.checked) {
        toChange[target].values = [...toChange[target].values, id];
      } else {
        toChange[target].values = [...toChange[target].values.filter((idx: string) => idx !== id)];
      }
      onChangeMapping(toChange);
    }
  };
  const { mobile, web } = mapping;

  values.sort((a, b) => {
    if (fixedValues && fixedValues.includes(a.name)) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <div id={prefix}>
        {values &&
          values.length > 0 &&
          values.map((additionalValue, additionalValueIndex) => {
            const { _id, name, value, subValues, info, tooltip } = additionalValue;
            const { image: baseImage, attachedImage: baseAttachedImage } = baseValues[additionalValueIndex];

            const baseValuesArray = [
              ['name', name],
              ['value', value],
            ];
            if (isTooltip) {
              baseValuesArray.push(['tooltip', tooltip!]);
            }

            const tags = fieldTags && fieldTags.map((tagDescription) => {
              switch(typeof tagDescription) {
                // this is object value
                case 'object':
                  const { tagType, amount } = tagDescription;

                  if (fieldTagsExclusions && ~fieldTagsExclusions[tagType].findIndex(target => target === name)) {
                    return null;
                  }

                  const tagColor = fieldTagsColors[tagType].color;
    
                return (
                    (amount === -1 || amount > additionalValueIndex) &&
                    <div
                      style={{
                        background: tagColor,
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                      }}
                    />
                );
                default:
                throw new Error('Unexpected value')
              }
            });

            return (
              <div className='my-2 d-flex align-items-center' key={_id || prefix + additionalValueIndex} id={name}>
                <div className='flex-grow-1 position-relative'>
                  <div
                    className='d-flex flex-column position-absolute'
                    style={{
                      gap: '3px',
                      minWidth: '8px',
                      left: '-11px',
                      top: '15px',
                    }}
                  >
                    {tags}
                  </div>
                  <div className='d-flex flex-wrap-small align-items-center mt-2'>
                    {baseValuesArray.map(([key, value], index) => (
                      <Fragment key={`${key}-${index}`}>
                        <Input
                          onChange={(e) => {
                            const toChange = [...values];
                            toChange[additionalValueIndex][key] = e.target.value;
                            onChange(toChange);
                          }}
                          inputProps={{
                            placeholder: key[0].toUpperCase() + key.slice(1),
                            disabled: fixedValues && fixedValues.includes(additionalValue[key]),
                          }}
                          className={isImageUpload ? 'w-25 mr-2' : 'w-33 mr-2'}
                          value={value}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <div className='d-flex px-3 mr-2 bg-dark box-shadow-footer py-2 align-items-center justify-content-between'>
                    {onChangeMapping ? (
                      <div className='d-flex'>
                        <div className='text-center'>
                          <label htmlFor={'mobile_' + prefix + additionalValueIndex} className='p-0 m-0'>
                            <p className='h6 p-0 m-0'>Mobile</p>
                          </label>
                          <div className='custom-control custom-checkbox-toggle'>
                            <input
                              type='checkbox'
                              checked={!!mobile.values.includes(additionalValue._id)}
                              value={additionalValue._id}
                              onChange={handleChangeMapping}
                              disabled={!additionalValue._id}
                              className='custom-control-input'
                              id={'mobile_' + prefix + additionalValueIndex}
                            />
                            <label
                              className='custom-control-label'
                              htmlFor={'mobile_' + prefix + additionalValueIndex}
                            />
                          </div>
                        </div>
                        <div className='ml-2 text-center'>
                          <label htmlFor={'web_' + prefix + additionalValueIndex} className='p-0 m-0'>
                            <p className='h6 p-0 m-0'>Web</p>
                          </label>
                          <div className='custom-control custom-checkbox-toggle'>
                            <input
                              type='checkbox'
                              checked={!!web.values.includes(additionalValue._id)}
                              value={additionalValue._id}
                              onChange={handleChangeMapping}
                              disabled={!additionalValue._id}
                              className='custom-control-input'
                              id={'web_' + prefix + additionalValueIndex}
                            />
                            <label className='custom-control-label' htmlFor={'web_' + prefix + additionalValueIndex} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {addSubValues ? (
                      <Button
                        className='btn btn-sm btn-rounded-circle btn-success'
                        onClick={() => {
                          const toChange = [...values];
                          if (!toChange[additionalValueIndex].subValues) {
                            toChange[additionalValueIndex].subValues = [];
                          }
                          //TODO check undefined case
                          // @ts-ignore
                          toChange[additionalValueIndex].subValues.push({
                            name: '',
                            value: '',
                          });
                          onChange(toChange);
                        }}
                      >
                        <span className='fe fe-plus'></span>
                      </Button>
                    ) : null}
                    {!!info ? <div className='alert text-center m-0 px-4 py-1 h5 alert-warning'>{info}</div> : null}
                    {(!fixedValues || !fixedValues.includes(additionalValue.name)) && (
                      <div className='btn-group'>
                        <Button
                          label='Remove'
                          className='btn-sm btn-danger'
                          onClick={(e) => {
                            const toChange = [...values];
                            toChange.splice(additionalValueIndex, 1);
                            onChange(toChange);
                          }}
                        />
                        {additionalValue._id && enableSort ? (
                          <a
                            href='#!'
                            onClick={(e) => e.preventDefault()}
                            className={`btn btn-sm btn-primary ui-sortable-handle sortable-${prefix}`}
                          >
                            <i className='fe fe-move'></i>
                          </a>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                {isImageUpload && fileCategory &&
                  <div className="d-flex" style={{ height: '87px', width: '200px' }}>
                    {imagesPrefix && imagesToDelete && setImagesToDelete && (
                      <Images
                        prefix={imagesPrefix}
                        images={
                          baseImage
                            ? [baseImage]
                            : []
                        }
                        imagesToDelete={imagesToDelete}
                        setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                        containerClassName='mt-0 w-50'
                        contentContainerClassName='h-100'
                      />
                    )}
                    <div className="h-100 w-50">
                      <DropImages
                        category={fileCategory}
                        callback={(droppedImages) => {
                          const toChange = cloneDeep([...baseValues]);
                          toChange[additionalValueIndex].attachedImage = droppedImages[0];
                          baseOnChange(toChange);
                        }}
                        droppedImages={baseAttachedImage ? [baseAttachedImage] : []}
                        multiple={false}
                        maxCount={1}
                        acceptFileExtensions={acceptFileExtensions}
                      />
                    </div>
                  </div>
                }

                {subValues && subValues.length > 0 ? (
                  <div className='my-2'>
                    {subValues.map((subValue, subValueIndex) => {
                      const { name, value, _id } = subValue;
                      return (
                        <div
                          className='d-flex flex-wrap-small align-items-center ml-5 my-1'
                          key={_id || prefix + additionalValueIndex + '_subValue_' + subValueIndex}
                        >
                          <Input
                            onChange={(e) => {
                              const toChange = [...values];
                              // @ts-ignore
                              toChange[additionalValueIndex].subValues[subValueIndex][e.target.name] = e.target.value;

                              onChange(toChange);
                            }}
                            className='w-50 mr-2'
                            value={name || ''}
                            inputProps={{
                              name: 'name',
                            }}
                          />
                          <Input
                            onChange={(e) => {
                              const toChange = [...values];
                              // @ts-ignore
                              toChange[additionalValueIndex].subValues[subValueIndex][e.target.name] = e.target.value;

                              onChange(toChange);
                            }}
                            className='w-50'
                            value={value || ''}
                            inputProps={{
                              name: 'value',
                            }}
                          />
                          <div className='p-2 bg-dark box-shadow-footer'>
                            <Button
                              label='Remove'
                              className='btn-sm btn-danger'
                              onClick={(e) => {
                                const toChange = [...values];
                                // @ts-ignore
                                toChange[additionalValueIndex].subValues.splice(subValueIndex, 1);

                                onChange(toChange);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
      {newAdditionalValues.length > 0 &&
        newAdditionalValues.map((item, index) => {
          const { name, value, tooltip, image, attachedImage } = item;
          const additionalValuesArray = [
            ['name', name],
            ['value', value],
          ];

          if (isTooltip) {
            additionalValuesArray.push(['tooltip', tooltip!]);
          }

          return (
            <div className='py-2' id={name} key={'detailToAdd' + index}>
              <hr />
              <div className='d-flex  align-items-center flex-wrap-small'>
                <div className='d-flex flex-grow-1'>
                  {additionalValuesArray.map(([key, value]) => {
                    return (
                      <Fragment key={key}>
                        <Input
                          value={value}
                          onChange={(e) => onChangeNewValue(e, index)}
                          className='col mr-2'
                          inputProps={{
                            placeholder: key[0].toUpperCase() + key.slice(1),
                            name: key,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </div>
                {isImageUpload && fileCategory &&
                  <div className="h-100 d-flex" style={{ height: '87px', width: '200px' }}>
                    {imagesPrefix && imagesToDelete && setImagesToDelete && (
                      <Images
                        prefix={imagesPrefix}
                        images={
                          image
                            ? [image]
                            : []
                        }
                        imagesToDelete={imagesToDelete}
                        setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                        containerClassName='mt-0 w-50'
                        contentContainerClassName='h-100 w-auto'
                      />
                    )}
                    <div className="h-100 w-50">
                      <DropImages
                        category={fileCategory}
                        callback={(droppedImages) => {
                          setNewAdditionalValues((prev) => {
                            prev[index].attachedImage = droppedImages[0];
                            return [...prev];
                          });
                        }}
                        droppedImages={attachedImage ? [attachedImage] : []}
                        multiple={false}
                        maxCount={1}
                        acceptFileExtensions={acceptFileExtensions}
                      />
                    </div>
                  </div>
                }
                <div className='btn-group ml-2'>
                  <Button className='btn-success' onClick={(e) => addValue(e, index)}>
                    Add
                  </Button>
                  <Button className='btn-light' onClick={(e) => onRemoveNewValue(e, index)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      <hr />
      <div className='d-flex justify-content-center'>
        <Button className='btn btn-rounded-circle btn-success mt-2' onClick={addNewAdditionalValue}>
          <span className='fe fe-plus'></span>
        </Button>
      </div>
      <hr />
    </>
  );
};

export default AdditionalValues;
