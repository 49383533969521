import * as Yup from 'yup';
import { IconType } from '../store/property/types';

export const propertyTeaserFormValidationSchema = Yup.object().shape({
  // summary: Yup.array()
  //   .of(Yup.object().shape({
  //     id: Yup.string().optional(),
  //     name: Yup.string().optional(),
  //     value: Yup.string().max(80, 'Too Long').optional(),
  //     tooltip: Yup.string().optional(),
  //     type: Yup.mixed<any>().oneOf([...Object.values(IconType), null])
  //   })),
  details: Yup.object().shape({
    highlighted: Yup.array()
      .of(Yup.object().shape({
        id: Yup.string().optional(),
        name: Yup.string().optional(),
        value: Yup.string().max(12, 'Too Long').optional(),
        tooltip: Yup.string().optional(),
        type: Yup.mixed<any>().oneOf([...Object.values(IconType), null])
      }))
  }),
  investmentStrategy: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().max(80, 'Too Long')
      })
    ),
  name: Yup.string().max(85, 'Too Long'),
})