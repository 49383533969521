import React from 'react'
import TopNavbar from "./TopNavbar";

type MainLayoutProps = {
    category: 'properties' | 'users' | 'roles' | 'sessions' | 'education-materials-groups'
    children: JSX.Element
}

const MainLayout: React.FC<MainLayoutProps> = ({category, children}) => {
    return (
        <div>
            <TopNavbar category={category}/>
            <div className="main-content mt-2 p-3">
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default MainLayout
