import {QUERY_RULES, QueryRules, SET_ABILITY, SetAbility} from './types';
import {Ability, AbilityTuple, Subject} from "@casl/ability";


export const queryRules = ( rules :  any ) : QueryRules => {
    return {
        type: QUERY_RULES,
        payload: rules
    }
}


export const setAbility = ( ability :  Ability<AbilityTuple<string, Subject>> ) : SetAbility => {
    return {
        type: SET_ABILITY,
        payload: ability
    }
}
