import {StatisticsType} from "../property/types";
import {Action} from "redux";

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const QUERY_TRANSACTIONS = 'QUERY_TRANSACTIONS';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';

export interface TransactionType {
    _id?: string,
    internalName?: string,
    taxes: StatisticsType,
    totalInvestmentAmount: string,
    profitIncomeTaxes: string,
    security?: string
}

export interface AddTransaction extends Action {
    type: typeof ADD_TRANSACTION,
    payload: TransactionType,
}

export interface DeleteTransaction extends Action {
    type: typeof DELETE_TRANSACTION,
    payload: string,
}

export interface QueryTransactions extends Action {
    type: typeof QUERY_TRANSACTIONS,
    payload: TransactionType[]
}

export interface UpdateTransaction extends Action {
    type: typeof UPDATE_TRANSACTION,
    payload: { transactionId: string, updated: Partial<TransactionType> }
}

export interface TransactionsState {
    transactions: TransactionType[]
}

export const INITIAL_STATE: TransactionsState = {
    transactions: []
};
