import React from 'react'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';

type Props = {
    name: string;
    label?: string;
    rightComponent?: JSX.Element,
    value: Date;
    onChange: (field: string, value: any) => void;
    isInvalid?: boolean;
    disabled?: boolean;
    containerClass?: string;
    placeholder?: string;
    pickerOptions?: DateTimePickerProps['options'];
    labelClass?: string
};

export const SelectDate: React.FC<Props> = ({
                                                name,
                                                label,
                                                rightComponent,
                                                value,
                                                onChange,
                                                isInvalid,
                                                disabled,
                                                containerClass = '',
                                                placeholder,
                                                pickerOptions = {},
                                                labelClass = ''
                                            }) => {
    return (
        <div className={`d-flex ${containerClass}`}>
            {label && <label className={`col-form-label label-fix-width ${isInvalid ? 'text-danger' : ''} ${labelClass}`}>{label}</label>}
            <Flatpickr
                name={name}
                className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                value={value}
                placeholder={placeholder}
                onChange={(date) => {
                    onChange(name, date[0]);
                }}
                options={{
                    dateFormat: 'm/d/Y',
                    ...pickerOptions,
                }}
                disabled={disabled}
            />
            {rightComponent && (<div className="input-group-append ml-1">
                    {rightComponent}
                    </div>)}
        </div>
    );
};
