import React, {useEffect} from 'react';
import './Snackbar.css';

type SnackbarProps = {
    show: boolean;
    message: string;
    snackbarHandler: () => void;
    position?: 'top' | 'bottom';
    color?: 'danger' | 'success' | 'dark',
    delay?: number,
    authHide?: boolean
};

const Snackbar: React.FC<SnackbarProps> = ({
                                               color = 'dark',
                                               show,
                                               message,
                                               snackbarHandler,
                                               position = 'right-bottom',
                                               delay = 3000,
                                               authHide = true
                                           }) => {

    useEffect(() => {
        if (show && authHide) {
            setTimeout(() => {
                snackbarHandler()
            }, delay)
        }
    }, [show, delay, authHide, snackbarHandler])

    if (!show) return null;

    return (
        <div
            className={`alert alert-${color} mb-0 text-${color} custom-snackbar snackbar-${position}`}
            role='alert'
        >
            <strong className='mr-3 mb-0'>{message}</strong>
            <button
                type='button'
                onClick={snackbarHandler}
                className='btn btn-sm btn-rounded-circle'
                data-dismiss='alert'
                aria-label='Close'
            >
                <i className="fe fe-x"></i>
            </button>
        </div>
    );
};

export default Snackbar;
