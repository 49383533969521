import { DroppedFile } from '../types/dropped-file';
import { EducationMaterial } from '../types/education-materials/education-material';
import { EducationMaterialsGroup } from '../types/education-materials/education-materials-group';
import { PartialBy } from '../types/partial-by';
import api from './estatingApi';

export const EducationMaterialsApi = {
  queryEducationMaterialsGroups: async (params?: URLSearchParams) => {
    return new Promise<Array<EducationMaterialsGroup>>(async (resolve, reject) => {
      try {
        const response = await api.get(`/education-materials-groups`, {
          params
        });
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to get groups'));
      }
    });
  },

  mutationCreateEducationMaterialsGroup: async () => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const response = await api.post(`/education-materials-groups`);
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to create group'));
      }
    });
  },

  mutationUpdateEducationMaterialsGroup: async (id: string, fields: Partial<Omit<EducationMaterialsGroup, '_id'>>, params?: URLSearchParams) => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const response = await api.patch(`/education-materials-groups/${id}`, fields, { params });
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to update group'));
      }
    });
  },

  mutationUpdateOrderEducationMaterialsGroups: async (reorderedObjectsIds: Array<string>) => {
    return new Promise<Array<EducationMaterialsGroup>>(async (resolve, reject) => {
      try {
        const response = await api.post(`/education-materials-groups/reorder`, reorderedObjectsIds);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to reorder groups'));
      }
    });
  },

  mutationDeleteEducationMaterialsGroup: async (id: string) => {
    return new Promise<Array<EducationMaterialsGroup>>(async (resolve, reject) => {
      try {
        const response = await api.delete(`/education-materials-groups/${id}`);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to delete group'));
      }
    });
  },

  mutationAddEducationMaterialToGroup: async (
    groupId: string,
    values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> },
    droppedFile: DroppedFile | null,
    customThumbnail: DroppedFile | null,
    queryParams?: URLSearchParams
  ) => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        
        if (droppedFile) {
          formData.append('file', droppedFile);
        }

        if (customThumbnail) {
          formData.append('thumbnail', customThumbnail);
        }

        formData.append('translations', JSON.stringify(values));

        const response = await api.post(`/education-materials-groups/${groupId}/add-material`, formData, { params: queryParams });
        if (response.status === 201) {
          resolve(response.data);
        }
          reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to add material into group'));
      }
    });
  },

  mutationUpdateOrderEducationMaterials: async (groupId: string, reorderedObjectsIds: Array<string>) => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const response = await api.post(`/education-materials-groups/${groupId}/reorder-materials`, reorderedObjectsIds);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to reorder groups'));
      }
    });
  },

  queryEducationMaterial: async (groupId: string, materialId: string) => {
    return new Promise<EducationMaterial>(async (resolve, reject) => {
      try {
        const response = await api.get(`/education-materials-groups/${groupId}/material/${materialId}`);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to get groups'));
      }
    });
  },

  mutationDeleteEducationMaterial: async (groupId: string, materialId: string) => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const response = await api.delete(`/education-materials-groups/${groupId}/remove-material/${materialId}`);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to reorder groups'));
      }
    });
  },

  mutationUpdateEducationMaterial: async (
    groupId: string,
    materialId: string,
    values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> },
    droppedFile: DroppedFile | null,
    customThumbnail: DroppedFile | null,
    params?: URLSearchParams,
  ) => {
    return new Promise<EducationMaterialsGroup>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        
        if (droppedFile) {
          formData.append('file', droppedFile);
        }

        if (customThumbnail) {
          formData.append('thumbnail', customThumbnail);
        }

        formData.append('translations', JSON.stringify(values));

        const response = await api.patch(`/education-materials-groups/${groupId}/edit-material/${materialId}`, formData, { params });
        if (response.status === 200) {
          resolve(response.data);
        }
          reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to add material into group'));
      }
    });
  },
};
