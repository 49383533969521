import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from './base-query';

export const propertyTeaserApi = createApi({
  reducerPath: 'propertyTeeaserApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
		getSynchronizationStatus: builder.query<any, string>({
			query: (teaserId) => `golden-sheet/synchronization-status/${teaserId}`
		})
	}),
});

export const { useGetSynchronizationStatusQuery } = propertyTeaserApi;