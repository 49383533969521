import React, { memo } from 'react';

type SectionProps = {
    label: string;
    children: JSX.Element;
};

const Section: React.FC<SectionProps> = memo(({ label, children }) => {
    return (
    <div className='single-block'>
        <div className='bg-dark'>
            <hr />
            <p className='h3 text-center'>{label}</p>
        <hr />
        </div>
        {children}
    </div>
    );
});

Section.displayName = 'Section'

export default Section;
