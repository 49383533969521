import { memo, FC, useCallback, useMemo } from 'react';
import { EducationMaterialsGroup } from '../../../types/education-materials/education-materials-group';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  deleteEducationMaterialsGroup,
  updateEducationMaterialsGroup,
} from '../../../store/education-materials/thunks';
import { AutoUpdateInput } from './auto-update-input';
import { MaterialsList } from './materials-list';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../../../types/drag-and-drop/item-types';
import { DraggableItem } from '../../../types/drag-and-drop/draggable-item';
import { AutoUpdateTextArea } from './auto-update-text-area';
import { useLanguage } from '../../../contexts/language/language-context';

interface MaterialGroupListItemProps {
  group: EducationMaterialsGroup;
  moveGroup: (id: string, to: number) => void;
  findGroup: (id: string) => { group: EducationMaterialsGroup; index: number };
}

export const MaterialGroupListItem: FC<MaterialGroupListItemProps> = memo(({ group, moveGroup, findGroup }) => {
  const { _id, title, materials, description } = group;
  const originalIndex = findGroup(_id).index;
  const { currentLanguage } = useLanguage();

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.MATERIAL_GROUP,
      item: { id: _id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveGroup(droppedId, originalIndex);
        }
      },
    }),
    [_id, originalIndex, moveGroup]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.MATERIAL_GROUP,
      hover({ id: draggedId }: DraggableItem) {
        if (draggedId !== _id) {
          const { index: overIndex } = findGroup(_id);
          moveGroup(draggedId, overIndex);
        }
      },
    }),
    [findGroup, moveGroup]
  );

  const dispatch = useDispatch();

  const onDeleteGroup = useCallback(() => {
    dispatch(deleteEducationMaterialsGroup(_id));
  }, [dispatch, _id]);

  const onUpdateTitle = useCallback(
    (newValue: string) => {
      dispatch(updateEducationMaterialsGroup(
        _id,
        { title: newValue },
        new URLSearchParams([
          ['lang', currentLanguage]
        ])
      ));
    },
    [_id, currentLanguage, dispatch]
  );

  const onUpdateDescription = useCallback(
    (newValue: string) => {
      dispatch(updateEducationMaterialsGroup(
        _id,
        { description: newValue },
        new URLSearchParams([
          ['lang', currentLanguage]
        ])
      ));
    },
    [_id, currentLanguage, dispatch]
  );

  const opacity = useMemo(() => isDragging ? 0 : 1, [isDragging]);

  return (
    <div
      className='w-100 m-2 material-group--list-item position-relative'
      id={_id}
      ref={(node) => drop(preview(node))}
      style={{ opacity }}
    >
      <div className='d-flex justify-content-between p-4'>
        <div className='d-flex mr-2 flex-grow-1 justify-conent-center align-items-center'>
          <div className='d-flex flex-column w-100'>
            <div
              className='d-flex flex-grow-1 justify-conent-center w-50'
              style={{ height: 'min-content' }}
            >
              <div className='d-flex justify-start align-items-start mb-5'>
                <a
                  href='#!'
                  onClick={(e) => e.preventDefault()}
                  className={`btn btn-sm btn-primary mr-2 d-flex justify-content-center align-items-center`}
                  style={{ width: '30px', height: '30px' }}
                  ref={(node) => drag(node)}
                >
                  <i className='fe fe-move'></i>
                </a>
              </div>
              <div className='flex-grow-1'>
                <AutoUpdateInput value={title} onUpdate={onUpdateTitle} label='Title' containerClass='mb-2' />
                <AutoUpdateTextArea
                  containerClass='w-100 mb-2'
                  label='Description'
                  value={description}
                  onUpdate={onUpdateDescription}
                />
              </div>
            </div>
            <MaterialsList groupId={_id} materials={materials} />
          </div>
        </div>
        <div className='btn-group align-self-start'>
          <Link className='btn btn-primary btn-sm' to={`/education-materials-groups/${_id}/education-materials/add`}>
            Add material
          </Link>
          <button
            className='btn btn-danger btn-sm'
            data-toggle='modal'
            onClick={onDeleteGroup}
            data-target='#delete-property'
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
});
