import {
    Financials,
    IconType,
    LocationDetails, Orientation,
    PropertyStateEnum,
    PropertyTeaserType,
    UpsertProperty
} from "../store/property/types";

import RiskLevelsSVG from "../assets/highlighted-details/icon-risk.svg";
import ProgressBarSVG from "../assets/highlighted-details/icon-progress1.svg";
import CalendarSVG from "../assets/highlighted-details/icon-calendar.svg";
import PieChartSVG from "../assets/highlighted-details/pie-chart.svg";
import CalculatorSVG from "../assets/highlighted-details/icon-calc.svg";
import CoinsSVG from "../assets/highlighted-details/coins.svg";
import CurrencySVG from "../assets/highlighted-details/currency.svg";
import LineChartSVG from "../assets/highlighted-details/line-chart.svg";

const REQUIRED_FIELDS_DETAILS = [
    {
        name: 'Property Type',
        value: '',
    },
    {
        name: 'Completion Date',
        value: '',
    },
    {
        name: 'Minimum Payout p.a.',
        value: '',
    },
    {
        name: 'Minimum Investment',
        value: '',
    },
    {
        name: 'Purchase Price (net)',
        value: '',
    },
    {
        name: 'Purchase Cost (x)',
        value: '',
    },
    {
        name: 'Cash Reserve (x)',
        value: '',
    },
    {
        name: 'Price in % (x)',
        value: ''
    },
    {
        name: 'Projected Average Yield p.a. (x)',
        value: '',
    },
    {
        name: 'Projected 10y IRR (x)',
        value: '',
    },
    {
        name: 'Projected Target Annual Rental Income (x)',
        value: '',
    },
    {
        name: 'Projected Loan-to-Value',
        value: '',
    },
    {
        name: 'Real Estate (short description of the property for PS usage)',
        value: ''
    },
    {
        name: 'Furnishing',
        value: ''
    },
    {
        name: 'About the Object ',
        value: ''
    },
    {
        name: 'Base Case Rental income growth rate p.a.',
        value: ''
    },
    {
        name: 'Conservative Case Rental income growth rate p.a.',
        value: ''
    },
    {
        name: 'Growth Case Rental income growth rate p.a.',
        value: ''
    },
    {
        name: 'Base Case Property valuation increase p.a.',
        value: ''
    },
    {
        name: 'Conservative Case Property valuation increase p.a.',
        value: ''
    },
    {
        name: 'Growth Case Property valuation increase p.a.',
        value: ''
    },
    {
        name: '+ Valuation increase',
        value: ''
    },
    {
        name: '- Property related tax ',
        value: ''
    },
    {
        name: '- Property related administration and servicing costs',
        value: ''
    },
    {
        name: '- Security Related Cost',
        value: ''
    },
    {
        name: '- Fixed Payout before tax',
        value: ''
    }
];
const REQUIRED_FIELDS_INVESTMENT = [
    {
        name: 'Issuance Price',
        value: '',
    },
    {
        name: 'Eligibility',
        value: '',
    },
    {
        name: 'Auditor',
        value: '',
    },
    {
        name: 'Security Lawyer',
        value: '',
    },
    {
        name: 'Country of Registration',
        value: '',
    },
    {
        name: 'Issuer',
        value: '',
    },
    {
        name: 'Maturity',
        value: '',
    },
    {
        name: 'Client Target Market',
        value: '',
    },
];
const REQUIRED_DETAILS_HIGHLIGHTS = [
    {
        description: 'Projected Average Yield p.a.',
        value: '',
        icon:
            'https://estating.s3.eu-central-1.amazonaws.com/icons/balance-sheet.svg',
    },
    {
        description: 'Projected 10y IRR',
        value: '',
        icon: 'https://estating.s3.eu-central-1.amazonaws.com/icons/calendar.svg',
    },
    {
        description: 'Minimum Payout p.a.',
        value: '',
        icon: 'https://estating.s3.eu-central-1.amazonaws.com/icons/dollar.svg',
    },
];

export const iconTypes: { [key in IconType]: string } = {
    [IconType.RISK_LEVELS]: RiskLevelsSVG,
    [IconType.PROGRESS_BAR]: ProgressBarSVG,
    [IconType.CALENDAR]: CalendarSVG,
    [IconType.PIE_CHART]: PieChartSVG,
    [IconType.CALCULATOR]: CalculatorSVG,
    [IconType.COINS]: CoinsSVG,
    [IconType.CURRENCY]: CurrencySVG,
    [IconType.LINE_CHART]: LineChartSVG,
    [IconType.TEXT]: '',
    [IconType.LINE]: ''
}

const REQUIRED_FLOORPLAN_HIGHLIGHTS = [
    {
        description: 'Bathrooms',
        value: '',
        icon: 'https://estating.s3.eu-central-1.amazonaws.com/icons/bathroom.svg',
    },
    {
        description: 'Bedrooms',
        value: '',
        icon: 'https://estating.s3.eu-central-1.amazonaws.com/icons/bedroom.svg',
    },
    {
        description: 'Floor',
        value: '',
        icon: 'https://estating.s3.eu-central-1.amazonaws.com/icons/stairs.svg',
    },
    {
        description: 'Surface',
        value: '',
        icon:
            'https://estating.s3.eu-central-1.amazonaws.com/icons/streamline-icon-ruler-triangle%40140x140.svg',
    },
];
const REQUIRED_FIELDS_PROPERTY_DETAILS = [
    {
        name: 'Name Project',
        value: '',
    },
    {
        name: 'About the Address',
        value: '',
    },
    {
        name: 'Rooms',
        value: '',
    },
    {
        name: 'Bathrooms',
        value: '',
    },
    {
        name: 'Bedrooms',
        value: '',
    },
    {
        name: 'Floor',
        value: '',
    },
    {
        name: 'Surface (x)',
        value: '',
    },
    {
        name: 'Surface Interior',
        value: '',
    },
    {
        name: 'Surface Exterior (net)',
        value: '',
    },
    {
        name: 'Property Type',
        value: '',
    },
    {
      name: 'Status',
      value: ''
    },
    {
        name: 'Property / Quality',
        value: '',
    },
    {
        name: 'Year of Construction',
        value: '',
    },
    {
        name: 'Developer',
        value: '',
    },
    {
        name: 'Seller/Broker',
        value: '',
    },
];

const REQUIRED_FIELDS_FINANCIALS: Financials = {
    priceInfos: [
        {
            name: 'Purchase Price (gross)',
            value: '',
            subValues: [
                {
                    name: 'Purchase Price (net)',
                    value: '',
                },
                {
                    name: 'Purchase Tax',
                    value: '',
                },
                {
                    name: 'Closing Cost',
                    value: '',
                },
                {
                    name: 'Sales Agent Fee',
                    value: '',
                },
                {
                    name: 'Other costs (e.g. furniture, flooring)',
                    value: '',
                },
            ]
        },
        {
            name: 'Projected Target Cash Reserve (x)',
            value: '',
        },
        {
            name: 'Multiple',
            value: '',
        },
        {
            name: 'Current-Multiple',
            value: '',
        },
    ],
    percentInfos: [
        {
            name: 'Vacancy Rate',
            value: '',
        },
        {
            name: 'Leverage',
            value: '',
        },
        {
            name: 'Projected Loan-to-Value',
            value: '',
        },
        {
            name: 'Projected Financing Costs p.a.',
            value: '',
        },
        {
            name: 'Projected Amortisation p.a.',
            value: '',
        },
        {
            name: 'Projected Interest p.a.',
            value: '',
        },


    ],
    infoBlockLeft: [
        {
            header: 'Projected Net Operating Cash Flow Before Tax (x)',
            htmlString: '',
        },
        {
            header: 'Projected Rental Return',
            htmlString: '',
        },
        {
            header: 'Projected 10y Value Increase (x)',
            htmlString: '',
        },

    ],
    infoBlockRight: [
        {
            header: 'Cost Contribution Fee (Estating) p.a.',
            htmlString: '',
        },
        {
            header: 'Projected Property Related Cost p.a.',
            htmlString: '',
        },
        {
            header: 'Expected security Related Cost p.a.',
            htmlString: '',
        },

    ],
    statistics: {
        disclaimers: [''],
        header: ' Projected 10y IRR (x)',
        columns: ['Rental yield', 'Change in property value'],
        rows: [
            {
                label: 'Growth Case',
                value: '',
                data: {
                    'Rental yield': '',
                    'Change in property value': ''
                }
            },
            {
                label: 'Base Case',
                value: '',
                data: {
                    'Rental yield': '',
                    'Change in property value': ''
                }
            },
            {
                label: 'Conservative Case',
                value: '',
                data: {
                    'Rental yield': '',
                    'Change in property value': ''
                }
            }
        ]
    },
    disclaimers: ['']

};
const REQUIRED_FIELDS_ESTATE_MANAGEMENT = [
    {
        name: 'Property Manager',
        value: '',
    },
    {
        name: 'Furnished',
        value: '',
    },
    {
        name: 'Target Tenant',
        value: '',
    },
    {
        name: 'Rental Strategy',
        value: '',
    },
    {
        name: 'Target Rental Income/m²',
        value: '',
    },
    {
        name: 'Target Rental Income p.a.',
        value: '',
    },
];

const REQUIRED_FIELDS_SWOT_ANALYSIS = [
    {
        header: 'Strengths',
        descriptions: ['']
    },
    {
        header: 'Weaknesses',
        descriptions: ['']
    },
    {
        header: 'Opportunities',
        descriptions: ['']
    },
    {
        header: 'Risks',
        descriptions: ['']
    }
]

const REQUIRED_FIELDS_LOCATION_DETAILS: LocationDetails = {
    city: {
        name: '',
        descriptions: [''],
        images: [],
        disclaimers: ['']
    },
    country: {
        name: '',
        graphTitle: '',
        descriptions: [''],
        images: [],
        disclaimers: ['']
    },
    neighborhood: {
        name: '',
        descriptions: [''],
        images: [],
        disclaimers: [''],
        footerValues: []
    }
}


export const PROPERTY_MAPPING = {
    details: {
        web: {
            values: [],
            highlights: []
        },
        mobile: {
            values: [],
            highlights: []
        }
    },
    location: {
        web: {
            descriptions: [],
        },
        mobile: {
            descriptions: []
        }
    },
    floorPlan: {
        web: {
            highlights: [],
            descriptions: [],
        },
        mobile: {
            highlights: [],
            descriptions: [],
        }
    },
    investment: {
        mobile: {
            values: []
        },
        web: {
            values: []
        }
    },
    cio: {
        mobile: {
            descriptions: []
        },
        web: {
            descriptions: []
        }
    },
    assets: {
        web: {
            values: []
        },
        mobile: {
            values: []
        }
    }
}

export const Property: UpsertProperty = {
    mapping: PROPERTY_MAPPING,
    details: {
        values: REQUIRED_FIELDS_DETAILS,
        highlights: REQUIRED_DETAILS_HIGHLIGHTS,
        disclaimers: ['']
    },
    investment: REQUIRED_FIELDS_INVESTMENT,
    name: '',
    internalName: '',
    expectedReturn: '',
    costs: '',
    assets: [],
    location: {
        address: '',
        coords: {
            latitude: 0,
            longitude: 0,
            city: '',
            state: '',
            country: '',
        },
        images: [],
        descriptions: [''],
    },
    floorPlan: {
        assets: [],
        highlights: REQUIRED_FLOORPLAN_HIGHLIGHTS,
        descriptions: [''],
    },
    cio: {
        title: '',
        body: [''],
        disclaimer: '',
    },
    opportunity: [
        {
            subHeader: {
                text: 'Strategic Rationale',
                icon: 'https://estating-test.s3.eu-central-1.amazonaws.com/icons/strategic_rationale.svg',

            },
            header: '',
            descriptions: ['']
        },
        {
            subHeader: {
                text: 'Property Vetting',
                icon: 'https://estating-test.s3.eu-central-1.amazonaws.com/icons/property_vetting.svg',

            },
            header: '',
            descriptions: ['']
        },
        {
            subHeader: {
                text: 'Estate Management',
                icon: 'https://estating-test.s3.eu-central-1.amazonaws.com/icons/estate_management.svg'

            },
            header: '',
            descriptions: ['']
        },
        {
            subHeader: {
                text: 'Projected Financials',
                icon: 'https://estating-test.s3.eu-central-1.amazonaws.com/icons/projected_financials.svg'

            },
            header: '',
            descriptions: ['']
        }
    ],
    documents: [],
    disclaimer: [],
    estateManagement: {
        values: REQUIRED_FIELDS_ESTATE_MANAGEMENT,
        descriptions: ['']
    },
    propertyDetails: {
        values: REQUIRED_FIELDS_PROPERTY_DETAILS,
        disclaimers: ['']
    },
    swotAnalysis: REQUIRED_FIELDS_SWOT_ANALYSIS,
    financials: REQUIRED_FIELDS_FINANCIALS,
    status: 'PRIMARY_MARKET',
    state: PropertyStateEnum.DRAFT,
    propertyType: [],
    capitalStructure: 'EQUITY',
    locationDetails: REQUIRED_FIELDS_LOCATION_DETAILS,
    isDefaultProperty: false,
    tags: []
};

export const PROPERTY_SUMMARY = [
    {
        id: 'targetHoldingPeriod',
        name: 'Target Holding Period',
        value: '',
        tooltip: '',
        type: IconType.CALENDAR
    },
    {
        id: 'investorIRR',
        name: 'Investor IRR',
        value: '',
        tooltip: '',
        type: IconType.LINE_CHART
    },
    {
        id: 'investorMultiple',
        name: 'Investor Multiple',
        value: '',
        tooltip: '',
        type: IconType.CALCULATOR
    },
    {
        id: 'investorCashflow',
        name: 'Investor Cashflow',
        value: '',
        tooltip: '',
        type: IconType.COINS
    },
    {
        id: 'totalIssuanceVolume',
        name: 'Total Issuance Volume',
        value: '',
        tooltip: '',
        type: IconType.TEXT
    },
    {
        id: 'relincPrice',
        name: 'Relinc Price',
        value: '',
        tooltip: '',
        type: IconType.TEXT
    },
];

export const PROPERTY_SUMMARY_ESPANISH = [
    {
        id: 'targetHoldingPeriod',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.CALENDAR
    },
    {
        id: 'investorIRR',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.LINE_CHART
    },
    {
        id: 'investorMultiple',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.CALCULATOR
    },
    {
        id: 'investorCashflow',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.COINS
    },
    {
        id: 'totalIssuanceVolume',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.TEXT
    },
    {
        id: 'relincPrice',
        name: '',
        value: '',
        tooltip: '',
        type: IconType.TEXT
    },
];

export const PROPERTY_TEASER: PropertyTeaserType = {
    assets: [],
    name: '',
    internalName: '',
    opportunityType: 'PROPERTY_INVESTMENTS',
    isDefaultProperty: false,
    summary: PROPERTY_SUMMARY,
    teaserSummary: '',
    teaserFootnotes: [],
    goldenSheetPath: '',
    academyMaterials: [],
    about: {
        values: [
            {
                name: 'Name Project',
                value: '',
            },
            {
                name: 'About the Address',
                value: '',
            },
            {
                name: 'Rooms',
                value: '',
            },
            {
                name: 'Bathrooms',
                value: '',
            },
            {
                name: 'Bedrooms',
                value: '',
            },
            {
                name: 'Floor',
                value: '',
            },
            {
                name: 'Surface (x)',
                value: '',
            },
            {
                name: 'Surface Interior',
                value: '',
            },
            {
                name: 'Surface Exterior (net)',
                value: '',
            },
            {
                name: 'Property Type',
                value: '',
            },
            {
                name: 'Property / Quality',
                value: '',
            },
            {
                name: 'Year of Construction',
                value: '',
            },
            {
                name: 'Developer',
                value: '',
            },
            {
                name: 'Seller/Broker',
                value: '',
            },
        ],
        descriptions: [],
        footnotes: []
    },
    risk: {
        riskMitigationFactors: '',
        riskMitigationImages: [],
        documents: [],
    },
    dataRoom: {
        marketResearchText: '',
        marketResearchImage: '',
        documents: [],
    },
    details: {
        secondary: {
            financialStructure: null,
            capitalStructure:  'EQUITY',
            assetType: [],
            others: [
                {
                    "id": "totalEquity",
                    "name": "Total Equity",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "totalInvestmentSize",
                    "name": "Total Investment Size",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "refurbishment",
                    "name": "Refurbishment & FF&E",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "totalDebt",
                    "name": "Total Debts",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "assetPurchasePrice",
                    "name": "Asset Purchase Price",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "projectSize",
                    "name": "Project Size",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
            ],
        },
        values: [
            {
                name: 'Total Investment Size',
                value: ''
            },
            {
                name: 'Targeted Hold Period',
                value: ''
            },
            {
                name: 'Risk level',
                value: ''
            },
            {
                name: 'ISIN',
                value: ''
            },
            {
                name: 'Completion Date',
                value: ''
            },
            {
                name: 'Issuance Volume',
                value: ''
            },
            {
                name: 'Minimum Investment',
                value: ''
            },
            {
                name: 'Net Purchase Price ¹',
                value: ''
            },
            {
                name: 'Projected Purchase Cost ²',
                value: ''
            },
            {
                name: 'Renovation & FF&E ³',
                value: ''
            },
            {
                name: 'Projected Cash Reserve ⁴',
                value: ''
            },
            {
                name: 'Target Rental Income p.a. ⁵',
                value: ''
            },
            {
                name: 'Price in % ⁶',
                value: ''
            },
            {
                name: 'Investor Cashflow p.a.',
                value: ''
            },
            {
                name: 'Investor IRR ⁷',
                value: ''
            },
            {
                name: 'Investor Multiple ⁸',
                value: ''
            },
            {
                name: 'Loan to Value',
                value: ''
            }
        ],
        highlighted: [
            {
                id: "riskLevel",
                name: "Risk level",
                type: IconType.RISK_LEVELS,
                tooltip: '',
                value: '',
            },
            {
                id: "loanToValue",
                name: "Loan to Value",
                type: IconType.PROGRESS_BAR,
                tooltip: '',
                value: '',
            },
            {
                "id": "targetHoldingPeriod",
                "name": "Target Holding Period",
                type: IconType.CALENDAR,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorIrr",
                "name": "Investor IRR",
                type: IconType.PIE_CHART,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorMultiple",
                "name": "Investor Multiple",
                type: IconType.CALCULATOR,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorCashflow",
                "name": "Investor Cashflow",
                type: IconType.COINS,
                tooltip: '',
                value: '',
            },
            {
                "id": "issuanceVolume",
                "name": "Issuance Volume",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            },
            {
                "id": "totalEquity",
                "name": "Total Equity",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            },
            {
                "id": "minimumInvestment",
                "name": "Minimum Investment",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            }
        ],
        highlights: REQUIRED_DETAILS_HIGHLIGHTS,
        footnotes: [{ value: '', name: '' }],
        secondaryKpi: [
            {
                name: 'Total Equity',
                value: '',
            },
            {
                name: 'Total Debt',
                value: '',
            },
            {
                name: 'Total Investment Size',
                value: '',
            },
            {
                name: 'Asset Purchase Price',
                value: '',
            },
            {
                name: 'Refurbishment & FF&E',
                value: '',
            },
            {
                name: 'Other Transaction Costs',
                value: '',
            },
            {
                name: 'Project Size',
                value: '',
            }
        ]
    },
    floorPlan: {
        assets: [],
        footnotes: [],
        orientation: Orientation.VERTICAL
    },
    securityAndRoles: {
        security: [
            {
                name: 'Country of the security',
                value: ''
            },
            {
                name: 'Type of Security',
                value: ''
            },
            {
                name: 'Price ¹',
                value: ''
            },
            {
                name: 'Country of Registration',
                value: ''
            },
            {
                name: 'Legal Maturity',
                value: ''
            },
            {
                name: 'Currency',
                value: ''
            },
            {
                name: 'Client Target Market',
                value: ''
            },
        ],
        roles: [
            {
                name: 'Sourcing',
                value: ''
            },
            {
                name: 'Structure',
                value: ''
            },
            {
                name: 'Paying Agent',
                value: ''
            },
            {
                name: 'Estate Management',
                value: ''
            },
            {
                name: 'Issuer',
                value: ''
            },
            {
                name: 'Security Lawyer',
                value: ''
            },
            {
                name: 'Divestment',
                value: ''
            },
            {
                name: 'Advisor',
                value: ''
            },
            {
                name: 'Auditor',
                value: ''
            },
            {
                name: 'Central Security Depository',
                value: ''
            }
        ],
        aboutThePartner: {
            text: '',
            image: '',
        }
    },
    partners: [],
    documents: [],
    performanceReport: {
        documents: [],
        passwords: [],
    },
    disclaimers: [],
    investmentStrategy: [],
    tags: [],
    options: {
        financialStructure: null,
        status: 'PRIMARY_MARKET',
        resaleAvailable: false,
        state: 'DRAFT',
        investmentStrategy: null,
        category: null,
        propertyType: [],
        capitalStructure: 'EQUITY',
        teaserStatus: null,
        relincPrice: '',
        isin: '',
    },
    location: {
        address: '',
        coords: {
            latitude: 0,
            longitude: 0,
            city: '',
            state: '',
            country: '',
        },
        footnotes: []
    },
    locations: [],
    timeline: {
        enabled: false,
        events: [],
    }
};

export const PROPERTY_TEASER_ESPANISH: PropertyTeaserType = {
    assets: [],
    name: '',
    internalName: '',
    opportunityType: 'PROPERTY_INVESTMENTS',
    isDefaultProperty: false,
    summary: PROPERTY_SUMMARY_ESPANISH,
    teaserSummary: '',
    teaserFootnotes: [],
    goldenSheetPath: '',
    academyMaterials: [],
    about: {
        values: [
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
        ],
        descriptions: [],
        footnotes: []
    },
    risk: {
        riskMitigationFactors: '',
        riskMitigationImages: [],
        documents: [],
    },
    dataRoom: {
        marketResearchText: '',
        marketResearchImage: '',
        documents: [],
    },
    details: {
        secondary: {
            financialStructure: null,
            capitalStructure:  'EQUITY',
            assetType: [],
            others: [
                {
                    "id": "totalEquity",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "totalInvestmentSize",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "refurbishment",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "totalDebt",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "assetPurchasePrice",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
                {
                    "id": "projectSize",
                    "name": "",
                    "type": IconType.TEXT,
                    tooltip: '',
                    value: ''
                },
            ],
        },
        values: [
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            }
        ],
        highlighted: [
            {
                id: "riskLevel",
                name: "",
                type: IconType.RISK_LEVELS,
                tooltip: '',
                value: '',
            },
            {
                id: "loanToValue",
                name: "",
                type: IconType.PROGRESS_BAR,
                tooltip: '',
                value: '',
            },
            {
                "id": "targetHoldingPeriod",
                "name": "",
                type: IconType.CALENDAR,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorIrr",
                "name": "",
                type: IconType.PIE_CHART,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorMultiple",
                "name": "",
                type: IconType.CALCULATOR,
                tooltip: '',
                value: '',
            },
            {
                "id": "investorCashflow",
                "name": "",
                type: IconType.COINS,
                tooltip: '',
                value: '',
            },
            {
                "id": "issuanceVolume",
                "name": "",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            },
            {
                "id": "totalEquity",
                "name": "",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            },
            {
                "id": "minimumInvestment",
                "name": "",
                type: IconType.TEXT,
                tooltip: '',
                value: '',
            }
        ],
        highlights: REQUIRED_DETAILS_HIGHLIGHTS,
        footnotes: [{ value: '', name: '' }],
        secondaryKpi: [
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            },
            {
                name: '',
                value: '',
            }
        ]
    },
    floorPlan: {
        assets: [],
        footnotes: [],
        orientation: Orientation.VERTICAL
    },
    securityAndRoles: {
        security: [
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
        ],
        roles: [
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            },
            {
                name: '',
                value: ''
            }
        ],
        aboutThePartner: {
            text: '',
            image: '',
        }
    },
    partners: [],
    documents: [],
    performanceReport: {
        documents: [],
        passwords: [],
    },
    disclaimers: [],
    investmentStrategy: [],
    tags: [],
    options: {
        financialStructure: null,
        status: 'PRIMARY_MARKET',
        resaleAvailable: false,
        state: 'DRAFT',
        investmentStrategy: null,
        category: null,
        propertyType: [],
        capitalStructure: 'EQUITY',
        teaserStatus: null,
        relincPrice: '',
        isin: '',
    },
    location: {
        address: '',
        coords: {
            latitude: 0,
            longitude: 0,
            city: '',
            state: '',
            country: '',
        },
        footnotes: []
    },
    locations: [],
    timeline: {
        enabled: false,
        events: [],
    }
};

export enum DetailHighlightIcon {
    BALANCE_SHEET = 'https://estating.s3.eu-central-1.amazonaws.com/icons/balance-sheet.svg',
    CALENDAR = 'https://estating.s3.eu-central-1.amazonaws.com/icons/calendar.svg',
    DOLLAR = 'https://estating.s3.eu-central-1.amazonaws.com/icons/dollar.svg',
    GRAPH = 'https://estating.s3.eu-central-1.amazonaws.com/icons/graph.svg',
}

export const DETAIL_HIGHLIGHT_ICONS = Object.values(DetailHighlightIcon);

export const FLOORPLAN_HIGHLIGHT_ICONS = [
    'https://estating.s3.eu-central-1.amazonaws.com/icons/bathroom.svg',
    'https://estating.s3.eu-central-1.amazonaws.com/icons/bedroom.svg',
    'https://estating.s3.eu-central-1.amazonaws.com/icons/stairs.svg',
    'https://estating.s3.eu-central-1.amazonaws.com/icons/streamline-icon-ruler-triangle%40140x140.svg',
];
