import { MouseEvent, FC, useCallback } from 'react';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { DroppedFile, FileCategory } from '../../../types/dropped-file';
import { generateThumbnail } from '../../../helpers/thumbnail-generator';

interface MaterialFileDropProps {
  droppedFile: DroppedFile | null;
  onDrop: (droppedFile: DroppedFile | null) => void;
  disabled?: boolean;
  description?: string | JSX.Element;
  category: FileCategory;
  accept?: string | Array<string>
}

const MaterialFileDrop: FC<MaterialFileDropProps> = ({ description = '', disabled = false, onDrop, droppedFile, category, accept }) => {

  const onDropCallback = useCallback(async (acceptedFiles: Array<FileWithPath>) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const { type } = file;
      let thumbnail;
      
      if (type.startsWith('video/')) {
        thumbnail = (await generateVideoThumbnails(file as File, 1, file.type))[0];
      }

      else if (type.startsWith('image/')) {
        thumbnail = URL.createObjectURL(file);
      }

      else {
        thumbnail = generateThumbnail(type, URL.createObjectURL(file));
      }

      onDrop(Object.assign(file, { preview: thumbnail, category }));
    }
  }, [onDrop, category]);

  const deletePreviewImage = useCallback((e: MouseEvent<HTMLButtonElement>, imagePath?: string) => {
    e.preventDefault();
    onDrop(null);
  }, [onDrop]);

  return (
    <Dropzone onDrop={onDropCallback} disabled={disabled} accept={accept}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div data-toggle='dropzone'>
          <div
            className={`dz-message py-5
            ${
              isDragActive
                ? isDragReject
                  ? 'border-danger'
                  : ''
                : droppedFile
                ? 'border-success'
                : ''
            }`}
            {...getRootProps()}
          >
            <input name='uploadedImages' {...getInputProps()} />
            <span>Click here or drop a file to upload!</span>
            {description ? typeof description === 'string' ? <p>{description}</p> : description : null}
          </div>
          <aside className='mt-2 d-flex flex-wrap'>
            {droppedFile && (
              <div key={droppedFile.preview} className='m-3'>
                <div className='position-relative'>
                  <div className='avatar avatar-xxl'>
                    <img className='avatar-img rounded' alt='...' key={droppedFile.preview} src={droppedFile.preview} />
                  </div>
                  <button
                    onClick={deletePreviewImage}
                    className='btn btn-block btn-danger btn-sm position-absolute opacity-hover-5'
                    style={{ top: '0' }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}
          </aside>
        </div>
      )}
    </Dropzone>
  );
};

export default MaterialFileDrop;
