import api from "./estatingApi";
import {Session} from "../store/sessions/types";

export const SessionsApi = {
    querySessions: () => {
        return new Promise<Session[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/sessionLog`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to get sessions'))
            }
        })
    },
}
