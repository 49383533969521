import React, {ChangeEvent, MouseEvent} from 'react'
import generator from "generate-password";
import {RolesAPI} from "../../../api/roleApi";
import {useDispatch, useSelector} from "react-redux";
import {rolesSelector} from "../../../store/roles/selectors";
import {AddAdmin, AdminType, UpdateAdmin} from "../../../store/admin/types";
import {adminSelector} from "../../../store/admin/selectors";
import Input from "../../../components/Input";
import {addAdmin, updateAdmin} from "../../../store/admin/actions";
import Button from "../../../components/Button";
import {Can} from "../../../casl/Can";
import {adminsAPI} from "../../../api/adminApi";

type UpsertAdminProps = {
    cancelBtnClick?: (e: MouseEvent<HTMLButtonElement>) => any,
    callback?: (...args: any) => any,
    adminToUpdate?: string
}

const AdminUpsert: React.FC<UpsertAdminProps> = ({
                                                     cancelBtnClick = () => {
                                                     },
                                                     callback = () => {
                                                     },
                                                     adminToUpdate = ''
                                                 }) => {
    const [adminToUpsert, setAdminToUpsert] = React.useState<AdminType>({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
    })
    const [newPassword, setNewPassword] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [hasError, setHasError] = React.useState(false)

    const admin = useSelector(adminSelector(adminToUpdate));

    React.useEffect(() => {
        admin && setAdminToUpsert(admin);
    }, [admin]);

    const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        setAdminToUpsert(newAdmin => {
            newAdmin[e.target.name] = e.target.value
            return {...newAdmin}
        })
    }

    const [generatedPWD, setGeneratedPWD] = React.useState('')
    const roles = useSelector(rolesSelector)
    const dispatch = useDispatch()

    const saveAdmin = () => {
        if (!!adminToUpdate) {
            RolesAPI.updateAdmin(adminToUpdate, adminToUpsert)
                .then(admin => {
                    setHasError(true)
                    dispatch<UpdateAdmin>(updateAdmin(adminToUpdate, admin))
                    callback && callback()
                }).catch((e) => {
                setHasError(true)
                setMessage(e.message || 'Fail to update admin')
            })
        } else {
            RolesAPI.addAdmin(
                {
                    ...adminToUpsert,
                    password: newPassword
                })
                .then(admin => {
                    setHasError(false)
                    dispatch<AddAdmin>(addAdmin(admin))
                    callback && callback()
                }).catch((e) => {
                setMessage(e.message || 'Fail to add admin')
                setHasError(true)
            })
        }

    }

    const resetPassword = () => {
        if (adminToUpsert) {
            adminsAPI.resetPassword(adminToUpdate, newPassword)
                .then((message) => {
                    setNewPassword('')
                    setGeneratedPWD('')
                    setHasError(false)
                    setMessage(message)
                }).catch((e) => {
                setHasError(true)
                setMessage(e.message || 'Fail to update admin password')
            }).finally(() => {
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
        }
    }

    return (
        <>
            {
                message ?
                    <div className={`alert alert-${hasError ? 'danger' : 'success'} text-center my-3`} role="alert">
                        <p className='text-center mb-0'>{message}</p>
                    </div>
                    :
                    null
            }
            <div className='d-flex flex-column'>
                <div>
                    <Input type="text"
                           onChange={onChange}
                           name='firstName'
                           value={adminToUpsert.firstName}
                           label='First Name'

                    />
                    <Input type="text"
                           onChange={onChange}
                           name='lastName'
                           containerClass='my-2'
                           value={adminToUpsert.lastName}
                           label='Last Name'

                    />
                    {
                        !adminToUpdate ?
                            <>
                                <div className='d-flex align-items-end my-2'>
                                    <Input label='Password'
                                           onChange={(e) => setNewPassword(e.target.value)}
                                           className='form-control' name='password'
                                           value={newPassword}
                                    />
                                    <button className='btn btn-primary ml-2 col-md-2 px-0' onClick={() => {
                                        const generatedPWD = generator.generate({
                                            numbers: true,
                                            length: 8,
                                            symbols: true,
                                            uppercase: true,
                                            lowercase: true,
                                        })
                                        setGeneratedPWD(generatedPWD)
                                        setNewPassword(generatedPWD)
                                    }}>Generate
                                    </button>
                                </div>
                            </>
                            :
                            null
                    }
                    <Input type="text"
                           onChange={onChange}
                           name='email'
                           value={adminToUpsert.email}
                           label='Email'

                    />

                    <div className='d-flex flex-wrap-small align-items-start w-100 my-2'>
                        <label className='label-fix-width'>
                            Role
                        </label>
                        <select className='form-control '
                                onChange={onChange}
                                name='role'
                                value={adminToUpsert.role}>
                            <option value="" hidden>Select role</option>
                            {
                                roles.map(role => {
                                    return <option value={role.role}
                                                   key={role.role}>{role.label}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className='btn-group mt-3 align-self-end'>
                    <button className='btn btn-success' onClick={saveAdmin}>
                        {!!adminToUpdate ? 'Update' : 'Create'}
                    </button>
                    <button className='btn btn-light' onClick={cancelBtnClick}>
                        Cancel
                    </button>
                </div>
            </div>
            <hr/>
            <Can I='resetPassword' a='admin'>
                <div>
                    {
                        !!adminToUpdate ?
                            <>
                                <div className='d-flex align-items-end my-2'>
                                    <Input label='Password'
                                           onChange={(e) => setNewPassword(e.target.value)}
                                           className='form-control' name='password'
                                           value={newPassword}
                                    />
                                    <button className='btn btn-primary ml-2 col-md-2 px-0' onClick={() => {
                                        const generatedPWD = generator.generate({
                                            numbers: true,
                                            length: 8,
                                            symbols: true,
                                            uppercase: true,
                                            lowercase: true,
                                        })
                                        setGeneratedPWD(generatedPWD)
                                        setNewPassword(generatedPWD)
                                    }}>Generate
                                    </button>
                                </div>
                            </>
                            :
                            null
                    }
                    <div className='d-flex justify-content-end'>
                        <Button
                            onClick={resetPassword}
                            className='btn btn-success col-md-2 px-0'
                            label="Reset Password"
                        />
                    </div>
                    {
                        !!generatedPWD ?
                            <div className='alert alert-warning my-2 d-flex justify-content-between'>
                                <span>
                                    Generated password:{" "}
                                    <b data-toggle="tooltip"
                                       data-placement="top"
                                       onClick={() => {
                                           navigator.clipboard.writeText(generatedPWD)
                                               .then(() => {
                                                   alert('Generated password copied')
                                               })

                                       }}
                                       title="Click to copy">{generatedPWD}</b>
                                </span>
                                <Button
                                    onClick={() => setGeneratedPWD('')}
                                    className='btn-outline-light btn-rounded-circle btn-sm'
                                >
                                    <i className="fe fe-x"></i>
                                </Button>
                            </div>
                            :
                            null
                    }
                </div>
            </Can>

        </>
    )
}

export default AdminUpsert
