import { Dayjs } from 'dayjs';
import { memo, FC, useMemo } from 'react';
import styled from 'styled-components';
import { oneDayPixelWidth, quarterNameBlockHeight, quarterNameBlockMarginBottom } from '../../utils/constants';

interface MonthColumnProps {
  days: number;
  month: Dayjs;
}

export const MonthColumn: FC<MonthColumnProps> = memo(({ days, month }) => {
  const monthDescription = useMemo(() => month.format('MMM/YY'), [month]);

  return (
    <MonthColumnContainer>
      <NameContainer>
        {monthDescription}
      </NameContainer>
      <Column days={days} />
    </MonthColumnContainer>
  );
});

interface ColumnProps {
  days: number;
}

const Column = styled.div<ColumnProps>`
  width: ${({ days }) => `${days * oneDayPixelWidth}px`};
  height: 100%;

  border: 1px solid rgba(255, 255, 255, 0.10);
  border-radius: 20px;
  box-shadow: 2px 2px 20px 0px rgba(255, 255, 255, 0.10);
`;

const NameContainer = styled.div`
  position: sticky;
  
  top: 0px;

  height: ${quarterNameBlockHeight}px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
`

const MonthColumnContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  grid-gap: ${quarterNameBlockMarginBottom}px;
`;