import { ChangeEvent, memo, useCallback } from 'react';
import { LocationDescription } from '../../../store/property/types';
import { Location } from './Location';
import Button from '../../../components/Button';
import { FieldTag, TagDescription, fieldTagsColors } from '../../../constants/fields-tags';

interface LocationsProps {
  locations: Array<LocationDescription>;
  onChange: (newValue: Array<LocationDescription>) => void;
  fieldTags?: Array<TagDescription>;
  specialLocationDescription?: string;
  handleChange: (event: ChangeEvent<any>) => void;
}

const specialLocationTags = [
  FieldTag.Teaser,
  FieldTag.Catalyst
];

export const Locations: React.FC<LocationsProps> = memo(({ locations, onChange, fieldTags, specialLocationDescription, handleChange }) => {
  const onChangeValues = useCallback((newValue: LocationDescription, index: number) => {
    const copy: Array<LocationDescription> = JSON.parse(JSON.stringify(locations));
    
    copy[index] = newValue;

    onChange(copy);
  }, [locations, onChange]);

  const onAddNewLocation = useCallback(() => {
    const copy: Array<LocationDescription> = JSON.parse(JSON.stringify(locations));
    
    copy.push({
      address: '',
      coords: {
          latitude: 0,
          longitude: 0,
          city: '',
          state: '',
          country: null,
      },
    });

    onChange(copy);
  }, [locations, onChange]);

  const onRemoveLocation = useCallback((index: number) => {
    const copy: Array<LocationDescription> = JSON.parse(JSON.stringify(locations));

    copy.splice(index, 1);

    onChange(copy);
  }, [locations, onChange]);

  return (
    <div>
      <div className='d-flex flex-wrap-small my-2 position-relative'>
        <label className='label-fix-width'>Special Location</label>
        <div
          className='d-flex flex-column position-absolute'
          style={{
            gap: '3px',
            minWidth: '8px',
            left: '-11px',
            top: '4px',
          }}
        >
          {specialLocationTags.map((fieldTag) => (
            <div
              style={{
                background: fieldTagsColors[fieldTag].color,
                width: '8px',
                height: '8px',
                borderRadius: '50%',
              }}
            />
          ))}
        </div>
        <input
          type='text'
          value={specialLocationDescription}
          name='specialLocationDescription'
          id='specialLocationDescription'
          onChange={handleChange}
          className='form-control mb-5'
        />
      </div>
      {locations.map((location, index) =>
        <>
          <Location
            index={index}
            location={location}
            onChange={onChangeValues}
            onRemove={onRemoveLocation}
            fieldTags={fieldTags}
          />
          <hr />
        </>
      )}
      <div className='d-flex justify-content-center'>
        <Button
          className="btn-rounded-circle btn-success mt-2"
          onClick={onAddNewLocation}
        >
          <span className="fe fe-plus"></span>
        </Button>
      </div>
      <hr/>
    </div>
  );
});
