import { INITIAL_STATE, SET_PROPERTIES, ADD_PROPERTY, DELETE_PROPERTY, UPDATE_DEFAULT_PROPERTY_MODE } from './types';

// TODO check type for action object
export const propertiesReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    // case UPSERT_PROPERTY:
    //     return {
    //         ...properties,
    //         payload
    //     };
    // case DELETE_PROPERTY:
    //     return {
    //         properties: [...state.properties]
    //             .filter((property: Property) => property._id !== payload)
    //     }
    case SET_PROPERTIES:
      return {
        ...state,
        properties: payload,
      };
    case ADD_PROPERTY:
      return {
        ...state,
        properties: [...state.properties, payload],
      };
    case DELETE_PROPERTY:
      return {
        ...state,
        properties: [...state.properties.filter((p) => p._id !== payload)],
      };
    case UPDATE_DEFAULT_PROPERTY_MODE:
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (property._id === payload.id) {
            property.isDefaultProperty = payload.isDefaultProperty;
          }
          return property;
        }),
      };
    default:
      return state;
  }
};
