import api from "./estatingApi";
import {ContractType, Signer} from "../screens/user/UserContracts";
import {DocusignTemplate} from "../screens/contacts/SendContracts";

export const DocuSignApi = {
    queryTemplates: () => {
        return new Promise<DocusignTemplate[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/ds/templates`)
                resolve(response.data)
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to get docusign templates'))
            }
        })
    },
    requestToSignTemplate: (userId: string, template: DocusignTemplate, signers: Signer[]) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const response = await api.post(`/ds/template/send/${userId}`, {template, signers})
                resolve(response.data)
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to send template to sign'))
            }
        })
    },

    updateEnvelopeStatus: (envelopeId: string, userId: string) => {
        return new Promise<ContractType[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/ds/envelope/${envelopeId}/${userId}`)
                resolve(response.data)
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to get envelope status'))
            }
        })
    }
}
