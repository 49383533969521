import { memo, FC, useEffect, useCallback, useMemo, useState } from 'react';
import { MaterialGroupListItem } from './material-group-list-item';
import { useDispatch, useSelector } from 'react-redux';
import { educationMaterialsGroupsSelector } from '../../../store/education-materials/selectors';
import { updateEducationMaterialsGroupsOrder } from '../../../store/education-materials/thunks';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { ItemTypes } from '../../../types/drag-and-drop/item-types';
import { debounce } from 'lodash';
import { EducationMaterialsGroup } from '../../../types/education-materials/education-materials-group';

export const MaterialGroupList: FC<{}> = memo(() => {
  const dispatch = useDispatch();
  const groupsFromStore = useSelector(educationMaterialsGroupsSelector);

  const groups = useMemo(() => 
  JSON.parse(JSON.stringify(groupsFromStore)) as Array<EducationMaterialsGroup> | null || [],
  [groupsFromStore]);

  const [groupsState, setGroupsState] = useState(groups);

  useEffect(() => {
    setGroupsState(groups);
  }, [groups]);

  const findGroup = useCallback(
    (id: string) => {
      const targetIndex = groupsState.findIndex((group) => group._id === id);

      return {
        group: groupsState[targetIndex],
        index: targetIndex,
      };
    },
    [groupsState]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeOrder = useCallback(
    debounce(async (reorderedObjectsIds: Array<string>) => {
      dispatch(updateEducationMaterialsGroupsOrder(reorderedObjectsIds));
    }, 500),
    [dispatch]
  );

  const moveGroup = useCallback(
    (id: string, atIndex: number) => {
      const { group, index } = findGroup(id);
      const updatedOrder = update(groupsState, {
        $splice: [
          [index, 1],
          [atIndex, 0, group],
        ],
      });

      setGroupsState(updatedOrder);
      onChangeOrder(updatedOrder.map((group) => group._id))
    },
    [findGroup, groupsState, onChangeOrder]
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.MATERIAL_GROUP }));

  if (!groups) {
    return <div className='d-flex justify-content-center align-items-center flex-column w-100 position-relative' />;
  }

  return (
    <div className='d-flex justify-content-center align-items-center flex-column w-100 position-relative' ref={drop}>
      {groupsState.map((group) => (
        <MaterialGroupListItem key={group._id} group={group} findGroup={findGroup} moveGroup={moveGroup} />
      ))}
    </div>
  );
});
