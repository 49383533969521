import React from 'react'
import {RouteComponentProps} from "react-router-dom";
import { User } from "../../store/users/types";
import {UsersAPI} from "../../api/userApi";
import {withRouter} from "react-router-dom";
import UserDetails from "./UserDetails";
import {Can} from "../../casl/Can";
import UserContracts from "./UserContracts";
import AdditionalInfo from "./AdditionalInfo";
import {Link} from "react-router-dom";
import UserPropertiesTable from "./UserPropertiesTable";
import AuthenticationSteps from "./AuthenticationSteps";
import AdditionalInfoNonIVO from "./AdditionalInfoNonIVO";
import SessionLog from "./SessionLog";
import UserInteractionsTable from './UserInteractionsTable';
import { WhoAreYou } from '../../types/user/who-are-you';

type UsersProps = {}

type ParamList = { id: string };

const SingleUser: React.FC<RouteComponentProps<ParamList> & UsersProps> = ({match}) => {


    const userId = match.params.id;
    const [user, setUser] = React.useState<User | null>(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (userId) {
            setLoading(true)
            UsersAPI.queryUser(userId)
                .then(user => {
                    setUser(user)
                })
                .catch(e => {
                })
                .finally(() => {
                    setLoading(false)
                })
        }

    }, [userId])

    if (loading) {
        return (
            <div className='w-100 mt-5'>
                <span className='m-auto loader loader-primary loader-lg'/>
            </div>
        )
    }

    if (!user) {
        return null
    }
    return (
        <>
            <ol className='breadcrumb mb-3'>
                <li className='breadcrumb-item'>
                    <Link to={'/users'}>Users</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                    {user ? (user.email || user._id) : 'create new'}
                </li>
            </ol>
            <ul className="nav nav-tabs mb-3" role="tablist">
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2 active" data-toggle="tab" href="#tab_user_details" role="tab"
                       aria-selected="true">
                        Details
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_user_additional_info"
                       role="tab" aria-selected="false">
                        Additional information
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_user_properties" role="tab"
                       aria-selected="false">
                        Opportunities
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_user_authentication_steps" role="tab"
                       aria-selected="false">
                        Authentication steps
                    </a>
                </li>

                <Can I='manageContracts' a='user'>
                    <li className="nav-item">
                        <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_user_contracts" role="tab"
                           aria-selected="false">
                            Contracts
                        </a>
                    </li>
                </Can>
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_user_session_log" role="tab"
                       aria-selected="false">
                        Session Log
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link pt-0 pb-2" data-toggle="tab" href="#tab_interactions" role="tab"
                       aria-selected="false">
                        Interactions
                    </a>
                </li>
            </ul>
            <div className="tab-content mt-3" id="tabs-tabContent">
                <Can I='read' a='user'>
                    <div className="tab-pane fade show active" id="tab_user_details" role="tabpanel">
                        <UserDetails user={user}/>
                    </div>
                </Can>

                <Can I='read' a='user'>
                    <div className="tab-pane fade show" id="tab_user_additional_info" role="tabpanel">
                        {
                            user.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ?
                                <AdditionalInfo
                                    is_accepted={!!user.is_accepted}
                                    user={user}
                                />
                                :
                                <AdditionalInfoNonIVO
                                    is_accepted={!!user.is_accepted}
                                    user={user}
                                />
                        }


                    </div>
                </Can>
                <Can I='read' a='user'>
                    <div className="tab-pane fade show" id="tab_user_properties" role="tabpanel">
                        <UserPropertiesTable user={user}/>
                    </div>
                </Can>

                <Can I='read' a='user'>
                    <div className="tab-pane fade show" id="tab_user_authentication_steps" role="tabpanel">
                        <AuthenticationSteps userId={user._id}/>
                    </div>
                </Can>

                <Can I='manageContracts' a='user'>
                    <div className="tab-pane fade show" id="tab_user_contracts" role="tabpanel">
                        <UserContracts user={user}/>
                    </div>
                </Can>
                <Can I='manageContracts' a='user'>
                    <div className="tab-pane fade show" id="tab_user_session_log" role="tabpanel">
                        <SessionLog user={user._id}/>
                    </div>
                </Can>
                <Can I='manageContracts' a='user'>
                    <div className="tab-pane fade show" id="tab_interactions" role="tabpanel">
                        <UserInteractionsTable user={user._id}/>
                    </div>
                </Can>

            </div>

        </>
    )
}

export default withRouter(SingleUser)
