if (typeof window === 'undefined') {
  // eslint-disable-next-line
  window = {
    _env_: {}
  }
}
if (!window._env_) {
  window._env_ = {}
}

export const config = {
  ESTATING_API_URI: process.env.REACT_APP_ESTATING_API_URI || window._env_.REACT_APP_ESTATING_API_URI,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || window._env_.REACT_APP_GOOGLE_MAP_API_KEY,
  GEOCODING_API_KEY: process.env.REACT_APP_GEOCODING_API_KEY || window._env_.REACT_APP_GEOCODING_API_KEY,
  WEBAPP_SIGNUP_URI: process.env.REACT_APP_WEBAPP_SIGNUP_URI || window._env_.REACT_APP_WEBAPP_SIGNUP_URI,
  REACT_APP_ESTATING_PUBLIC_API_URI: process.env.REACT_APP_ESTATING_PUBLIC_API_URI || window._env_.REACT_APP_ESTATING_PUBLIC_API_URI,
  REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL: process.env?.REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL || window._env_?.REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL,
  REACT_APP_TOLGEE_API_KEY: process.env?.REACT_APP_TOLGEE_API_KEY || window._env_?.REACT_APP_TOLGEE_API_KEY,
  REACT_APP_TOLGEE_API_URL: process.env?.REACT_APP_TOLGEE_API_URL || window._env_?.REACT_APP_TOLGEE_API_URL
};
