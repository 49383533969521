import React from 'react';
import Table from "./Table";

const Sessions: React.FC = () => {
    return (
        <>
            <Table/>
        </>
    )
}

export default Sessions;
