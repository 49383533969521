import {Action} from 'redux';
import {PropertyTeaserType} from "../property/types";

export const UPSERT_PROPERTY = 'UPSERT_PROPERTY';

export const SET_PROPERTIES = 'SET_PROPERTIES';
export const DELETE_PROPERTY = 'DELETE_PROPERTY'
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const UPDATE_DEFAULT_PROPERTY_MODE = 'UPDATE_DEFAULT_PROPERTY_MODE';



export interface PropertiesState {
  properties: PropertyTeaserType[];
}


export interface DeleteProperty extends Action {
  type: typeof DELETE_PROPERTY;
  payload: string;
}

export interface SetProperties extends Action {
  type: typeof SET_PROPERTIES;
  payload: PropertyTeaserType[];
}

export interface AddProperty extends Action {
  type: typeof ADD_PROPERTY;
  payload: PropertyTeaserType;
}

export interface UpdateDefaultPropertyMode extends Action {
  type: typeof UPDATE_DEFAULT_PROPERTY_MODE;
  payload: {
    id: string,
    isDefaultProperty: boolean
  };
}

export const INITIAL_STATE: PropertiesState = {
  properties: [],
};

