import {Action} from "redux";

export const QUERY_LOANS = 'QUERY_LOANS'
export const ADD_LOAN = 'ADD_LOAN'
export const UPDATE_LOAN = 'UPDATE_LOAN'
export const DELETE_LOAN = 'DELETE_LOAN'

export type Loan = {
    _id?: string,
    transaction?: string,
    internalName: string,
    therefromLoanAmount: string,
    lendingBank: string,
    loanInterestRate: string,
    amortization: string,
    annuity: string,
    financingCosts: string,
    maturityInterestPeriod: string
}

export interface QueryLoans extends Action {
    type: typeof QUERY_LOANS,
    payload: Loan[]
}

export interface DeleteLoan extends Action {
    type: typeof DELETE_LOAN,
    payload: string
}

export interface AddLoanAction extends Action {
    type: typeof ADD_LOAN,
    payload: Loan
}

export interface UpdateLoan extends Action {
    type: typeof UPDATE_LOAN,
    payload: {loanId: string, updated: Loan}
}

export interface LoanState {
    loans: Loan[]
}

export const INITIAL_STATE: LoanState = {
    loans: []
};

