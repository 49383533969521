import React from 'react'
import {NonIVOUserAdditionalInformation, User} from "../../store/users/types";
import {UsersAPI} from "../../api/userApi";
import Button from "../../components/Button";
import Select from "../../components/Select";
import {COUNTRIES} from "../../constants/countries";
import Input from "../../components/Input";

type AdditionalInfoNonIVOProps = {
    user: User,
    is_accepted: boolean,
}

const AdditionalInfoNonIVO: React.FC<AdditionalInfoNonIVOProps> = ({
                                                                       user,
                                                                       is_accepted
                                                                   }) => {
    const [additionalInformation, setAdditionalInformation] = React.useState<NonIVOUserAdditionalInformation>({
        street: "",
        streetAddition: "",
        postalCode: "",
        poBox: "",
        city: "",
        country: "",
        estate: "",
    });
    const [loading, setLoading] = React.useState(false)
    const [hasError, setHasError] = React.useState<boolean>(false)
    const [responseMessage, setResponseMessage] = React.useState<string>('')

    const toggleIsAccepted = async (is_accepted: boolean) => {
        await UsersAPI.toggleAcceptUserAdditionalInfo(user._id, is_accepted)
    }

    const _alertMessage = (message: string, delay: number = 3000) => {
        setResponseMessage(() => {
            setTimeout(() => {
                setResponseMessage('')
            }, delay)

            return message
        })
    }


    React.useEffect(() => {
        if (user.additionalInformation) {
            setAdditionalInformation(user.additionalInformation)
        }
    }, [user])
    const save = () => {
        setLoading(true)
        UsersAPI.updateUser(user._id, {additionalInformation})
            .then(() => {
                _alertMessage('Successfully updated')
                setHasError(false)
            })
            .catch(e => {
                setHasError(true)
                _alertMessage( e.message)
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;

        setAdditionalInformation({
            ...additionalInformation,
            [name]: value
        });
    };

    return (
        <>
            <div className='border p-5'>
                <Input
                    type="text"
                    onChange={onChange}
                    label='Street'
                    name='street'
                    value={additionalInformation.street}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={onChange}
                    label='Street Addition'
                    name='streetAddition'
                    value={additionalInformation.streetAddition}
                />
                <Input
                    type="text"
                    onChange={onChange}
                    label='Postal Code'
                    name='postalCode'
                    value={additionalInformation.postalCode}
                />
                <Input
                    type="text"
                    containerClass='my-2'
                    onChange={onChange}
                    label='PO Box'
                    name='poBox'
                    value={additionalInformation.poBox}
                />
                <Input
                    type="text"
                    onChange={onChange}
                    label='City'
                    name='city'
                    value={additionalInformation.city}
                />
                <Input
                    type="text"
                    onChange={onChange}
                    label='State'
                    containerClass='my-2'
                    name='estate'
                    value={additionalInformation.estate}
                />
                <Select
                    label='Country'
                    selected={additionalInformation.country}
                    onChange={onChange}
                    name='country'
                    values={COUNTRIES}
                    placeholder='Select the country'
                />

            </div>

            <div
                className='bottom-fixed-save justify-content-between bg-light p-3 align-items-center flex-wrap'>
                <Button
                    label='Save'
                    className={`btn-success ${loading ? 'is-loading disabled' : ''}`}
                    onClick={save}
                />
                {
                    responseMessage ?
                        <div
                            className={`alert mb-0 col-4 px-0 py-2 text-center  ${hasError ? 'alert-danger' : 'alert-success'}`}>
                            {responseMessage}
                        </div>
                        :
                        null
                }
                <div className='d-flex align-items-center'>
                    <label htmlFor={user._id + '_isActive'}
                           className="p-0 m-0">
                        <p className="h5 p-0 mr-3 mb-0">Verified</p>
                    </label>
                    <div className="custom-control custom-checkbox-toggle">
                        <input
                            type="checkbox"
                            defaultChecked={is_accepted}
                            onChange={e => {
                                toggleIsAccepted(e.target.checked)
                            }}
                            className="custom-control-input"
                            id={user._id + '_isActive'}/>
                        <label className="custom-control-label"
                               htmlFor={user._id + '_isActive'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdditionalInfoNonIVO
