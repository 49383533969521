import { basename } from 'path';

export const filenameFromUrlWithId = (url: string) => {
  const documentNameByDots = url && basename(decodeURI(url)).split('.');

  let extension, documentName;

  if (documentNameByDots) {
    extension = documentNameByDots.pop();
    const lastPartOfDotName = documentNameByDots.pop();
    const lastPartSpitedWithUnderScore = lastPartOfDotName?.split('_');
    lastPartSpitedWithUnderScore?.pop();

    documentName = `${documentNameByDots?.join('.')}${documentNameByDots?.join('.') ? '.' : ''}${lastPartSpitedWithUnderScore?.join('_')}.${extension}`;
  }

  return documentName;
};