import React from 'react';
import Button from "./Button";

export type FileUploadProps = {
    id?: string,
    label?: string,
    style?: React.CSSProperties,
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any,
    removeDocument?: (field: 'dataOfIncorporation' | 'ownership' | 'licenseOfOperate' | 'registrations' | '', index: number) => any,
    removeSigningRightsDocument?: (field: 'signingRightsDocuments', index: number) => any,
    removeUploadedFile: (field: 'incorporationDocuments' | 'ownershipDocuments' |
        'licenseOfOperateDocuments' | 'registrationsDocuments' | 'signingRightsDocuments', file: File) => any,
    field?: 'dataOfIncorporation' | 'ownership' | 'licenseOfOperate' | 'registrations' | '',
    signingRightsField?: 'signingRightsDocuments',
    required?: boolean,
    disabled?: boolean,
    name: 'incorporationDocuments' | 'ownershipDocuments' |
        'licenseOfOperateDocuments' | 'registrationsDocuments' | 'signingRightsDocuments';
    callback?: (isValid: boolean, field: string) => void,
    containerClass?: string,
    labelClass?: string,
    fixedWidth?: boolean,
    documents: string[],
    uploadedDocuments: File[],
    text?: string,
}

export const FileUpload: React.FC<FileUploadProps> = ({
                                                          id,
                                                          label,
                                                          style,
                                                          className,
                                                          onChange = () => {
                                                          },
                                                          required,
                                                          name,
                                                          containerClass = '',
                                                          labelClass,
                                                          disabled,
                                                          documents,
                                                          uploadedDocuments,
                                                          removeDocument= () => {
                                                          },
                                                          removeSigningRightsDocument= () => {
                                                          },
                                                          removeUploadedFile,
                                                          field='',
                                                          text,
                                                          signingRightsField
                                                      }) => {
    return (
        <div>
            <div className={`${containerClass} d-flex flex-wrap-small align-items-center my-2 w-100`}>
                {
                    label &&
                    <label
                        htmlFor={id}
                        className={`label-fix-width ${required ? 'font-weight-bold' : ''} ${labelClass || ''}`}>
                        {label}{required ? '*' : ''}
                    </label>
                }
                <div>
                    <input
                        type="file"
                        id={id}
                        multiple={true}
                        onChange={onChange}
                        name={name}
                        disabled={disabled}
                        style={style}
                        className={className}
                    />
                    {text && <p className="mt-2">{text}</p>}
                </div>
            </div>

            {
                uploadedDocuments && uploadedDocuments.map((file, index) => {
                    return <div
                        className="alert alert-info d-flex align-items-center justify-content-between my-2 flex-wrap"
                        key={file.name + index}
                    >
                        <p className="mb-0">{file.name}</p>
                        <div className="btn-group">
                            <button
                                onClick={() => removeUploadedFile(name, file)}
                                className="btn btn-danger btn-sm" style={{position: 'relative'}}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                })
            }
            {
                documents.length > 0 ?
                    documents.map((doc, index) => {
                        return (
                            <div
                                key={doc}
                                className='alert alert-light d-flex align-items-center justify-content-between my-2 flex-wrap'>
                                <p className='mb-0'>
                                    {doc.split('/').pop()}
                                </p>
                                <div className='btn-group'>
                                    <a
                                        href={doc}
                                        download={true}
                                        className='btn btn-success btn-sm'
                                    >
                                        Download
                                    </a>
                                    <Button
                                        label='Remove'
                                        onClick={() =>
                                            signingRightsField ? removeSigningRightsDocument(signingRightsField, index) :
                                                removeDocument(field, index)
                                    }
                                        className='btn-danger btn-sm'
                                    />
                                </div>
                            </div>
                        )
                    })
                    :
                    null
            }
        </div>
    )
}

export default FileUpload;


