import {Action} from "redux";
import {Ability, AbilityTuple, Subject} from "@casl/ability";
import {SubjectRawRule} from "@casl/ability/dist/types/RawRule";

export const QUERY_RULES = 'QUERY_RULES'
export const SET_ABILITY = 'SET_ABILITY'

type SubjectType = 'user' | 'property' | 'admin' | 'role' | string

export type RuleType = {
    _id?: string,
    subject: SubjectType,
    actions: string,
    conditions?: any,
    [key: string]: any
}

export interface QueryRules extends Action {
    type: typeof QUERY_RULES,
    payload: SubjectRawRule<string, string, any>[]
}

export interface SetAbility extends Action {
    type: typeof SET_ABILITY,
    payload: Ability<AbilityTuple<string, Subject>>
}
export interface RulesState {
    rules: []
}

export const INITIAL_STATE: RulesState = {
    rules: []
};

