import { memo, FC, useContext, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { TableContext } from '..'
import { Background } from './background'
import { Rows } from './content/rows'

interface TableProps {
}

export const Table: FC<TableProps> = memo(() => {
  const { tableWidth } = useContext(TableContext);
  const [backgroundHeight, setBackgroundHeight] = useState(0);
  const rowsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBackgroundHeight(rowsContainer.current?.clientHeight ?? 0);
  }, [rowsContainer.current?.clientHeight]);

  return (
    <TableContainer width={tableWidth}>
      <Background height={backgroundHeight} />
      <Rows ref={rowsContainer} />
    </TableContainer>
  )
})


interface TableContainerProps {
  width: number;
}

const TableContainer = styled.div<TableContainerProps>`
  position: relative;

  width: ${({width}) => width}px;

  padding: 50px 0;
`