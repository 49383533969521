import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { config } from '../appConfig';
import history from '../history';

const baseQuery = fetchBaseQuery({
  baseUrl: config.ESTATING_API_URI,
  prepareHeaders: (headers) => {
    headers.set('x-auth', localStorage.getItem('jwtToken') ?? '');
  },
  credentials: 'include',
});

export const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('jwtToken');
    history.push('/login');
  }
  return result;
};
