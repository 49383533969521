import { useMemo } from 'react';
import { Ability } from "@casl/ability";
import {SubjectRawRule} from "@casl/ability/dist/types/RawRule";
import {useSelector} from "react-redux";
import {rulesSelector} from "../store/rules/selectors";

const useAbility = () => {
    const rules = useSelector(rulesSelector)

    const memoizedAbility = useMemo(() => {
        return new Ability(JSON.parse(JSON.stringify(rules)).map((rule: SubjectRawRule<string, string, any> & { actions?: string }) => {
            if (!rule.action && rule.actions) {
                rule.action = rule.actions
                delete rule.actions
            }

            return rule
        }))
    }, [rules]);

    return memoizedAbility
}

export default useAbility
