import { Fragment, useCallback, useEffect, useMemo, useState, FC, ChangeEvent, useContext } from 'react';
import { useFormik } from 'formik';
import {
  PropertyType,
  CapitalStructureEnum,
  Event,
  PropertyCategoryEnum,
  PropertyInvestmentStrategyEnum,
  PropertyOpportunityTypeEnum,
  PropertyStateEnum,
  PropertyTeaserType,
  SubscriptionStatus,
  FinancialStructure,
  TeaserStatus,
} from '../../../store/property/types';
import Section from '../../../components/Section';
import Input from '../../../components/Input';
import Tags from '../Tags';
import { SelectBox } from '../../../components/SelectBox';
import Images from '../components/Images';
import { iconTypes } from '../../../constants/property';
import AdditionalValues from '../components/AdditionalValues';
import { Locations } from '../components/Locations';
import SubHeader from '../../../components/SubHeader';
import Documents from '../components/Documents';
import { cloneDeep, isEqual } from 'lodash';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import DropImages from '../../../components/DropImages';
import WysiwygEditor from '../../../components/WysiwygEditor';
import { DroppedFile, FileCategory } from '../../../types/dropped-file';

import Switch, { SwitchType } from '../../../components/Switch';
import { PropertyTimelineGanttChart } from '../../../components/properties-timeline-gantt-chart';
import { MultipleSelecet } from '../../../components/multiple-selet';
import MaterialFileDrop from '../../education-materials/components/material-file-drop';
import { LoadedFilePesenter } from '../../education-materials/components/loaded-file-pesenter';
import { useGetEducationMaterialGroupsQuery } from '../../../services/education-material-groups';
import { MultiSelect } from 'react-multi-select-component';
import { MaterialsList } from '../../education-materials/components/materials-list';
import { EducationMaterial } from '../../../types/education-materials/education-material';
import { convertEnumToOptions } from '../../../helpers/convert-enum-to-options';
import { ExtendedAdditionalDetails } from '../components/extended-additional-details';
import { fieldTagsColors, fieldsTags } from '../../../constants/fields-tags';
import { propertyTeaserFormValidationSchema } from '../../../helpers/property-teaser-from-validation-schema';
import { PerformaceReportPasswordInput, Option } from '../../../components/performace-report-password-input';
import { MultiValue } from 'react-select';
import { AbilityContext } from '../../../casl/Can';
import { DescriptionsAdditionalValues } from '../components/descriptions-additional-values';
import { LanguageSwicher } from '../../../components/language-switcher';
import { useLanguage } from '../../../contexts/language/language-context';
import { useTranslate } from '@tolgee/react';
import { RoleProtectedDocuments } from '../components/role-protected-documents';

interface PropertyTeaserFormProps {
  initialValues: { [key: string]: PropertyTeaserType };
  onSubmit: (
    translations: { [key: string]: PropertyTeaserType},
    droppedImages: DroppedFile[],
    imagesToDelete: string[],
    callback: () => any
  ) => any;
  isEdit?: boolean;
  isLoading?: boolean;
  availableLanguages: Array<{ label: string; value: string; }>
}

const PropertyTeaserForm: FC<PropertyTeaserFormProps> = ({
  initialValues,
  onSubmit,
  isEdit,
  isLoading,
  availableLanguages
}) => {
  const { data: educationMaterialGroups, isFetching: isEducationMaterialGroupsFetching } =
    useGetEducationMaterialGroupsQuery();    
  const { t: tEnums } = useTranslate('enums');

  const {
    opportunityTypeOptions,
    subscribtionStatusOptions,
    teaserStatusOptions,
    propertyInvestmentStrategyOptions,
    propertyCategoryOptions,
    financialStructureOptions,
    propertyStateOptions,
    capitalStructureOptions,
    propertyTypeOptions
  } = useMemo(() => ({
    opportunityTypeOptions: convertEnumToOptions(PropertyOpportunityTypeEnum, false, tEnums, 'opportunity_type'),
    subscribtionStatusOptions: convertEnumToOptions(SubscriptionStatus, false, tEnums, 'subscription_status'),
    teaserStatusOptions: convertEnumToOptions(TeaserStatus, true, tEnums, 'teaser_status'),
    propertyInvestmentStrategyOptions: convertEnumToOptions(PropertyInvestmentStrategyEnum, true, tEnums, 'investment_strategy'),
    propertyCategoryOptions: convertEnumToOptions(PropertyCategoryEnum, true, tEnums, 'property_category'),
    financialStructureOptions: convertEnumToOptions(FinancialStructure, true, tEnums, 'financial_structure'),
    propertyStateOptions: convertEnumToOptions(PropertyStateEnum, false, tEnums, 'property_state'),
    capitalStructureOptions: convertEnumToOptions(CapitalStructureEnum, false, tEnums, 'capital_structure'),
    propertyTypeOptions: convertEnumToOptions(PropertyType, false, tEnums, 'property_type')
  }), [tEnums]);

  const educationMaterialOptions = useMemo(() => {
    if (!educationMaterialGroups || isEducationMaterialGroupsFetching) {
      return [];
    }

    const out: Array<{ label: string; value: string }> = [];

    educationMaterialGroups.forEach((group) => {
      const groupName = group.title;

      group.materials.forEach((material) => {
        out.push({ label: `${groupName} > ${material.name}`, value: material._id });
      });
    });

    return out;
  }, [educationMaterialGroups, isEducationMaterialGroupsFetching]);

  const [selectedSuggestedMaterials, setSelectedSuggestedMaterials] = useState<Array<{ label: string; value: string }>>(
    []
  );

  useEffect(() => {
    setSelectedSuggestedMaterials(
      isEdit
        ? educationMaterialOptions.reduce(
            (accum, current) => {
              if (~initialValues.en.academyMaterials.findIndex((material) => (material as string) === current.value)) {
                return [...accum, current];
              }
              return accum;
            },
            [] as {
              label: string;
              value: string;
            }[]
          )
        : []
    );
  }, [initialValues, isEdit, educationMaterialOptions]);

  const selectedSuggestedMaterialsPreview = useMemo(() => {
    const out: Array<EducationMaterial> = [];

    if (!educationMaterialGroups || (educationMaterialGroups && !educationMaterialGroups.length)) {
      return out;
    }

    const educationMaterials = educationMaterialGroups.reduce((acc, current) => {
      return acc.concat(current.materials);
    }, [] as Array<EducationMaterial>);

    const map: { [id: string]: EducationMaterial } = {};

    let findArrayCounter = 0;

    for (let i = 0; i < selectedSuggestedMaterials.length; i++) {
      const currentId = selectedSuggestedMaterials[i].value;

      if (Object.prototype.hasOwnProperty.call(map, currentId)) {
        out.push(map[currentId]);
      }

      if (findArrayCounter >= educationMaterials.length) {
        continue;
      }

      for (; findArrayCounter < educationMaterials.length; findArrayCounter++) {
        const currentMaterial = educationMaterials[findArrayCounter];

        if (currentMaterial._id === currentId) {
          out.push(currentMaterial);
          continue;
        }

        map[currentMaterial._id] = currentMaterial;
      }
    }

    return out;
  }, [selectedSuggestedMaterials, educationMaterialGroups]);
  
  const { currentLanguage, setCurrentLanguage } = useLanguage();

  // only one way to properly call hook is call it every render so it's not possible to do it dynamically depend of count of keys for translations
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translationsFormiks = {
    en: useFormik({
      initialValues: initialValues.en,
      enableReinitialize: isEdit,
      validationSchema: propertyTeaserFormValidationSchema,
      onSubmit: () => {},
    }),
    es: useFormik({
      initialValues: initialValues.es || initialValues.en,
      enableReinitialize: isEdit,
      validationSchema: propertyTeaserFormValidationSchema,
      onSubmit: () => {},
    })
  }

  const values = useMemo(() => {
    const out: any = {};

    Object.keys(translationsFormiks).forEach((key) => {
      out[key] = translationsFormiks[key].values;
    });

    return out;
  }, [translationsFormiks]);


  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [droppedImages, setDroppedImages] = useState<DroppedFile[]>([]);

  const {
    handleChange: translatableHandleChange,
    setFieldValue: translatableSetFieldValue,
    values: translatableValues,
    errors: translatableErrors,
  } = translationsFormiks[currentLanguage];

  const {
    handleChange: baseHandleChange,
    setFieldValue: baseSetFieldValue,
    values: baseValues,
    errors: baseErrors,
  } = translationsFormiks['en'];

  const submitForm = useCallback(async () => {
    values.en.academyMaterials = selectedSuggestedMaterials.map(({ value }) => value);
    // await baseSetFieldValue('academyMaterials', selectedSuggestedMaterials.map(({ value }) => value));

    onSubmit(values, droppedImages, imagesToDelete, () => {
      Object.keys(translationsFormiks).forEach((key) => {
        translationsFormiks[key].resetForm();
      });
      setDroppedImages([]);
      setImagesToDelete([]);
    })
  }, [droppedImages, imagesToDelete, onSubmit, selectedSuggestedMaterials, translationsFormiks, values]);

  const handleEnumChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      const { name, value } = target;

      baseSetFieldValue(name, value || null);
    },
    [baseSetFieldValue]
  );

  const ability = useContext(AbilityContext);
  
  const { canReadPerfomanceReportPassword, canEditPerfomanceReportPassword } = useMemo(() => {
    return {
      canReadPerfomanceReportPassword: ability.can('read', 'property-investor-report'),
      canEditPerfomanceReportPassword: ability.can('update', 'property-investor-report')
    }
  }, [ability]);

  const onEventAdd = useCallback(
    (event) => {
      const prevValue = JSON.parse(JSON.stringify(baseValues.timeline.events)) as Array<Event>;
      baseSetFieldValue('timeline.events', [...prevValue, event]);
    },
    [baseValues, baseSetFieldValue]
  );

  const onEventEdit = useCallback(
    (targetIndex: number, updatedValue: Event) => {
      const deepCopy = JSON.parse(JSON.stringify(baseValues.timeline.events));

      if (targetIndex < 0) {
        return;
      }
      deepCopy[targetIndex] = updatedValue;

      baseSetFieldValue('timeline.events', deepCopy);
    },
    [baseValues, baseSetFieldValue]
  );

  const onEventDelete = useCallback(
    (targetIndex: number) => {
      const deepCopy = JSON.parse(JSON.stringify(baseValues.timeline.events)) as Array<Event>;

      deepCopy.splice(targetIndex, 1);

      baseSetFieldValue('timeline.events', deepCopy);
    },
    [baseValues, baseSetFieldValue]
  );

  const _primeVal = (property: PropertyTeaserType) => {
    return property?.internalName?.trim() || property?.name?.trim() || property?._id;
  };

  const setAboutPartnerImage = useCallback((images: Array<DroppedFile>) => {
    setDroppedImages(images);
  }, []);

  const performancePasswordFieldValue = useMemo(() => {

    return baseValues.performanceReport.passwords.map(({ decoded }) => ({ label: decoded, value: decoded }));

  }, [baseValues.performanceReport.passwords]);

  const onPerformancePasswordFieldValueChange = useCallback((newValue: MultiValue<Option>) => {
    baseSetFieldValue('performanceReport.passwords', newValue.map(({ label }) => ({ decoded: label})));
  }, [baseSetFieldValue]);

  const onAddValue = useCallback((fieldName: string, index, newValue: any) => {
    Object.keys(translationsFormiks).forEach((languageKey) => {
      const { values, setFieldValue } = translationsFormiks[languageKey];
      const pathSteps = fieldName.split('.');
      
      const targetValue = pathSteps.reduce((acc, step) => {
        return acc[step];
      }, values);
      
      const copy = cloneDeep(targetValue);
      copy.splice(index, 0, cloneDeep(newValue));

      setFieldValue(fieldName, copy);
    });
  }, [translationsFormiks]);

  const onRemoveValue = useCallback((fieldName, index) => {
    Object.keys(translationsFormiks).forEach((languageKey) => {
      const { values, setFieldValue } = translationsFormiks[languageKey];
      const pathSteps = fieldName.split('.');

      const targetValue = pathSteps.reduce((acc, step) => {
        return acc[step];
      }, values);

      const copy = cloneDeep(targetValue);
      copy.splice(index, 1);

      setFieldValue(fieldName, copy);
    });
  }, [translationsFormiks]);

  return (
    <Fragment>
      <ol className='breadcrumb mb-3'>
        <li className='breadcrumb-item'>
          <Link to={'/opportunities'}>Opportunities</Link>
        </li>
        <li className='breadcrumb-item active' aria-current='page'>
          {isEdit ? _primeVal(baseValues) : 'create new'}
        </li>
      </ol>

      <div className='card'>
        <div className='card-header'>
          <div className='d-flex flex-1 justify-content-between align-items-center'>
            <h2 className='card-header-title'>
              {baseValues?._id ? 'Edit property: ' + _primeVal(baseValues) : 'Add property'}
            </h2>
            <div>
              <LanguageSwicher
                selectedLanguage={currentLanguage}
                availableLanguages={availableLanguages}
                onLanguageSelect={setCurrentLanguage}
              />
            </div>
          </div>
        </div>
        <div className='px-4 pt-3 pb-1 d-flex'>
          {Object.keys(fieldTagsColors).map((key) => {
            const { name, color } = fieldTagsColors[key];
            
            return (
              <div className='d-flex align-items-center justify-content-center mr-2'>
                <div
                  style={{
                    background: color,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                  }}
                  className='mr-1'
                />
                <div
                  style={{
                    color,
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '16.5px',
                    letterSpacing: '0.3px',
                    textTransform: 'capitalize'
                  }}
                >
                  {name}
                </div>
              </div>
            );
          })}
        </div>
        <div className='card-body pt-0'>
          {/* Metadata */}
          {/*            <Section label='Metadata'>
              <GoldenSheetSynchronization
                goldenSheetPath={goldenSheetPath}
                onSheetPathSelect={onSheetPathSelect}
                onSheetPathClear={onSheetPathClear}
                propertyTeaserId={id}
              />
            </Section>*/}

          <Section label='Name'>
            <Fragment>
              <Input
                containerClass='my-2'
                label='Internal Name'
                name='internalName'
                value={baseValues.internalName}
                onChange={baseHandleChange}
              />
              <Input
                label='Description'
                name='name'
                value={translatableValues.name}
                onChange={translatableHandleChange}
                fieldTags={fieldsTags['name']}
                formikError={(translatableErrors as any).name}
              />
              <Input
                containerClass='my-2'
                label='ISIN'
                name='options.isin'
                value={baseValues.options.isin}
                onChange={baseHandleChange}
                fieldTags={fieldsTags['options.isin']}
              />
              <Input
                containerClass='my-2'
                label='Relinc Price'
                name='options.relincPrice'
                value={baseValues.options.relincPrice}
                onChange={baseHandleChange}
                fieldTags={fieldsTags['options.relincPrice']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.opportunityType}
                label='Type of Opportunity'
                options={opportunityTypeOptions}
                name='[opportunityType]'
                fieldTags={fieldsTags['[opportunityType]']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.options.status}
                label='Status'
                options={subscribtionStatusOptions}
                name='[options.status]'
                fieldTags={fieldsTags['[options.status]']}
              />
              {baseValues.options.status === 'SECONDARY_MARKET' &&
                <Switch
                  label={'Resale available'}
                  checked={baseValues.options.resaleAvailable}
                  onChange={baseHandleChange}
                  prefix='resaleAvailable'
                  name='options.resaleAvailable'
                />
              }
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.options.teaserStatus}
                label='Teaser Status'
                options={teaserStatusOptions}
                name='[options.teaserStatus]'
                fieldTags={fieldsTags['[options.teaserStatus]']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.options.investmentStrategy}
                label='Investment Strategy'
                options={propertyInvestmentStrategyOptions}
                name='[options.investmentStrategy]'
                fieldTags={fieldsTags['[options.investmentStrategy]']}
              />

              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.options.category}
                label='Category'
                options={propertyCategoryOptions}
                name='[options.category]'
                fieldTags={fieldsTags['[options.category]']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.options.state}
                label='On-line Status'
                options={propertyStateOptions}
                name='[options.state]'
              />
              <Tags
                tags={baseValues.tags || []}
                callback={(updated) => {
                  baseSetFieldValue('tags', updated);
                }}
                fieldTags={fieldsTags['tags']}
              />
              <Switch
                name='timeline-enable-switch'
                prefix='timeline-enable-switch'
                label='Timeline'
                checked={baseValues.timeline.enabled}
                onChange={() => {
                  baseSetFieldValue('timeline.enabled', !baseValues.timeline.enabled);
                }}
                switchType={SwitchType.SLIDER}
              />
            </Fragment>
          </Section>

          <Section label='Timeline'>
            <PropertyTimelineGanttChart
              property={baseValues}
              onEventAdd={onEventAdd}
              onEventEdit={onEventEdit}
              onEventDelete={onEventDelete}
              fieldTags={fieldsTags['timeline']}
            />
          </Section>

          <Section label='Slider images'>
            <Fragment>
              <Images
                prefix='sliderImages_teaser'
                images={baseValues.assets}
                sortCallback={(sortedImages) => {
                  baseSetFieldValue('assets', sortedImages);
                }}
                imagesToDelete={imagesToDelete}
                setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                fieldTags={fieldsTags['assets']}
              />
              <DropImages
                description={
                  <div className='mt-2 d-flex justify-content-center p-2'>
                    <ul className='text-left mb-0 rounded bg-dark p-4' style={{ listStyle: 'none' }}>
                      <li>
                        The preferable image sizes for having good pictures with high quality should be either:{' '}
                        <b>480x345</b> or <b>750x562</b> (width-height) <br />
                      </li>
                      <li>
                        The most important thing is to keep the ratio of width to a height equal to <b>1.3-1.5</b>{' '}
                        <br />
                      </li>
                      <li>
                        This means that the image can be bigger/smaller than the above sizes but the ratio must be kept
                      </li>
                    </ul>
                  </div>
                }
                category={FileCategory.SLIDER}
                callback={(droppedImages) => setDroppedImages(droppedImages)}
                droppedImages={droppedImages}
              />
            </Fragment>
          </Section>

          <Section label='Investment Strategy'>
            <DescriptionsAdditionalValues
              prefix='investmentStrategy'
              onChange={(changedValue) => {
                translatableSetFieldValue('investmentStrategy', changedValue);
              }}
              onAdd={(index, newValue) => {
                onAddValue('investmentStrategy', index, newValue);
              }}
              onRemove={(index) => {
                onRemoveValue('investmentStrategy', index);
              }}
              descriptions={translatableValues.investmentStrategy}
              fieldTags={fieldsTags['investmentStrategy']}
              errors={(translatableErrors as any).investmentStrategy}
            />
          </Section>

          <Section label={`Teaser's summary`}>
            <WysiwygEditor
              // as this component is uncontrolled we need to re-render it on each language change to pick actual value from formik state as it loads onse on component mount
              key={currentLanguage}
              value={translatableValues.teaserSummary || ''}
              onValueChange={(value) => {
                translatableSetFieldValue('teaserSummary', value !== `<p style="text-align:start;"></p>\n` ? value : '');
              }}
              fieldTags={fieldsTags['teaserSummary']}
            />
          </Section>

          <Section label='Opportunity Summary (cards)'>
            <ExtendedAdditionalDetails
              baseValues={baseValues.summary}
              baseOnChange={(changedValues) => {
                baseSetFieldValue('summary', changedValues);
              }}
              isNew={!isEdit}
              prefix='propertySummary_cards'
              onSave={() => {}}
              icons={iconTypes}
              onChange={(changedValues) => {
                translatableSetFieldValue('summary', changedValues);
              }}
              onAdd={(index, newValue) => {
                onAddValue('summary', index, newValue);
              }}
              onRemove={(index) => {
                onRemoveValue('summary', index);
              }}
              values={translatableValues.summary}
              addNew={false}
              disabledFields={['iconType', 'id', 'name', '_reorder']}
              fieldTags={fieldsTags['[summary]']}
              withTooltip={false}
              errors={(baseErrors as any).summary}
            />
          </Section>

          <Section label='Opportunity Details'>
            <Fragment>
              <SubHeader label='Highlighted details' />
              <ExtendedAdditionalDetails
                baseValues={baseValues.details.highlighted}
                baseOnChange={(changedValues) => {
                  baseSetFieldValue('[details.highlighted]', changedValues);
                }}
                isNew={!isEdit}
                prefix='detailHighlighted_teaser'
                onSave={() => {}}
                icons={iconTypes}
                onChange={(changedValues) => {
                  translatableSetFieldValue('[details.highlighted]', changedValues);
                }}
                onAdd={(index, newValue) => {
                  onAddValue('details.highlighted', index, newValue);
                }}
                onRemove={(index) => {
                  onRemoveValue('details.highlighted', index);
                }}
                values={translatableValues.details.highlighted}
                addNew={false}
                fieldTags={fieldsTags['[details.highlighted]']}
                errors={(baseErrors as any).details?.highlighted}
              />
              <SubHeader label='Secondary details' />
              <div className='mt-2 d-flex justify-content-center p-2'>
                <ul className='text-left mb-0 rounded bg-dark p-4' style={{ listStyle: 'none' }}>
                  <li>
                    A maximum of 9 secondary fields will be displayed in the Teaser
                  </li>
                </ul>
              </div>
              <MultipleSelecet
                onChange={baseHandleChange}
                selected={baseValues.details.secondary.assetType}
                label='Asset Type'
                options={propertyTypeOptions}
                name='details.secondary.assetType'
                changeCallback={(values) => {
                  baseSetFieldValue('details.secondary.assetType', values);
                }}
                fieldTags={fieldsTags['details.secondary.assetType']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.details.secondary.capitalStructure}
                label='Capital Structure'
                options={capitalStructureOptions}
                name='[details.secondary.capitalStructure]'
                fieldTags={fieldsTags['[details.secondary.capitalStructure]']}
              />
              <SelectBox
                onChange={handleEnumChange}
                onSave={() => {}}
                isNew={!isEdit}
                selected={baseValues.details.secondary.financialStructure}
                label='Financial Structure'
                options={financialStructureOptions}
                name='details.secondary.financialStructure'
                fieldTags={fieldsTags['details.secondary.financialStructure']}
              />
              <ExtendedAdditionalDetails
                baseValues={baseValues.details.secondary.others}
                baseOnChange={(changedValues) => {
                  baseSetFieldValue('details.secondary.others', changedValues);
                }}
                isNew={!isEdit}
                prefix='detailSecondary_teaser'
                onSave={() => {}}
                icons={iconTypes}
                onChange={(changedValues) => {
                  translatableSetFieldValue('[details.secondary.others]', changedValues);
                }}
                onAdd={(index, newValue) => {
                  onAddValue('details.secondary.others', index, newValue);
                }}
                onRemove={(index) => {
                  onRemoveValue('details.secondary.others', index);
                }}
                values={translatableValues.details.secondary.others}
                removable
                fieldTags={fieldsTags['[details.secondary.others]']}
              />
              <DescriptionsAdditionalValues
                label='Footnotes'
                prefix='details_footnotes'
                onChange={(changedValue) => {
                  translatableSetFieldValue('[details.footnotes]', changedValue);
                }}
                onAdd={(index, newValue) => {
                  onAddValue('details.footnotes', index, newValue);
                }}
                onRemove={(index) => {
                  onRemoveValue('details.footnotes', index);
                }}
                descriptions={translatableValues.details.footnotes}
                fieldTags={fieldsTags['[details.footnotes]']}
              />
            </Fragment>
          </Section>

          <Section label='About Opportunity'>
            <>
              <WysiwygEditor
                // as this component is uncontrolled we need to re-render it on each language change to pick actual value from formik state as it loads onse on component mount
                key={currentLanguage}
                value={translatableValues.about.wysiwygDescription || ''}
                onValueChange={(value) => translatableSetFieldValue('about.wysiwygDescription', value)}
                fieldTags={fieldsTags['[about.wysiwygDescription]']}
              />
              <hr />
              <Images
                prefix='floorPlanImages'
                images={baseValues.floorPlan.assets}
                imagesToDelete={imagesToDelete}
                setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                fieldTags={fieldsTags['floorPlan.assets']}
              />
              <DropImages
                callback={(droppedImages) => setDroppedImages(droppedImages)}
                category={FileCategory.FLOORPLAN}
                description='(floorplan)'
                droppedImages={droppedImages}
              />
            </>
          </Section>

          <Section label='Partner'>
            <Fragment>
              <SubHeader label='Text' />
              <div>
                <div className='d-flex justify-content-end pb-1'>
                  <Button
                    label='Clear'
                    onClick={() => {
                      baseSetFieldValue('securityAndRoles.aboutThePartner.text', '');
                    }}
                    className='btn-danger btn-sm'
                  />
                </div>
                <WysiwygEditor
                  // as this component is uncontrolled we need to re-render it on each language change to pick actual value from formik state as it loads onse on component mount
                  key={currentLanguage}
                  value={translatableValues.securityAndRoles?.aboutThePartner?.text || ''}
                  onValueChange={(value) => {
                    translatableSetFieldValue('securityAndRoles.aboutThePartner.text', value !== `<p style="text-align:start;"></p>\n` ? value : '');
                  }}
                  fieldTags={fieldsTags['securityAndRoles.aboutThePartner.text']}
                />
              </div>

              <SubHeader label='Image' />
              <Images
                prefix={FileCategory.ABOUT_PARTNER_IMAGE}
                images={baseValues.securityAndRoles?.aboutThePartner?.image ? [baseValues.securityAndRoles?.aboutThePartner?.image] : []}
                imagesToDelete={imagesToDelete}
                setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                fieldTags={fieldsTags['securityAndRoles.aboutThePartner.image']}
              />
              <DropImages
                callback={setAboutPartnerImage}
                category={FileCategory.ABOUT_PARTNER_IMAGE}
                description='(about partner image)'
                droppedImages={droppedImages}
                multiple={false}
                maxCount={1}
              />
            </Fragment>
          </Section>

          <Section label='Location'>
            <Locations
              onChange={(newValue) => baseSetFieldValue('locations', newValue)}
              locations={baseValues.locations}
              fieldTags={fieldsTags['locations']}
              handleChange={baseHandleChange}
              specialLocationDescription={baseValues.specialLocationDescription}
            />
          </Section>

          <Section label='Risk'>
            <Fragment>
              <SubHeader label='Text' />
              <div className='d-flex justify-content-end pb-1'>
                <Button
                  label='Clear'
                  onClick={() => {
                    baseSetFieldValue('risk.riskMitigationFactors', '');
                  }}
                  className='btn-danger btn-sm'
                />
              </div>
              <WysiwygEditor
                // as this component is uncontrolled we need to re-render it on each language change to pick actual value from formik state as it loads onse on component mount
                key={currentLanguage}
                value={translatableValues.risk.riskMitigationFactors || ''}
                onValueChange={(value) => {
                  translatableSetFieldValue(
                    'risk.riskMitigationFactors',
                      value !== `<p style="text-align:start;"></p>\n` && value !== '<p></p>\n' ? value : ''
                  );
                }}
                fieldTags={fieldsTags['risk.riskMitigationFactors']}
              />
              <SubHeader label='Images' />
              <Images
                prefix='riskMigrationImages'
                images={baseValues.risk.riskMitigationImages}
                imagesToDelete={imagesToDelete}
                sortCallback={(sortedImages) => {
                  baseSetFieldValue('risk.riskMitigationImages', sortedImages);
                }}
                setDeletedImages={(imagesToDelete) => setImagesToDelete(imagesToDelete)}
                fieldTags={fieldsTags['risk.riskMitigationImages']}
              />
              <DropImages
                callback={(droppedImages) => setDroppedImages(droppedImages)}
                category={FileCategory.RISK_MIGRATION_IMAGE}
                description='(risk migration image)'
                droppedImages={droppedImages}
              />
              <SubHeader label='Additional Documents' />
              <Documents
                documents={baseValues.risk.documents}
                onChange={(changed) => {
                  baseSetFieldValue('risk.documents', changed);
                }}
                fieldTags={fieldsTags['risk.documents']}
              />
            </Fragment>
          </Section>

          <Section label='Roles'>
            <AdditionalValues
              baseValues={baseValues.securityAndRoles.roles}
              baseOnChange={(changedValues) => {
                baseSetFieldValue('securityAndRoles.roles', changedValues);
              }}
              isNew={!isEdit}
              prefix='rolesAndResponsibilities_teaser'
              onChange={(changedValue) => {
                translatableSetFieldValue('[securityAndRoles.roles]', changedValue);
              }}
              sortCallback={(sortedValues) => {
                translatableSetFieldValue('[securityAndRoles.roles]', sortedValues);
              }}
              onSave={() => {}}
              values={translatableValues.securityAndRoles.roles}
              isTooltip={true}
              isImageUpload={true}
              imagesPrefix="roles-images"
              imagesToDelete={imagesToDelete}
              setImagesToDelete={setImagesToDelete}
              fileCategory={FileCategory.ROLES_AND_RESPONSIBILITIES_IMAGE}
              fieldTags={fieldsTags['[securityAndRoles.roles]']}
              acceptFileExtensions={[
                "image/avif",
                "image/bmp",
                "image/gif",
                "image/vnd.microsoft.icon",
                "image/jpeg",
                "image/png",
                "image/tiff",
                "image/webp",
                "video/mp4"
              ]}
            />
          </Section>

          <Section label='Security Information'>
            <AdditionalValues
              baseValues={baseValues.securityAndRoles.security}
              baseOnChange={(changedValues) => {
                baseSetFieldValue('securityAndRoles.security', changedValues);
              }}

              isNew={!isEdit}
              prefix='security_teaser'
              onChange={(changedValue) => {
                translatableSetFieldValue('[securityAndRoles.security]', changedValue);
              }}
              sortCallback={(sortedValues) => {
                translatableSetFieldValue('[securityAndRoles.security]', sortedValues);
              }}
              onSave={() => {}}
              values={translatableValues.securityAndRoles.security}
              isTooltip={true}
              fieldTags={fieldsTags['[securityAndRoles.security]']}
            />
          </Section>

          <Section label='Data Room'>
            <Fragment>
              <SubHeader label='Market Research Text' />
              <div className='d-flex justify-content-end pb-1'>
                <Button
                  label='Clear'
                  onClick={() => {
                    baseSetFieldValue('dataRoom.marketResearchText', '');
                  }}
                  className='btn-danger btn-sm'
                />
              </div>
              <WysiwygEditor
                // as this component is uncontrolled we need to re-render it on each language change to pick actual value from formik state as it loads onse on component mount
                key={currentLanguage}
                value={translatableValues.dataRoom.marketResearchText || ''}
                onValueChange={(value) => {
                  translatableSetFieldValue(
                    'dataRoom.marketResearchText',
                    value !== `<p style="text-align:start;"></p>\n` ? value : ''
                  );
                }}
                fieldTags={fieldsTags['dataRoom.marketResearchText']}
              />
              <SubHeader label='Market Research Image' />
              <>
                {!!baseValues.dataRoom.marketResearchImage && (
                  <LoadedFilePesenter
                    fileUrl={baseValues.dataRoom.marketResearchImage}
                    mimeType={'image/'}
                    imagesToDelete={imagesToDelete}
                    setDeletedImages={setImagesToDelete}
                    fieldTags={fieldsTags['dataRoom.marketResearchImage']}
                  />
                )}
                <MaterialFileDrop
                  onDrop={(droppedFile) => {
                    if (!droppedFile) {
                      return setDroppedImages((prev) =>
                        prev.filter((image) => image.category !== FileCategory.DATA_ROOM_IMAGE)
                      );
                    }
                    setDroppedImages((prev) => [...prev, droppedFile]);
                  }}
                  droppedFile={droppedImages.filter((image) => image.category === FileCategory.DATA_ROOM_IMAGE)[0]}
                  category={FileCategory.DATA_ROOM_IMAGE}
                  accept={'image/*'}
                />
              </>
              <SubHeader label='Additional Documents' />
              <RoleProtectedDocuments
                documents={baseValues.dataRoom.documents}
                onChange={(changed) => {
                  baseSetFieldValue('dataRoom.documents', changed);
                }}
                fieldTags={fieldsTags['dataRoom.documents']}
              />
            </Fragment>
          </Section>

          <Section label='Investor Report'>
            <Fragment>
              <SubHeader label='Documents' />
              <RoleProtectedDocuments
                documents={baseValues.performanceReport.documents || []}
                onChange={(changed) => {
                  baseSetFieldValue('performanceReport.documents', changed);
                }}
                fieldTags={fieldsTags['performanceReport.documents']}
              />
              {
                canReadPerfomanceReportPassword && (
                  <>
                    <SubHeader label='Password' />
                    <PerformaceReportPasswordInput
                      value={performancePasswordFieldValue}
                      onChangeValue={onPerformancePasswordFieldValueChange}
                      readonly={!canEditPerfomanceReportPassword}
                    />
                  </>
                )
              }
            </Fragment>
          </Section>

          <Section label='Education and Research'>
            <Fragment>
              <div className='d-flex flex-wrap-small position-relative'>
                <div
                  className='d-flex flex-column position-absolute'
                  style={{
                    gap: '3px',
                    minWidth: '8px',
                    left: '-14px',
                    top: '0'
                  }}
                >
                  {fieldsTags['educationMaterials'] && fieldsTags['educationMaterials'].map((tagDescription) => {
                    let mockUp;

                    switch (typeof tagDescription) {
                      // this is enum value
                      case 'number':
                        const tagColor = fieldTagsColors[tagDescription].color;
                        mockUp = (
                          <div
                            style={{
                              background: tagColor,
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                            }}
                          />
                        );
                        break;
                    }
                    return mockUp;
                  })}
                </div>
                <label className='label-fix-width'>Materials</label>
                <MultiSelect
                  disableSearch
                  className='w-100 z-3'
                  options={educationMaterialOptions}
                  value={selectedSuggestedMaterials}
                  onChange={setSelectedSuggestedMaterials}
                  labelledBy='Select'
                />
              </div>
              <div className='d-flex flex-wrap-small'>
                <label className='label-fix-width'>Preview</label>
                <MaterialsList materials={selectedSuggestedMaterialsPreview} readonly />
              </div>
            </Fragment>
          </Section>

          <Section label='Disclaimer'>
            <DescriptionsAdditionalValues
              prefix='disclaimerDesc'
              onChange={(changedValue) => {
                translatableSetFieldValue('disclaimers', changedValue);
              }}
              onAdd={(index, newValue) => {
                onAddValue('disclaimers', index, newValue);
              }}
              onRemove={(index) => {
                onRemoveValue('disclaimers', index);
              }}
              descriptions={translatableValues.disclaimers}
              fieldTags={fieldsTags['disclaimers']}
            />
          </Section>
        </div>
      </div>
      <div className='bottom-fixed-save bg-light p-3 align-items-center flex-wrap'>
        {isLoading ? (
          <div className='w-100 position-absolute'>
            <span className='m-auto loader loader-primary loader-lg'></span>
          </div>
        ) : null}
        {!isEqual(initialValues, values) && !isLoading ? (
          <p className='h6 alert alert-warning mb-0 py-2 mr-auto col-12 col-md-8 col-lg-8 col-xl-8 box-shadow-footer'>
            You have unsaved changes, click{' '}
            <Button
              className='btn-primary btn-sm'
              onClick={() => {
                Object.keys(translationsFormiks).forEach((key) => {
                  translationsFormiks[key].resetForm();
                });
              }}
            >
              <p className='h6 mb-0'>here</p>
            </Button>{' '}
            to reset your changes, or save them in future
          </p>
        ) : null}
        <div className='pt-2'>
          <Button className='btn btn-success' label='Save' onClick={submitForm}>
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default PropertyTeaserForm;
