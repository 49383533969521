import { ChangeEvent, MouseEvent, memo, useState, FC, useEffect, useCallback } from 'react';
import { CustomField, IconType } from '../../../store/property/types';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import $ from 'jquery';
import Select from '../../../components/Select';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';
import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';

interface HighlightedDetailsProps {
  baseValues: Array<CustomField>;
  baseOnChange: (changedValues: Array<CustomField>) => void;

  values: Array<CustomField>;
  onChange: (changedValues: Array<CustomField>) => void;
  onAdd: (index: number, newValue: CustomField) => void;
  onRemove: (index: number) => void;
  icons: { [key: string]: string };
  prefix: string;
  onChangeMapping?: (e: ChangeEvent<HTMLInputElement>) => any;
  changeMapping?: (changed: any) => any;
  addNew?: boolean;
  enableSort?: boolean;
  mapping?: any;
  onSave?: (e: MouseEvent<HTMLButtonElement>) => any;
  isNew?: boolean;
  removable?: boolean;
  description?: JSX.Element;
  disabledFields?: Array<'iconType' | 'id' | 'name' | 'value' | 'tooltip' | '_reorder'>;
  fieldTags?: Array<TagDescription>;
  withTooltip?: boolean;
  errors?: string | string[] | FormikErrors<CustomField>[];
}

export const ExtendedAdditionalDetails: FC<HighlightedDetailsProps> = memo(
  ({
    baseValues,
    baseOnChange,

    icons,
    values,
    onChange,
    onAdd,
    onRemove,
    prefix,
    changeMapping,
    mapping = {},
    addNew = true,
    enableSort = true,
    removable,
    description,
    disabledFields,
    fieldTags,
    withTooltip = true,
    errors,
  }) => {
    const [newValues, setNewValues] = useState<Array<CustomField>>([]);

    const addHighlight = (e: MouseEvent<HTMLButtonElement>) => {
      setNewValues((prevValues: Array<CustomField>) => {
        return [
          ...prevValues,
          {
            value: '',
            icon: '',
            id: '',
            name: '',
            tooltip: '',
            type: IconType.CALCULATOR,
          },
        ];
      });
    };

    const deleteNewValue = (e: MouseEvent<HTMLButtonElement>, index: number) => {
      setNewValues((newValues) => {
        return [...newValues.filter((_, idx) => idx !== index)];
      });
    };

    const addNewValue = (e: MouseEvent<HTMLButtonElement>, index: number) => {
      if (addNew) {
        const valueToAdd = newValues[index];

        const { name, type } = valueToAdd;
        if (name && type) {
          onAdd(values.length, valueToAdd);
          // onChange([...values, valueToAdd]);
          setNewValues((newValues) => {
            return [...newValues.filter((_, idx) => idx !== index)];
          });
          // !isNew && onSave(e)
        }
      }
    };

    useEffect(() => {
      const sortableValues: any = $(`#${prefix}`);
      if (sortableValues && enableSort) {
        sortableValues.sortable({
          update: () => {
            const newOrderedNames = sortableValues.sortable('toArray');

            const newOrderedValues = new Array(newOrderedNames.length);

            values.forEach((highlight) => {
              const index = newOrderedNames.indexOf(highlight.id);
              newOrderedValues[index] = highlight;
            });
            onChange(newOrderedValues);
          },
          handle: `.sortable-${prefix}`,
          containment: 'parent',
          revert: true,
          cursor: 'pointer',
        });
      }
    }, [prefix, values, enableSort, onChange]);

    const handleMapping = (e) => {
      const target = e.target.id.split('_')[0];
      const id = e.target.value;

      const toChange = { ...mapping };
      if (e.target.checked) {
        toChange[target].highlights = [...toChange[target].highlights, id];
      } else {
        toChange[target].highlights = [...toChange[target].highlights.filter((valueId: string) => valueId !== id)];
      }

      changeMapping && changeMapping(toChange);
    };

    const { mobile, web } = mapping;

    const getIsInputDisabled = useCallback(
      (id: string) => {
        if (!disabledFields) {
          return false;
        }

        return !!disabledFields.find((value) => value === id);
      },
      [disabledFields]
    );

    return (
      <>
        <div id={prefix}>
          {values && values.length > 0 ? (
            values.map((_value, index) => {
              if (!_value) {
                return null;
              }
              
              const { _id, tooltip, value, name } = _value;
              const { type: baseType, id: baseId } = baseValues[index];

              const tags =
                fieldTags &&
                fieldTags.map((tagDescription) => {
                  let mockUp;

                  switch (typeof tagDescription) {
                    // this is object value
                    case 'object':
                      const { tagType, amount } = tagDescription;
                      const tagColor = fieldTagsColors[tagType].color;

                      mockUp = (amount === -1 || amount > index) && (
                        <div
                          style={{
                            background: tagColor,
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                          }}
                        />
                      );
                      break;
                  }
                  return mockUp;
                });

              return (
                <div className='my-3' key={_id || prefix + index}>
                  <div className='d-flex flex-wrap-small'>
                    <div className='d-flex flex-wrap-small w-100 position-relative'>
                      <div
                        className='d-flex flex-column position-absolute'
                        style={{
                          gap: '3px',
                          minWidth: '8px',
                          left: '-11px',
                          top: '4px',
                        }}
                      >
                        {tags}
                      </div>
                      <Select
                        id='iconType'
                        placeholder='Icon type'
                        selected={baseType || ''}
                        onChange={(e) => {
                          const toChange = cloneDeep([...baseValues]);
                          toChange[index].type = e.target.value as IconType;
                          baseOnChange(toChange);
                        }}
                        values={Object.keys(IconType).map((key) => ({ label: key, value: key }))}
                        className='mr-2'
                        selectProps={{
                          disabled: getIsInputDisabled('iconType'),
                        }}
                      />

                      <label
                        className='d-flex align-items-center justify-content-between'
                        style={{
                          minWidth: '30px',
                        }}
                      >
                        {baseType && icons[baseType] && (
                          <div className='d-flex'>
                            <img src={icons[baseType]} height={30} width={30} alt='icon' className='mr-2' />
                          </div>
                        )}
                      </label>
                    </div>

                    <Input
                      onChange={(e) => {
                        const toChange = cloneDeep([...baseValues]);
                        toChange[index].id = e.target.value;
                        baseOnChange(toChange);
                      }}
                      className='w-50 mr-2'
                      inputProps={{
                        name: 'id',
                        placeholder: 'Id',
                        disabled: getIsInputDisabled('id'),
                      }}
                      value={baseId || ''}
                    />

                    <Input
                      onChange={(e) => {
                        const toChange = cloneDeep([...values]);
                        toChange[index].name = e.target.value;
                        onChange(toChange);
                      }}
                      className='w-50 mr-2'
                      inputProps={{
                        name: 'name',
                        placeholder: 'Name',
                        disabled: getIsInputDisabled('name'),
                      }}
                      value={name || ''}
                    />

                    <Input
                      onChange={(e) => {
                        const toChange = cloneDeep([...values]);
                        toChange[index].value = e.target.value;
                        onChange(toChange);
                      }}
                      className='w-50 mr-2'
                      inputProps={{
                        name: 'value',
                        placeholder: 'Value',
                        disabled: getIsInputDisabled('value'),
                      }}
                      value={value || ''}
                      formikError={errors && (errors[index] as CustomField) && (errors[index] as CustomField).value}
                    />

                    {withTooltip && (
                      <Input
                        onChange={(e) => {
                          const toChange = cloneDeep([...values]);
                          toChange[index].tooltip = e.target.value;
                          onChange(toChange);
                        }}
                        className='w-50 mr-2'
                        inputProps={{
                          name: 'tooltip',
                          placeholder: 'Tooltip',
                          disabled: getIsInputDisabled('tooltip'),
                        }}
                        value={tooltip || ''}
                      />
                    )}
                  </div>
                  <div className='d-flex px-3 bg-dark box-shadow-footer mr-2 py-2 align-items-center justify-content-between'>
                    {changeMapping ? (
                      <div className='d-flex'>
                        <div className='text-center'>
                          <label htmlFor={'mobile_' + prefix + index} className='p-0 m-0'>
                            <p className='h6 p-0 m-0'>Mobile</p>
                          </label>
                          <div className='custom-control custom-checkbox-toggle'>
                            <input
                              checked={!!mobile.highlights.includes(_value._id)}
                              disabled={!_value._id}
                              type='checkbox'
                              className='custom-control-input'
                              onChange={handleMapping}
                              value={_value._id}
                              id={'mobile_' + prefix + index}
                            />
                            <label className='custom-control-label' htmlFor={'mobile_' + prefix + index} />
                          </div>
                        </div>
                        <div className='ml-2 text-center'>
                          <label htmlFor={'web_' + prefix + index} className='p-0 m-0'>
                            <p className='h6 p-0 m-0'>Web</p>
                          </label>
                          <div className='custom-control custom-checkbox-toggle'>
                            <input
                              type='checkbox'
                              checked={!!web.highlights.includes(_value._id)}
                              disabled={!_value._id}
                              onChange={handleMapping}
                              value={_value._id}
                              className='custom-control-input'
                              id={'web_' + prefix + index}
                            />
                            <label className='custom-control-label' htmlFor={'web_' + prefix + index} />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className='btn-group'>
                      {removable && (
                        <Button
                          label='Remove'
                          className='btn-sm btn-danger'
                          onClick={(e) => {
                            onRemove(index);
                            // const toChange = [...values];
                            // toChange.splice(index, 1);
                            // onChange(toChange);
                          }}
                        />
                      )}

                      {!getIsInputDisabled('_reorder') &&
                        (_id ? (
                          <a
                            href='#!'
                            onClick={(e) => e.preventDefault()}
                            className={`btn btn-sm btn-primary ui-sortable-handle sortable-${prefix}`}
                          >
                            <i className='fe fe-move'></i>
                          </a>
                        ) : (
                          <a
                            href='#!'
                            // onClick={(e) => e.preventDefault()}
                            className='btn btn-sm btn-primary disabled'
                          >
                            <i className='fe fe-move'></i>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <hr />
              <h3 className='text-center'>No icons</h3>
            </>
          )}
        </div>

        {addNew ? (
          <>
            {newValues.map((_value, index) => {
              const { _id, id, tooltip, value, type, name } = _value;

              return (
                <div className='py-2' key={_id || 'floorPlanNewHighlight' + index}>
                  <hr />
                  <div className='d-flex flex-wrap-small align-items-center'>
                    <div className='flex-grow-1'>
                      <div className='d-flex flex-wrap-small w-100 py-0 my-md-1'>
                        <Select
                          id='subscriptionStatus'
                          placeholder='Select status'
                          selected={type || ''}
                          onChange={(e) => {
                            const toChange = [...newValues];
                            toChange[index].type = e.target.value as IconType;
                            setNewValues(toChange);
                          }}
                          values={Object.keys(IconType).map((key) => ({ label: key, value: key }))}
                          className='mr-2'
                        />

                        <label
                          className='d-flex align-items-center justify-content-between'
                          style={{
                            minWidth: '30px',
                          }}
                        >
                          {type && icons[type] && (
                            <div className='d-flex'>
                              <img src={icons[type]} height={30} width={30} alt='icon' className='mr-2' />
                            </div>
                          )}
                        </label>
                      </div>
                      <Input
                        onChange={(e) => {
                          const toChange = [...newValues];
                          toChange[index].id = e.target.value;
                          setNewValues(toChange);
                        }}
                        className='w-50 mr-2 py-0 my-md-1'
                        inputProps={{
                          name: 'id',
                          placeholder: 'Id',
                        }}
                        value={id || ''}
                      />

                      <Input
                        onChange={(e) => {
                          const toChange = [...newValues];
                          toChange[index].name = e.target.value;
                          setNewValues(toChange);
                        }}
                        className='w-50 mr-2 py-0 my-md-1'
                        inputProps={{
                          name: 'name',
                          placeholder: 'Name',
                        }}
                        value={name || ''}
                      />

                      <Input
                        onChange={(e) => {
                          const toChange = [...newValues];
                          toChange[index].value = e.target.value;
                          setNewValues(toChange);
                        }}
                        className='w-50 mr-2 py-0 my-md-1'
                        inputProps={{
                          name: 'value',
                          placeholder: 'Value',
                        }}
                        value={value || ''}
                      />

                      {withTooltip && (
                        <Input
                          onChange={(e) => {
                            const toChange = [...newValues];
                            toChange[index].tooltip = e.target.value;
                            setNewValues(toChange);
                          }}
                          className='w-50 mr-2 py-0 my-md-1'
                          inputProps={{
                            name: 'tooltip',
                            placeholder: 'Tooltip',
                          }}
                          value={tooltip || ''}
                        />
                      )}
                      <div className='btn-group mt-2 float-right'>
                        <Button label='Add' className='btn-success' onClick={(e) => addNewValue(e, index)} />
                        <Button label='Cancel' className='btn-light' onClick={(e) => deleteNewValue(e, index)} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <hr />
            <div className='d-flex justify-content-center'>
              <Button className='btn btn-rounded-circle btn-success' onClick={addHighlight}>
                <span className='fe fe-plus'></span>
              </Button>
            </div>
            <hr />
          </>
        ) : null}
        {description}
      </>
    );
  }
);
