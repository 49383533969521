import { Dayjs } from 'dayjs';
import { memo, FC } from 'react'
import styled from 'styled-components'
import { gapBetweenMonthColumns } from '../../utils/constants'
import { MonthColumn } from './month-column'

interface QuarterProps {
  name: string;
  monthsDescription: Array<{ month: Dayjs, countDayInMonth: number }>;
}

export const Quarter: FC<QuarterProps> = memo(({ name, monthsDescription }) => {
  return (
    <QuartileContainer>
      {monthsDescription.map(({ month, countDayInMonth }) => (
        <MonthColumn days={countDayInMonth} month={month} key={month.format()} />
      ))}
    </QuartileContainer>
  )
})

const QuartileContainer = styled.div`
  height: 100%;

  display: flex;
  grid-gap: ${gapBetweenMonthColumns}px;
`;
