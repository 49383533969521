import {
    ADD_ROLE,
    AddRole,
    DELETE_ROLE,
    DeleteRole,
    QUERY_ROLES,
    QueryRoles,
    RoleType,
    UPDATE_ROLE,
    UpdateRole
} from './types';


export const queryRoles = (roles :  RoleType[] ) : QueryRoles => {
    return {
        type: QUERY_ROLES,
        payload: roles
    }
}

export const deleteRole = (roleId :  string ) : DeleteRole => {
    return {
        type: DELETE_ROLE,
        payload: roleId
    }
}

export const addRole = (role :  RoleType ) : AddRole => {
    return {
        type: ADD_ROLE,
        payload: role
    }
}

export const updateRole = (roleId : string, updated: RoleType ) : UpdateRole => {
    return {
        type: UPDATE_ROLE,
        payload: {roleId, updated}
    }
}
