import { memo, useMemo } from 'react';

export enum SwitchType {
    SLIDER = 'slide-switch',
    BUTTON = 'button'
}

type Props = {
    name: string;
    label: string;
    className?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    prefix: string;
    switchType?: SwitchType;
};

const Switch: React.FC<Props> = memo(({ name, label, checked, onChange, prefix, switchType }) => {
    const input = useMemo(() => {
        switch (switchType) {
            case SwitchType.BUTTON:
                return (
                    <label 
                        className="btn btn-primary"
                        style={{ boxShadow: 'none' }}
                    >
                        <input
                            type="checkbox"
                            name={name}
                            onChange={onChange}
                            value={checked.toString()}
                            id={prefix}
                            className="custom-control-input"
                        />
                        {checked ? 'Enabled' : 'Disabled'}
                    </label>
                )

            case SwitchType.SLIDER:
            default:
                return (
                    <>
                        <input
                            type="checkbox"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            className="custom-control-input"
                            id={prefix}
                        />
                        <label 
                            className="custom-control-label"
                            htmlFor={prefix}
                        />
                    </>
                )
        }
    }, [switchType, name, checked, prefix, onChange]);

    return (
        <div className='d-flex mt-2'>
            {label &&  <label className='label-fix-width' htmlFor='isDefaultProperty'>{label}</label>}
            <div className="custom-control custom-checkbox-toggle">
                {input}
            </div>
        </div>
    );
});

export default Switch