import {
    ADD_ADMIN, CHANGE_ROLE,
    DELETE_ADMIN,
    INITIAL_STATE, QUERY_ADMINS, UPDATE_ADMIN
} from './types';

export const adminsReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_ADMINS:
            return {
                admins: payload
            }
        case DELETE_ADMIN:
            return {
                admins: state.admins.filter(admin => admin._id !== payload)
            }
        case ADD_ADMIN:
            return {
                admins: [...state.admins, payload]
            }
        case UPDATE_ADMIN:
            return {
                admins: [...state.admins.map(role => {
                    if (role._id === payload.adminId) {
                        return payload.updated
                    }
                    return role
                })]
            }
        case CHANGE_ROLE:
            return {
                admins: [...state.admins.map(role => {
                    if (role._id === payload.adminId) {
                        role.role = payload.role
                    }
                    return role
                })]
            }
        default:
            return state;
    }
};
