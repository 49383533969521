import { FC, KeyboardEventHandler, memo, useCallback, useState, MouseEventHandler } from 'react';
import { DropdownIndicatorProps, InputActionMeta, MultiValue, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

export interface Option {
  readonly label: string;
  readonly value: string;
}

interface PerformaceReportPasswordInputProps {
  value: Array<Option>;
  onChangeValue: (newValue: MultiValue<Option>) => void;
  readonly?: boolean;
}

// I don't see any more suitable options to wrap click event
const outsideCustomDropdownIndicatorClickHandler = (_onClick?: () => void) => {
  const CustomDropdownIndicator = ({ children, innerProps, ...props }: DropdownIndicatorProps<Option, true>) => {
    const { onClick } = innerProps;
  
    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
      if (_onClick) {
        _onClick();
      }
      if (onClick) {
        onClick(event)
      }
    }, [onClick]);
  
    return (
      <components.DropdownIndicator innerProps={ { ...innerProps, onClick: handleClick}} {...props}>
        {children}
      </components.DropdownIndicator>
    );
  };
  
  return CustomDropdownIndicator;
}




export const PerformaceReportPasswordInput: FC<PerformaceReportPasswordInputProps> = memo(({
  value,
  onChangeValue,
  readonly,
}) => {
  const [inputValue, setInputValue] = useState('');

  const onInputValueChange = useCallback((newValue: string, _: InputActionMeta) => {
    setInputValue(newValue);
  }, []);

  const addNewValue = useCallback(() => {
    if (!inputValue) {
      return;
    }
  
    onChangeValue([...value, { label: inputValue, value: inputValue }]);
    setInputValue('');
  }, [inputValue, onChangeValue, value]);

  const handleKeyDown: KeyboardEventHandler = useCallback((event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        addNewValue();
        event.preventDefault();
    }
  }, [addNewValue]);

  return (
    <CreatableSelect
      onChange={onChangeValue}
      value={value}
      isMulti
      isClearable
      components={{
        DropdownIndicator: outsideCustomDropdownIndicatorClickHandler(addNewValue)
      }}
      placeholder="Type passwords and press enter..."
      menuIsOpen={false}
      inputValue={inputValue}
      onInputChange={onInputValueChange}
      onKeyDown={handleKeyDown}
      isDisabled={readonly}
    />
  )
})
