import api from "./estatingApi";

export const adminsAPI = {

    resetPassword: (adminId: string, newAdminPassword: string) => {
        return new Promise<string>((resolve, reject) => {
            api.post(`/reset/${adminId}`, {newAdminPassword})
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch(err => {
                    reject(new Error(err?.response?.data?.message || 'Fail to reset password'))

                })
        })
    }

}

