import { useFormik } from 'formik';
import { memo, FC, useCallback } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { CreateEventInitialvalues } from '../../../types/property-teaser/timeline/create-event-initial-values';
import Select from '../../Select';
import { SubscriptionStatus } from '../../../store/property/types';
import DatePicker from 'react-datepicker';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { Event } from '../../../store/property/types';

interface AddEventPopUpProps {
  isShow: boolean;
  handleClose: () => void;
  initialValues: CreateEventInitialvalues;
  handleCreate: (values: Event) => void;
  handleEdit: (values: Event) => void;
  isEdit?: boolean;
  handleDelete: () => void;
}

const subscribtionStatusValues = Object.keys(SubscriptionStatus).map((key) => ({
  label: SubscriptionStatus[key],
  value: key,
}));

const validationSchema = Yup.object({
  subscriptionStatus: Yup.string().oneOf(Object.keys(SubscriptionStatus)).required(),
  startDate: Yup.mixed().test({
    name: 'startDate',
    message: 'Start date must be before or equal to the end date',
    test: function(startDate) {
      const endDate = dayjs(this.resolve(Yup.ref('endDate')));

      if (!startDate || !endDate) {
        return false; // Allow null values or if either field is missing
      }

      return dayjs(startDate as Dayjs).isSameOrBefore(endDate);
    },
  }),
  endDate: Yup.mixed().test({
    name: 'endDate',
    message: 'End date must be after or equal to the start date',
    test: function(endDate) {
      const startDate = dayjs(this.resolve(Yup.ref('startDate')));

      if (!startDate || !endDate) {
        return false; // Allow null values or if either field is missing
      }

      return dayjs(endDate as Dayjs).isSameOrAfter(startDate);
    },
  }),
});

export const EventPopUp: FC<AddEventPopUpProps> = memo(({
  isShow,
  handleClose,
  initialValues,
  handleCreate,
  handleEdit,
  handleDelete,
  isEdit
}) => {

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: function (values, { validateForm, resetForm }) {
      if(isEdit) {
        handleEdit(values as Event);
      } else {
        handleCreate(values as Event);
      }
      
      resetForm();
      validateForm();
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { 
    setFieldValue,
    values,
    handleChange,
    resetForm,
    isValid,
    isValidating,
    validateForm,
    submitForm
  } = formik;

  const closeForm = useCallback(() => {
    handleClose();
    resetForm();
    validateForm();
  }, [handleClose, resetForm, validateForm]);

  const setStartDate = useCallback((start: Date | null) => {
    setFieldValue('startDate', dayjs(start));
  }, [setFieldValue]);

  const setEndDate = useCallback((end: Date | null) => {
    setFieldValue('endDate', dayjs(end));
  }, [setFieldValue]);

  const onDelete = useCallback(() => {
    handleDelete();
    resetForm();
    validateForm();
  } , [handleDelete, resetForm, validateForm]);

  return (
    <Modal
        show={isShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column' style={{ gridGap: '10px' }}>
            <div className='d-flex flex-wrap-small'>
              <label className='label-fix-width'>Status</label>
              <Select
                id='subscriptionStatus'
                placeholder='Select status'
                selected={values.subscriptionStatus}
                onChange={handleChange}
                values={subscribtionStatusValues}
              />
            </div>
            <div className='d-flex flex-wrap-small justify-content-between'>
              <label className='label-fix-width'>Start</label>
              <div>
                <DatePicker
                  selected={values.startDate?.toDate()}
                  onChange={setStartDate}
                  selectsStart
                  startDate={values.startDate?.toDate()}
                  endDate={values.endDate?.toDate()}
                  calendarClassName="custom-calendar"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className='d-flex flex-wrap-small justify-content-between'>
              <label className='label-fix-width'>End</label>
              <div>
                <DatePicker
                  selected={values.endDate?.toDate()}
                  onChange={setEndDate}
                  selectsEnd
                  startDate={values.startDate?.toDate()}
                  endDate={values.endDate?.toDate()}
                  minDate={values.startDate?.toDate()}
                  calendarClassName="custom-calendar"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeForm}>
            Close
          </Button>
          {
            isEdit && (
              <Button 
                variant='secondary'
                color='danger'
                onClick={onDelete}
              >
                Delete
              </Button>
            )
          }
          <Button 
            variant="primary"
            disabled={!isValid}
            onClick={submitForm}
          >
            {
              isValidating ? 
              (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">
                    Loading...
                  </span>
                </>
              )
              :
              (
                isEdit ? 
                  'Edit'
                  :
                  'Create'
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
  )
})