import { ChangeEvent, FC } from 'react';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';
import { AdditionalValue } from '../../../store/property/types';
import { FormikErrors } from 'formik';

export type DescriptionsAdditionalValuesProps = {
  descriptions: Array<AdditionalValue>;
  prefix: string;
  onChange: (changedValue: Array<AdditionalValue>) => any;
  onAdd: (index: number, newValue: AdditionalValue) => void;
  onRemove: (index: number) => void;
  onChangeMapping?: (e: ChangeEvent<HTMLInputElement>) => any;
  mapping?: any;
  label?: string;
  textAreaProps?: Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'value' & 'style'>;
  fieldTags?: Array<TagDescription>;
  errors?: string | string[] | FormikErrors<AdditionalValue>[];
};

export const DescriptionsAdditionalValues: FC<DescriptionsAdditionalValuesProps> = ({
  descriptions,
  prefix,
  onChange,
  onChangeMapping,
  mapping = {},
  label,
  fieldTags,
  errors,
  onAdd,
  onRemove
}) => {

  const { mobile, web } = mapping;
  return (
    <div className='d-flex flex-wrap-small'>
      {label && <label className='label-fix-width'>{label}</label>}
      <div className='w-100'>
        <div id={prefix}>
          {descriptions &&
            descriptions.map((description, index) => {
              const tags = fieldTags && fieldTags.map((tagDescription) => {
                let mockUp;

                switch(typeof tagDescription) {
                  // this is object value
                  case 'object':
                    const { tagType, amount } = tagDescription;
                    const tagColor = fieldTagsColors[tagType].color;
      
                    mockUp = (
                      (amount === -1 || amount > index) &&
                      <div
                        style={{
                          background: tagColor,
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                        }}
                        key={tagType}
                      />
                    )
                  break;
                }
                return mockUp;
              });

              return (
                <div key={prefix + index} className='mb-3 position-relative'>
                  <div
                    className='d-flex flex-column position-absolute'
                    style={{
                      gap: '3px',
                      minWidth: '8px',
                      left: '-11px',
                      top: '4px',
                    }}
                  >
                    {tags}
                  </div>
                  <TextArea
                    onChange={(e) => {
                      const toChange = [...descriptions];
                      toChange[index].value = e.target.value;
                      onChange(toChange);
                    }}
                    value={description.value || ''}
                    invalidFeedback={(errors && (errors[index]) && ((errors[index] as any)?.value)) as any}
                  />
                  {
                    <div className='bg-dark box-shadow-footer py-2 px-3 d-flex align-items-center'>
                      {mapping && onChangeMapping ? (
                        <div className='d-flex'>
                          <div className='text-center'>
                            <label htmlFor={'mobile_' + prefix + index} className='p-0 m-0'>
                              <p className='h6 p-0 m-0'>Mobile</p>
                            </label>
                            <div className='custom-control custom-checkbox-toggle'>
                              <input
                                type='checkbox'
                                checked={!!mobile.descriptions.includes(index)}
                                value={index}
                                onChange={onChangeMapping}
                                className='custom-control-input'
                                id={'mobile_' + prefix + index}
                              />
                              <label className='custom-control-label' htmlFor={'mobile_' + prefix + index} />
                            </div>
                          </div>
                          <div className='ml-2 text-center'>
                            <label htmlFor={'web_' + prefix + index} className='p-0 m-0'>
                              <p className='h6 p-0 m-0'>Web</p>
                            </label>
                            <div className='custom-control custom-checkbox-toggle'>
                              <input
                                type='checkbox'
                                checked={web.descriptions.includes(index)}
                                value={index}
                                onChange={onChangeMapping}
                                className='custom-control-input'
                                id={'web_' + prefix + index}
                              />
                              <label className='custom-control-label' htmlFor={'web_' + prefix + index} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className='btn-group ml-auto'>
                        <Button
                          label='Remove'
                          className='btn-sm btn-danger ml-auto'
                          onClick={(e) => {
                            onRemove(index);
                            // const toChange = [...descriptions];
                            // toChange.splice(index, 1);
                            // onChange(toChange);
                          }}
                        />
                        {/*<a*/}
                        {/*    href='#!'*/}
                        {/*    onClick={(e) => e.preventDefault()}*/}
                        {/*    className={`btn btn-sm btn-primary ui-sortable-handle sortable-${prefix}`}*/}
                        {/*>*/}
                        {/*    <i className='fe fe-move'></i>*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  }
                </div>
              );
            })}
        </div>
        <hr />
        <div className='d-flex justify-content-center'>
          <Button
            className='btn-rounded-circle btn-success mt-2'
            onClick={() => {
              // onChange([...descriptions, { value: '', name: '' }]);
              onAdd(descriptions.length, { value: '', name: '' })
            }}
          >
            <span className='fe fe-plus'></span>
          </Button>
        </div>
        <hr />
      </div>
    </div>
  );
};
