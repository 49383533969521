import React from 'react'
import RoleUpsert from "./RoleUpsert";
import RolesTable from "./RolesTable";

const RolesTab: React.FC<{}> = () => {
    const [roleToUpdate, setRoleToUpdate] = React.useState('')
    const [toggleAddRole, setToggleAddRole] = React.useState(false)


    return (
        <>
            <h3 className='text-center'>Roles</h3>
            {
                toggleAddRole ?
                    <RoleUpsert
                        roleToUpdate={roleToUpdate}
                        callback={() => setToggleAddRole(false)}
                        cancelBtnClick={() => setToggleAddRole(false)}/>
                    :
                    <RolesTable
                        editBtnClick={(roleId: string) => {
                            setRoleToUpdate(roleId)
                            setToggleAddRole(true)
                        }}
                        addRoleBtnClick={() => {
                            setRoleToUpdate('')
                            setToggleAddRole(true)
                        }}
                    />
            }
        </>
    )
}

export default RolesTab
