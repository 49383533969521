import React from 'react'
import {UsersAPI} from "../../api/userApi";

type ValuesType = {
    officeAddress: {
        street: {
            old: string,
            current: string
        },
        streetAddition: {
            old: string,
            current: string
        },
        postalCode: {
            old: string,
            current: string,
        },
        poBox: {
            old: string,
            current: string
        },
        city: {
            old: string,
            current: string
        },
        estate: {
            old: string,
            current: string
        },
        phoneNumber: {
            old: string,
            current: string
        },
        faxNumber: {
            old: string,
            current: string
        },
        contact: {
            name: {
                old: string,
                current: string
            },
            email: {
                old: string,
                current: string
            },
            phone: {
                old: string,
                current: string
            }
        }
    },
    companyData: {
        dataOfIncorporation: {
            date: {
                old: string,
                current: string
            },
            documents: {
                old: string,
                current: string
            }
        },
        ownership: {
            value: {
                old: string,
                current: string
            },
            documents: {
                old: string,
                current: string
            }
        },
        licenseOfOperate: {
            value: {
                old: string,
                current: string
            },
            documents: {
                old: string,
                current: string
            }
        },
        registrations: {
            value: {
                old: string,
                current: string
            },
            documents: {
                old: string,
                current: string
            }
        },
        regulatoryStatus: {
            old: string,
            current: string
        },
        supervisorAuthority: {
            old: string,
            current: string
        },
    },
    internalOrganisation: {
        riskManagement: {
            old: string,
            current: string
        },
        compliance: {
            old: string,
            current: string
        },
        operations: {
            old: string,
            current: string
        },
        manuals: {
            old: string,
            current: string
        },
        activitiesPerformed: {
            old: string,
            current: string
        }
    },
    businessActivities: {
        numberOfSalespeople: {
            old: string,
            current: string
        },
        backgroundSalespeople: {
            old: string,
            current: string
        },
        salesLocations: {
            old: string,
            current: string
        },
        clientSegments: {
            old: string,
            current: string
        },
        totalAssetsUnderManagement: {
            old: string,
            current: string
        },
        offerAdvisoryMandates: {
            old: string,
            current: string
        },
        offerDiscretionaryMandates: {
            old: string,
            current: string
        },
        clientsDomicilies: {
            old: string,
            current: string
        },
        coreProductOfferings: {
            old: string,
            current: string
        },
        custodianBanks: {
            old: string,
            current: string
        },
    }
};

type ChangesType = {
    date: string,
    values: ValuesType,
};

type AuthenticationStepsProps = {
    userId: string,
};

type AuditLogs = {
    _id: string,
    date: string,
    description: string,
    action: string,
    changes: ChangesType[],
    target: string
};

const mappingFieldNames = {
    street: 'Street',
    streetAddition: 'Street Addition',
    postalCode: 'Postal Code',
    poBox: 'PO Box',
    city: 'City',
    estate: 'Estate',
    phoneNumber: 'Phone Number',
    faxNumber: 'Fax Number',
    regulatoryStatus: 'Regulatory Status',
    supervisorAuthority: 'Supervisor Authority',
    riskManagement: 'Risk Management',
    compliance: 'Compliance',
    operations: 'Operations',
    manuals: 'Manuals',
    activitiesPerformed: 'Activities Performed',
    numberOfSalespeople: 'Number of Sales people',
    backgroundSalespeople: 'Background Sales people',
    salesLocations: 'Sales Locations',
    clientSegments: 'Client Segments',
    totalAssetsUnderManagement: 'Total Assets Under Management',
    offerAdvisoryMandates: 'Offer Advisory Mandates',
    offerDiscretionaryMandates: 'Offer Discretionary Mandates',
    clientsDomicilies: 'Clients Domicilies',
    coreProductOfferings: 'Core Product Offerings',
    custodianBanks: 'Custodian Banks'
}

const AuthenticationSteps: React.FC<AuthenticationStepsProps> = ({userId}) => {
    const [auditLogs, setAuditLogs] = React.useState<AuditLogs[]>([]);

    React.useEffect(() => {
        UsersAPI.queryAuditLogs(userId)
            .then(auditLogs => {
                setAuditLogs(auditLogs);
            })
            .catch(e => {
                console.error(e)
            });
    }, [userId]);


    const _renderChanges = (objectToRender: object) => {
        const items: JSX.Element[] = []
        const placeholder: JSX.Element = <p className='my-2'>Changes not available to show</p>
        if (objectToRender) {
            Object.entries(objectToRender).forEach(([fieldName, childObject]) => {
                if (childObject.hasOwnProperty('old') && childObject.hasOwnProperty('current')) {
                    const item = (
                        <div key={fieldName} className='d-flex  col-xl-4 col-lg-5 px-0 py-2 justify-content-between border p-3 flex-wrap overflow-hidden'>
                            <b className='mb-0 mr-2'>{mappingFieldNames[fieldName] || fieldName}</b>
                            <p className='mb-0'>
                                {
                                    childObject.old ?
                                        <>
                                            <b className='bg-danger p-1 rounded'>{childObject.old}</b>
                                            {" "}changed with{" "}
                                            <b className='bg-success p-1 rounded'>{childObject.current}</b>
                                        </>
                                        :
                                        <>
                                            Set new value{" "}<b className='bg-success p-1 rounded'>{childObject.current}</b>{" "}
                                        </>
                                }

                            </p>
                        </div>
                    )
                    items.push(item)
                }
                else {
                    _renderChanges(childObject)
                }

            })
        }

        return items.length > 0 ? items : placeholder
    }
    return (
       <>
           {
               auditLogs.map((auditLog) => {
                   return <div key={auditLog._id}>
                       <hr/>
                       <p className='mb-2 font-weight-bold text-center'>{auditLog.action}</p>
                       <span>Date: {auditLog.date.slice(0, 10)}</span>
                       <span>{" "}time: {auditLog.date.slice(11, 19)}</span>
                       <p>Description: <b>{auditLog.description}</b></p>
                       {
                           auditLog.changes.map((change, index) => {
                               const {date, values} = change
                               const {officeAddress, internalOrganisation, companyData} = values || {}
                               return (
                                   <div key={date + index}>
                                       <h4>Changes Date: {change.date?.slice(0, 10)}{" "}{change.date?.slice(11, 19)}</h4>
                                       <p>
                                           <a className="btn btn-primary" data-toggle="collapse" href={`#${auditLog.action + index}`}
                                              role="button" aria-expanded="false" aria-controls={auditLog.action + index}>
                                               Show changes
                                           </a>
                                       </p>
                                       <div className="collapse" id={auditLog.action + index}>
                                           <div className="card p-2">
                                               <hr/>
                                               <h3 className='text-center'>Office Address</h3>
                                               {
                                                   _renderChanges(officeAddress)
                                               }
                                               <hr/>
                                               <h3 className='text-center'>Company Date</h3>
                                               {
                                                   _renderChanges(companyData)
                                               }
                                               <hr/>
                                               <h3 className='text-center'>Internal Organisation</h3>
                                               {
                                                   _renderChanges(internalOrganisation)
                                               }
                                               <hr/>
                                               <h3 className='text-center'>Business Activities</h3>
                                               {
                                                   //_renderChanges(businessActivities)
                                               }
                                           </div>
                                       </div>
                                   </div>
                               )
                           })
                       }
                   </div>
               })
           }
       </>
    );
};

export default AuthenticationSteps;
