import {
    INITIAL_STATE,
    QUERY_RULES, SET_ABILITY
} from './types';

export const rulesReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_RULES:
            return {
                rules: payload
            }
        case SET_ABILITY:
            return {
                ability: payload
            }
        default:
            return state;
    }
};
