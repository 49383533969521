import React from 'react'
import AdminsTable from "./AdminsTable";
import AdminUpsert from "./AdminUpsert";


const AdminsTab: React.FC<{}> = () => {
    const [toggleAddAdmin, setToggleAddAdmin] = React.useState(false)
    const [adminToUpdate, setAdminToUpdate] = React.useState('')



    return (
        <>
            <h3 className='text-center'>Admins</h3>
            {
                toggleAddAdmin ?
                    <AdminUpsert
                        cancelBtnClick={() => setToggleAddAdmin(false)}
                        callback={() => setToggleAddAdmin(false)}
                        adminToUpdate={adminToUpdate}
                    />
                    :
                    <AdminsTable
                        editBtnClick={(adminId) => {
                            setAdminToUpdate(adminId)
                            setToggleAddAdmin(true)
                        }}
                        addAdminBtnClick={() => {
                            setAdminToUpdate('')
                            setToggleAddAdmin(true)
                        }}
                    />
            }
        </>
    )
}

export default AdminsTab
