import { FC, createContext, memo, PropsWithChildren, useState, useContext, useCallback } from "react";
import { ILanguageContext } from "./types";
import { Tolgee, TolgeeProvider, DevTools, FormatSimple, LanguageDetector, LanguageStorage, BackendFetch, TolgeeInstance } from "@tolgee/react";
import { config } from "../../appConfig";
let tolgee = Tolgee();
let tolgeeInstance: TolgeeInstance;

if (process.env.NODE_ENV === 'development') {
  tolgee = tolgee.use(DevTools())
}
  
  tolgee = tolgee
  .use(LanguageDetector())
  .use(LanguageStorage())
  .use(FormatSimple());

  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
    tolgeeInstance = tolgee
    .use(BackendFetch({ prefix: config.REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL }))
    .init({
      fallbackLanguage: 'en',
      defaultLanguage: 'en',
      availableLanguages: ['en', 'es'],
    });
  } else {
    tolgeeInstance = tolgee
    .init({
      fallbackLanguage: 'en',
      defaultLanguage: 'en',
      availableLanguages: ['en', 'es'],

      // for development
      apiUrl: config.REACT_APP_TOLGEE_API_URL,
      apiKey: config.REACT_APP_TOLGEE_API_KEY,
    });
  }

const LanguageContext = createContext<ILanguageContext>({
  currentLanguage: 'en',
  setCurrentLanguage: () => {},
});

export const LanguageProvider: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const _setCurrentLanguage = useCallback((language: string) => {
    setCurrentLanguage(() => language);
    tolgeeInstance.changeLanguage(language);
  }, []);

  return (
    <TolgeeProvider
      tolgee={tolgeeInstance}
      options={{
        useSuspense: false,
      }}
    >
      <LanguageContext.Provider value={{
        currentLanguage,
        setCurrentLanguage: _setCurrentLanguage
      }}>
        {children}
      </LanguageContext.Provider>
    </TolgeeProvider>
  );
});

export const useLanguage = () => useContext(LanguageContext);