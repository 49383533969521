import api from "./estatingApi";

export const GoldenSheetApi = {
  queryGoldenSheetDirectories: async (path = '/') => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await api.post(`/golden-sheet/files-structure`, { path });
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to get groups'));
      }
    });
  },

  mutationSynchronizeGoldenSheet: async (sheetUrl: string, propertyTeaserId: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await api.post(`/golden-sheet/synchronize-sheet`, { sheetUrl, propertyTeaserId });
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to synchronize sheet'));
      }
    })
  }
}