import {
    UPSERT_PROPERTY, CLEAN_PROPERTY, INITIAL_STATE, UPDATE_DEFAULT_PROPERTY_MODE
} from './types';


export const propertyReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case UPSERT_PROPERTY:
            return {
                ...state,
                property: payload
            };
        case CLEAN_PROPERTY:
            return {
                ...state,
                property: null
            };
        case UPDATE_DEFAULT_PROPERTY_MODE:
            return {
                ...state,
                property: {
                    ...state.property,
                    isDefaultProperty: payload.isDefaultProperty
                }
            }
        default:
            return state;
    }
};
