import api from "./estatingApi";
import {User} from "../store/users/types";
import {AxiosResponse} from "axios";
import {SessionLogState} from "../screens/user/SessionLog";

export interface IAuditLogApi {
  _id: string;
  action: 'OPPORTUNITY_SHARED' | 'INVESTMENT_ASK';
  date: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  changes: {
    values: {
      opportunity: {
        _id: string;
        name: string;
      };
      sharedWith: string;
      ISIN?: string;
      internalName?: string;
      investmentStrategy?: string;
      classification?: string;
      address?: string;
      serviceProviderName?: string;
      contactPersonName?: string;
      telephoneNumber?: string;
      email?: string;
      investmentAmount?: string;
      custodyBank?: string;
      bankContactNameEmail?: string;
      currency?: string;
      issuanceVolume?: string;
      minimumInvestment?: string;
    };
  }[];
}

export const UsersAPI = {
    queryUsers: (queryParams?: {
                     include_fields?: string,
                     is_accepted?: boolean,
                     include_properties?: boolean,
                     pendingToDelete?: boolean,
                     include_FA?: boolean,
                     page?: number,
                     limit?: number,
                     firstName?: string,
                     lastName?: string,
                     email?: string,
                     country?: string,
                     whoAreYou?: string,
                     // search?: string,
                     // searchBy?: string
                 },
    ) => {
        return new Promise<{
            users: User[],
            count: number
        }>(async (resolve, reject) => {
            try {
                const response: AxiosResponse & {
                    users: User[],
                    count: number
                } = await api.get(`user/`, {
                    params: queryParams || {}
                })
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get users'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    queryUser: (userId: string) => {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const response = await api.get(`/user/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get user'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },
    updateUser: (userId: string, updated: Partial<Pick<User, 'firstName' | 'lastName' | 'email' | 'country' | 'whoAreYou' | 'is_accepted' | 'additionalInformation'>>) => {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const response = await api.put(`/user/${userId}`, {updated})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to update user'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    queryUserContracts: (userId: string) => {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/user/contracts/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to get user contacts'))
            }
        })
    },


    attachPropertiesForUser: (userId: string, propertyIds: string[]) => {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const response = await api.post(`/user/attach`, {propertyIds, userId})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to attach properties for user'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },
    detachPropertiesForUser: (userId: string, propertyIds: string[]) => {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const response = await api.post(`/user/detach`, {propertyIds, userId})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to attach properties for user'))
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Something went wrong'))
            }
        })
    },
    toggleVerify: (userId: string, is_verified: boolean) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.post(`/user/verify/${userId}`, {is_verified})
                if (response.status === 202) {
                    resolve(is_verified)
                }
                resolve(null)
            } catch (e) {
                resolve(null)
            }
        })
    },
    toggleAcceptUserAdditionalInfo: (userId: string, is_accepted: boolean) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.post(`/user/accept/${userId}`, {is_accepted})
                if (response.status === 202) {
                    resolve(is_accepted)
                }
                resolve(null)
            } catch (e) {
                resolve(null)
            }
        })
    },
    getIvoAdditionalInfo: (userId: string) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.get(`/user/additionalInfo/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                resolve(null)
            } catch (e) {
                resolve(null)
            }
        })
    },
    queryAuditLogs: (id) => {
        return new Promise<any>(async (resolve) => {
            try {
                const response = await api.get(`/auditLog/${id}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                resolve([])
            } catch (e) {
                resolve([])
            }
        })
    },
    upsertAdditionalInfoIVO: (userId: string, additionalInformation: any, {
        incorporationDocumentToAdd,
        ownershipDocumentToAdd,
        licenseOfOperateDocumentToAdd,
        registrationsDocumentToAdd,
        signingRightsDocumentToAdd
    }, review: any) => {

        return new Promise<any>((resolve, reject) => {
            const formData = new FormData()
            formData.append('additionalInformation', JSON.stringify(additionalInformation))
            if (review) {
                formData.append('review', JSON.stringify(review))
            }

            if (incorporationDocumentToAdd?.length > 0) {
                incorporationDocumentToAdd.forEach(incorporationDocumentToAdd => {
                    formData.append('incorporationDocument', incorporationDocumentToAdd)
                })
            }
            if (ownershipDocumentToAdd?.length > 0) {
                ownershipDocumentToAdd.forEach(ownershipDocumentToAdd => {
                    formData.append('ownershipDocument', ownershipDocumentToAdd)
                })
            }
            if (licenseOfOperateDocumentToAdd?.length > 0) {
                licenseOfOperateDocumentToAdd.forEach(licenseOfOperateDocumentToAdd => {
                    formData.append('licenseOfOperateDocument', licenseOfOperateDocumentToAdd)
                })
            }
            if (registrationsDocumentToAdd?.length > 0) {
                registrationsDocumentToAdd.forEach(registrationsDocumentToAdd => {
                    formData.append('registrationsDocument', registrationsDocumentToAdd)
                })
            }
            if (signingRightsDocumentToAdd?.length > 0) {
                signingRightsDocumentToAdd.forEach(signingRightsDocumentToAdd => {
                    formData.append('signingRightsDocument', signingRightsDocumentToAdd)
                })
            }

            api.put(`/user/additionalInfo/${userId}`, formData)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(new Error(response?.data?.message || 'Something went wrong'))
                    }
                }).catch(error => {
                reject(new Error(error?.response?.data?.message || 'Something went wrong'))
            })
        })
    },

    resetPassword: (userId: string, email: string) => {
        return new Promise<string>((resolve, reject) => {
            api.post(`/user/reset/${userId}`, {
                email,
                fromWeb: true
            })
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch(err => {
                    reject(new Error(err.response.data?.message || 'Fail to reset password'))

                })
        })
    },

    disableUser: (userId: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.patch(`/user/disable/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to disable user'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    enableUser: (userId: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.patch(`/user/enable/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to enable user'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    getUserDependenciesInfo: (userId: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.get(`/user/dependencies/${userId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get user dependencies information'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    deleteUser: (userId: string) => {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const response = await api.delete(`/user/${userId}`)
                if (response.status === 204) {
                    resolve(true)
                }
                reject(new Error(response?.data?.message || 'Fail to get user dependencies information'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    querySessionLog: (id) => {
        return new Promise<SessionLogState>(async (resolve, reject) => {
            try {
                const response = await api.get(`/sessionLog/${id}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get session log'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    queryInteractionsLog: (id) => {
        return new Promise<IAuditLogApi[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/auditLog/${id}/interactions`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get session log'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },

    queryAllInteractionsLog: () => {
        return new Promise<IAuditLogApi[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/auditLog/interactions`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Fail to get session log'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Something went wrong'))
            }
        })
    },
}
