import React from 'react'
import cryptoRandomString from "crypto-random-string";

const Select: React.FC<{
    id?: string,
    label?: string,
    values: string[] | { label: string, value: string }[],
    selected?: string,
    selectProps?: React.InputHTMLAttributes<HTMLSelectElement>,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any,
    placeholder?: string,
    name?: string
    className?: string,
    containerClass?: string,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    rightComponent?: JSX.Element,
    required?: boolean

}> = ({
          id,
          label,
          selectProps = {},
          onChange = () => {
          },
          values,
          selected = '',
          placeholder,
          name,
          className,
          containerClass,
          leftIcon,
          rightIcon,
          rightComponent,
          required
      }) => {

    const selectId = id || selectProps?.id || cryptoRandomString({length: 10})

    return (
        <div className={`d-flex flex-wrap-small col px-0 ${containerClass || ''}`}>
            {
                label && <label className={`label-fix-width ${required ? 'font-weight-bold': ''}`} htmlFor={selectId}>{label} {required ? '*': ''}</label>
            }

            <div className="input-group">
                {
                    leftIcon &&
                    <div className="input-group-prepend">
                        {
                            leftIcon
                        }
                    </div>
                }
                <select
                    name={name}
                    value={selected}
                    onChange={onChange}
                    id={selectId}
                    className={`form-control ${className}`}
                    {...selectProps}
                >
                    {placeholder && <option value='' disabled={true} hidden={true}>{placeholder}</option>}
                    {
                        values.map((option, index) => {
                                if (typeof option === 'string') {
                                    return (
                                        <option value={option} key={option + index}>{option}</option>
                                    )
                                } else {
                                    const {label, value} = option
                                    return (
                                        <option value={value} key={value + index}>{label}</option>
                                    )
                                }
                            }
                        )
                    })
                </select>
                {
                    rightIcon &&
                    <div className="input-group-append ml-1">
                        {
                            rightIcon
                        }
                    </div>
                }
                {rightComponent && (<div className="input-group-append ml-1">
                    {rightComponent}
                    </div>)}
            </div>

        </div>
    )
}

export default Select
