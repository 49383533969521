import api from "./estatingApi";
import {PropertyStateEnum, PropertyTeaserType} from "../store/property/types";
import parsePath from "parse-filepath"
import { DroppedFile, FileCategory } from "../types/dropped-file";

const getUniqueFileName = (fileName: string) => {
    const parse = parsePath(fileName);
    return `${parse.name}_${new Date().getTime()}${parse.ext}`
}

export const PropertyTeaserAPI = {
    findAll:  (params?: {
        name?: string,
        internalName?: string,
        state?: PropertyStateEnum
    })  => {
        const {name, state, internalName} = params || {}
        return new Promise<PropertyTeaserType[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/propertyTeaser`, {
                    params: {
                        name: name || '',
                        state: state || '',
                        internalName: internalName || ''

                    }
                })
                resolve(response.data)
            } catch (e: any) {
                reject(new Error(e?.response?.data?.message || 'Fail to get properties'))
            }
        })
    },
    findById: (id: string) => {
        return new Promise<{ [key: string]: PropertyTeaserType }>(async (resolve, reject) => {
            try {
                const response = await api.get(`/propertyTeaser/${id}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e: any) {
                reject(new Error(e.response?.data?.message || 'Fail to get property'))
            }
        })
    },
    copy: ({id, name} : {id: string, name: string}) => {
        return new Promise<PropertyTeaserType>(async (resolve, reject) => {
            try {
                const response = await api.post('/propertyTeaser/copy', {
                    propertyId: id,
                    propertyName: name
                })
                if (response.status === 200) {
                    resolve(response.data)
                }
            } catch (e: any)  {
                reject(new Error(e.response?.data?.message || 'Fail to copy property'))
            }

        })

    },
    removeById: (id : string) =>  {
        return new Promise<string | Error>(async (resolve, reject )=> {
            try {
                await api.delete(`/propertyTeaser/${id}`)
                resolve(id)
            } catch (e: any) {
                reject(new Error(e.response?.data?.message || 'Fail to delete property'))
            }
        })

    },
    updateDefaultPropertyMode: (id : string, isDefaultProperty: boolean) =>  {
        return new Promise<{id: string, isDefaultProperty: boolean}>(async (resolve, reject )=> {
            try {
                await api.put(`/propertyTeaser/defaultPropertyMode/${id}`, {isDefaultProperty})
                resolve({id, isDefaultProperty})
            } catch (e: any) {
                reject(new Error(e.response?.data?.message || 'Fail to change default property mode'))
            }
        })
    },
    updateOrder: (ids: Array<string>) => {
        return new Promise<Array<PropertyTeaserType>>(async (resolve, reject )=> {
            try {
                const response = await api.patch(`/propertyTeaser/update-order`, ids)
                resolve(response.data);
            } catch (e: any) {
                reject(new Error(e.response?.data?.message || 'Fail to change default property mode'))
            }
        })
    },
    edit: (propertyId: string, baseProperty: PropertyTeaserType, translations: { [key: string]: any }, droppedImages: Array<DroppedFile> ) => {
        return new Promise<{[key: string]: PropertyTeaserType}>(async (resolve, reject) => {
            try {
                const formData = new FormData()
                const sliderImages = droppedImages.filter(img => img.category === FileCategory.SLIDER);
                const floorPlanImages = droppedImages.filter(img => img.category === FileCategory.FLOORPLAN);
                const partnerLogoImages = droppedImages.filter(img => img.category === FileCategory.PARTNER_LOGO);
                const dataRoomImage = droppedImages.filter(img => img.category === FileCategory.DATA_ROOM_IMAGE)[0];
                const riskMigrationImages = droppedImages.filter(img => img.category === FileCategory.RISK_MIGRATION_IMAGE);
                const aboutPartnerImage = droppedImages.filter(img => img.category === FileCategory.ABOUT_PARTNER_IMAGE)[0];

                baseProperty.securityAndRoles.roles.forEach((row, index) => {
                    if (row.attachedImage) {
                        formData.append('securityAndRolesRoles', row.attachedImage, `${row.attachedImage.name}.${String(index)}`);
                    }
                });

                sliderImages.forEach((image) => {
                    formData.append('sliderImages', image, getUniqueFileName(image.name))
                })

                floorPlanImages.forEach(image => {
                    formData.append('floorPlanImages', image, getUniqueFileName(image.name))
                })

                partnerLogoImages.forEach(image => {
                    formData.append('partnerLogoImages', image, getUniqueFileName(image.name))
                });

                riskMigrationImages.forEach(image => {
                    formData.append('riskMigrationImages', image, getUniqueFileName(image.name))
                });

                baseProperty.documents.forEach(document => {
                    if (document.file) {
                        formData.append('documents', document.file, getUniqueFileName(document.file.name))
                    }
                });

                baseProperty.dataRoom.documents.forEach(document => {
                    if (document.file) {
                        formData.append('dataRoomDocuments', document.file, getUniqueFileName(document.file.name));
                    }
                });

                baseProperty.risk.documents.forEach(document => {
                    if (document.file) {
                        formData.append('riskDocuments', document.file, getUniqueFileName(document.file.name));
                    }
                });

                baseProperty.performanceReport.documents.forEach(document => {
                    if (document.file) {
                        formData.append('performanceReportDocuments', document.file, getUniqueFileName(document.file.name));
                    }
                });

                if (dataRoomImage) {
                    formData.append('dataRoomImage', dataRoomImage, getUniqueFileName(dataRoomImage.name));
                }

                if (aboutPartnerImage) {
                    formData.append('aboutPartnerImage', aboutPartnerImage, getUniqueFileName(aboutPartnerImage.name));
                }

                formData.append('property', JSON.stringify(baseProperty))
                formData.append('translations', JSON.stringify(translations));

                const response = await api.put(`/propertyTeaser/edit/${propertyId}`, formData)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e: any) {
                console.error({e});
                reject(new Error(e.response?.data?.message || 'Fail to add property teaser'))
            }
        })
    },

    add: (baseProperty: any, translations: { [key: string]: any }, droppedImages) => {
        return new Promise<{ baseProperty: PropertyTeaserType, translations: {} }>(async (resolve, reject) => {
            try {
                const formData = new FormData()

                const sliderImages = droppedImages.filter(img => img.category === FileCategory.SLIDER);
                const floorPlanImages = droppedImages.filter(img => img.category === FileCategory.FLOORPLAN);
                const partnerLogoImages = droppedImages.filter(img => img.category === FileCategory.PARTNER_LOGO);
                const dataRoomImage = droppedImages.filter(img => img.category === FileCategory.DATA_ROOM_IMAGE)[0];
                const riskMigrationImages = droppedImages.filter(img => img.category === FileCategory.RISK_MIGRATION_IMAGE);

                baseProperty.securityAndRoles.roles.forEach((row, index) => {
                    if (row.attachedImage) {
                        formData.append('securityAndRolesRoles', row.attachedImage, `${row.attachedImage.name}.${String(index)}`);
                    }
                });

                sliderImages.forEach(image => {
                    formData.append('sliderImages', image, getUniqueFileName(image.name))
                })

                floorPlanImages.forEach(image => {
                    formData.append('floorPlanImages', image, getUniqueFileName(image.name))
                })

                partnerLogoImages.forEach(image => {
                    formData.append('partnerLogoImages', image, getUniqueFileName(image.name))
                });

                riskMigrationImages.forEach(image => {
                    formData.append('riskMigrationImages', image, getUniqueFileName(image.name))
                });

                baseProperty.documents.forEach(document => {
                    if (document.file) {
                        formData.append('documents', document.file, getUniqueFileName(document.file.name))
                    }
                })

                baseProperty.dataRoom.documents.forEach(document => {
                    if (document.file) {
                        formData.append('dataRoomDocuments', document.file, getUniqueFileName(document.file.name));
                    }
                });

                if (dataRoomImage) {
                    formData.append('dataRoomImage', dataRoomImage, getUniqueFileName(dataRoomImage.name));
                }

                formData.append('property', JSON.stringify(baseProperty));
                formData.append('translations', JSON.stringify(translations));

                const response = await api.post(`/propertyTeaser/add`, formData)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e: any) {
                console.error({e});
                reject(new Error(e.response?.data?.message || 'Fail to add property teaser'))
            }
        })
    },
}
