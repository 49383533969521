import classNames from 'classnames';
import { memo, FC } from 'react';

interface LanguageSwitcher {
  availableLanguages: Array<{ value: string; label: string }>;
  onLanguageSelect: (selectedLanguage: string) => void;
  selectedLanguage: string;
}

export const LanguageSwicher: FC<LanguageSwitcher> = memo(({ availableLanguages, onLanguageSelect, selectedLanguage }) => {
  return (
    <div
      className='d-flex flex-wrap justify-content-between'
      style={{
        width: '180px'
      }}
    >
      {availableLanguages.map(({ value, label }, index) => {
        const isSelected = value === selectedLanguage;

        return (
          <label
            className={classNames(
              'mb-0 btn mr-2',
              {'btn-primary': isSelected},
              {'btn-light': !isSelected},
            )}
            onClick={() => {
              onLanguageSelect(value);
            }}
            key={value + index}
          >
            {label || 'Null'}
          </label>
        );
      })}
    </div>
  );
});
