import {
  memo, 
  MouseEventHandler,
  FC,
  Dispatch,
  SetStateAction,
  useCallback
} from 'react';
import { Can } from '../../casl/Can';
import { PropertyTeaserType } from '../../store/property/types';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../types/drag-and-drop/item-types';
import { ProptertiesTableRow } from './propterties-table-row';
import update from 'immutability-helper';

interface ProptertiesTableProps {
  handleSort: MouseEventHandler<HTMLTableElement>;
  propertiesLocal: Array<PropertyTeaserType>;
  setPropertiesLocal: Dispatch<SetStateAction<Array<PropertyTeaserType>>>;
  changeDefaultPropertyMode: (id: string, defaultPropertyMode: boolean) => void;
  setPropertyToCopy: Dispatch<SetStateAction<{
    name: string;
    id: string;
  }>>;
  setPropertyToDelete: Dispatch<SetStateAction<string>>;
}

export const ProptertiesTable: FC<ProptertiesTableProps> = memo(({
  handleSort,
  propertiesLocal,
  setPropertiesLocal,
  changeDefaultPropertyMode,
  setPropertyToCopy,
  setPropertyToDelete
}) => {
  const [, drop] = useDrop(() => ({ accept: ItemTypes.PROPERTY_TEASER }));

  const findProperty = useCallback(
    (id: string) => {
      const targetIndex = propertiesLocal.findIndex((property) => property._id === id);

      return {
        property: propertiesLocal[targetIndex],
        index: targetIndex,
      };
    },
    [propertiesLocal]
  );

  const moveProperty = useCallback(
    (id: string, atIndex: number) => {
      const { property: group, index } = findProperty(id);
      const updatedOrder = update(propertiesLocal, {
        $splice: [
          [index, 1],
          [atIndex, 0, group],
        ],
      });

      setPropertiesLocal(updatedOrder);
    },
    [findProperty, propertiesLocal, setPropertiesLocal]
  );

  return (
    <table
      className='table table-sm card-table'
      onClick={handleSort}
    >
      <thead>
        <tr>
          <Can I='reorder' a='property'>
            <th>
              <a href='#!' className='text-muted'>
                Order
              </a>
            </th>
          </Can>
          <th>
            <a href='#!' className='text-muted'>
              Internal Name
            </a>
          </th>
          <th>
            <a href='#!' className='text-muted sort' data-sort='options.status'>
              Status
            </a>
          </th>
          <th className='table-header-row-sticky'>
            <a href='#!' className='text-muted'>
              Address
            </a>
          </th>
          <Can I='update' a='property'>
            <th>
              <a href='#!' className='text-muted'>
                Sample Opportunity
              </a>
            </th>
          </Can>
          <th>
            <a href='#!' className='text-muted'>
              Date
            </a>
          </th>
          <th className='text-center'>
            <a href='#!' className='text-muted'>
              QR Image
            </a>
          </th>
          <th>
            <a href='#!' className='text-muted'>
              Actions
            </a>
          </th>
        </tr>
      </thead>

      <tbody className='list position-relative' ref={drop}>
        {propertiesLocal.map((property) => {
          return (
            <ProptertiesTableRow
              key={property._id}
              property={property}
              moveProperty={moveProperty}
              findProperty={findProperty}
              setPropertyToCopy={setPropertyToCopy}
              setPropertyToDelete={setPropertyToDelete}
              changeDefaultPropertyMode={changeDefaultPropertyMode}
            />
          );
        })}
      </tbody>
    </table>
  );
});
