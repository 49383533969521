import { SubscriptionStatus } from "../../../store/property/types";
import { CreateEventInitialvalues } from "../../../types/property-teaser/timeline/create-event-initial-values";

export const oneDayPixelWidth = 2.5;
export const gapBetweenMonthColumns= 26;


export const propertyNameColumnWidth = 160;
export const propertyNameColumnPaddingRight = 11;

export const tablePaddingX = 30;

export const quarterNameBlockHeight = 25;
export const quarterNameBlockMarginBottom = 12;

export const eventHeight = 45;

export const eventStatsColors: { [key in SubscriptionStatus]: string } = {
  'Subscription Open': '#B6DAE0',
  'Closed': '#1B2633',
  'Pre-subscription': '#E1DFCD',
  'Secondary Market': '#9E8163',
};

export const createEventInitialValues: CreateEventInitialvalues = {
  subscriptionStatus: "",
  startDate: null,
  endDate: null,
}
