import axios from 'axios';
import history from "../history";
import {config} from "../appConfig";

const api = axios.create({
    proxy: true,
    baseURL: config.ESTATING_API_URI,
    headers: {
        'x-auth': localStorage.getItem('jwtToken'),
    },
    withCredentials: true,
    timeout: 6000000,
});

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('jwtToken');
            history.push('/login');
        }
        return Promise.reject(error);
    }
);

export default api;
