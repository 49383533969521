import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { setDefaults, fromLatLng, OutputFormat } from 'react-geocode';
import { config } from '../../../appConfig';
import { AllowedCountries, LocationDescription } from '../../../store/property/types';
import Button from '../../../components/Button';
import Select from 'react-select';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';

interface LocationProps {
  location: LocationDescription;
  onChange: (newValue: LocationDescription, index: number) => void;
  onRemove: (index: number) => void;
  index: number;
  fieldTags?: Array<TagDescription>;
}

const countries = Object.keys(AllowedCountries).map((key) => ({ value: key, label: AllowedCountries[key] as string }));

export const Location: React.FC<LocationProps> = memo(({ location, onChange, index, onRemove, fieldTags }) => {
  const { coords, address } = location;
  const [ googleMapsURL, setGoogleMapsURL ] = useState('');

  const getLocationFromUrl = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    const regex = new RegExp('@(.*),(.*),');
    const lonLatMatch = url.match(regex);
    const toChange = { ...location };

    if (lonLatMatch && lonLatMatch.length === 3) {
      const latitude = lonLatMatch[1];
      const longitude = lonLatMatch[2];

      toChange.coords.longitude = Number(longitude);
      toChange.coords.latitude = Number(latitude);


      setDefaults({
        key: config.GEOCODING_API_KEY as string, // Your API key here.
        language: "en", // Default language for responses.
        region: "es", // Default region for responses.
        outputFormat: OutputFormat.JSON,
      });

      try {
        const response = await fromLatLng(Number(latitude), Number(longitude));
        const addressComponents = response.results[0].address_components;

        let streetNumber = '';
        let streetName = '';
        let city = '';
        let country = '';

        for (let i = 0; i < addressComponents.length; i++) {
          const types = addressComponents[i].types;
          if (types.includes('locality')) {
            city = addressComponents[i].long_name;
            toChange.coords.city = addressComponents[i].long_name;
          } else if (types.includes('street_number')) {
            streetNumber = addressComponents[i].long_name;
          } else if (types.includes('route')) {
            streetName = addressComponents[i].long_name;
          } else if (types.includes('administrative_area_level_1')) {
            toChange.coords.state = addressComponents[i].long_name;
          } else if (types.includes('country')) {
            const target = countries.find(({ value, label }) => label === addressComponents[i].long_name as string);
            country = target?.value || '';

            toChange.coords.country = country as AllowedCountries;
          }
        }

        toChange.address = `${AllowedCountries[country]}, ${city} ${streetName} ${streetNumber}`;
      } catch (error) {
        console.error(error);
      }
    } else {
      toChange.coords.longitude = 0;
      toChange.coords.latitude = 0;
      toChange.coords.country = null;
      toChange.coords.city = '';
      toChange.coords.state = '';
      toChange.address = '';
    }

    onChange(toChange, index);
    setGoogleMapsURL(url);
  }, [index, location, onChange]);

  const onChangeLocation = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const toChange = { ...location };
    toChange[e.target.name] = e.target.value;

    onChange(toChange, index);
  },[index, location, onChange]);

  const onChangeLocationCoords = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const toChange = { ...location };
    toChange.coords[e.target.name] = e.target.value;
    onChange(toChange, index);
  }, [index, location, onChange]);

  const countrySelectValue = useMemo(() => 
    ({ value: location.coords.country || '', label: AllowedCountries[location.coords.country || ''] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [location, location.coords.country]);

  const onSelectChange = useCallback(({ value }, { name }) => {
    const toChange = { ...location };
    toChange.coords[name] = value;

    onChange(toChange, index);
  }, [index, location, onChange]);

  const tags = useMemo(() => {
    return fieldTags && fieldTags.map((tagDescription) => {
      let mockUp;

      switch(typeof tagDescription) {
        // this is object value
        case 'object':
          const { tagType, amount } = tagDescription;
          const tagColor = fieldTagsColors[tagType].color;

          mockUp = (
            (amount === -1 || amount > index) &&
            <div
              style={{
                background: tagColor,
                width: '8px',
                height: '8px',
                borderRadius: '50%',
              }}
            />
          )
        break;
      }
      return mockUp;
    })
  }, [fieldTags, index]);

  return (
    <div>
      <div className='d-flex flex-wrap-small my-2 position-relative'>
        <label className='label-fix-width'>Address</label>
        <div
          className='d-flex flex-column position-absolute'
          style={{
            gap: '3px',
            minWidth: '8px',
            left: '-11px',
            top: '4px',
          }}
        >
          {tags}
        </div>
        <input
          type='text'
          value={address || ''}
          name='address'
          onChange={onChangeLocation}
          className='form-control'
        />
      </div>
      <div className='d-flex flex-wrap-small  my-2'>
        <label className='label-fix-width'></label>
        <div className='w-100'>
          <div className='row'>
            <div className='col mr-3'>
              <label>Latitude</label>
              <input
                type='number'
                name='latitude'
                onChange={onChangeLocationCoords}
                value={coords.latitude || ''}
                className='form-control'
              />
            </div>
            <div className='col mr-3'>
              <label>Longitude</label>
              <input
                type='number'
                name='longitude'
                onChange={onChangeLocationCoords}
                value={coords.longitude || ''}
                className='form-control'
              />
            </div>
            <div className='col mr-3 position-relative'>
              <label>Country</label>
              <div
                className='d-flex flex-column position-absolute'
                style={{
                  gap: '3px',
                  minWidth: '8px',
                  left: '-11px',
                  top: '4px',
                }}
              >
                {tags}
              </div>
              <Select
                onChange={onSelectChange}
                value={countrySelectValue}
                options={countries}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5.2,
                  colors: {
                    ...theme.colors,
                    primary25: '#009acd',
                    primary: '#00b3f0',
                    neutral0: '#1E3A5C',
                    neutral20: 'transparent',
                    neutral90: '#fff',
                    neutral80: '#fff',
                  },
                  
                })}
                isSearchable
                name="country"
              />
            </div>
            <div className='col mr-3 position-relative'>
              <label>City</label>
              <div
                className='d-flex flex-column position-absolute'
                style={{
                  gap: '3px',
                  minWidth: '8px',
                  left: '-11px',
                  top: '4px',
                }}
              >
                {tags}
              </div>
              <input
                type='text'
                name='city'
                onChange={onChangeLocationCoords}
                value={coords.city || ''}
                className='form-control'
              />
            </div>
            <div className='col mr-3'>
              <label>State</label>
              <input
                type='text'
                name='state'
                onChange={onChangeLocationCoords}
                value={coords.state || ''}
                className='form-control'
              />
            </div>
          </div>
          <div className='my-2'>
            <input
              type='text'
              onChange={getLocationFromUrl}
              value={googleMapsURL}
              placeholder='Paste google maps location URL'
              className='form-control'
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          label='Remove'
          className='btn-sm btn-danger ml-auto'
          onClick={() => onRemove(index)}
        />
      </div>
    </div>
  );
});
