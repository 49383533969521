import React from 'react';
import {UsersAPI} from "../../api/userApi";
import {SessionObj} from "../../store/sessions/types";
import prettyMilliseconds from "pretty-ms";
import {getTzDate} from "../../helpers/utils";

type SessionLogProps = {
    user: string,
};

export interface SessionLogState {
    _id: string,
    user: string,
    sessions: SessionObj[],
}

const SessionLog: React.FC<SessionLogProps> = ({user}) => {

    const [sessionLog, setSessionLog] = React.useState<SessionLogState>({
        _id: '',
        user: '',
        sessions: []
    });

    React.useEffect(() => {
        UsersAPI.querySessionLog(user)
            .then((sessionLog) => {
                setSessionLog(sessionLog);
            })
            .catch(e => console.error(e))
    }, [user]);

    return (
        <div className='d-flex row'>
            {
                sessionLog.sessions ? sessionLog.sessions.map(({
                                                                   _id,
                                                                   loginTime,
                                                                   logoutTime,
                                                                   sessionSize,
                                                                   action,
                                                                   deviceType,
                                                               },
                                                               index) => {
                    return (
                        <div key={_id} className='col-sm-12 col-md-5 col-lg-3 col-xl-3'>
                            <p>
                                <a data-toggle="collapse" href={`#${action + index}`}
                                   role="button" aria-expanded="false" aria-controls={action + index}>
                                    <span>Show session</span><br/>
                                    <span>
                                    {getTzDate(loginTime, 'YYYY-MM-DD')}
                                </span>
                                </a>
                            </p>
                            <hr/>
                            <div className="collapse" id={action + index}>
                                <div className={`card d-flex p-3 align-items-center`}>
                                    <div>
                                        <p className='my-1'>
                                            <span><b>Login:</b>
                                                {" "}{getTzDate(loginTime)}
                                            </span>
                                        </p>
                                        <p className='my-1'>
                                            <span><b>Logout:</b>
                                                {" "}
                                                {(action === 'sign_out')
                                                    ? getTzDate(logoutTime)
                                                    : 'currently active'}
                                            </span>
                                        </p>
                                        <p className='my-1'>
                                            <span><b>Session size:</b>
                                                {" "}
                                                {(action === 'sign_out')
                                                    ? prettyMilliseconds(sessionSize)
                                                    : 'present'}
                                            </span>
                                        </p>
                                        <p className='my-1'>
                                            <span><b>Device:</b>{" "}{deviceType}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : null
            }
        </div>
    )
}

export default SessionLog;
