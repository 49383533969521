import React, { ChangeEvent, Fragment } from 'react';

type Props = {
    checked?: string;
    checkedArray?: string[];
    label?: string;
    rightComponent?: JSX.Element,
    items: { label: string; value: string }[];
    name: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    containerClass?: string;
    labelClass?: string;
    id?: string,
    type?: string
};

const RadioButtons: React.FC<Props> = ({ type='radio',checkedArray, checked, label, items, onChange, name, containerClass, labelClass, rightComponent }) => {

    return (
        <div className={`d-flex align-items-center ${containerClass}`}>
            {label && <label className={`label-fix-width ${labelClass}`}>{label}</label>}
            <div className='btn-group btn-group-toggle'>
                {items.map((item, index) => {
                    const key = `${name}_${index}`;
                    const { label, value } = item;
                    const isChecked = checked ? checked === value : checkedArray?.includes(value);
                    return (
                        <Fragment key={key}>
                            <input
                                type={type}
                                className='btn-check d-none'
                                onChange={onChange}
                                name={name}
                                id={key}
                                value={value}
                                checked={isChecked}
                            />
                            <label className={`btn btn-white ${isChecked ? 'active' : ''} `} htmlFor={key}>
                                {label}
                            </label>
                        </Fragment>
                    );
                })}
            </div>
            {rightComponent && (<div className="input-group-append ml-1">
                    {rightComponent}
                    </div>)}
        </div>
    );
};

export default RadioButtons;
