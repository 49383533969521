import { PropertyTeaserType, Event } from "../../../../store/property/types";
import dayjs, { Dayjs } from "dayjs";
import { gapBetweenMonthColumns, oneDayPixelWidth, propertyNameColumnPaddingRight, propertyNameColumnWidth, tablePaddingX } from "../constants";

/**
 * Calculates the global bounds for the given properties.
 * @param {PropertyTeaserType} property - The array of PropertyTeaserType objects.
 * @returns {Object} An object containing the global start time and global end time.
 */
export const calculateGlobalBounds = (property: PropertyTeaserType) => {
  let globalStart: number | undefined, globalEnd: number | undefined;
  const deepCopy = JSON.parse(JSON.stringify(property)) as PropertyTeaserType;

    deepCopy.timeline.events.forEach((event: Event ) => {
      const startDateA = new Date(event.startDate as string);
      const endDateA = new Date(event.endDate as string);

      if(globalStart === undefined || globalStart > startDateA.getTime()) {
        globalStart = startDateA.getTime();
      }

      if(globalEnd === undefined || globalEnd < endDateA.getTime()) {
        globalEnd = endDateA.getTime();
      }
      
      event.startDate = dayjs(event.startDate)
      event.endDate = dayjs(event.endDate)
    });

  return { globalStart: dayjs(globalStart), globalEnd: dayjs(globalEnd), teaserWithDateEvents: deepCopy };
};

export const getBackgroundColumnsDescription = (start: Dayjs, end: Dayjs) => {
  const dateOneMonthBeforeStart = start.subtract(1, 'month').startOf('month');
  const startEndDiff = dateOneMonthBeforeStart.diff(end, "months") < 8 ? 8 : 1;
  const dateOneMonthAfterEnd = end.add(startEndDiff, "month").endOf("month");
  const quartiles: {[key: string]: Array<{ month: Dayjs, countDayInMonth: number}>} = {};
  let totalMonths = 0;
  let dateOneMonthBeforeStartCounter = dayjs(dateOneMonthBeforeStart);

  while(
    dateOneMonthBeforeStartCounter.isSameOrBefore(dateOneMonthAfterEnd)
  ) {
    const quarterName = `Q${dateOneMonthBeforeStartCounter.format('Q / YYYY')}`;

    if(!Object.prototype.hasOwnProperty.call(quartiles, quarterName)) {
      quartiles[quarterName] = [];
    }
    quartiles[quarterName].push({
      countDayInMonth: dateOneMonthBeforeStartCounter.daysInMonth(),
      month: dateOneMonthBeforeStartCounter,
    });

    dateOneMonthBeforeStartCounter = dateOneMonthBeforeStartCounter.add(1, 'month').endOf('month');
    totalMonths++;
  }

  const totalDays = dateOneMonthAfterEnd.diff(dateOneMonthBeforeStart, 'days');

  return { totalMonths, backgroundColumnsDescription: Object.entries(quartiles), totalDays, dateOneMonthBeforeStart, dateOneMonthAfterEnd }
};

export const calculateTableWidth = (totalMonths: number, totalDays: number) => 
  tablePaddingX * 2 + 
  propertyNameColumnWidth +
  propertyNameColumnPaddingRight +
  (totalMonths - 1) * gapBetweenMonthColumns +
  (totalDays + 1) * oneDayPixelWidth;


export const calculateEventXPosition = (event: Event, backgrounGlobalStart: Dayjs) => {
  const startDate = event.startDate as Dayjs;

  const monthsBetweenDates = startDate.diff(backgrounGlobalStart, 'months');
  const daysBetweenDates = startDate.diff(backgrounGlobalStart, 'days');

  return (
    monthsBetweenDates *
    gapBetweenMonthColumns +
    daysBetweenDates *
    oneDayPixelWidth +
    tablePaddingX +
    propertyNameColumnWidth +
    propertyNameColumnPaddingRight
  );
};

export const calculateEventDuration = (event: Event) => {
  const { startDate, endDate } = event;
  return (
    dayjs(endDate).diff(dayjs(startDate), 'days') * oneDayPixelWidth +
    (
      dayjs(endDate).isSame(dayjs(startDate), 'month') ?
          0 
        :
          (dayjs(endDate).diff(dayjs(startDate), 'months') || 1)
          * gapBetweenMonthColumns)
  );
};