import { RootState } from '../';
import { createSelector } from 'reselect';
import { EducationMaterialsGroupsState } from './types';

export const rootSelector: (state: RootState) => EducationMaterialsGroupsState = (state: RootState) =>
  state.educationMaterials;

export const educationMaterialsGroupsSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.groups
);

export const educationMaterialsGroupsLoadingSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.isLoading
);

export const educationMaterialsGroupsUpdatingSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.isUpdating
);

export const educationMaterialsGroupsErrorSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.error
);

export const educationMaterialFormEntitySelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.materialFormEntity
);
