import { memo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EducationMaterialForm } from './components/education-material-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EducationMaterial } from '../../types/education-materials/education-material';
import { PartialBy } from '../../types/partial-by';
import { DroppedFile } from '../../types/dropped-file';
import { getEducationMaterialFormEntity, updateEducationMaterial } from '../../store/education-materials/thunks';
import { educationMaterialFormEntitySelector, educationMaterialsGroupsLoadingSelector } from '../../store/education-materials/selectors';
import { LanguageSwicher } from '../../components/language-switcher';
import { useLanguage } from '../../contexts/language/language-context';

export const EditEducationMaterial = memo(() => {
  const { groupId, materialId } = useParams<{ groupId: string; materialId: string }>();
  const dispatch = useDispatch();
  const isLoading = useSelector(educationMaterialsGroupsLoadingSelector);
  const initialValue = useSelector(educationMaterialFormEntitySelector);
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  useEffect(() => {
    dispatch(getEducationMaterialFormEntity(groupId, materialId));
  }, [groupId, materialId, dispatch]);

  const onSubmit = useCallback(
    (values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> }, droppedFile: DroppedFile | null, customThumbnail: DroppedFile | null) => {
      dispatch(updateEducationMaterial(
        groupId,
        materialId,
        values,
        droppedFile,
        customThumbnail,
        new URLSearchParams([
          ['lang', currentLanguage]
        ])
      ));
    },
    [currentLanguage, dispatch, groupId, materialId]
  );

  if (isLoading) {
    return (
      <div className='w-100 mt-5'>
        <span className='m-auto loader loader-primary loader-lg' />
      </div>
    );
  }

  if (!initialValue) {
    return null;
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <ol className='breadcrumb mb-3'>
          <li className='breadcrumb-item'>
            <Link to={'/education-materials-groups'}>Estating Academy</Link>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            edit
          </li>
        </ol>
        <div className="h-min">
          <LanguageSwicher
            selectedLanguage={currentLanguage}
            availableLanguages={[
              { label: 'Default', value: 'en' },
              { label: 'Spanish', value: 'es' },
            ]}
            onLanguageSelect={setCurrentLanguage}
          />
        </div>
      </div>

      <EducationMaterialForm initialValues={initialValue} onSubmit={onSubmit} isEdit />
    </div>
  );
});
