import { FileWithPath } from "react-dropzone";

export enum FileCategory {
  FLOORPLAN = 'floorplan',
  SLIDER = 'slider',
  LOCATION = 'location',
  COUNTRY = 'country',
  CITY = 'city',
  NEIGHBORHOOD = 'neighborhood',
  PARTNER_LOGO = 'partner-logo',
  EDUCATION_MATERIAL = 'education-material',
  EDUCATION_MATERIAL_THUMBNAIL = 'education-material-thumbnail',
  DATA_ROOM_IMAGE = 'data-room-image',
  RISK_MIGRATION_IMAGE = 'risk-migration-image',
  ABOUT_PARTNER_IMAGE = 'about-partner-image',
  ROLES_AND_RESPONSIBILITIES_IMAGE = 'roles-and-responsentials-image',
}

export interface DroppedFile extends FileWithPath {
  preview: string,
  category?: FileCategory
}