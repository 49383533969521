import { memo, FC, useMemo } from 'react';
import styled from 'styled-components';
import { propertyNameColumnWidth } from '../../utils/constants';
import { TimelineEvent } from './timeline-event';
import { PropertyTeaserType } from '../../../../store/property/types';

interface RowProps {
  teaser: PropertyTeaserType;
}

export const Row: FC<RowProps> = memo(({ teaser }) => {
  const events = useMemo(() => teaser.timeline.events, [teaser]);

  return (
    <ColumnsContainer>
      <NameColumn>
        {teaser.name}
      </NameColumn>
      {events.map((event, index) => (
        <TimelineEvent event={event}  index={index} />
      ))}
    </ColumnsContainer>
  )
})

const NameColumn = styled.div`
  position: sticky;
  left: 0;

  display: inline-block;

  width: ${propertyNameColumnWidth}px;

  border-radius: 5px;

  z-index: 4;
`

const ColumnsContainer = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  align-items: center;
`;