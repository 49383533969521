export enum FieldTag {
  Catalyst,
  Teaser
}
export const fieldTagsColors: {[key in FieldTag]: { color: string, name: string }} = Object.freeze({
  [FieldTag.Catalyst]: {
    color: 'rgba(190, 80, 159, 1)',
    name: 'Catalyst'
  },
  [FieldTag.Teaser]: {
    color: 'rgba(104, 119, 138, 1)',
    name: 'Teaser'
  },
});

export type TagDescription = FieldTag | { tagType: FieldTag; amount: number };

export type FieldsTagsDescription = { [key: string]: Array<TagDescription> };

export const fieldsTags: FieldsTagsDescription = {
  'name': [FieldTag.Teaser, FieldTag.Catalyst],
  '[options.status]': [FieldTag.Teaser, FieldTag.Catalyst],
  '[options.teaserStatus]': [FieldTag.Teaser],
  '[options.investmentStrategy]': [FieldTag.Teaser, FieldTag.Catalyst],
  'investmentStrategy': [{ tagType: FieldTag.Teaser, amount: 3 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  'teaserSummary': [FieldTag.Teaser],
  '[options.category]': [FieldTag.Teaser, FieldTag.Catalyst],
  'options.financialStructure': [FieldTag.Teaser, FieldTag.Catalyst],
  '[options.capitalStructure]': [FieldTag.Teaser, FieldTag.Catalyst],
  'options.propertyType': [FieldTag.Teaser, FieldTag.Catalyst],
  'options.isin': [FieldTag.Teaser],
  'options.relincPrice': [FieldTag.Teaser, FieldTag.Catalyst],
  'assets': [{ tagType: FieldTag.Teaser, amount: 7 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[details.highlighted]':  [{ tagType: FieldTag.Teaser, amount: -1 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[details.secondary.others]': [{ tagType: FieldTag.Teaser, amount: 6 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[about.wysiwygDescription]': [FieldTag.Teaser, FieldTag.Catalyst],
  'securityAndRoles.aboutThePartner.text': [FieldTag.Teaser, FieldTag.Catalyst],
  'locations': [{ tagType: FieldTag.Teaser, amount: 1 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[securityAndRoles.roles]': [{ tagType: FieldTag.Teaser, amount: 9 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[securityAndRoles.security]': [{ tagType: FieldTag.Teaser, amount: 6 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  'disclaimers': [{ tagType: FieldTag.Teaser, amount: -1 }, { tagType: FieldTag.Catalyst, amount: -1 }],
  '[details.footnotes]': [{ tagType: FieldTag.Catalyst, amount: -1 }, { tagType: FieldTag.Teaser, amount: -1 }],

  '[opportunityType]': [FieldTag.Catalyst],
  'tags': [FieldTag.Catalyst],
  'timeline': [FieldTag.Catalyst],
  '[summary]': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'details.secondary.assetType': [FieldTag.Teaser, FieldTag.Catalyst],
  '[details.secondary.capitalStructure]': [FieldTag.Teaser, FieldTag.Catalyst],
  'details.secondary.financialStructure': [FieldTag.Teaser, FieldTag.Catalyst],
  'floorPlan.assets': [{ tagType: FieldTag.Catalyst, amount: 1 }],
  'securityAndRoles.aboutThePartner.image': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'risk.riskMitigationFactors': [FieldTag.Catalyst],
  'risk.riskMitigationImages': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'risk.documents': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'dataRoom.marketResearchText': [FieldTag.Catalyst],
  'dataRoom.marketResearchImage': [FieldTag.Catalyst],
  'dataRoom.documents': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'performanceReport.documents': [{ tagType: FieldTag.Catalyst, amount: -1 }],
  'performanceReport.password': [FieldTag.Catalyst],
  'educationMaterials': [FieldTag.Catalyst],
}