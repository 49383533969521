import { ChangeEvent, FC, memo, useCallback } from 'react';
import { generateThumbnail } from '../../../helpers/thumbnail-generator';
import styled from 'styled-components';
import { TagDescription, fieldTagsColors } from '../../../constants/fields-tags';

interface LoadedFilePresenterProps {
  fileUrl: string;
  mimeType: string;
  setDeletedImages?: (deletedImages: string[]) => any;
  imagesToDelete?: string[];
  fieldTags?: Array<TagDescription>;
}

export const LoadedFilePesenter: FC<LoadedFilePresenterProps> = memo(
  ({ fileUrl, mimeType, setDeletedImages, imagesToDelete, fieldTags }) => {
    const imageToDelete = !!imagesToDelete?.length && imagesToDelete.includes(fileUrl);

    const toggleDeleteImages = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (setDeletedImages && imagesToDelete) {
          setDeletedImages(
            e.target.checked
              ? [...imagesToDelete, e.target.value]
              : [...imagesToDelete.filter((a) => a !== e.target.value)]
          );
        }
      },
      [imagesToDelete, setDeletedImages]
    );

    return (
      <LoadedFilePesenterContainer>
        <div
          className='d-flex flex-column position-absolute'
          style={{
            gap: '3px',
            minWidth: '8px',
            left: '-14px',
            top: '0'
          }}
        >
          {fieldTags &&
            fieldTags.map((tagDescription) => {
              let mockUp;

              switch (typeof tagDescription) {
                // this is enum value
                case 'number':
                  const tagColor = fieldTagsColors[tagDescription].color;
                  mockUp = (
                    <div
                      style={{
                        background: tagColor,
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  );
                  break;
              }
              return mockUp;
            })}
        </div>
        <div className='w-100 h-100 position-relative'>
          {mimeType.startsWith('video/') ? (
            <video width='100%' height='100%' controls className={`border border-2`}>
              <source src={fileUrl} type='video/mp4' />
            </video>
          ) : (
            <img
              src={mimeType.startsWith('image/') ? fileUrl : generateThumbnail(mimeType, fileUrl)}
              alt='Slider item'
              crossOrigin='anonymous'
              className={`border border-2`}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          )}

          {imagesToDelete && setDeletedImages && (
            <div>
              <input
                type='checkbox'
                className='custom-control-input'
                onChange={toggleDeleteImages}
                value={fileUrl}
                id={fileUrl}
              />
              <label
                className={`check-delete-image btn btn-sm ${imageToDelete ? 'btn-warning' : 'btn-danger'}`}
                htmlFor={fileUrl}
              >
                {imageToDelete ? <i className='fe fe-refresh-ccw'></i> : <i className='fe fe-trash'></i>}
              </label>
            </div>
          )}
        </div>
      </LoadedFilePesenterContainer>
    );
  }
);

const LoadedFilePesenterContainer = styled.div`
  position: relative;
  max-width: 320px;
  height: 240px;

  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
