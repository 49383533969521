import React, {ChangeEvent} from 'react'
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import {UsersAPI} from "../../api/userApi";
import { User } from '../../store/users/types';
import {SignInTokenAPI} from "../../api/signInTokenApi";
import {config} from "../../appConfig";
import validator from 'validator'
import {AbilityContext} from "../../casl/Can";
import {useAbility} from "@casl/react";
import {COUNTRIES} from "../../constants/countries";
import {Link} from "react-router-dom";
import { WhoAreYou } from '../../types/user/who-are-you';

type UserDetailsProps = {
    user: User
}

export type SignInTokenType = {
    _id: string,
    requestedFields: string,
    tokenExpires: Date,
    token: string,
    whoCreated: any,
    user: User,
    createdAt: Date,
}

const UserDetails: React.FC<UserDetailsProps> = ({user}) => {

    const [localUser, setLocalUser] = React.useState<User | null>(null)
    const [links, setLinks] = React.useState<SignInTokenType[]>([])
    const [signedUpUsers, setSignedUpUsers] = React.useState<{ [x: string]: User[]; }>({})
    const [updateLoading, setUpdateLoading] = React.useState(false)
    const [emailToReset, setEmailToReset] = React.useState('');
    const [isEmailValid, setIsEmailValid] = React.useState(true)

    const [responseMessage, setResponseMessage] = React.useState("");
    const [hasError, setError] = React.useState(false);
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const ability = useAbility(AbilityContext)

    React.useEffect(() => {
        setLocalUser(user)
        setEmailToReset(user.email)
    }, [user])

    React.useEffect(() => {
        if (user?.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR) {
            SignInTokenAPI.getUserLinks(user?._id || '')
                .then(links => {
                    setLinks(links)
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }, [user])

    React.useEffect(() => {
        links.forEach(link => {
            getSignedUpUsers(link._id)
        })
    }, [links])


    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        if (localUser) {
            setLocalUser({
                ...localUser,
                [e.target.name]: e.target.value
            })
        }
    }

    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmailToReset(e.target.value);
    }

    const _alertMessage = (message: string, delay: number = 3000) => {
        setResponseMessage(() => {
            setTimeout(() => {
                setResponseMessage('')
            }, delay)

            return message
        })
    }

    const _isValid = () => {
        return (!!password && password === confirmPassword) || !password
    }

    const updateUser = () => {
        if (localUser && _isValid()) {
            setUpdateLoading(true)
            const toUpdate: Partial<User> = {
                firstName: localUser.firstName,
                lastName: localUser.lastName,
                email: localUser.email,
                country: localUser.country,
                whoAreYou: localUser.whoAreYou,
            }
            if (password) {
                toUpdate.password = password
            }
            UsersAPI.updateUser(localUser._id, toUpdate)
                .then(updated => {
                    setLocalUser(updated)
                    _alertMessage('Successfully updated')
                    setError(false)
                    setPassword('')
                    setConfirmPassword('')
                })
                .catch(e => {
                    setError(true)
                    _alertMessage(e.message || 'Fail to update user')
                })
                .finally(() => {
                    setUpdateLoading(false)
                });
        }
    }

    const getSignedUpUsers = (linkId: string) => {
        SignInTokenAPI.signedInUsers(linkId)
            .then(users => {
                setSignedUpUsers((signedInUsers: { [x: string]: User[]; }) => {
                    signedInUsers[linkId] = users

                    return {...signedInUsers}
                })
            })
    }

    const resetPassword = () => {
        if (localUser && !!emailToReset && isEmailValid) {

            setUpdateLoading(true)
            UsersAPI.resetPassword(localUser._id, emailToReset)
                .then(message => {
                    setUpdateLoading(false);
                    setError(false);
                    _alertMessage(message);
                })
                .catch(e => {
                    setError(true);
                    _alertMessage("Fail to reset password");
                })
                .finally(() => {
                    setUpdateLoading(false)
                });
        }

    }

    if (!localUser) {
        return null
    }

    const canUpdateIVO = ability.can('updateIVO', 'user')
    const canUpdateUser = ability.can('update', 'user')
    const isIVO = localUser.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR

    return (
        <div>
            {responseMessage &&
                <div className={`alert my-3 ${hasError ? 'alert-danger' : 'alert-success'}`}>
                    <p className='text-center mb-0'>{responseMessage}</p>
                </div>
            }
            <Input
                onChange={onChange}
                label='First Name'
                value={localUser?.firstName}
                name='firstName'
                inputProps={{
                    disabled: (!isIVO && !canUpdateUser) || (isIVO && !canUpdateIVO)
                }}
            />
            <Input
                containerClass='my-2'
                onChange={onChange}
                label='Last Name'
                value={localUser?.lastName}
                name='lastName'
                inputProps={{
                    disabled: (!isIVO && !canUpdateUser) || (isIVO && !canUpdateIVO)
                }}
            />
            <Input
                label='Email'
                onChange={onChange}
                name='email'
                value={localUser?.email}
                inputProps={{
                    disabled: (!isIVO && !canUpdateUser) || (isIVO && !canUpdateIVO)
                }}
            />

            <Select
                label='Country'
                selected={localUser?.country || ""}
                placeholder='Select the country'
                onChange={onChange}
                containerClass='my-2'
                name='country'
                selectProps={{
                    disabled: (!isIVO && !canUpdateUser) || (isIVO && !canUpdateIVO)
                }}
                values={COUNTRIES}
            />

            <Select
                selected={localUser?.whoAreYou}
                label='Who are you'
                onChange={onChange}
                name='whoAreYou'
                placeholder='Please select'
                values={['Financial Advisor', 'Investor', 'Other']}
                selectProps={{
                    disabled: (!isIVO && !canUpdateUser) || (isIVO && !canUpdateIVO)
                }}
            />
            <Input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                label='Password'
                containerClass='my-2'
            />
            <Input
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                validator={value => value === password}
                callback={isValid => setIsEmailValid(isValid)}
                invalidFeedback='Password is not matched'
                label='Confirm password'
            />
            <div className="d-flex flex-wrap-small align-items-start my-2">
                <label className="label-fix-width">Financial Advisor</label>
                <div>
                    {localUser.financialAdvisor ?
                        <>
                            <Link
                                className='h5'
                                to={`/user/${localUser.financialAdvisor._id}`}>
                                <b>{`${localUser.financialAdvisor.firstName} ${localUser.financialAdvisor.lastName}`}</b>
                            </Link>
                            <p>{localUser.financialAdvisor.email}</p>
                        </>
                        :
                        <p>Not exist</p>
                    }
                </div>
            </div>

            <div className='d-flex justify-content-end mt-3'>
                {
                    (!isIVO && canUpdateUser) || (isIVO && canUpdateIVO) ?
                        <Button
                            onClick={() => updateUser()}
                            buttonAttributes={{
                                disabled: !_isValid()
                            }}
                            className={`btn-primary ${updateLoading ? 'is-loading' : ''}`}
                            label='Update'
                        />
                        :
                        null
                }
            </div>
            <hr/>
            <div className='d-flex align-items-center justify-content-center'>
                <Input
                    type="email"
                    inputProps={{
                        placeholder: 'Mail to sent link'
                    }}
                    onChange={onChangeEmail}
                    value={emailToReset}
                    name='resetPassword'
                    validator={value => validator.isEmail(value)}
                    callback={isValid => setIsEmailValid(isValid)}
                    invalidFeedback='Please fill valid Email'
                />
                <Button
                    onClick={resetPassword}
                    className="btn-info col-auto ml-2"
                    label='Reset Password'
                    buttonAttributes={{
                        disabled: !isEmailValid || !emailToReset
                    }}
                />
            </div>

            <hr/>
            {
                localUser.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ?
                    <>
                        <h4 className='mr-4 text-center'>Predefined account links</h4>
                        <div className='d-flex align-items-start'>
                            {
                                isIVO && canUpdateIVO ?
                                    <Button
                                        label='Add link'
                                        className='btn btn-light'
                                        onClick={() => {
                                            SignInTokenAPI.signToken(localUser._id)
                                                .then(signInToken => {
                                                    signInToken && setLinks([...links, signInToken])
                                                }).catch(() => {
                                            })
                                        }}
                                    />
                                    :
                                    null
                            }
                        </div>
                        {
                            links.length > 0 ?
                                links.map(link => {
                                    const {whoCreated} = link
                                    return (
                                        <div key={link._id} className='bg-dark px-3'>
                                            <hr/>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href={`${config.WEBAPP_SIGNUP_URI}?authToken=${link.token}`}
                                                   rel="noreferrer" target='_blank'>Open link</a>
                                                <div className="btn-group">
                                                    {
                                                        isIVO && canUpdateIVO ?
                                                            <>
                                                                <button className='btn btn-sm btn-light'
                                                                        onClick={(e) => {
                                                                            let selected: any = false;
                                                                            const el = document.createElement('textarea');
                                                                            el.value = `${config.WEBAPP_SIGNUP_URI}?authToken=${link.token}`;
                                                                            el.setAttribute('readonly', '');
                                                                            el.style.position = 'absolute';
                                                                            el.style.left = '-9999px';
                                                                            document.body.appendChild(el);
                                                                            // @ts-ignore
                                                                            if (document.getSelection().rangeCount > 0) {
                                                                                // @ts-ignore
                                                                                selected = document.getSelection().getRangeAt(0)
                                                                            }
                                                                            el.select();
                                                                            document.execCommand('copy');
                                                                            document.body.removeChild(el);
                                                                            if (selected) {
                                                                                // @ts-ignore
                                                                                document.getSelection().removeAllRanges();
                                                                                // @ts-ignore
                                                                                document.getSelection().addRange(selected);
                                                                            }
                                                                            alert(el.value)
                                                                        }}>Copy
                                                                </button>

                                                                <button className='btn btn-sm btn-danger'
                                                                        onClick={() => {
                                                                            if (window.confirm('Are you sure?')) {
                                                                                SignInTokenAPI.deleteUserLink(link._id)
                                                                                    .then(deletedId => {
                                                                                        setLinks([
                                                                                            ...links.filter((link => link._id !== deletedId))
                                                                                        ])
                                                                                    })
                                                                                    .catch(() => {
                                                                                    })
                                                                            }
                                                                        }}>
                                                                    Remove
                                                                </button>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            {/*<p className={`mb-0 ${isExpires ? 'text-danger' : 'text-success'}`}>*/}
                                            {/*    <b> Token expires:</b> <i>{new Date(link.tokenExpires).toLocaleString('en-US')}</i>*/}
                                            {/*</p>*/}
                                            <p className='mb-0 mt-3'>
                                                <b>Created at</b> <br/>
                                                <i>{new Date(link.createdAt).toLocaleDateString("en-US", {
                                                    day: "2-digit",
                                                    year: "numeric",
                                                    month: "long",
                                                    hour: "2-digit",
                                                    minute: "2-digit"
                                                })}</i>
                                            </p>
                                            {
                                                signedUpUsers[link._id] ?
                                                    <p className='mb-0 mt-3'>
                                                        <b>Signed up users count</b> <br/>
                                                        <i>({signedUpUsers[link._id].length})</i>
                                                    </p>
                                                    :
                                                    null
                                            }
                                            <p className='my-3'>
                                                <b>Who creates</b> <br/>
                                                {
                                                    whoCreated ?
                                                        <i>{link.whoCreated.firstName} {link.whoCreated.lastName}</i>
                                                        :
                                                        <i>User deleted</i>
                                                }
                                            </p>
                                            <p className='mb-0'>
                                                <b>Included fields</b> <br/>
                                                <i>({Object.keys(JSON.parse(link.requestedFields)).join(' , ')}) <br/>
                                                    ({Object.values(JSON.parse(link.requestedFields)).join(' , ')})</i>
                                            </p>
                                            <hr/>
                                        </div>
                                    )
                                })
                                :
                                null

                        }
                    </>
                    :
                    null
            }
        </div>

    )
}

export default UserDetails
