import {Action} from 'redux';
import {PropertyObj} from "../property/types";

export const QUERY_USERS = 'QUERY_USERS'
export const VERIFY_TOGGLE = 'VERIFY_TOGGLE'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

export enum WhoAreYouEnum {
    INVESTOR = 'Investor',
    FINANCIAL_ADVISOR = 'Financial Advisor',
    OTHER = 'Other'
}

type WhoAreYou =
    'Investor'
    | 'Financial Advisor'
    | 'Other'
    | string

export enum HaveFinancialAdvisorEnum {
    Yes = 'Yes',
    No = 'No',
}


type HearAboutFrom = 'Investor'
    | 'Financial Advisor'
    | 'Advertisement'
    | 'Linkedin'
    | 'Estating Team Member'
    | 'Personal or Professional Network'
    | string

interface Bookmark {
    property: PropertyObj['property'],
    status: string
}

export interface User {
    googleUserId?: string,
    facebookUserId?: string,
    _id: string,
    appleId?: string,
    linkedinUserId?: string,
    financialAdvisor?: User,
    haveFinancialAdvisor?: HaveFinancialAdvisorEnum,
    phoneNumber?: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    whoAreYou: WhoAreYou,
    country: string,
    hearAboutFrom: HearAboutFrom,
    createdAt?: Date,
    updatedAt?: Date,
    properties?: PropertyObj[],
    bookmarks: Bookmark[],
    is_verified?: boolean,
    is_accepted?: boolean,
    origin?: 'hubspot' | 'cms',
    pendingToDelete?: boolean,
    additionalInformation?: NonIVOUserAdditionalInformation,
    [key: string]: any
}

export type NonIVOUserAdditionalInformation = {
    street: string,
    streetAddition: string,
    postalCode: string
    poBox: string
    city: string
    country: string
    estate: string
}
export interface UsersState {
    users: User[]
}


export interface QueryUsers extends Action {
    type: typeof QUERY_USERS,
    payload: User[]
}

export interface VerifyToggle extends Action {
    type: typeof VERIFY_TOGGLE,
    payload: {userId: string, is_verified: boolean}
}

export interface UpdateUser extends Action {
    type: typeof UPDATE_USER,
    payload: {userId: string, updated: User}
}

export interface DeleteUser extends Action {
    type: typeof DELETE_USER,
    payload: {userId: string}
}

export const INITIAL_STATE: UsersState = {
    users: []
};


