import {
  memo,
  FC,
  useState,
  useCallback,
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  LegacyRef,
  useRef,
} from 'react';
import Input from '../../../components/Input';

interface AutoUpdateInputProps {
  onUpdate: (newValue: string) => void;
  value: string;
  containerClass?: string;
  label?: string;
}

const enterKey = 13;

export const AutoUpdateInput: FC<AutoUpdateInputProps> = memo(({ onUpdate, value, containerClass, label }) => {
  const [displayedValue, setDisplayedValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDisplayedValue(event.target.value);
  }, []);

  const onBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    onUpdate(displayedValue);
  }, [onUpdate, displayedValue]);

  const onKeyUp = useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
    if (event.keyCode === enterKey) {
      event.preventDefault();
      inputRef.current?.blur();
    }
  }, []);

  return (
    <Input
      label={label}
      value={displayedValue}
      onChange={onChange}
      containerClass={containerClass}
      inputProps={{ onBlur, onKeyUp, ref: inputRef as LegacyRef<HTMLInputElement> }}
    />
  );
});
