import React from 'react'

type Props = {
    label: string
}

const SubHeader :React.FC<Props> = ({label}) => {

    return (
        <>
            <hr/>
            <p className="h4 text-center">{label}</p>
            <hr/>
        </>
    )
}

export default SubHeader