import {Action} from "redux";

export const QUERY_ADMINS = 'QUERY_ADMINS'
export const ADD_ADMIN = 'ADD_ADMIN'
export const UPDATE_ADMIN = 'UPDATE_ADMIN'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const CHANGE_ROLE = 'CHANGE_ROLE'

export type AdminType = {
    _id?: string,
    firstName: string,
    lastName: string,
    email:string,
    role: string
}

export interface QueryAdmins extends Action {
    type: typeof QUERY_ADMINS,
    payload: AdminType[]
}

export interface DeleteAdmin extends Action {
    type: typeof DELETE_ADMIN,
    payload: string
}

export interface AddAdmin extends Action {
    type: typeof ADD_ADMIN,
    payload: AdminType
}

export interface UpdateAdmin extends Action {
    type: typeof UPDATE_ADMIN,
    payload: {adminId: string, updated: AdminType}
}

export interface ChangeAdminRole extends Action {
    type: typeof CHANGE_ROLE,
    payload: {adminId: string, role: string}
}

export interface AdminsState {
    admins: AdminType[]
}

export const INITIAL_STATE: AdminsState = {
    admins: []
};

