import React, {ChangeEvent, MouseEvent} from 'react'
import {Can} from "../../../casl/Can";
import ConfirmationModal from "../../../modal/ConfirmationModal";
import {RolesAPI} from "../../../api/roleApi";
import {useDispatch, useSelector} from "react-redux";
import {rolesSelector} from "../../../store/roles/selectors";
import {adminsSelector} from "../../../store/admin/selectors";
import {cloneDeep} from "lodash";
import {AdminType, ChangeAdminRole, DeleteAdmin} from "../../../store/admin/types";
import {changeAdminRole, deleteAdmin} from "../../../store/admin/actions";

type AdminsTableProps = {
    addAdminBtnClick?: (...args: any) => any,
    editBtnClick?: (adminId: string) => any,
}

const AdminsTable: React.FC<AdminsTableProps> = ({
                                                     addAdminBtnClick = () => {},
                                                     editBtnClick = () => {}
                                                 }) => {
    const [hasError, setHasError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const [adminsLocal, setAdminsLocal] = React.useState<AdminType[]>([])
    const [adminToDelete, setAdminToDelete] = React.useState<string>('')
    const roles = useSelector(rolesSelector)
    const admins = useSelector(adminsSelector)
    const dispatch = useDispatch()

    React.useEffect(() => {
        setAdminsLocal(cloneDeep(admins))
    }, [admins])

    const removeAdmin = (e: MouseEvent<HTMLButtonElement>, id: string) => {
        e.preventDefault()
        RolesAPI.removeAdmin(id)
            .then(() => {
                dispatch<DeleteAdmin>(deleteAdmin(id))
                setHasError(false)
                setErrorMessage('')
            })
            .catch(e => {
                setErrorMessage(e.message)
                setHasError(true)
            })
    }
    const onChangeRole = (adminId: string, e: ChangeEvent<HTMLSelectElement>) => {
        const role = e.target.value
        RolesAPI.changeAdminRole(adminId, role)
            .then(() => {
                setHasError(false)
                setErrorMessage('')
                // alert('Admin user role updated')
                dispatch<ChangeAdminRole>(changeAdminRole(adminId, role))
            })
            .catch((e) => {
                setHasError(true)
                setErrorMessage(e.message)
            })


    }
    const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const qTerm = e.target.value.toLowerCase()

        setAdminsLocal(() => {
            return admins.filter(admin => {
                let queryString = ''
                admin.firstName && (queryString += admin.firstName.toLowerCase() + ' ')
                admin.lastName && (queryString += admin.lastName.toLowerCase() + ' ')
                admin.email && (queryString += admin.email.toLowerCase() + ' ')
                admin.role && (queryString += admin.role.toLowerCase() + ' ')

                return queryString.indexOf(qTerm) !== -1
            })
        })

    };

    if (hasError && !!errorMessage) {
        return (
            <div className="alert alert-warning text-center py-4" role="alert">
                <h1 className='mb-0'>{errorMessage}</h1>
                <span>Please contact us for questions (info@estating.com)</span>
            </div>
        )
    }

    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='col'>
                        <div className='row align-items-center'>
                            <div className='col-auto pr-0'>
                                <span className='fe fe-search text-muted'></span>
                            </div>
                            <div className='col'>
                                <input
                                    type='search'
                                    onChange={handleSearch}
                                    className='form-control form-control-flush search'
                                    placeholder='Search'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive ml-0 pt-0 pl-0 pr-0'>
                    <table className='table table-sm table-nowrap card-table table-bordered'>
                        <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            adminsLocal && adminsLocal.length > 0 ?
                                adminsLocal.map(admin => {
                                    return (
                                        <tr key={admin._id}>
                                            <td>{admin.firstName}</td>
                                            <td>{admin.lastName}</td>
                                            <td>{admin.email}</td>
                                            <td>
                                                <Can I='update' a='admin'>
                                                    <select className='form-control'
                                                            onChange={(e) => onChangeRole(admin._id || '', e)}
                                                            value={admin.role}>
                                                        <option value="" hidden>Select role</option>
                                                        {
                                                            roles.map(role => {
                                                                return <option value={role.role}
                                                                               key={role.role}>{role.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                </Can>
                                                <Can not I='update' a='admin'>
                                                    {admin.role}
                                                </Can>
                                            </td>
                                            <td className='text-center'>
                                                <div className='btn-group'>
                                                    <Can I='update' a='admin'>
                                                        <button className="btn btn-light btn-sm"
                                                                onClick={() => {
                                                                    editBtnClick(admin._id || '')
                                                                }}>
                                                            Edit
                                                        </button>
                                                    </Can>
                                                    <Can I='delete' a='admin'>
                                                        <button className="btn btn-danger btn-sm"
                                                                data-toggle="modal"
                                                                onClick={() => setAdminToDelete(admin._id || '')}
                                                                data-target="#delete-admin-user"
                                                        >
                                                            Delete
                                                        </button>
                                                    </Can>
                                                </div>

                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <Can I='create' a='admin'>
                <button className="btn btn-rounded-circle btn-success btn-bottom-right-fixed m-3"
                        onClick={addAdminBtnClick}>
                    <span className="fe fe-plus"></span>
                </button>
            </Can>
            <Can I='delete' a='admin'>
                <ConfirmationModal
                    id='delete-admin-user'
                    label='You want to delete this admin?'
                    message='Are you sure?'
                    danger={true}
                    handleYes={removeAdmin}
                    idToDelete={adminToDelete}
                />
            </Can>
        </>
    )
}

export default AdminsTable
