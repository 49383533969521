import {
    DELETE_USER,
    INITIAL_STATE,
    QUERY_USERS, UPDATE_USER, VERIFY_TOGGLE
} from './types';

export const usersReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    let users = [...state.users]

    switch (type) {
        case QUERY_USERS:
            return {
                users: payload
            }
        case VERIFY_TOGGLE:
            const {userId, is_verified} = payload
            return {
                users: users.map(u => {
                    if (u._id === userId) {
                        u.is_verified = is_verified
                    }

                    return u
                })
            }
        case UPDATE_USER:
            return {
                users: users.map(u => {
                    if (u._id === payload.userId) {
                        return payload.updated
                    }

                    return u
                })
            }
        case DELETE_USER:
            return {
                users: users.filter(user => user._id !== payload.userId)
            }
        default:
            return state;
    }
};
