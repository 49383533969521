import dayjs, { Dayjs } from 'dayjs';
import { memo, FC, useMemo, createContext, useCallback, useState, Dispatch, SetStateAction } from 'react';
import { styled } from 'styled-components';
import { Table } from './components/table';
import {
  calculateGlobalBounds,
  calculateTableWidth,
  getBackgroundColumnsDescription,
} from './utils/helpers/calculations';
import { PropertyTeaserType } from '../../store/property/types';
import Button from '../Button';
import { Toolbar } from './components/toolbar';
import { EventPopUp } from './components/event-pop-up-form';
import { createEventInitialValues } from './utils/constants';
import { Event } from '../../store/property/types';
import { CreateEventInitialvalues } from '../../types/property-teaser/timeline/create-event-initial-values';
import { TagDescription, fieldTagsColors } from '../../constants/fields-tags';

interface PropertiesTimelineGanttChartProps {
  property: PropertyTeaserType;
  onEventAdd: (event: Event) => void;
  onEventEdit: (targetIndex: number, updated: Event) => void;
  onEventDelete: (targetIndex: number) => void;
  fieldTags?: Array<TagDescription>;
}

export const PropertyTimelineGanttChart: FC<PropertiesTimelineGanttChartProps> = memo(
  ({ property, onEventAdd, onEventEdit, onEventDelete, fieldTags }) => {
    const { globalStart, globalEnd, teaserWithDateEvents } = useMemo(() => calculateGlobalBounds(property), [property]);

    const { totalMonths, backgroundColumnsDescription, totalDays, dateOneMonthBeforeStart } = useMemo(() => {
      return getBackgroundColumnsDescription(globalStart, globalEnd);
    }, [globalStart, globalEnd]);

    const tableWidth = useMemo(() => calculateTableWidth(totalMonths, totalDays), [totalMonths, totalDays]);

    const [isShow, setIsShow] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState<{ event: Event; index: number } | null>(null);

    const handleOpen = useCallback(() => setIsShow(true), []);

    const handleClose = useCallback(() => {
      setIsShow(false);
      setSelectedEvent(null);
    }, []);

    const _onEventAdd = useCallback(
      (newEvent: Event) => {
        handleClose();
        onEventAdd(newEvent);
      },
      [handleClose, onEventAdd]
    );

    const _onEventEdit = useCallback(
      (editedEvent: Event) => {
        if (!selectedEvent || !selectedEvent.event || !editedEvent) {
          return;
        }
        onEventEdit(selectedEvent.index, editedEvent);
        setSelectedEvent(null);
      },
      [selectedEvent, onEventEdit]
    );

    const _onEventDelete = useCallback(() => {
      if (!selectedEvent) {
        return;
      }

      onEventDelete(selectedEvent.index);
      setSelectedEvent(null);
    }, [selectedEvent, onEventDelete]);

    const contextValue = useMemo(
      () => ({
        backgroundColumnsDescription,
        tableWidth,
        teaserWithDateEvents,
        globalStart,
        dateOneMonthBeforeStart,
        setSelectedEvent,
      }),
      [
        backgroundColumnsDescription,
        tableWidth,
        teaserWithDateEvents,
        globalStart,
        dateOneMonthBeforeStart,
        setSelectedEvent,
      ]
    );

    return (
      <>
        <Container>
          <div
            className='d-flex flex-column position-absolute'
            style={{
              gap: '3px',
              minWidth: '8px',
              left: '-14px',
            }}
          >
            {fieldTags &&
              fieldTags.map((tagDescription) => {
                let mockUp;

                switch (typeof tagDescription) {
                  // this is enum value
                  case 'number':
                    const tagColor = fieldTagsColors[tagDescription].color;
                    mockUp = (
                      <div
                        style={{
                          background: tagColor,
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                        }}
                      />
                    );
                    break;
                }
                return mockUp;
              })}
          </div>
          <PropertiesTimelineGanttChartContainer>
            <TableContext.Provider value={contextValue}>
              <Table />
            </TableContext.Provider>
          </PropertiesTimelineGanttChartContainer>
          <ToolbarContainer>
            <Toolbar />
          </ToolbarContainer>
          <ButtonAddContainer>
            <Button className='btn btn-rounded-circle btn-success' onClick={handleOpen}>
              <span className='fe fe-plus'></span>
            </Button>
          </ButtonAddContainer>
        </Container>
        <EventPopUp
          isShow={!!selectedEvent || isShow}
          handleClose={handleClose}
          initialValues={(selectedEvent?.event || createEventInitialValues) as unknown as CreateEventInitialvalues}
          handleCreate={_onEventAdd}
          handleEdit={_onEventEdit}
          isEdit={!!selectedEvent}
          handleDelete={_onEventDelete}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.property.timeline.events === nextProps.property.timeline.events;
  }
);

interface TableContextType {
  backgroundColumnsDescription: Array<[string, Array<{ month: Dayjs; countDayInMonth: number }>]>;
  tableWidth: number;
  teaserWithDateEvents: PropertyTeaserType;
  globalStart: Dayjs;
  dateOneMonthBeforeStart: Dayjs;
  setSelectedEvent: Dispatch<SetStateAction<{ event: Event; index: number } | null>>;
}

export const TableContext = createContext<TableContextType>({
  backgroundColumnsDescription: [],
  tableWidth: 0,
  teaserWithDateEvents: {} as PropertyTeaserType,
  globalStart: dayjs(),
  dateOneMonthBeforeStart: dayjs(),
  setSelectedEvent: () => {},
});

const PropertiesTimelineGanttChartContainer = styled.div`
  grid-area: table;

  height: 100%;
  width: 100%;

  overflow: auto;
`;

const Container = styled.div`
  position: relative;

  display: grid;

  grid-template-areas:
    'table table toolbar'
    '. . btn-add';
`;

const ToolbarContainer = styled.div`
  grid-area: toolbar;
`;

const ButtonAddContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  grid-area: btn-add;
`;
