import {
    ADD_LOAN,
    DELETE_LOAN, INITIAL_STATE,
    QUERY_LOANS, UPDATE_LOAN
} from './types';

export const loansReducer = (state = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case QUERY_LOANS:
            return {
                loans: payload
            }
        case DELETE_LOAN:
            return {
                loans: state.loans.filter(masterdata => masterdata._id !== payload)
            }
        case ADD_LOAN:
            return {
                loans: [...state.loans, payload]
            }
        case UPDATE_LOAN:
            return {
                loans: [...state.loans.map(masterData => {
                    if (masterData._id === payload.loanId) {
                        return payload.updated
                    }
                    return masterData
                })]
            }
        default:
            return state;
    }
};
