import { memo, FC, useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { MultiSelect } from 'react-multi-select-component';
import { EducationMaterial } from '../../../types/education-materials/education-material';
import Section from '../../../components/Section';
import Input from '../../../components/Input';
import { PartialBy } from '../../../types/partial-by';
import TextArea from '../../../components/TextArea';
import MaterialFileDrop from './material-file-drop';
import Button from '../../../components/Button';
import { DroppedFile, FileCategory } from '../../../types/dropped-file';
import { LoadedFilePesenter } from './loaded-file-pesenter';
import { WhoAreYou } from '../../../types/user/who-are-you';
import { useHistory } from 'react-router-dom';
import { useGetEducationMaterialGroupsQuery } from '../../../services/education-material-groups';
import { MaterialsList } from './materials-list';
import Tags from '../../property/Tags';
import { useLanguage } from '../../../contexts/language/language-context';

interface EducationMaterialFormProps {
  initialValues: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> };
  isEdit?: boolean;
  onSubmit: (
    values: { [key: string]: PartialBy<EducationMaterial, '_id' | 'file' | 'mimeType'> },
    droppedFile: DroppedFile | null,
    customThumbnail: DroppedFile | null,
  ) => void;
}

const WhoAreYouValues: Array<string> = Object.values(WhoAreYou);
const selectOptions = WhoAreYouValues.map((value) => ({ label: value, value }));

export const EducationMaterialForm: FC<EducationMaterialFormProps> = memo(({ initialValues, isEdit, onSubmit }) => {
  const [droppedFile, setDroppedFile] = useState<DroppedFile | null>(null);
  const [_customThumbnail, set_CustomThumbnail] = useState<DroppedFile | null>(null);
  const [selectedVisibleFor, setSelectedVisibleFor] = useState(
    (initialValues.en.accessFor).reduce((accum, current) => {
      if (~WhoAreYouValues.indexOf(current)) {
        return [...accum, { label: current, value: current }];
      }
      return accum;
    }, [] as Array<{ label: string, value: string }>)
  );

  const { 
    data: educationMaterialGroups,
    isFetching: isEducationMaterialGroupsFetching
  } = useGetEducationMaterialGroupsQuery();

  const suggestedMaterialOptions = useMemo(() => {
    if (!educationMaterialGroups || isEducationMaterialGroupsFetching) {
      return [];
    }

    const out: Array<{ label: string, value: string }> = [];

    educationMaterialGroups.forEach(group => {
      const groupName = group.title;

      group.materials.forEach(material => {
        if (isEdit && material._id === initialValues.en._id) {
          return;
        }
        out.push({ label: `${groupName} > ${material.name}`, value: material._id });
      })
    });

    return out;
  }, [educationMaterialGroups, isEducationMaterialGroupsFetching, isEdit, initialValues.en._id]);

  const [selectedSuggestedMaterials, setSelectedSuggestedMaterials] 
  = useState<Array<{ label: string, value: string }>>([]);

  useEffect(() => {
    setSelectedSuggestedMaterials(
      isEdit
    ?
    suggestedMaterialOptions.reduce((accum, current) => {
      if (~initialValues.en.suggestedMaterials.findIndex((material) => (material as EducationMaterial)._id as string === current.value)) {
        return [...accum, current];
      }
      return accum;
    }, [] as {
      label: string;
      value: string;
      }[])
    :
    []
    )
  }, [initialValues.en.suggestedMaterials, initialValues.suggestedMaterials, isEdit, suggestedMaterialOptions]);

  const selectedSuggestedMaterialsPreview = useMemo(() => {
    const out: Array<EducationMaterial> = [];

    if (!educationMaterialGroups || (educationMaterialGroups && !educationMaterialGroups.length)) {
      return out;
    }

    const educationMaterials = educationMaterialGroups.reduce((acc, current) => {
      return acc.concat(current.materials);
    }, [] as Array<EducationMaterial>);

    const map: { [id: string]: EducationMaterial } = {};

    let findArrayCounter = 0;

    for (let i = 0; i < selectedSuggestedMaterials.length; i++) {
      const currentId = selectedSuggestedMaterials[i].value;

      if (Object.prototype.hasOwnProperty.call(map, currentId)) {
        out.push(map[currentId]);
      }

      if (findArrayCounter >= educationMaterials.length) {
        continue;
      }

      for (;findArrayCounter < educationMaterials.length; findArrayCounter++) {
        const currentMaterial = educationMaterials[findArrayCounter];

        if (currentMaterial._id === currentId) {
          out.push(currentMaterial);
          continue;
        }

        map[currentMaterial._id] = currentMaterial;
      }
    }

    return out;
  }, [selectedSuggestedMaterials, educationMaterialGroups]);

  const history = useHistory();

  const translationsFormiks = {
    en: useFormik({
      initialValues: initialValues.en,
      enableReinitialize: isEdit,
      onSubmit: () => {},
    }),
    es: useFormik({
      initialValues: initialValues.es,
      enableReinitialize: isEdit,
      onSubmit: () => {},
    })
  }

  const { currentLanguage } = useLanguage();

  const {
    handleChange: translatableHandleChange,
    setFieldValue: translatableSetFieldValue,
    values: translatableValues,
    // errors: translatableErrors,
    resetForm: resetTranslatableForm,
  } = translationsFormiks[currentLanguage];

  const {
    // handleChange: baseHandleChange,
    setFieldValue: baseSetFieldValue,
    values: baseValues,
    // errors: baseErrors,
    resetForm: resetBaseForm,
  } = translationsFormiks['en'];
  const { file, mimeType, customThumbnail, tags } = baseValues;

  const _onSubmit = useCallback(() => {
    onSubmit({ 
        en: {
          ...baseValues,
          accessFor: selectedVisibleFor.map(({ value }) => value),
          suggestedMaterials: selectedSuggestedMaterials.map(({ value }) => value),
        },
        es: {
          ...translationsFormiks.es.values,
          accessFor: selectedVisibleFor.map(({ value }) => value),
          suggestedMaterials: selectedSuggestedMaterials.map(({ value }) => value),
        },
      },
      droppedFile,
      _customThumbnail,
    );
      resetTranslatableForm();
      resetBaseForm();
      setSelectedVisibleFor([]);
      setSelectedSuggestedMaterials([])
      setDroppedFile(null);
      history.goBack();
  }, [
    _customThumbnail, 
    baseValues,
    droppedFile,
    history,
    onSubmit,
    resetBaseForm,
    resetTranslatableForm,
    selectedSuggestedMaterials,
    selectedVisibleFor,
    translationsFormiks.es.values
  ]);

  const onDescriptionChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    translatableSetFieldValue('description', event.target.value);
  },
  [translatableSetFieldValue]);

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='d-flex flex-1 justify-content-between align-items-center'>
            <h2 className='card-header-title'>{initialValues?._id ? 'Edit material' : 'Add material'}</h2>
          </div>
        </div>
        <Section label='Description'>
          <>
            <Input containerClass='mb-2' label='Name' name='name' value={translatableValues.name} onChange={translatableHandleChange} />
            <TextArea
              label='Description'
              className='w-100 mb-2'
              onChange={onDescriptionChange}
              value={translatableValues.description}
            />
            <div className='d-flex flex-wrap-small'>
              <label className='label-fix-width'>Visible for</label>
              <MultiSelect
                disableSearch
                className='w-100'
                options={selectOptions}
                value={selectedVisibleFor}
                onChange={setSelectedVisibleFor}
                labelledBy='Select'
              />
            </div>
            <Tags 
              tags={tags}
              callback={(updated) => {
                baseSetFieldValue('tags', updated);
              }}
            />
          </>
        </Section>

        <Section label='Related Materials'>
          <>
            <div className='d-flex flex-wrap-small'>
              <label className='label-fix-width'>Materials</label>
              <MultiSelect
                disableSearch
                className='w-100'
                options={suggestedMaterialOptions}
                value={selectedSuggestedMaterials}
                onChange={setSelectedSuggestedMaterials}
                labelledBy='Select'
              />
            </div>
            <div className='d-flex flex-wrap-small'>
              <label className='label-fix-width'>Preview</label>
              <MaterialsList materials={selectedSuggestedMaterialsPreview} readonly />
            </div>
          </>
        </Section>

        <Section label='File'>
          <>
            {!!file && !!mimeType && 
              <LoadedFilePesenter fileUrl={file} mimeType={mimeType} />
            }
            <MaterialFileDrop
              onDrop={setDroppedFile}
              droppedFile={droppedFile}
              category={FileCategory.EDUCATION_MATERIAL}
            />
          </>
        </Section>

        <Section label='Thumbnail'>
          <>
            {customThumbnail ? (
                !!mimeType && <LoadedFilePesenter fileUrl={customThumbnail} mimeType={'image/'} />
              ) 
                : 
              (
                <div
                  className={`text-center mb-4 p-1`}
                >
                  <span>No Thumbnail Selected</span>
                </div>
              )
            }
            <MaterialFileDrop
              onDrop={set_CustomThumbnail}
              droppedFile={_customThumbnail}
              category={FileCategory.EDUCATION_MATERIAL_THUMBNAIL}
              description="JPEG or PNG with suggested dimensions 300.x 120"
              accept={['image/jpeg', 'image/png']}
            />
          </>
        </Section>
      </div>
      <div className='bottom-fixed-save bg-light p-3 align-items-center flex-wrap'>
        <div className='pt-2'>
          <Button
            className='btn btn-success'
            label='Save'
            onClick={_onSubmit}
          />
        </div>
      </div>
    </>
  );
});
