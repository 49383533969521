import {
    SET_PROPERTIES,
    ADD_PROPERTY,
    SetProperties,
    AddProperty,
    DELETE_PROPERTY,
    DeleteProperty, UPDATE_DEFAULT_PROPERTY_MODE, UpdateDefaultPropertyMode,
} from './types';
import {PropertyTeaserType} from "../property/types";


export const setProperties = (properties: PropertyTeaserType[]): SetProperties => {
    return {
        type: SET_PROPERTIES,
        payload: properties,
    };
};
export const addProperty = (property: PropertyTeaserType): AddProperty => {
    return {
        type: ADD_PROPERTY,
        payload: property,
    };
};

export const deleteProperty = (id: string): DeleteProperty => {
    return {
        type: DELETE_PROPERTY,
        payload: id,
    };
};

export const updateDefaultPropertyMode = (id: string, isDefaultProperty: boolean): UpdateDefaultPropertyMode => {
    return {
        type: UPDATE_DEFAULT_PROPERTY_MODE,
        payload: {
            id,
            isDefaultProperty
        },
    };
};
