import { createSelector } from 'reselect';
import { PropertiesState } from './types';
import { RootState } from '..';

const dealsSelector: (state: RootState) => PropertiesState = (state: RootState) => state.properties;

export const selectProperties = createSelector(dealsSelector, (items) => items.properties);


export const selectCountOfProperties = createSelector(dealsSelector, (items) => items.properties.length);
