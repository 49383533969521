import api from "./estatingApi";
import {SubjectRawRule} from "@casl/ability/dist/types/RawRule";
import {RuleType} from "../store/rules/types";
import {RoleType} from "../store/roles/types";
import {AdminType} from "../store/admin/types";

export const RolesAPI = {
    queryRoles: () => {
        return new Promise<RoleType[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/role`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to get roles'))
            }
        })
    },
    addAdmin: (admin: AdminType & {password: string}) => {
        return new Promise<AdminType>(async (resolve, reject) => {
            try {
                const response = await api.post(`/add`, admin)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to add admin user'))
            }
        })
    },
    updateAdmin: (id: string, updated: AdminType) => {
        return new Promise<AdminType>(async (resolve, reject) => {
            try {
                const response = await api.put(`/${id}`, {updated})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to update admin user'))
            }
        })
    },
    removeAdmin: (adminId: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.delete(`/${adminId}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to remove admin'))
            }
        })
    },
    queryAdmins: (queryParams: {
        include_fields?: string,
    } = {}) => {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/`, {
                    params: queryParams
                })
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to get admin users'))
            }
        })
    },
    queryRules: () => {
        return new Promise<SubjectRawRule<string, string, any>[]>(async (resolve, reject) => {
            try {
                const response = await api.get(`/role/myself`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to get rules'))
            }
        })
    },

    deleteRule: (roleId: string, indexToDelete: number) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const response = await api.delete(`/role/rule/${roleId}/${indexToDelete}`)
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to update rules'))
            }
        })
    },

    changeAdminRole: (adminId: string, role: string) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const response = await api.patch(`/role/${adminId}`, {role})
                if (response.status === 200) {
                    resolve(response.data)
                }
            } catch (e) {
                reject(new Error(e?.response?.data?.message || 'Fail to update role'))
            }
        })
    },
    addRoleRule: (roleId: string, rule: RuleType) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const response = await api.post(`/role/addRule/${roleId}`, {rule})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to add rule'))
            }
        })
    },
    addRole: (role: Omit<RoleType, '_id'>) => {
        return new Promise<RoleType>(async (resolve, reject) => {
            try {
                const response = await api.post(`/role`, {role})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to add role'))
            }
        })
    },

    deleteRole: (roleId: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const response = await api.delete(`/role/${roleId}`)
                resolve(response.data)
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to delete role'))
            }
        })
    },

    copyRole: (roleId: string, options: {name: string, prefix: string}) => {
        return new Promise<RoleType>(async (resolve, reject) => {
            try {
                const response = await api.post(`/role/copy/${roleId}`, {options})
                resolve(response.data)
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to copy role'))
            }
        })
    },

    updateRole: (roleId: string, updated: RoleType) => {
        return new Promise<RoleType>(async (resolve, reject) => {
            try {
                const response = await api.put(`/role/${roleId}`, {updated})
                if (response.status === 200) {
                    resolve(response.data)
                }
                reject(new Error(response?.data?.message || 'Something went wrong'))
            } catch (e) {
                reject(new Error(e.response?.data?.message || 'Fail to update role'))
            }
        })
    },
}



